<template>
  <v-card width="1000">
    <v-tabs v-model="tab" bg-color="primary">
      <v-tab v-for="(reference, key) in storeReferences" :key="key" :value="key">
        {{ key }}
      </v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item v-for="(reference, key) in storeReferences" :key="key" :value="key">
          <v-row v-if="key === tab">
            <v-col cols="6">
              <v-card>
                <v-card-title>Options</v-card-title>
                <v-card-text>
                  <v-list>

                    <v-list-item
                        v-for="(item, i) in reference.options"
                        :key="i"
                        :value="item"
                        color="primary"
                        rounded="xl"
                    >
                      <template v-slot:prepend>
                        <v-list-item-title v-text="i" class="font-weight-bold"></v-list-item-title> : &nbsp;
                      </template>

                      <v-list-item-title v-text="item"></v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="6">
              <v-card>
                <v-card-title>Categories</v-card-title>
                <v-card-text>
                  <v-list>

                    <v-list-item
                        v-for="(item, i) in reference.data"
                        :key="i"
                        :value="item"
                        color="primary"
                        rounded="xl"
                    >
                      <template v-slot:prepend>
                        <v-list-item-title v-text="i" class="font-weight-bold"></v-list-item-title>
                      </template>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card>
                <v-card-title class="mb-10">
                  Categories Data
                  <v-btn
                      variant="tonal"
                      color="error"
                      class="m-2 ml-10"
                      @click="pull()"
                  >
                    <v-icon :icon="icons.pull" /> Pull
                  </v-btn>
                </v-card-title>
                <v-card-text v-for="(items, i) in reference.data" :key="i">
                  <v-chip color="red">{{ i }}</v-chip>
                  <v-btn
                      variant="tonal"
                      color="warning"
                      class="m-2 ml-10"
                      @click="push(i)"
                  >
                    <v-icon :icon="icons.push" /> Push
                  </v-btn>
                  <v-table>
                    <thead>
                    <tr>
                      <th class="text-left">
                        Id
                      </th>
                      <th class="text-left">
                        Status
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, key) in items" :key="item.name">
                      <td>{{ key }}</td>
                      <td>
                        <v-chip :color="getElementStatus(item)['color']">
                          {{ getElementStatus(item)['text'] }}
                        </v-chip>
                      </td>
                    </tr>
                    </tbody>
                  </v-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>


<script setup>
import {ref} from 'vue';
import {useStoreManager} from '../stores/storeManager';
import { mdiArrowCollapseDown, mdiArrowExpandUp } from '@mdi/js';

const icons = {
  pull: mdiArrowCollapseDown,
  push: mdiArrowExpandUp
};

const tab = ref(null);

const {storeReferences} = useStoreManager();
const storeManager = useStoreManager();

function getElementStatus(element) {
  if (element.hasOwnProperty('is_new')) {
    return {
      text: 'new',
      color: 'green'
    };
  }
  if (element.hasOwnProperty('to_remove')) {
    return {
      text: 'remove',
      color: 'red'
    };
  }
  if (element.hasOwnProperty('to_sync')) {
    return {
      text: 'sync',
      color: 'green'
    };
  }
  return {
    text: 'original',
    color: 'default'
  };
}

async function pull() {
  await storeManager.pull(tab.value);
}

async function push(category) {
  await storeManager.push(tab.value, category);
}

</script>

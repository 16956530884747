import {useApplicationStore} from "@/stores/applicationStore";

export default class Timer {
  constructor(label, level = 0) {
    if (useApplicationStore().showDebugMode) {
      this.label = label;
      this.timelineArray = [performance.now()];
      this.prefix = "  ".repeat(level);
      console.info(this.prefix+label+" started");
    }
  }
  addStep() {
    if (useApplicationStore().showDebugMode) {
      this.timelineArray.push(performance.now());
    }
  }

  echoStep(subStepName) {
    if (useApplicationStore().showDebugMode) {
      this.addStep();
      console.log(this.prefix + this.label, "> " + subStepName + " duration : " + (this.timelineArray[this.timelineArray.length - 1] - this.timelineArray[this.timelineArray.length - 2]) + " ms");
    }
  }

  echo() {
    if (useApplicationStore().showDebugMode) {
      this.addStep();
      console.info(this.prefix + this.label, "Total duration " + (this.timelineArray[this.timelineArray.length - 1] - this.timelineArray[0]) + " ms");
    }
  }
}
<template>
  <v-card variant="tonal" class="mb-3" :color="missionPlanningHasConcurrentReading?'red-darken-4':''">

    <mission-planning-debug-block
      v-if="showDebugMode"
      :debug-type="DEBUG.TYPE.MP.PLANNING_LIST"
      :mission-planning="missionPlanning"
      :point-of-sale="pointOfSale"
      :reading="currentReading"
      :concurrentReading="concurrentMissionPlanningReading"
    />

    <v-card-title>
      <span
        class="font-weight-bold"
        v-if="missionPlanning.id"
      >
        {{ dateHandler.convertUnixTimestampToHumanTime(missionPlanning.date_start) || $t('global.text.none.f') }}
        <v-icon
            :icon="mdiArrowRight"
            size="x-small"
            class="mb-1"
        />
        {{ dateHandler.convertUnixTimestampToHumanTime(missionPlanning.date_end) || $t('global.text.none.f') }} -&nbsp;
      </span>

      <visit-new-modal
        :page="PAGE.PLANNING"
        :pointOfSale="pointOfSale"
        v-if="!missionPlanning.transmittedReading && !missionPlanning.id && !currentReadingMissionPlanningId && configuration?.settings?.planning_type === SETTINGS.PLANNING_TYPE.FREE"
      >
        <template #trigger="slotProps">
          <span
              class="cursor-pointer"
              @click="slotProps.triggerAction"
          >
            {{ (pointOfSale?.code || $t('global.text.none.f')) }} -
            {{ (pointOfSale?.store?.name || $t('global.text.none.f')) + ' - ' + (pointOfSale?.name || $t('global.text.none.f')) }}
          </span>
        </template>
      </visit-new-modal>

      <span
        v-else
        :class="{ 'cursor-pointer': isRedirectionToMissionPlanningAllowed }"
        @click="goToMissionPlanningPage()"
      >
        {{ (pointOfSale?.code || $t('global.text.none.f')) }} -
        {{ (pointOfSale?.store?.name || $t('global.text.none.f')) + ' - ' + (pointOfSale?.name || $t('global.text.none.f')) }}
      </span>
      <v-dialog
          v-model="concurrentReadingDialog"
          width="auto"
      >
        <v-card>
          <v-card-text>
            {{}}
            {{ $t('mission-planning.warning.already-started-statement', {
               date: dateHandler.convertUnixTimestampToHuman(missionsPlannings?.[concurrentMissionPlanningReading?.mission_planning]?.date_start)
          })}}
          </v-card-text>
          <v-card-actions>
            <v-btn @click="concurrentReadingDialog = false">{{ $t('global.btn.close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>

    <v-card-subtitle>
      {{ generatePosAddressInfo(pointOfSale) || $t('global.text.none.f') }}
      <span v-if="pointOfSaleMission">
        - {{ (pointOfSaleMission.total_readings || 0) + '/' + (pointOfSaleMission.frequency || 1)}}
      </span>
    </v-card-subtitle>

    <v-card-text>
      <v-divider v-if="missionPlanning.id" />
      <div class="reading-block pt-2">
        <div v-if="!_.isEmpty(currentReadingCounters) && missionPlanning.id">
          <counter-capsule
            :icon="mdiCamera"
            :value-current="currentReadingCounters.pictures"
            :value-total="currentReadingCounters.requiredPictures"
            :color-capsule="chipsColor"
            :color-counter="chipsColor"
        />
          <counter-capsule
              :icon="mdiFormatListChecks"
              :value-current="currentReadingCounters.mandatoryAnswered"
              :value-total="currentReadingCounters.mandatory"
              :color-capsule="chipsColor"
              :color-counter="chipsColor"
          />
          &nbsp;
          <reading-status-send-report
              v-if="currentReading.status === READING.STATUS.NEW && currentReadingCounters?.countStatus === COUNTER.STATUS.READY"
              :reading="currentReading"
              :readingId="currentReading?.id"
          />
          <span
              v-if="currentReading.status === READING.STATUS.TO_BE_TRANSMITTED || currentReading.status === READING.STATUS.TRANSMITTED"
              :class="`mx-3 text-${chipsColor}`">{{ $t(`mission-planning.qst.status.label.${currentReading.status}`) }}</span>
        </div>
        <div v-else-if="missionPlanning.transmittedReading">
          <span class="mx-3">{{ $t(`mission-planning.qst.status.label.${READING.STATUS.TRANSMITTED}`) }}</span>
        </div>
        <v-spacer />


        <v-btn
          v-if="allowExpandPosHistory"
          :class="{'pos-tile-caret': !missionPlanning.id}"
          variant="tonal"
          size="x-small"
          :icon="!expandedDetails ? mdiChevronDown : mdiChevronUp"
          @click="expandedDetails = !expandedDetails"
        />
      </div>
    </v-card-text>

    <v-expand-transition>
      <div v-show="expandedDetails">
        <v-divider />
        
        <v-card-text>
          <v-table density="compact" v-if="readingsHistory">
            <tbody>
            <tr v-for="readingHistory in readingsHistory" :key="readingHistory.id">
              <td>
                <strong>
                  {{ dateHandler.convertUnixTimestampToHumanDate(readingHistory.date) }}
                </strong>
                <v-icon :icon="mdiCheckCircle" color="grey-darken-1" class="ml-5" />
              </td>
              <td>
                <statistic-indicators-wrapper
                    :counters="readingHistory?.counters"
                    :show-label="false"
                    :show-previous-reading="false"
                />
              </td>
            </tr>
            </tbody>
          </v-table>

          <v-divider v-if="readingsHistory.length" />

          <v-table density="compact" v-if="showCurrentReadingLine">
            <tbody>
            <tr :key="currentReading.id">
              <td>
                <strong>
                  {{ dateHandler.convertUnixTimestampToHumanDate(missionPlanning.date_start) }}
                </strong>
                <v-icon :icon="mdiSquareRoundedOutline" color="grey-darken-1" class="ml-5" />
              </td>
              <td>
                <statistic-indicators-wrapper
                    :counters="currentReadingCounters"
                    :show-label="false"
                    :show-previous-reading="false"
                />
              </td>
            </tr>
            </tbody>
          </v-table>

          <v-divider v-if="showCurrentReadingLine" />

          <v-table density="compact" v-if="missionMissionsPlanningsList.length">
            <tbody>
            <tr v-for="mMissionPlanning in missionMissionsPlanningsList" :key="mMissionPlanning.id">
              <td>
                <strong>
                  {{ dateHandler.convertUnixTimestampToHumanDate(mMissionPlanning.date_start) }}
                </strong>

                <v-icon
                  v-if="mMissionPlanning.date_start <= dateHandler.generateNowUnixTimestamp()"
                  :icon="mdiSquareRoundedOutline"
                  color="grey-darken-1"
                  class="ml-5"
                />

                <v-icon
                  v-else
                  :icon="mdiCalendarMonth"
                  color="grey-darken-1"
                  class="ml-5"
                />
              </td>
              <td>
                <div class="mx-5"></div>
              </td>
              <td>
<!--                <v-chip-->
<!--                  variant="tonal"-->
<!--                  color="error"-->
<!--                  class="mx-1 my-1"-->
<!--                  v-for="kpi in [' - ']"-->
<!--                >-->
<!--                  {{ kpi }}-->
<!--                </v-chip>-->
              </td>
              <td v-for="n in 2"></td>
            </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useRouter } from 'vue-router';

  import {
    mdiChevronDown,
    mdiChevronUp,
    mdiCheckCircle,
    mdiSquareRoundedOutline,
    mdiCalendarMonth,
    mdiCamera,
    mdiFormatListChecks,
    mdiArrowRight
  } from '@mdi/js';
  import dateHandler from '@/utils/dateHandler';
  import { generatePosAddressInfo } from "@/stores/manager/pointOfSalesManager";
  import { useStoreManager } from '@/stores/storeManager';
  import { useApplicationStore } from '@/stores/applicationStore';
  import MissionPlanningDebugBlock from '@/components/global/MissionPlanningDebugBlock.vue';
  import VisitNewModal from "../mission/appointments/VisitNewModal.vue";
  import { SETTINGS, READING, COUNTER, PAGE, DEBUG } from '@/constants';
  import _ from "lodash";
  import CounterCapsule from "../common/CounterCapsule.vue";
  import ReadingStatusSendReport from "../mission-planning/questionnaires/modals/ReadingStatusSendReport.vue";
  import StatisticIndicatorsWrapper from "@/components/common/StatisticIndicatorsWrapper.vue";

  const props = defineProps({
    missionPlanning: {
      type: Object,
      required: false,
      default: []
    },
    pointOfSale: {
      type: Object,
      required: true,
    },
    readingsHistory: {
      type: Object,
      required: true,
    },
    missionMissionsPlannings: {
      type: Object,
      required: true,
    },
    configuration: {
      type: Object,
      required: false,
    },
  });

  const expandedDetails = ref(false);
  const concurrentReadingDialog = ref(false);

  const router = useRouter();
  const storeManager = useStoreManager();
  const applicationStore = useApplicationStore();

  const missions = computed(() =>
    storeManager.getFromStoreByCategory('mission', 'missions') || {}
  );

  const readings = computed(() =>
    storeManager.getFromStoreByCategory('reading', 'reading') || {}
  );

  const missionsPlannings = computed(() =>
    storeManager.getFromStoreByCategory('mission', 'missionsPlannings') || {}
  );

  const showDebugMode = computed(() => applicationStore.showDebugMode);

  function allowExpandPosHistory() {
    return !(
      Object.values(props.readingsHistory).length > 0 ||
      Object.values(props.missionMissionsPlannings).length > 0
    );
  }

  function goToMissionPlanningPage() {
    if (!isRedirectionToMissionPlanningAllowed.value) {
      return;
    }
    if (missionPlanningHasConcurrentReading.value) {
      concurrentReadingDialog.value = true;
      return;
    }
    router.push({
      name: 'mission-planning-questionnaires',
      params: {
        id: props.missionPlanning.id || currentReadingMissionPlanningId.value
      },
    });
  }

  function getMissionsIdsFromPointOfSaleId(pointOfSaleId) {
    return Object.entries(missions.value)
        .filter(([key, mission]) => mission.pos.id.toString() === pointOfSaleId.toString())
        .map(([key, mission]) => key);
  }

  function getMissionsPlanningsIdsFromMissionsIds(missionIds) {
    if (missionIds) {
      return Object.entries(missionsPlannings.value)
        .filter(([key, missionPlanning]) => _.includes(missionIds, missionPlanning?.mission?.id?.toString()))
        .map(([key, missionPlanning]) => key);
    }
    return {};
  }

  function getReadingMissionPlanningIdFromMissionPlanningIds(missionPlanningsIds) {
    return Object.entries(readings.value)
        .filter(([key, reading]) => missionPlanningsIds.includes(reading.mission_planning.toString()))
        .map(([key, missionPlanning]) => missionPlanning)[0]?.mission_planning || null;
  }

  const missionsIds = computed(() => {
    return props.pointOfSale ? getMissionsIdsFromPointOfSaleId(props.pointOfSale.id) : {};
  })

  const missionsPlanningsIds = computed(() => {
    return getMissionsPlanningsIdsFromMissionsIds(missionsIds.value);
  })

  const showCurrentReadingLine = computed(() => {
    return currentReading.value && (currentReading.value.status === READING.STATUS.TO_BE_TRANSMITTED || currentReading.value.status === READING.STATUS.NEW) ;
  })

  const currentReadingMissionPlanningId = computed(() => {
    return getReadingMissionPlanningIdFromMissionPlanningIds(missionsPlanningsIds.value);
  })

  const currentReading = computed(() => {
    return Object.entries(readings.value)
        .map(([key, reading]) => {return {id: key, ...reading}})
        .find(iteratedReading => {
          return iteratedReading?.mission_planning?.toString() === (props.missionPlanning?.id?.toString() || currentReadingMissionPlanningId.value?.toString())
        })
  })

  const pointOfSaleMission = computed(() =>
    Object.values(missions.value).find(mission => mission.pos.id.toString() === props.pointOfSale?.id?.toString()));

  const currentReadingCounters = computed(() => currentReading.value?.counters || {});

  const chipsColor = computed(() => {
    if (currentReading.value?.status === READING.STATUS.TO_BE_TRANSMITTED) {
      return 'blue-darken-1';
    } else if (currentReading.value?.status === READING.STATUS.NEW) {
      if (currentReadingCounters.value?.countStatus === COUNTER.STATUS.NOT_READY) {
        return 'deep-orange-darken-1';
      } else if (currentReadingCounters.value?.countStatus === COUNTER.STATUS.READY) {
        return 'green-darken-1';
      }
    }
    return undefined;
  })

  const isRedirectionToMissionPlanningAllowed = computed(() => {
    return (!currentReading.value || ![READING.STATUS.TRANSMITTED, READING.STATUS.TO_BE_TRANSMITTED].includes(currentReading.value.status)) &&
        !props.missionPlanning.to_remove &&
        (props.missionPlanning.id || currentReadingMissionPlanningId.value) &&
        !props.missionPlanning.transmittedReading
  })

  const mappedMissionMissionsPlannings = computed(() =>
    props.missionMissionsPlannings.map(missionPlanning => missionPlanning.id) || []
  )

  const missionPlanningIdsFromReadings = Object.values(readings.value).map(item => item.mission_planning);

  const concurrentMissionPlanningReading = computed(() => {
    return Object.entries(readings.value)
        .map(([key, reading]) => {return {id: key, ...reading}})
        .find((iteratedReading) =>
          mappedMissionMissionsPlannings.value.some((a) => a == iteratedReading.mission_planning) &&
            props.missionPlanning.id != iteratedReading.mission_planning &&
            iteratedReading.status === READING.STATUS.NEW &&
            !_.includes(missionPlanningIdsFromReadings, props.missionPlanning.id)
        ) || {}
  })

  const missionMissionsPlanningsList = computed(() => {
    return props.missionMissionsPlannings
      .filter((mp) =>
          Number(mp.date_start) > dateHandler.generateNowUnixTimestamp() &&
          !mp.transmittedReading &&
          !(showCurrentReadingLine.value && currentReading.value?.mission_planning?.toString() === mp.id.toString())
      )
      .sort((a, b) => a.date_start - b.date_start);

  });

  const missionPlanningHasConcurrentReading = computed(() => {
    return isRedirectionToMissionPlanningAllowed.value && !_.isEmpty(concurrentMissionPlanningReading.value) && props.missionPlanning.id
  });
</script>

<style scoped>
.reading-block {
  display: flex;
  justify-content: space-between;
}

.v-card-text {
  padding: 0.5rem 1rem;
  line-height: 2rem;
}
</style>

<template>
  <v-dialog
    :model-value="modelValue"
    width="auto"
    min-width="40%"
  >
    <v-card>
      <v-card-title>
        {{ $t('mission-planning.actions.edit.title') }}
      </v-card-title>

      <v-divider />

      <v-form ref="form" validate-on="submit">
      <v-card-text>
        <v-alert v-if="warningAlert" class="mb-5" variant="tonal" type="warning" :text="warningAlert" />
          <v-col>
            <v-row>
              <date-picker-input
                :label="$t('appointment.new.modal.datepicker-label')"
                :rules="[v => !!v || $t('appointment.new.modal.validation.date')]"
                :min="minDate"
                :to-iso="true"
                v-model="selectedDate"
              />
            </v-row>

            <v-row>
              <time-picker-input
                component-class="mr-3"
                :label="$t('appointment.new.modal.timepicker-from')"
                :rules="[v => !!v || $t('appointment.new.modal.validation.from')]"
                :minutesIncrement="15"
                :minutesGridIncrement="15"
                v-model="selectedTimeFrom"
              />

              <time-picker-input
                component-class="ml-3"
                :label="$t('appointment.new.modal.timepicker-to')"
                :rules="[
                  (v) => !!v || t('appointment.new.modal.validation.to'),
                  (v) => dateHandler.transformStdDateToUnix(selectedTimeFrom) < dateHandler.transformStdDateToUnix(selectedTimeTo) || t('appointment.new.modal.validation.need-higher-time')
                ]"
                :minutesIncrement="15"
                :minutesGridIncrement="15"
                v-model="selectedTimeTo"
              />
            </v-row>

            <v-row>
              <v-autocomplete
                :disabled="hasReading"
                :label="$t('mission-planning.actions.edit.form.pos')"
                :rules="[v => !!v || $t('mission-planning.actions.edit.validation.pos')]"
                :items="pointOfSalesList"
                :return-object="false"
                v-model="selectedPointOfSaleMission"
                item-title="text"
                item-value="value"
                density="compact"
                single-line
              />
            </v-row>
        </v-col>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn
          class="float-right"
          color="grey-darken-2"
          :prepend-icon="mdiClose"
          variant="tonal"
          @click="closeModal"
        >
          {{ $t('global.btn.cancel') }}
        </v-btn>

        <v-btn
          class="float-right"
          color="success"
          :prepend-icon="mdiCheck"
          variant="tonal"
          @click="validate"
        >
          {{ $t('global.btn.edit') }}
        </v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { mdiCheck, mdiClose } from '@mdi/js';
import {computed, ref} from 'vue';
import { useRouter } from 'vue-router';
import { useStoreManager } from '@/stores/storeManager';
import { getPointsOfSaleListForSelectIndexedByMissionId } from '@/stores/manager/pointOfSalesManager';
import { useI18n } from 'vue-i18n';
import DatePickerInput from '@/components/common/DatePickerInput.vue';
import TimePickerInput from '@/components/common/TimePickerInput.vue';
import dateHandler from '@/utils/dateHandler';
import moment from "moment/moment";
import { copyMissionsKeysInsideOfTheObject } from '@/stores/manager/missionManager';

const props = defineProps({
  missionPlanningId: {
    type: String,
    required: true,
  },
  missionPlanning: {
    type: Object,
    required: true,
  },
  mission: {
    type: Object,
    required: true,
  },
  pointsOfSale: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: Boolean,
    required: false,
    default: false
  },
  accessControl: {
    required: true,
  },
  hasReading: {
    type: Boolean,
    required: true,
  }
});

const emit = defineEmits(['update:modelValue']);
const router = useRouter();
const storeManager = useStoreManager();
const { t } = useI18n();

const form = ref(null);

const missions = computed(() => storeManager.getFromStoreByCategory('mission', 'missions'));

const selectedPointOfSaleMission = ref(props.missionPlanning?.mission?.id?.toString());
const warningAlert = ref(null);

const selectedDate = ref(null);
const selectedTimeFrom = ref(null);
const selectedTimeTo = ref(null);

const dateStart = ref(null);
const dateEnd = ref(null);

if (props.missionPlanning?.date_start) {
  selectedDate.value = dateHandler.convertUnixTimestampToDate(props.missionPlanning?.date_start);
  selectedTimeFrom.value = dateHandler.convertUnixTimestampToDateTime(props.missionPlanning?.date_start);
}

const pointOfSalesList = computed(() => getPointsOfSaleListForSelectIndexedByMissionId(
    props.pointsOfSale,
    copyMissionsKeysInsideOfTheObject(missions.value),
    props.mission?.configuration?.id
));

if (props.missionPlanning?.date_end) {
  selectedTimeTo.value = dateHandler.convertUnixTimestampToDateTime(props.missionPlanning?.date_end);
}

const validate = async () => {
  const { valid } = await form.value.validate();

  if (valid) editMissionPlanning();
}
const minDate = computed(() => {
  const defaultMinDate = moment()
  if (props.accessControl.edit_delete_visit_past) {
    defaultMinDate.subtract(2, 'days')
  }
  return defaultMinDate.format('YYYY-MM-DD');
});
const editMissionPlanning = () => {

    dateStart.value = concatenateDateWithHour(selectedDate.value, selectedTimeFrom.value);
    dateEnd.value = concatenateDateWithHour(selectedDate.value, selectedTimeTo.value);

    const changedMissionPlanning = ref(props.missionPlanning);
    changedMissionPlanning.value.date_start = dateStart.value;
    changedMissionPlanning.value.date_end = dateEnd.value;
    changedMissionPlanning.value.mission.id = parseInt(selectedPointOfSaleMission.value);

    storeManager.update('mission', 'missions_plannings', props.missionPlanningId, changedMissionPlanning.value);
    closeModal();
}

const concatenateDateWithHour = (date, time) => {
  const humanTime = window.$moment(time).format('HH:mm');
  const humanDate = window.$moment(date).format('YYYY-MM-DD');
  return dateHandler.transformHumanDateToUnix(humanDate + ' ' + humanTime);
}

const closeModal = () => {
  emit('update:modelValue', false);
}

const closeAlert = () => {
  setTimeout(() => {
    warningAlert.value = null;
  }, 3000);
}
</script>

import arrayHandler from '@/utils/arrayHandler';
import { useStoreManager } from '@/stores/storeManager';
import localForageService from '@/services/localForage';
import { TABLE } from '@/constants';
import moment from 'moment';
import imageHandler from '@/utils/imageHandler';
import dateHandler from '@/utils/dateHandler';
import { v4 as uuidv4 } from 'uuid';

export function filterThreadsByConfigId(threads, configurationId) {
  let result = {};
  if (threads) {
    Object.keys(threads).forEach((threadId) => {
      let threadConfigId = parseInt(threads[threadId].configuration.id);
      if (threadConfigId === parseInt(configurationId)) {
        result[threadId] = threads[threadId];
      }
    });
  }
  return result;
}

export function extractThreadByPosId(threads, posId) {
  let result = null;
  Object.keys(threads).forEach((threadKey) => {
    if (threads[threadKey].pos.id.toString() === posId.toString()) {
      result = threadKey;
    }
  });
  return result;
}

export function countUnreadMessagesForUserByThreadId(messages, authUserDetails, threadId) {
  let count = 0;
  for (const key in messages) {
    if (messages.hasOwnProperty(key)) {
      if (
        arrayHandler.isInArrayNotStrict(threadId, messages[key].threads_ids) &&
        messages[key].read_by_user === false &&
        messages[key].author.id !== authUserDetails.id
      ) {
        count++;
      }
    }
  }
  return count;
}

export function threadByUserHasUnreadMessage(messages, authUserDetails, threadId) {
  return countUnreadMessagesForUserByThreadId(messages, authUserDetails, threadId) > 0;
}

export function configurationByUserHasUnreadMessage(threads, configurationId, authUserDetails, messages) {
  let result = false;
  if (threads) {
    for (const [threadId, thread] of Object.entries(threads)) {
      let threadConfigId = parseInt(threads[threadId].configuration.id);
      if (threadConfigId !== parseInt(configurationId)) {
        continue;
      }
      if (threadByUserHasUnreadMessage(messages, authUserDetails, threadId)) {
        return true;
      }
    }
  }
  return result;
}

export function extractMessageByThreadId(messages, threadId) {
  return messages[threadId] || {};
}

export function extractPointOfSaleById(pointOfSales, posId) {
  return pointOfSales[posId] || {};
}

export function countUnreadMessagesForUser(messages, authUserDetails, threads) {
  let count = 0;
  for (const key in messages) {
    if (messages.hasOwnProperty(key)) {
      const areMsgThreadsAvailableInStore = arrayHandler
        .hasCommonElement(messages[key].threads_ids.map(String), Object.keys(threads));
      if (
        messages[key].read_by_user === false &&
        messages[key].author.id !== authUserDetails.id &&
        areMsgThreadsAvailableInStore
      ) {
        count++;
      }
    }
  }
  return count;
}

/**
 *
 * @param pointOfSale
 * @returns string the uuid of the new generated thread
 */
export function addNewThread(id, pointOfSale) {
  const newThreadObject = {
    configuration: pointOfSale.configuration,
    pos: {
      id: id
    },
    last_message: []
  }

  return useStoreManager().add('postIt', 'threads', newThreadObject);
}

export async function addNewPicture(
  file,
  pictureOptions,
  userId,
  config
) {
  let result = false;
  try {
    const creationDate = dateHandler.generateNowUnixTimestamp();
    const uuid = `${moment().format("YYYY_MM_DD")}__${uuidv4()}`;
    const resizedFile = await imageHandler.resizeImage(file, pictureOptions.maxWidth);
    const newFile = new File([resizedFile], resizedFile.name, {type: resizedFile.type});
    await localForageService.storeData(TABLE.POSTIT_PICTURES, uuid, newFile);

    result = await addNewPostitPicture(uuid, creationDate, config)
  } catch (err) {
    console.log(err);
  }

  return result;
}

const addNewPostitPicture = (localForageFileId, creationDate, configuration) => {
  const storeManager = useStoreManager();
  const postitPicture = {
    creation_date: creationDate,
    configuration_id: configuration?.id,
    customer_id: configuration?.customer?.id,
    sent: false,
    file: localForageFileId
  };
  return storeManager.add('postIt', 'picture', postitPicture);
}
export function extractUnsentPostitPictures(postitPictures) {
  let result = {};
  if (postitPictures) {
    for (const uuid in postitPictures) {
      if (!postitPictures[uuid].sent) {
        result[uuid] = postitPictures[uuid];
      }
    }
  }
  return result;
}

export async function getImagesFromLocalForge(pictures) {
  let result = {};

  try {
    for (const [id, picture] of Object.entries(pictures)) {
      await localForageService
        .retrieveData(TABLE.POSTIT_PICTURES, picture.file)
        .then(function (img) {
          let blob = new Blob([img]);
          result[picture.file] = blob ? window.URL.createObjectURL(blob) : null;
        });
    }
  } catch (err) {
    console.log(err);
  }
  return result;
}

export async function removePicture(picture, pictureId) {
  let result = false;

  try {
    const storeManager = useStoreManager();
    await localForageService.removeItem(TABLE.POSTIT_PICTURES, picture.file)
    if (await storeManager.hardDelete('postIt', 'picture', pictureId)) {
      result = true;
    } else {
      console.error('An error occurred while deleting!');
    }
  } catch (err) {
    console.log(err);
  }

  return result;
}

<template>
  <v-card
    class="pa-5 mb-10 mission-block"
    variant="outlined"
  >
    <div class="block-title">{{ $t('mission.block.my-visit.title') }}</div>
    <div class="d-flex" v-if="presenceRequired">
      <v-switch
        class="w-50"
        v-model="visit.started"
        color="success"
        :label="visitStartLabel"
        @update:modelValue="visitStarted"
      ></v-switch>
      <v-switch
        class="w-50"
        v-model="visit.finished"
        color="success"
        :disabled="!visit.started"
        :label="visitEndLabel"
        @update:modelValue="visitFinished"
      ></v-switch>
    </div>
    <v-card-actions>
      <v-btn
        class="mt-3 text-subtitle-1"
        :prepend-icon="mdiListBox"
        variant="outlined"
        href="/"
        :disabled="presenceRequired && !visit.started"
      >
        <template v-slot:prepend>
          <v-icon color="blue" size="large"></v-icon>
        </template>
        <span class="font-weight-bold">{{ $t('mission.block.my-visit.btn') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import { mdiListBox } from '@mdi/js';
import {computed, inject, reactive} from "vue";
import dateHandler from "@/utils/dateHandler";
import { useStoreManager } from '@/stores/storeManager';
import { useApplicationStore } from '@/stores/applicationStore';
import { useI18n } from "vue-i18n";

const currentMissionPlanningId = inject('currentMissionPlanningId');
const storeManager = useStoreManager();
const missionPlanning = computed(() =>
  storeManager.getFromStoreByCategory('mission', 'missionsPlannings')[currentMissionPlanningId.value] || {}
);
const mission = computed(() =>
  storeManager.getFromStoreByCategory('mission', 'missions')[missionPlanning.value.mission.id] || {}
);
const presenceRequired = computed(() => {
  let configuration = storeManager.getFromStoreByCategory('configuration', 'configurations')[mission.value.configuration.id];
  return configuration.settings.presence_required;
});
const visit = reactive({
  started: !!missionPlanning.value.presence_start?.date,
  finished: !!missionPlanning.value.presence_end?.date,
});
const { t } = useI18n();
const visitStartLabel = computed(() => {
  let label = t('mission.block.my-visit.start');
  if (visit.started) {
    label += ': ' + dateHandler.convertUnixTimestampToMoment(missionPlanning.value.presence_start.date).format("DD/MM/YYYY HH:mm");
  }
  return label;
});
const visitEndLabel = computed(() => {
  let label = t('mission.block.my-visit.end');
  if (visit.started && visit.finished) {
    label += ': ' + dateHandler.convertUnixTimestampToMoment(missionPlanning.value.presence_end.date).format("DD/MM/YYYY HH:mm");
  }
  return label;
});
const applicationStore = useApplicationStore();
function visitStarted() {
  if (visit.started) {
    missionPlanning.value.presence_start = {
      date: dateHandler.generateNowUnixTimestamp(),
      latitude: applicationStore.location.latitude,
      longitude: applicationStore.location.longitude
    };
  } else {
    visit.finished = false;
    missionPlanning.value.presence_start = null;
    missionPlanning.value.presence_end = null;
  }
  storeManager.update('mission', 'missions_plannings', currentMissionPlanningId.value, missionPlanning.value);
}
function visitFinished() {
  if (visit.finished) {
    missionPlanning.value.presence_end = {
      date: dateHandler.generateNowUnixTimestamp(),
      latitude: applicationStore.location.latitude,
      longitude: applicationStore.location.longitude
    };
  } else {
    missionPlanning.value.presence_end = null;
  }
  storeManager.update('mission', 'missions_plannings', currentMissionPlanningId.value, missionPlanning.value);
}
</script>
<template>
  <div class="missions">
    <div class="d-flex justify-space-between">
      <missions-filter :filter="filter" :missionsCount="missionsCount" />
    </div>
    <v-alert v-if="!missionsCount" class="my-2" variant="tonal" type="warning" :text="$t('planning.message.no-rdv')" />
    <v-timeline
        side="end"
        align="start"
        density="compact"
    >
      <v-timeline-item
          v-for="(groupedMissionsPlannings, groupDay) in groupMissionsByWeekDays(paginatedMissionsPlannings, filter)"
          :key="groupDay"
          :icon="mdiCalendarClock"
          fill-dot
          dot-color="transparent"
          width="100%"
      >
        <div class="text-h7 text-uppercase font-weight-bold my-2 text-blue-grey">{{ groupDay }}</div>
        <div v-for="missionPlanning in groupedMissionsPlannings" :key="missionPlanning.id">
          <planning-point-of-sale
              :class="{'opacity-05': missionPlanning.to_remove}"
              :missionPlanning="missionPlanning"
              :pointOfSale="filterPointsOfSaleByMission(missionPlanning.mission.id)"
              :readingsHistory="filterReadingsHistoryByPos(missionPlanning.mission.id)"
              :missionMissionsPlannings="getMissionsPlanningsByMission(missionPlanning)"
              :configuration="configurations[missions[missionPlanning.mission.id]?.configuration.id]"
          />
        </div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script setup>


import MissionsFilter from './MissionsFilter.vue';
import PlanningPointOfSale from './PlanningPointOfSale.vue';
import { computed, reactive } from 'vue';
import { mdiCalendarClock } from '@mdi/js';
import {
  filterMissionsByYearAndWeekDays,
  groupMissionsByWeekDays,
  extractMissionsPlanningsByMission
} from '@/stores/manager/missionManager';
import { filterReadingsByMissions} from '@/stores/manager/readingHistoryManager';
import { useStoreManager } from '@/stores/storeManager';

const props = defineProps({
  missionsPlannings: {
    type: Object,
    required: true,
  },
  missions: {
    type: Object,
    required: true,
  },
  pointOfSales: {
    type: Object,
    required: true,
  },
  readingsHistory: {
    type: Object,
    required: true,
  },
  filter: {
    type: Object,
    required: true,
  },
});

const storeManager = useStoreManager()


const missionsPlanningsList = computed(() => {
  return props.filter.selectedWeek.value
    ? filterMissionsByYearAndWeekDays(props.missionsPlannings, props.filter.selectedWeek.value, props.filter.selectedYear)
    : {};
});


const configurations = computed(() =>
  storeManager.getFromStoreByCategory('configuration', 'configurations')
);


const getMissionsPlanningsByMission = computed(() =>
        (missionPlanning) => extractMissionsPlanningsByMission(props.missionsPlannings, [parseInt(missionPlanning.mission.id)])
);


const paginatedMissionsPlannings = computed(() => Object.values(missionsPlanningsList.value));

const missionsCount = computed(() =>
  Object.values(missionsPlanningsList.value).length
);

function filterReadingsHistoryByPos(missionId) {
  return filterReadingsByMissions(props.readingsHistory, [missionId]);
}

function filterPointsOfSaleByMission(mission) {
  return props.pointOfSales[
    props.missions[mission]?.pos?.id
  ];
}
</script>
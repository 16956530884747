import { useApplicationStore } from '@/stores/applicationStore';
import { useI18n } from 'vue-i18n';
import { COMPARE } from '@/constants';

class DateHandler {

  setTranslator(translator) {
    this.t = translator
  }

  extractLocale() {
    const applicationStore = useApplicationStore();
    return applicationStore.locale || window.env.VITE_DEFAULT_LOCALE;
  }

  convertUnixTimestampToHuman(timestamp) {
    return timestamp ? window.$moment.unix(timestamp).format(this.t('global.locale.fullDatePattern')) : null;
  }

  convertUnixTimestampToStandardHuman(timestamp) {
    return timestamp ? window.$moment.unix(timestamp).format(this.t('global.locale.fullStdDatePattern')) : null;
  }

  convertUnixTimestampToHumanTime(timestamp) {
    return timestamp ? window.$moment.unix(timestamp).format(this.t('global.locale.timePattern')) : null;
  }

  convertUnixTimestampToHumanDate(timestamp) {
    return timestamp ? window.$moment.unix(timestamp).format(this.t('global.locale.onlyDatePattern')) : null;
  }

  convertDateToHumanDate(date) {
    return date ? date.format(this.t('global.locale.onlyDatePattern')) : date;
  }

  convertUnixTimestampToHumanWordsDate(timestamp) {
    const locale = this.extractLocale();
    window.$moment.updateLocale(`${locale}`, locale);
    return timestamp ? window.$moment.unix(timestamp).format(this.t('global.locale.datePattern')) : null;
  }

  convertUnixTimestampToDate(timestamp) {
    return timestamp ? window.$moment.unix(timestamp).format('YYYY-MM-DD') : null;
  }

  convertUnixTimestampToDateTime(timestamp) {
    return timestamp ? window.$moment.unix(timestamp).format('YYYY-MM-DD HH:mm') : null;
  }

  convertUnixTimestampToYear(timestamp) {
    return timestamp ? window.$moment.unix(timestamp).format('YYYY') : null;
  }

  convertUnixTimestampToMoment(timestamp) {
    return timestamp ? window.$moment.unix(timestamp) : null;
  }

  transformHumanDateToUnix(date) {
    return date ? window.$moment(date).unix() : null
  }

  transformStdDateToUnix(date) {
    return date ? window.$moment(date).unix() : null;
  }

  generateNowUnixTimestamp() {
    return (Math.floor(Date.now() / 1000));
  }

  splitStringInDates(datesString) {
    return datesString ? datesString.split(',') : null;
  }

  getCurrentWeekMondayAndSunday() {
    const today = window.$moment();

    const startOfWeek = today.clone().startOf('isoWeek').format('YYYY-MM-DD');
    const endOfWeek = today.clone().endOf('isoWeek').format('YYYY-MM-DD');

    return startOfWeek + ',' + endOfWeek;
  }

  getLocaleWeekDays(startWeekDay, endWeekDay) {
    const locale = this.extractLocale();
    window.$moment.updateLocale(`${locale}`, locale);
    const start = window.$moment(startWeekDay);
    const end   = window.$moment(endWeekDay);
    const days = {};

    while (start <= end) {
      days[start.format('YYYY-MM-DD')] = start.format(this.t('global.locale.datePattern'));
      start.add(1, 'day');
    }
    return days;
  }

  getWeekNumberForYear(year) {
    return window.$moment().year(year).week();
  }

  getTotalNumberOfWeeksInYear(year) {
    return window.$moment().year(year).isoWeeksInYear();
  }

  getNowWeekRangeByYear(year) {
    const currentDate = window.$moment().year(year);
    const currentWeekNumber = currentDate.isoWeek();
    const firstDayOfWeek = currentDate.clone().isoWeekday(1).isoWeek(currentWeekNumber);
    const lastDayOfWeek = firstDayOfWeek.clone().add(6, 'days');

    return firstDayOfWeek.format('YYYY-MM-DD') +','+ lastDayOfWeek.format('YYYY-MM-DD');
  }

  generateWeeksForYear(year) {
    const weekRanges = [];
    const weeksInYear = window.$moment().year(year).isoWeeksInYear();
    let week = 1;

    while (week <= weeksInYear) {
      const startDate = window.$moment().year(year).isoWeek(week).startOf('isoWeek');
      const endDate = window.$moment().year(year).isoWeek(week).endOf('isoWeek');

      weekRanges.push({
        weekNumber: week,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD')
      });

      week++;
    }
    return weekRanges;
  }

  daysDifference(timestamp) {
    const timestampMs = timestamp * 1000;
    const currentDate = new Date().getTime();
    const timeDifference = timestampMs - currentDate;
    return timeDifference / (1000 * 60 * 60 * 24);
  }

  compareTimestamp(timestamp, caseType) {
    const daysDifference = this.daysDifference(timestamp);
    switch (caseType) {
      case COMPARE.TIME.PAST_MORE_THAN_2_DAYS:
        return daysDifference < -2;
      case COMPARE.TIME.PAST_NOT_MORE_THAN_2_DAYS:
        return daysDifference >= -2 && daysDifference < 0;
      case COMPARE.TIME.FUTURE:
        return daysDifference >= 0;
      default:
        return false;
    }
  }

  calculateVisitTimeIntervals() {
    let currentDate = window.$moment();
    let currentHours = currentDate.hours();
    let currentMinutes = currentDate.minutes();

    let visitFrom = { hours: currentHours, minutes: currentMinutes };
    let visitTo = { hours: currentHours, minutes: currentMinutes };

    if (currentMinutes >= 0 && currentMinutes <= 15) {
      visitFrom.minutes = 15;
    } else if (currentMinutes >= 16 && currentMinutes <= 30) {
      visitFrom.minutes = 30;
    } else if (currentMinutes >= 31 && currentMinutes <= 45) {
      visitFrom.minutes = 45;
    } else {
      visitFrom.minutes = 0;
      visitFrom.hours += 1;
    }

    visitTo.hours = visitFrom.hours + 2;
    visitTo.minutes = visitFrom.minutes;

    return { visitFrom, visitTo };
  }
}

export default new DateHandler();

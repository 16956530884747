import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { ApiManager } from './api/ApiManager';
import {useApplicationStore} from "./applicationStore";
import {useRoute, useRouter} from "vue-router";
import _ from "lodash";
import {useStoreManager} from "./storeManager";
import { TABLE } from "@/constants";
import { removePicture } from "@/stores/manager/postItManager";
import imageHandler from "@/utils/imageHandler";
import {StoreManagerRepository} from "@/repositories";

export const usePostItStore = defineStore('postItStore',
  () => {
    const applicationStore = useApplicationStore();
    const userDetails = computed(() => applicationStore.userDetails || false);
    const route = useRoute();
    const router = useRouter();

    const storeCategories = {
      [userDetails.value.id]: {
          messages: {},
          threads: {},
          point_of_sales: {},
          picture: {},
      }
    };

    const apiPushOverrideFunction = async (payload) => {
      let response;
      payload.images = await imageHandler.extractImagesFromLocalForage(payload, TABLE.POSTIT_PICTURES);
      response = await StoreManagerRepository.push('postit', payload);
      delete response.data.data.images;
      return response.data.data;
    };

    const options = {
      apiManager: new ApiManager('postit', apiPushOverrideFunction),
      readonly: false,
      sync: true,
      allTime: false,
      persistentLocalId: false
    }

    const data = ref(storeCategories);

    const threads = computed(() => data.value[userDetails.value.id]?.threads || {});
    const messages = computed(() => data.value[userDetails.value.id]?.messages || {});
    const pointOfSales = computed(() => data.value[userDetails.value.id]?.point_of_sales || {});
    const picture = computed(() => data.value[userDetails.value.id]?.picture || {});

    const updateCurrentRouteThreadIds = (receivedData, sentData) => {
      if (!_.isEmpty(receivedData?.threads) && !_.isEmpty(sentData?.threads)) {
        const receivedThreads = receivedData.threads;
        const sentThreads = sentData.threads;
        let keysMatchings = [];
        Object.entries(receivedThreads).forEach(([newId, receivedThread]) => {
          const posId = receivedThread?.pos?.id?.toString();
          if (posId) {
            const foundMatchingPosInSentThreadsUuid = Object.entries(sentThreads).find(([uuid, sentThread]) => {
              return sentThread?.pos?.id?.toString() === posId;
            })[0];
            if (foundMatchingPosInSentThreadsUuid) {
              keysMatchings[foundMatchingPosInSentThreadsUuid] = newId;
            }
          }
        })
        Object.keys(keysMatchings).forEach(key => {
          if (route.path.includes(key) && route.name === 'thread-details') {
            const newPath = route.path.replace(key, keysMatchings[key]);
            router.replace(newPath);
          }
        });
      }
    }

    function forceKeepEmptyThreads(receivedData) {
      if (_.isEmpty(receivedData?.threads)) {
        return;
      }
      const receivedThreads = receivedData.threads;
      const existingMessagesIds = Object.values(messages.value).reduce(
        (acc, cVal) => new Set([...acc, ...cVal?.threads_ids]), new Set(),
      )
      for (const threadId of Object.keys(receivedThreads)) {
        if (!existingMessagesIds.has(parseInt(threadId))) {
          useStoreManager().getFromStoreByCategory('postIt', 'threads')[threadId].to_sync = true;
        }
      }
    }

    function removeLocalSentPictures(receivedData) {
      if (!_.isEmpty(receivedData?.picture)) {
        for (const [pictureId,pictureData] of Object.entries(receivedData.picture)) {
          if (!pictureData?.file) {
            continue;
          }
          removePicture(pictureData, pictureId);
        }
      }
    }

    function postPushActions(receivedData, sentData) {
      this.updateCurrentRouteThreadIds(receivedData, sentData);
      this.forceKeepEmptyThreads(receivedData);
      this.removeLocalSentPictures(receivedData);
    }

    function isPushPrevented(item, category) {
      // if postit image belongs to a message which is not sent yet
      return category === "picture" && item.sent !== true;
    }

    return {
      options,
      data,
      threads,
      messages,
      pointOfSales,
      picture,
      postPushActions,
      updateCurrentRouteThreadIds,
      forceKeepEmptyThreads,
      removeLocalSentPictures,
      isPushPrevented
    }
  },
  {
    persistedState: {
      overwrite: true
    }
  }
);

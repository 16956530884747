export function extractOptionsForSelects(storePosList, selectedConfiguration, data) {
  Object.keys(storePosList.value)
    .forEach((posKey) => {
      if (storePosList.value[posKey].customer.id === selectedConfiguration.customer.id) {
        storePosList.value[posKey].id = parseInt(posKey);
        data.posList.push(storePosList.value[posKey]);
        if (
          storePosList.value[posKey].store &&
          !data.filterStores.some(store => store.id === storePosList.value[posKey].store.id)
        ) {
          data.filterStores.push(storePosList.value[posKey].store);
        }

        let deptNumber = storePosList.value[posKey]?.postal_code?.slice(0, 2) || '';
        if (storePosList.value[posKey].postal_code && !data.filterDepartments.includes(deptNumber)) {
          data.filterDepartments.push(deptNumber);
        }
      }
    });
}

export function generatePosAddressInfo(pos) {
  if (!pos) return '';

  let result = '';
  if (pos.postal_code) {
    result += pos.postal_code + ' ';
  }
  if (pos.city) {
    result += pos.city + ' ';
  }
  if (pos.postal_code) {
    result += '(' + pos.postal_code.slice(0, 2) + ') ';
  }
  if (pos.pos_code) {
    result += '- PDV ' + pos.pos_code;
  }
  return result;
}

export function getPointsOfSaleListForSelectIndexedByMissionId(pointsOfSale, missions, configurationId) {
  return Object.values(missions)
    .filter(m => m?.configuration?.id.toString() === configurationId.toString())
    .map((m) => {
      return {
        'text': pointsOfSale[m?.pos.id?.toString()]?.name,
        'value': m.id?.toString()
      }
    });
}
export class PosItemCheck {
    types = {
        "POS-SIGN": ( ctxPos ) => ctxPos?.store?.sign?.id,
        "POS-STORE": ( ctxPos ) => ctxPos?.store?.id,
        "POS-REGION": ( ctxPos ) => ctxPos?.area?.root,
        "POS-SECTOR": ( ctxPos ) => ctxPos?.area?.id,
        "POS-TYPO": ( ctxPos ) => ctxPos?.typology?.id,
        "POS-NETWORK": ( ctxPos ) => ctxPos?.network?.id,
        "POS-CENTRAL": ( ctxPos ) => ctxPos?.central?.id,
    }

    messages = {
        "POS-SIGN": ( reverse ) => reverse ? "not sign" : "sign",
        "POS-STORE": ( reverse ) => reverse ? "not store" : "store",
        "POS-REGION": ( reverse ) => reverse ? "not region" : "region",
        "POS-SECTOR": ( reverse ) => reverse ? "not sector" : "sector",
        "POS-TYPO": ( reverse ) => reverse ? "not typology" : "typology",
        "POS-NETWORK": ( reverse ) => reverse ? "not network" : "network",
        "POS-CENTRAL": ( reverse ) => reverse ? "not central" : "central",
    }

    informations = {}

    constructor() {

    }

    process(item, context) {
        this.checkContext(context);
        const checkResult = item?.value.toString() === (this.types[item.type] ? this.types[item.type](context.pos)?.toString() : "");
        return {
            result: item.reverse ? !checkResult : checkResult,
            message: this.messages[item.type](item.reverse)
        };
    }

    checkContext(context) {
        if (!context.hasOwnProperty("pos")) {
            throw new Error("Context doesn't match requirements");
        }
    }
}
import moment from 'moment';
import i18n from "@/i18n";
import dateHandler from "../dateHandler";
export class FieldDateItemCheck {
    t = i18n.global.t

    types = {
        "FUTURE": ( ctx, itemValue, reverse ) => reverse ?
            moment(ctx.nowDatetime?.setHours(0,0,0,0)) > moment(ctx.currentValue).toDate() :
            ctx.nowDatetime < moment(ctx.currentValue).toDate(),
        "PASTNDAY": ( ctx, itemValue, reverse ) => reverse ?
            moment(ctx.nowDatetime?.setHours(0,0,0,0)).add(itemValue, 'days').toDate() > moment(ctx.currentValue).toDate() :
            moment(ctx.nowDatetime?.setHours(0,0,0,0)).subtract(itemValue, 'days').toDate() <= moment(ctx.currentValue).toDate(),
        "FUTURNDAY": ( ctx, itemValue, reverse ) => reverse ?
            moment(ctx.nowDatetime?.setHours(0,0,0,0)).subtract(itemValue, 'days').toDate() < moment(ctx.currentValue).toDate() :
            moment(ctx.nowDatetime?.setHours(0,0,0,0)).add(itemValue, 'days').toDate() >= moment(ctx.currentValue).toDate(),
    }

    messages = {
        "FUTURE": ( ctx, itemValue, reverse ) => reverse ?
          this.t('conditions-messages.field-date.not-future', {date:dateHandler.convertDateToHumanDate(moment(ctx.nowDatetime))}) :
          this.t('conditions-messages.field-date.future', {date:dateHandler.convertDateToHumanDate(moment(ctx.nowDatetime))}),
        "PASTNDAY": ( ctx, itemValue, reverse ) => reverse ?
          this.t('conditions-messages.field-date.not-pastnday', {date:dateHandler.convertDateToHumanDate(moment(ctx.nowDatetime).add(parseInt(itemValue)-1, 'days'))}) :
          this.t('conditions-messages.field-date.pastnday', {date:dateHandler.convertDateToHumanDate(moment(ctx.nowDatetime).subtract(parseInt(itemValue), 'days'))}),
        "FUTURNDAY": ( ctx, itemValue, reverse ) => reverse ?
          this.t('conditions-messages.field-date.not-futurnday', {date:dateHandler.convertDateToHumanDate(moment(ctx.nowDatetime).subtract(parseInt(itemValue), 'days'))}) :
          this.t('conditions-messages.field-date.futurnday', {date:dateHandler.convertDateToHumanDate(moment(ctx.nowDatetime).add(parseInt(itemValue)+1, 'days'))}),
    }

    informations = {
        "FUTURE": ( ctx, itemValue, reverse ) => { return reverse ?
          { max: moment(ctx.nowDatetime).subtract(1, 'days').format('YYYY-MM-DD') } :
          { min: moment(ctx.nowDatetime).add(1, 'days').format('YYYY-MM-DD') }
        },
        "PASTNDAY": ( ctx, itemValue, reverse ) => { return reverse ?
          { max: moment(ctx.nowDatetime?.setHours(0,0,0,0)).add(parseInt(itemValue)-1, 'days').format('YYYY-MM-DD') } :
          { min: moment(ctx.nowDatetime?.setHours(0,0,0,0)).subtract(parseInt(itemValue), 'days').format('YYYY-MM-DD') }
        },
        "FUTURNDAY": ( ctx, itemValue, reverse ) => { return reverse ?
          { min: moment(ctx.nowDatetime?.setHours(0,0,0,0)).subtract(parseInt(itemValue), 'days').format('YYYY-MM-DD') } :
          { max: moment(ctx.nowDatetime?.setHours(0,0,0,0)).add(parseInt(itemValue)+1, 'days').format('YYYY-MM-DD') }
        },
    }
    constructor() {

    }

    process(item, context) {
        this.checkContext(context);
        if (!context.currentValue) return false;
        const dateObject = context.currentValue.includes('/') ? this.t('global.locale.onlyDatePattern') : "YYYY-MM-DD";
        context.currentValue = moment(context.currentValue, dateObject, true)
        return {
            result: this.types[item.type](context, item.value, item.reverse),
            message: this.messages[item.type](context, item.value, item.reverse)
        };
    }

    buildInformations(item, context, previousInformations) {
        const itemInformations = this.informations[item.type](context, item.value, item.reverse);
        if (itemInformations.min && previousInformations.min) {
            itemInformations.min = moment.max(moment(itemInformations.min), moment(previousInformations.min)).format('YYYY-MM-DD');
        }
        if (itemInformations.max && previousInformations.max) {
            itemInformations.max = moment.min(moment(itemInformations.max), moment(previousInformations.max)).format('YYYY-MM-DD');
        }
        return {...previousInformations, ...itemInformations};
    }

    checkContext(context) {
        if (!(
            context.hasOwnProperty("nowDatetime") && context.nowDatetime instanceof Date &&
            context.hasOwnProperty("currentValue") && (
              !context.currentValue ||
              moment(context.currentValue, "YYYY-MM-DD", true).isValid() ||
              moment(context.currentValue, this.t('global.locale.onlyDatePattern'), true).isValid()
            )
        )) {
            throw new Error("Context doesn't match requirements");
        }
    }
}
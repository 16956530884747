import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import {useApplicationStore} from "./applicationStore";
import {ApiManager} from "./api/ApiManager";

export const useLogStore = defineStore(
  'logStore',
  () => {
    const applicationStore = useApplicationStore();
    const userDetails = computed(() => applicationStore.userDetails || false);
    const canSaveLogs = computed(() => applicationStore.saveLogs || false);
    const logs = ref([])

    function insertLog(
      logRef,
      message,
      type= null,
      entity= null,
      payloadIds= null,
      pulledData= null,
      since= null,
      initialStoreData = null,
      resultingData = null
    ) {
      if (!canSaveLogs.value) {
        return;
      }
      if (!logs.value[userDetails.value.id]) {
        logs.value[userDetails.value.id] = [];
      }
      logs.value[userDetails.value.id].push({
        date: new Date().toISOString(),
        logRef: logRef,
        message: message,
        type: type,
        entity: entity,
        payloadIds: payloadIds,
        pulledData: pulledData,
        since: since,
        initialStoreData: initialStoreData,
        resultingData: resultingData
      })
    }

    async function sendLogs() {
      const currentLogs = logs.value[userDetails.value.id];
      try {
        let data = false;
        const apiManager = new ApiManager('logs');
        data = await apiManager.push({logs:currentLogs});

        if (data) {
          return data;
        }
      } catch (error) {
        console.error('Error Sending Logs: ', error.message + ' - ' + error.request?.response);
        return false;
      }
      return false;
    }

    async function getRemoteLogsList() {
      try {
        let data = false;
        const apiManager = new ApiManager('logs');
        data = await apiManager.call();

        if (data) {
          return data;
        }
      } catch (error) {
        console.error('Error Getting Logs List: ', error.message + ' - ' + error.request?.response);
        return false;
      }
      return false;
    }

    async function getRemoteLog(id) {
      try {
        let data = false;
        const apiManager = new ApiManager('logs/' + id);
        data = await apiManager.call();

        if (data) {
          return data;
        }
      } catch (error) {
        console.error('Error Getting Logs List: ', error.message + ' - ' + error.request?.response);
        return false;
      }
      return false;
    }

    function clearLogs() {
      logs.value[userDetails.value.id] = [];
    }

    return {
      logs,
      insertLog,
      sendLogs,
      clearLogs,
      getRemoteLogsList,
      getRemoteLog
    }
  },
  {
    persistedState: {
      overwrite: true
    }
  }
)

import axios from 'axios';

import {AuthRepository} from "../index";
import {useApplicationStore} from "../../stores/applicationStore";
import {computed} from 'vue';
import router from "../../router";

class HttpClient {
  constructor() {
    this.instance = axios.create({
      baseURL: window.env.VITE_APP_API_URL || import.meta.env.VITE_APP_API_URL,
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    return this.init();
  }

  init() {
    // Apply request interceptors
    this.instance.interceptors.request.use(this.handleRequest);

    // Apply response interceptors
    this.instance.interceptors.response.use(
      this.handleResponseSuccess,
      this.handleResponseError.bind(this),
    );

    return this.instance;
  }

  handleRequest(config) {
    const applicationStore = useApplicationStore();
    const token = computed(() => applicationStore.userToken);

    if (token.value) {
      config.headers = {
        Authorization: `Bearer ${token.value}`,
      };
    }

    return config;
  }

  handleResponseSuccess(response) {
    return response;
  }

  handleResponseError(error) {
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          console.error(error.response.status, error.message);
          break;
        case 401: // authentication error handler
          return this.handle401Error(error);
        case 503:
          return this.handle503Error();
        default:
          console.log(error);
          break;
      }
    }

    return Promise.reject(error);
  }

  async handle401Error(error) {
    const applicationStore = useApplicationStore();
    const token = computed(() => applicationStore.userToken);
    const user = computed(() => applicationStore.userDetails);

    if (!token.value) {
      return Promise.reject(error);
    }

    await AuthRepository.refreshToken(user.value?.login, token.value)
      .then((response) => {
        if (response.data.data.token) {
          applicationStore.updateToken(response.data.data.token);
        } else {
          applicationStore.handleLogout();
        }
        const config = error.config;
        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }).catch((error) => {
        console.log(error);
        return Promise.reject(error);
      });
  }

  handle503Error() {
    // router.push({ name: 'Maintenance' });
  }
}

export default new HttpClient();

import Timer from "../timer";

export class StatisticIndicatorCalculator {
  formula = {
    percentage: {
      rule: 'percentage',
      calculation: (statisticIndicator, ctx, statisticIndicatorId) => {
        const baseQuestions = this.buildBaseQuestions(statisticIndicator, ctx, statisticIndicatorId)
        const totalQuestions = baseQuestions.length
        const questionsWithTrueAnswers = baseQuestions.filter(question => question.hasAnswer && question.hasTrueAnswer).length;
        const result =  totalQuestions ? (questionsWithTrueAnswers / totalQuestions) * 100 : 0;
        return statisticIndicator?.type === 'detail' ? result?.toFixed(2) : Math.floor(result || 0);
      },
      unit: '%'
    },
    average: {
      rule: 'average',
      calculation: (statisticIndicator, ctx, statisticIndicatorId) => {
        const baseAnswers = this.buildBaseAnswers(statisticIndicator, ctx, statisticIndicatorId)
        const totalAnswers = baseAnswers.length
        const sum = baseAnswers.reduce((a, b) => a + parseInt(b.value), 0)
        return (totalAnswers ? sum / totalAnswers : 0)?.toFixed(2);
      },
      unit: null
    },
    sum: {
      rule: 'sum',
      calculation: (statisticIndicator, ctx, statisticIndicatorId) => {
        const baseAnswers = this.buildBaseAnswers(statisticIndicator, ctx, statisticIndicatorId)
        return baseAnswers.reduce((a, b) => a + parseInt(b.value), 0)
      },
      unit: null
    }
  }

  calculate(statisticIndicators, context) {
    const statisticIndicatorTimer = new Timer('statisticIndicatorCalculator', 3)
    let statisticsOutput = {}
    if (statisticIndicators.length === 0) {
      return statisticsOutput
    }
    if (this.isIterable(statisticIndicators)) {
      statisticIndicatorTimer.echoStep('starting for loop')
      for (const [statisticIndicatorId, statisticIndicator] of statisticIndicators) {
        statisticIndicatorTimer.echoStep(statisticIndicatorId)
        if (!this.formula.hasOwnProperty(statisticIndicator?.formula)) {
          continue;
        }
        statisticsOutput[statisticIndicatorId] = {
          label: statisticIndicator?.name,
          position: statisticIndicator.position,
          result: this.formula[statisticIndicator.formula].calculation(statisticIndicator, context, statisticIndicatorId),
          unit: this.formula[statisticIndicator.formula].unit,
          type: statisticIndicator?.type || '',
          thresholds: statisticIndicator?.thresholds || []
        }
        statisticIndicatorTimer.echoStep(statisticIndicatorId+' finished')
      }
    }
    statisticIndicatorTimer.echo()
    return statisticsOutput
  }

  isIterable(obj) {
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function'
  }

  buildBaseAnswers(statisticIndicator, ctx, statisticIndicatorId) {
    const statIndicatorProductIds = ctx.statisticIndicatorsProductIds ? ctx.statisticIndicatorsProductIds[statisticIndicatorId] || [] : [];
    return ctx.answers.filter(
      answer => answer.question?.toString() === statisticIndicator?.question?.toString() &&
      (
        (
          statIndicatorProductIds.length > 0 &&
          statIndicatorProductIds.includes(parseInt(answer.attachment_id)) &&
          answer.attachment_type === "by_product"
        ) || !statIndicatorProductIds.length > 0
      )
    )
  }

  buildBaseQuestions(statisticIndicator, ctx, statisticIndicatorId) {
    const statIndicatorProductIds = ctx.statisticIndicatorsProductIds ? ctx.statisticIndicatorsProductIds[statisticIndicatorId] || [] : [];
    return ctx.questionsConditionsWithAnswers.filter(
      question => question.question_id?.toString() === statisticIndicator?.question?.toString() &&
      question.visibility &&
      (
        (
          statIndicatorProductIds.length > 0 &&
          statIndicatorProductIds.map(pid => `by_product|${pid}`).some(v => question?.code.includes(v))
        ) || !statIndicatorProductIds.length > 0
      )
    )
  }
}
<template>
  <v-container>
    <div v-for="(question, index) in questions" key="index" class="mt-3">
      <v-form ref="singleFieldForms">
        <div
          v-if="question.data_type?.value === QST.INPUT.TYPE.TEXT"
          :class="{
            'text-disabled pointer-none': !question.visibility,
            'd-none': !showHiddenQuestions && !question.visibility
          }"
        >
          <div class="text-body-1 font-weight-bold">
            {{ question.label }} {{ question.mandatory && '*' || '' }}
            <v-progress-circular
                v-if="questionLoadingState[index]"
                size="x-small"
                indeterminate
            />
            <history-value
                :question="question"
                :missionReadingHistory="missionReadingHistory"
                :attachment-type="props.attachmentType"
                :attachment-id="props.attachmentId" />
          </div>

          <v-text-field
            v-model="formData[question.id]"
            type="text"
            density="compact"
            single-line
            :rules="inputRules(question)"
            :return-object="true"
            @update:model-value="() => {
              questionLoadingState[index] = true;
              debounceSubmit(index, question.id)
            }"
          />
        </div>

        <div
          v-if="question.data_type?.value === QST.INPUT.TYPE.NUMERIC"
          :class="{
            'text-disabled pointer-none': !question.visibility,
            'd-none': !showHiddenQuestions && !question.visibility
          }"
        >
          <div class="text-body-1 font-weight-bold">
            {{ question.label }} {{ question.mandatory && '*' || '' }}
            <v-progress-circular
                v-if="questionLoadingState[index]"
                size="x-small"
                indeterminate
            />
            <history-value
                :question="question"
                :missionReadingHistory="missionReadingHistory"
                :attachment-type="props.attachmentType"
                :attachment-id="props.attachmentId" />
          </div>

          <v-text-field
            v-model="formData[question.id]"
            type="number"
            v-bind="getQuestionInformations(question, formData[question.id])"
            density="compact"
            single-line
            :rules="inputRules(question)"
            :return-object="true"
            @update:model-value="() => {
              questionLoadingState[index] = true;
              debounceSubmit(index, question.id)
            }"
          />
        </div>

        <div
          v-if="question.data_type?.value === QST.INPUT.TYPE.TEXTAREA"
          :class="{
            'text-disabled pointer-none': !question.visibility,
            'd-none': !showHiddenQuestions && !question.visibility
          }"
        >
          <div class="text-body-1 font-weight-bold">
            {{ question.label }} {{ question.mandatory && '*' || '' }}
            <v-progress-circular
                v-if="questionLoadingState[index]"
                size="x-small"
                indeterminate
            />
            <history-value
                :question="question"
                :missionReadingHistory="missionReadingHistory"
                :attachment-type="props.attachmentType"
                :attachment-id="props.attachmentId" />
          </div>

          <v-textarea
            v-model="formData[question.id]"
            density="compact"
            single-line
            :rules="inputRules(question)"
            :return-object="true"
            @update:model-value="() => {
              questionLoadingState[index] = true;
              debounceSubmit(index, question.id)
            }"
          />
        </div>

        <div
          v-if="question.data_type?.value === QST.INPUT.TYPE.YES_NO"
          :class="{
            'text-disabled pointer-none': !question.visibility,
            'd-none': !showHiddenQuestions && !question.visibility
          }"
        >
          <div class="text-body-1 font-weight-bold">
            {{ question.label }} {{ question.mandatory && '*' || '' }}
            <v-progress-circular
                v-if="questionLoadingState[index]"
                size="x-small"
                indeterminate
            />
            <history-value
                :question="question"
                :missionReadingHistory="missionReadingHistory"
                :attachment-type="props.attachmentType"
                :attachment-id="props.attachmentId" />
          </div>

          <yes-no-toggle-input
            v-model="formData[question.id]"
            :rules="inputRules(question)"
            @update:model-value="() => {
              questionLoadingState[index] = true;
              submitField(index, question.id)
            }"
          />
        </div>

        <div
          v-if="question.data_type?.value === QST.INPUT.TYPE.DROPDOWN"
          :class="{
            'text-disabled pointer-none': !question.visibility,
            'd-none': !showHiddenQuestions && !question.visibility
          }"
        >
          <div class="text-body-1 font-weight-bold">
            {{ question.label }} {{ question.mandatory && '*' || '' }}
            <v-progress-circular
                v-if="questionLoadingState[index]"
                size="x-small"
                indeterminate
            />
            <history-value
                :question="question"
                :missionReadingHistory="missionReadingHistory"
                :attachment-type="props.attachmentType"
                :attachment-id="props.attachmentId" />
          </div>

          <v-select
            v-model="formData[question.id]"
            :items="question.value && question.value.split(',').map(item => item.trim()) || []"
            density="compact"
            single-line
            :rules="inputRules(question)"
            :return-object="true"
            :value="question.default_value"
            @update:model-value="() => {
              questionLoadingState[index] = true;
              submitField(index, question.id)
            }"
          />
        </div>

        <div
          v-if="question.data_type?.value === QST.INPUT.TYPE.DATE"
          :class="{
            'text-disabled pointer-none': !question.visibility,
            'd-none': !showHiddenQuestions && !question.visibility
          }"
        >
          <div class="text-body-1 font-weight-bold">
            {{ question.label }} {{ question.mandatory && '*' || '' }}
            <v-progress-circular
                v-if="questionLoadingState[index]"
                size="x-small"
                indeterminate
            />
            <history-value
                :question="question"
                :missionReadingHistory="missionReadingHistory"
                :attachment-type="props.attachmentType"
                :attachment-id="props.attachmentId" />
          </div>

          <date-picker-input
            v-model="formData[question.id]"
            :rules="inputRules(question)"
            :to-iso="true"
            v-bind="getQuestionInformations(question, formData[question.id])"
            @update:model-value="() => {
              questionLoadingState[index] = true;
              submitField(index, question.id)
            }"
          />
        </div>
      </v-form>
    </div>

    <div class="my-4 float-right">
      <v-row align="center">
        <v-col cols="auto">
          <v-btn
            v-if="existQuestionsWithHistory"
            variant="tonal"
            :prepend-icon="mdiHistory"
            color="info"
            @click="completeValuesFromHistory()"
          >
            {{ $t('mission-planning.qst.my-questionnaires.qst.btn.history-recovery') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
  <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      variant="elevated"
  >
    <div class="d-flex justify-center">
    {{ $t('mission-planning.qst.my-questionnaires.qst.history-recovery-notification', {answerCount: insertedAnswersFromHistoryCount}) }}
    </div>
  </v-snackbar>
</template>

<script setup>
import { mdiHistory } from '@mdi/js';
import { defineProps, ref, reactive, computed, inject, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { extractFormNamesFromQuestions } from '@/stores/manager/configurationManager';
import { useStoreManager } from '@/stores/storeManager';
import { useApplicationStore } from '@/stores/applicationStore';
import { READING, QST, VALUE } from '@/constants';
import {
  extractReadingIdByParams,
  extractReadingAnswersByReadingId,
  extractReadingAnswersByQuestionIdAndAttachment,
  createReadingIfNotExists
} from '@/stores/manager/readingManager';
import {
  extractQuestionsIds,
  checkIfExistsSomeQuestionsWithHistory
} from '@/stores/manager/configurationManager';
import DatePickerInput from '@/components/common/DatePickerInput.vue';
import HistoryValue from './HistoryValue.vue';
import dateHandler from '@/utils/dateHandler';
import _ from 'lodash';
import { createOrUpdateConditions, saveReadingCounters, clearInvalidAnswers } from '@/stores/manager/conditionsManager';
import { QuestionnaireConditionChecker } from '@/utils/conditionChecker/QuestionnaireConditionChecker';
import YesNoToggleInput from '@/components/common/YesNoToggleInput.vue';
import Timer from '@/utils/timer';

const { t } = useI18n();

const props = defineProps({
  questions: {
    type: Object,
    required: true
  },
  attachmentType: {
    type: String,
    required: false
  },
  attachmentId: {
    type: [Number, String],
    required: false
  },
});

const storeManager = useStoreManager();
const currentMissionPlanningId = inject('currentMissionPlanningId');
const currentConfiguration = inject('currentConfiguration');
const currentPointOfSale = inject('currentPointOfSale');
const singleFieldForms = ref([]);
const snackbar = ref(false);
const insertedAnswersFromHistoryCount = ref(0);

const applicationStore = useApplicationStore();
const userDetails = computed(() => applicationStore.userDetails);
const showHiddenQuestions = computed(() => applicationStore.showHiddenQuestions);

const questionLoadingState = reactive([]);

const missionPlanning = computed(() =>
  storeManager.getFromStoreByCategory('mission', 'missionsPlannings')[currentMissionPlanningId.value] || {}
);

const mission = computed(() =>
  storeManager.getFromStoreByCategory('mission', 'missions')[missionPlanning.value?.mission?.id] || {}
);

const readingsHistory = computed(() =>
  storeManager.getFromStoreByCategory('readingHistory', 'reading')
);

const readings = computed(() =>
  storeManager.getFromStoreByCategory('reading', 'reading') || {}
);

const readingAnswers = computed(() =>
  storeManager.getFromStoreByCategory('reading', 'answer') || {}
);

const missionReadingHistory = computed(() => {
  return Object.values(readingsHistory.value).find((rh) => parseInt(rh.mission) === parseInt(missionPlanning.value?.mission?.id))
});

const { foundAnswers } = getDataFound();

const currentReadingId = computed(() =>
    extractReadingIdByParams(
        readings.value,
        mission.value?.configuration?.id,
        currentMissionPlanningId.value,
        userDetails.value?.id
    )
);

const answers = computed(
    () =>
        Object.values(storeManager.getFromStoreByCategory('reading', 'answer'))
            .filter(answer => answer.reading?.toString() === currentReadingId.value?.toString())
        || {});


const formData = ref(
  extractFormNamesFromQuestions(
    props.questions,
    foundAnswers,
    props.attachmentType,
    props.attachmentId
  )
);

const existQuestionsWithHistory =
  checkIfExistsSomeQuestionsWithHistory(
    props.questions,
    missionReadingHistory?.value,
    props.attachmentType,
    props.attachmentId
  );

function getDataFound() {
  const foundReadingId =
    extractReadingIdByParams(
      readings.value,
      mission.value?.configuration?.id,
      currentMissionPlanningId.value,
      userDetails.value?.id
    );

  const foundAnswers =
    extractReadingAnswersByReadingId(
      readingAnswers.value,
      foundReadingId,
      extractQuestionsIds(props.questions)
    );

  return {
    foundReadingId,
    foundAnswers
  }
}

const currentAnswers = computed(() =>
    extractReadingAnswersByReadingId(
        readingAnswers.value,
        currentReadingId.value,
        extractQuestionsIds(props.questions)
    )
);

async function addNewReadingAnswer(questionId, readingId, creationDate) {
  const readingAnswer = {
    reading: readingId,
    question: parseInt(questionId),
    value: formData.value[questionId],
    attachment_type: props.attachmentType || READING.ATTACHMENT_TYPE.NONE,
    attachment_id: props.attachmentId || null,
    creation_date: creationDate
  };
  return await storeManager.add('reading', 'answer', readingAnswer);
}

let debounceSubmit = _.debounce(submitField, 300);

async function submitField(index, questionId) {
  const submitTimer = new Timer("Submit");
  await nextTick();
  const {valid} = await singleFieldForms.value[index].validate();
  if (valid) {
    let readingId;
    const creationDate = dateHandler.generateNowUnixTimestamp();
    const { foundAnswers, foundReadingId } = getDataFound();
    if (!foundReadingId) {
      readingId = await createReadingIfNotExists(
          '',
          mission.value?.configuration?.id,
          currentMissionPlanningId.value || null,
          userDetails.value.id,
          currentConfiguration.value?.data || null
      );
    } else {
      readingId = foundReadingId;
    }
    const foundAnswerForQuestion = await extractReadingAnswersByQuestionIdAndAttachment(
        foundAnswers,
        parseInt(questionId),
        props.attachmentType,
        props.attachmentId
    );
    if (_.isEmpty(foundAnswerForQuestion)) {
      await addNewReadingAnswer(questionId, readingId, creationDate);
    } else {
      const clonedFoundAnswerForQuestion = {
        ...foundAnswerForQuestion.obj
      };
      clonedFoundAnswerForQuestion.value = formData.value[questionId];
      storeManager.update('reading', 'answer', foundAnswerForQuestion.key, clonedFoundAnswerForQuestion);
    }
    updateConditions(readingId, index);
  } else {
    questionLoadingState[index] = false;
  }
  submitTimer.echo();
}

const answerAlreadyPresent = (answerHistory) => {
  return answers.value.some((answer) =>
      answer.question?.toString() === answerHistory.question_id?.toString() &&
      (answer.attachment_id?.toString() === answerHistory.related_to_id?.toString() || answerHistory.related_to_type === READING.ATTACHMENT_TYPE.NONE) &&
      (answer.attachment_type === answerHistory.related_to_type || answerHistory.related_to_type === READING.ATTACHMENT_TYPE.NONE) &&
      answer.value !== null
  )
}

async function addReadingAnswerFromHistory(questionId, readingId, answerValue, attachmentType, attachmentId) {
  const creationDate = dateHandler.generateNowUnixTimestamp();
  const readingAnswer = {
    reading: readingId,
    question: parseInt(questionId),
    value: answerValue,
    attachment_type: attachmentType,
    attachment_id: attachmentId,
    creation_date: creationDate
  };
  return await storeManager.add('reading', 'answer', readingAnswer);
}

function triggerFormsReload() {
  formData.value =
      extractFormNamesFromQuestions(
          props.questions,
          currentAnswers.value,
          props.attachmentType,
          props.attachmentId
      )
  ;
}

const recursiveClearInvalidAnswers = async (readingId) => {
  updateConditions(readingId);
  if (await clearInvalidAnswers(readingId)) {
    await recursiveClearInvalidAnswers(readingId);
  }
}

function countInsertedAnswers(insertedAnswers) {
  const answerKeys = Object.entries(storeManager.getFromStoreByCategory('reading', 'answer'))
          .filter( ([key, answer]) =>
              answer.reading?.toString() === currentReadingId.value?.toString() &&
              insertedAnswers.includes(key)
          )
      || {}
  return answerKeys.length;
}

async function completeValuesFromHistory() {
  insertedAnswersFromHistoryCount.value = 0;
  const answersHistory = missionReadingHistory.value?.answers;
  if (!answersHistory) {
    return;
  }
  if (!currentReadingId.value || currentReadingId.value === VALUE.DEFAULT) {
    await createReadingIfNotExists(
        currentReadingId.value,
        mission.value?.configuration?.id,
        currentMissionPlanningId.value || null,
        userDetails.value.id,
        currentConfiguration.value?.data || null
    );
  }
  const questionsIds = props.questions.map((q) => q.id.toString());
  const currentGroupAnswersHistory = Object.values(answersHistory).filter(
      (ah) => (ah.related_to_type === props.attachmentType || ah.related_to_type === READING.ATTACHMENT_TYPE.NONE)
          && (ah.related_to_id === props.attachmentId || !ah.related_to_id) &&
          questionsIds.includes(ah.question_id?.toString())
  );
  const insertedAnswers = [];
  for (const answerHistory of currentGroupAnswersHistory) {
    const question = Object.values(props.questions).find((q) => q.id?.toString() === answerHistory.question_id?.toString());
    if (!question || !question.history || answerAlreadyPresent(answerHistory)) {
      continue;
    }
    insertedAnswers.push( await addReadingAnswerFromHistory(
        answerHistory.question_id,
        currentReadingId.value,
        answerHistory.value,
        answerHistory.related_to_type,
        answerHistory.related_to_id
    ));
  }
  await recursiveClearInvalidAnswers(currentReadingId.value);
  triggerFormsReload();
  insertedAnswersFromHistoryCount.value = countInsertedAnswers(insertedAnswers);
  if (insertedAnswersFromHistoryCount.value > 0) snackbar.value = true;
}

function inputRules(qst) {
  return [
    (v) => qst.mandatory ? (!!v || v === false || t('global.validator.mandatoryField')) : true,
    (v) => conditionCheckerRule(qst, v)
  ];
}

function updateConditions(readingId, index = null ) {
  let initialContext = {
    currentValue: null,
    answers: Object.values(readingAnswers.value).filter((ra) => ra.reading === readingId),
    pos: currentPointOfSale.value,
    attachementType: 'none',
    attachementId: null,
    prod: null,
    nowDatetime: new Date()
  }
  const rawConfigurations = storeManager.getFromStoreByCategory('configuration', 'configurations')
  createOrUpdateConditions(
    rawConfigurations[mission.value?.configuration?.id],
    initialContext,
    readingId
  );
  saveReadingCounters(readingId, rawConfigurations[mission.value?.configuration?.id])
  if (index !== null) questionLoadingState[index] = false;
}

async function conditionCheckerRule(question, value) {
  return new Promise(resolve => {
    const questionnaireConditionChecker = new QuestionnaireConditionChecker()
    const context = buildContext(value)
    const questionValidityCondition = question.conditions['validity'];
    const validationResult = questionnaireConditionChecker.check(questionValidityCondition, context)
    return resolve(validationResult?.result === true || validationResult?.messages?.join(t('conditions-messages.or')));
  })
}

function getQuestionInformations(question, value) {
  const questionnaireConditionChecker = new QuestionnaireConditionChecker()
  const context = buildContext(value)
  const questionValidityCondition = question.conditions['validity'];
  return questionnaireConditionChecker.getInformations(questionValidityCondition, context)
}

function buildContext(value) {
  const products = currentConfiguration.value?.catalog?.product || []
  return {
    currentValue: value,
    answers: [],
    pos: currentPointOfSale.value,
    attachementType: props.attachmentType,
    attachementId: props.attachmentId,
    prod: props.attachmentType === READING.ATTACHMENT_TYPE.PRODUCT ? products[props.attachmentId] : null,
    nowDatetime: new Date()
  }
}

</script>

import { ACTION } from '@/constants';
import dateHandler from '@/utils/dateHandler';
import {COMPARE} from "../../constants";

export function checkMissionPlanningAccessControl(action, time, accessControl, missionPlanningDate) {
  if (time === 'past') {
    if (dateHandler.compareTimestamp(missionPlanningDate, COMPARE.TIME.PAST_NOT_MORE_THAN_2_DAYS)) {
      if ((action === ACTION.EDIT || action === ACTION.DELETE) && accessControl.edit_delete_visit_past) {
        return true;
      }
    }
  }
  if (time === 'future') {
    if (dateHandler.compareTimestamp(missionPlanningDate, 'future')) {
      if ((action === ACTION.EDIT || action === ACTION.DELETE) && accessControl.edit_delete_visit_future) {
        return true;
      }
    }
  }
  return false;
}
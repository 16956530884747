<template>
  <v-container>
    <v-row>
      <template v-for="[pictureId, picture] of Object.entries(pictures)">
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="4"
          v-if="!picture.to_remove"
        >
          <v-card
            :loading="loading"
            class="mx-auto"
            max-width="374"
          >
            <template v-slot:loader="{ isActive }">
              <v-progress-linear
                :active="isActive"
                color="warning"
                height="4"
                indeterminate
              />
            </template>

            <v-img
              cover
              height="250"
              :src="imagesUrls[picture.file]"
            />

            <v-card-item>
              <v-card-title>
                <span class="text-subtitle-1 font-weight-bold">
                  {{ dateHandler.convertUnixTimestampToHuman(picture.creation_date)  }}
                </span>
              </v-card-title>
            </v-card-item>

            <v-divider class="mx-4 mb-1" />

            <v-card-actions>
              <v-spacer />

              <add-edit-screen-by-picture
                :action="`edit`"
                :missionPlanningId="currentMissionPlanningId"
                :mission="mission"
                :userDetails="userDetails"
                :screen="screen"
                :groupedQuestions="groupedQuestions"
                :readingPictureId="pictureId"
                :readingPicture="picture"
                @picture-changed="onPictureChange"
              />

              <delete-screen-by-picture
                :readingPictureId="pictureId"
                :readingPicture="picture"
              />
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </v-row>

    <v-row>
      <v-divider class="mb-3" />

      <add-edit-screen-by-picture
        :action="`add`"
        :missionPlanningId="currentMissionPlanningId"
        :mission="mission"
        :userDetails="userDetails"
        :screen="screen"
        :groupedQuestions="groupedQuestions"
      />
    </v-row>
  </v-container>
</template>

<script setup>
import {defineProps, ref, computed, watchEffect, inject} from 'vue';
import { extractReadingIdByParams } from '@/stores/manager/readingManager';
import { useApplicationStore } from '@/stores/applicationStore';
import { useStoreManager } from '@/stores/storeManager';
import { extractReadingPicturesByReadingId } from '@/stores/manager/readingManager';
import DeleteScreenByPicture from '@/components/mission-planning/questionnaires/modals/DeleteScreenByPicture.vue';
import AddEditScreenByPicture from '@/components/mission-planning/questionnaires/modals/AddEditScreenByPicture.vue';
import dateHandler from '@/utils/dateHandler';
import { getImagesFromLocalForge } from '@/stores/manager/readingPicturesManager';

const props = defineProps({
  screen: {
    type: Object,
    required: true
  },
  groupedQuestions: {
    type: Object,
    required: false
  },
});

const loading = ref(true);
const imagesUrls = ref({});

const currentMissionPlanningId = inject('currentMissionPlanningId');

const storeManager = useStoreManager();
const applicationStore = useApplicationStore();
const userDetails = computed(() => applicationStore.userDetails);

const missionPlanning = computed(() =>
  storeManager.getFromStoreByCategory('mission', 'missionsPlannings')[currentMissionPlanningId.value] || {}
);

const mission = computed(() =>
  storeManager.getFromStoreByCategory('mission', 'missions')[missionPlanning.value?.mission?.id] || {}
);

const pointOfSale = computed(() =>
  storeManager.getFromStoreByCategory('mission', 'pointsOfSale')[mission.value?.pos?.id] || {}
);

const configuration = computed(() =>
  storeManager.getFromStoreByCategory('configuration', 'configurations')[mission.value?.configuration?.id] || {}
);

const readings = computed(() =>
  storeManager.getFromStoreByCategory('reading', 'reading') || {}
);

const readingPictures = computed(() =>
  storeManager.getFromStoreByCategory('reading', 'picture') || {}
);

const readingId = computed(() =>
  extractReadingIdByParams(
    readings.value,
    mission.value?.configuration?.id,
    currentMissionPlanningId.value,
    userDetails.value?.id
  )
);

const pictures = computed(() => extractReadingPicturesByReadingId(readingPictures.value, readingId.value));

const loadImages = async (pictures) => {
  await pictures.value;

  await getImagesFromLocalForge(pictures.value).then(urls => {
    imagesUrls.value = urls;
    loading.value = false;
  })
};

const onPictureChange = () => {
  loadImages(pictures);
};

watchEffect(() => {
  loadImages(pictures);
});
</script>
<template>
  <v-card
    class="pa-5 mb-10 mission-block"
    variant="outlined"
  >
    <div class="block-title">{{ $t('mission.block.plan-a-visit.title') }}</div>
    <v-card-title v-if="missionPlanningStartDate && missionPlanningEndDate">
      {{
        $t('mission.block.plan-a-visit.text', {
          day: missionPlanningStartDate.format("dddd"),
          date: missionPlanningStartDate.format("DD/MM/YYYY"),
          start_hour: missionPlanningStartDate.format("HH:mm"),
          end_hour: missionPlanningEndDate.format("HH:mm")
        })
      }}
    </v-card-title>
    <v-card-actions>
      <v-btn
        class="mt-3 text-subtitle-1"
        :prepend-icon="mdiListBox"
        variant="outlined"
        href="/"
      >
        <template v-slot:prepend>
          <v-icon color="blue" size="large"></v-icon>
        </template>
        <span class="font-weight-bold">{{ $t('mission.block.plan-a-visit.btn') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup>
import { mdiListBox } from '@mdi/js';
import { computed, toRefs } from "vue";
import dateHandler from "@/utils/dateHandler";
const props = defineProps({
  missionPlanning: {
    type: Object,
    required: true
  },
});
const { missionPlanning } = toRefs(props);
const missionPlanningStartDate = computed(() =>
  missionPlanning.value.date_start ? dateHandler.convertUnixTimestampToMoment(missionPlanning.value.date_start) : null
);
const missionPlanningEndDate = computed(() =>
  missionPlanning.value.date_end ? dateHandler.convertUnixTimestampToMoment(missionPlanning.value.date_end) : null
);
</script>
import { SCREEN } from '@/constants';

export function getItemColor(item) {
  if (item.answerStatus === SCREEN.STATUS.IN_PROGRESS) {
    return 'deep-orange-darken-1';
  }
  if (item.answerStatus === SCREEN.STATUS.FINISHED) {
    return 'green-darken-1';
  }
  return undefined;
}
<template>
  <v-dialog
      :model-value="showModal"
      @update:model-value="closeDialog()"
      max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ $t('contact.detail-title') }} (id : {{ contact.id }})</span>
      </v-card-title>

      <v-divider/>

      <v-card-text>
        <contact-form :contact="contact" :read-only="true"></contact-form>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue-darken-1"
            variant="text"
            @click="closeDialog()"
        >
          {{ $t('global.btn.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import ContactForm from "@/components/contact/contactForm.vue";

const props = defineProps({
  show: {
    type: Boolean,
    required: true
  },
  contact: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['closeDialog']);

function closeDialog() {
  emit('closeDialog');
}

const showModal = computed(() =>
    props.show
);

</script>

<template>
  <div class="mt-5">
    <pos-contacts
      :contacts="contacts"
    />
    <last-reading-infos
        :current-reading="currentReading"
        :last-reading="lastReadingHistory"
    />
    <last-reading-comments
        :configuration="configuration"
    />

    <last-reading-comments
      :configuration="configuration"
    />

    <informative-answers
        :missionPlanning="missionPlanning"
    />

    <flash-news
      :flashNews="flashNews"
      :pointOfSale="pointOfSale"
    />

    <perfect-store-chart
      :missionPlanning="missionPlanning"
    />
  </div>
</template>

<script setup>
import PosContacts from '@/components/mission-planning/info/PosContacts.vue';
import InformativeAnswers from '@/components/mission-planning/info/InformativeAnswers.vue';
import LastReadingComments from '@/components/mission-planning/info/LastReadingComments.vue';
import FlashNews from "@/components/mission-planning/info/FlashNews.vue";
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { useStoreManager } from '@/stores/storeManager';
import { extractLastReadingByDateAndMission } from '@/stores/manager/readingHistoryManager';
import {useApplicationStore} from "@/stores/applicationStore";
import {extractReadingIdByParams} from "@/stores/manager/readingManager";
import LastReadingInfos from "../../components/mission-planning/info/LastReadingInfos.vue";
import PerfectStoreChart from "../../components/mission-planning/info/PerfectStoreChart.vue";

const props = defineProps({
  missionPlanning: {
    type: Object,
    required: true
  },
  mission: {
    type: Object,
    required: true
  },
  pointOfSale: {
    type: Object,
    required: false,
    default: {}
  },
  configuration: {
    type: Object,
    required: true
  },
  readings: {
    type: Object,
    required: true,
  },
  flashNews: {
    type: Object,
    required: false,
    default: {}
  },
  contacts: {
    type: Object,
    required: false,
    default: {}
  }
});

const storeManager = useStoreManager();
const applicationStore = useApplicationStore();
const route = useRoute();
const currentMissionPlanningId = computed(() => route.params.id);
const missionPlanning = computed(() => {
  let mp = storeManager.getFromStoreByCategory('mission', 'missionsPlannings')[currentMissionPlanningId.value]
  return mp ? { ...mp, ['id']: currentMissionPlanningId.value } : {};
});

const readingsHistory = computed(() =>
    storeManager.getFromStoreByCategory('readingHistory', 'reading') || {}
);

const lastReadingHistory = computed(() =>
    extractLastReadingByDateAndMission(
        readingsHistory.value,
        missionPlanning.value?.mission?.id
    ) || {}
);
const readings = computed(() => storeManager.getFromStoreByCategory('reading', 'reading') || {})

const userDetails = computed(()=>applicationStore.userDetails);

const currentReadingId = computed(() =>
    extractReadingIdByParams(readings.value, props.configuration?.id, currentMissionPlanningId.value, userDetails.value?.id)
)
const currentReading = computed(() => readings?.value[currentReadingId.value] || {});

</script>
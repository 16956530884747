<template>
  <slot name="trigger" :triggerAction="openModal">
    <v-btn
      @click="openModal"
      size="small"
      color="surface-variant"
      variant="text"
      :title="$t('appointment.new.btn.title')"
      :aria-label="$t('appointment.new.btn.title')"
      :icon="mdiCalendarPlus"
    />
  </slot>
  <v-dialog
    v-model="dialog"
    width="auto"
    min-width="50%"
  >
    <v-card>
      <v-card-title>
        <span v-if="pointOfSale">{{configuration.name}} -&nbsp;</span> {{ $t('appointment.new.modal.title') }}
      </v-card-title>
      <v-form ref="form" validate-on="submit">
        <v-card-text>
          <v-container>
            <v-row v-if="!pointOfSale">
              <v-autocomplete
                :items="configurationsSelectList"
                :rules="[v => !!v || $t('appointment.new.modal.validation.configuration')]"
                item-title="name"
                v-model="configuration"
                :return-object="true"
                :label="$t('appointment.new.modal.configuration')"
                @update:modelValue="configurationChanged"
              />
            </v-row>
            <v-row>
              <v-autocomplete
                v-if="!pointOfSale"
                :label="$t('appointment.new.modal.pdv')"
                :items="items"
                item-title="title"
                item-value="value"
                v-model="selectedMissionId"
              >
                <template #item="data">
                  <v-list-subheader v-if="data.props.header" style="background-color: #dadada">
                    <strong class="text-uppercase">
                      {{ data.props.header }}
                    </strong>
                  </v-list-subheader>
                  <v-divider v-else-if="data.props.divider" />
                  <v-list-item v-else v-bind="data.props" />
                </template>
              </v-autocomplete>
            </v-row>
            <v-row>
              <v-combobox
                :label="$t('appointment.new.modal.type')"
                :rules="[v => !!v || $t('appointment.new.modal.validation.type')]"
                :items="appointmentTypes"
                v-model="appointmentType"
                item-title="text"
                item-value="value"
              />
            </v-row>
            <v-row>
              <v-radio-group
                v-if="configuration.settings.free_time_slots"
                v-model="isNow"
              >
                <v-radio :label="timeOptions.now" :value="true" />
                <v-radio :label="timeOptions.later" :value="false" />
              </v-radio-group>
            </v-row>
            <v-row>
              <date-picker-input
                v-if="isNow === false || !configuration.settings.free_time_slots"
                :label="$t('appointment.new.modal.datepicker-label')"
                :rules="[v => !!v || $t('appointment.new.modal.validation.date')]"
                v-model="visitDate"
                :min="moment().format('YYYY-MM-DD')"
              />
              <v-select
                class="ml-4"
                v-if="!configuration.settings.free_time_slots"
                :label="$t('appointment.new.modal.time-range')"
                :items="customTimeSlots"
                v-model="timeSlot"
                item-title="label"
                item-value="range"
                :rules="[v => !!v || $t('appointment.new.modal.validation.time')]"
                :no-data-text="$t('appointment.new.modal.no-slot')"
              />
            </v-row>
            <v-row>
              <time-picker-input
                component-class="mr-3"
                v-if="configuration.settings.free_time_slots"
                :label="$t('appointment.new.modal.timepicker-from')"
                :rules="[v => !!v || $t('appointment.new.modal.validation.from')]"
                :minutesIncrement="15"
                :minutesGridIncrement="15"
                v-model="visitFrom"
              />
              <time-picker-input
                component-class="ml-3"
                v-if="configuration.settings.free_time_slots"
                :label="$t('appointment.new.modal.timepicker-to')"
                :rules="[
                    (v) => !!v || t('appointment.new.modal.validation.to'),
                    (v) => dateHandler.transformStdDateToUnix(visitFrom) < dateHandler.transformStdDateToUnix(visitTo) || t('appointment.new.modal.validation.need-higher-time')
                  ]"
                :minutesIncrement="15"
                :minutesGridIncrement="15"
                v-model="visitTo"
              />
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            class="float-right"
            color="grey-darken-2"
            :prepend-icon="mdiClose"
            variant="tonal"
            @click="dialog = false"
          >
            {{ $t('global.btn.cancel') }}
          </v-btn>

          <v-btn
            class="float-right"
            color="success"
            :prepend-icon="mdiCheck"
            variant="tonal"
            @click="validate"
          >
            {{ $t('appointment.new.modal.create') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { mdiCheck, mdiClose, mdiCalendarPlus } from '@mdi/js';
import { computed, ref, watch, onMounted } from 'vue';
import { useStoreManager } from '@/stores/storeManager';
import { useI18n } from 'vue-i18n';
import { SETTINGS, PAGE } from '@/constants';
import DatePickerInput from '@/components/common/DatePickerInput.vue';
import TimePickerInput from '@/components/common/TimePickerInput.vue';
import moment from 'moment';
import dateHandler from '@/utils/dateHandler';
import {
  extractConfigurationIdByPosId,
  groupedPointsOfSaleList,
  copyMissionsKeysInsideOfTheObject,
  findMissionIdByPos,
  getFuturePlannedMissionsPosIdsFromMissionPlannings
} from '@/stores/manager/missionManager';
import { useRouter } from 'vue-router';
import _ from 'lodash';

const props = defineProps({
  pointOfSale: {
    type: Object,
    required: false,
    default: null
  },
  page: {
    type: String,
    required: false
  }
});

const storeManager = useStoreManager();
const dialog = ref(false);
const visitDate = ref(null);
const visitFrom = ref(null);
const visitTo = ref(null);
const timeSlot = ref(null);
const isNow = ref(true);
const form = ref(null);
const selectedMissionId = ref(null);
const appointmentType = ref(null);
const configuration = ref(null);
const {t} = useI18n();
const LocalTimeFormat = t('global.locale.timePattern');
const configurations = computed(() => storeManager.getFromStoreByCategory('configuration', 'configurations'));
const missionsPlannings = computed(() => storeManager.getFromStoreByCategory('mission', 'missionsPlannings'));
const missions = computed(() => storeManager.getFromStoreByCategory('mission', 'missions'));
const pointsOfSale = computed(() => storeManager.getFromStoreByCategory('mission', 'pointsOfSale'));
const router = useRouter();

const openModal = () => {
  dialog.value = true;
  const visitTimeIntervals = dateHandler.calculateVisitTimeIntervals();
  visitFrom.value = visitTimeIntervals.visitFrom;
  visitTo.value   = visitTimeIntervals.visitTo;
}
function resetForm() {
  visitDate.value = null;
  visitFrom.value = null;
  visitTo.value = null;
  timeSlot.value = null;
  appointmentType.value = null;
  isNow.value = true;
  if (!props.pointOfSale) selectedMissionId.value = null;
}

function getConfigurationFromPos() {
  const configurationId = extractConfigurationIdByPosId(missions.value, props.pointOfSale.id);
  return configurations.value[configurationId];
}

function configurationChanged() {
  selectedMissionId.value = null;
}

const appointmentTypes = computed(() =>
  [
    {text: t('appointment.new.modal.type-options.expected'), value: 'visit_rdv'},
    {text: t('appointment.new.modal.type-options.unexpected'), value: 'visit'}
  ]
);

const timeOptions = computed(() => {
  return {
    now: t('appointment.new.modal.time-options.now'),
    later: t('appointment.new.modal.time-options.later')
  }
});

const customTimeSlots = computed(() => {
  return configuration.value?.settings.time_slots.filter((slot) => slot.start_hour > 0).map(
    (slot) => {
      return {
        label: t('appointment.new.modal.timepicker-from') + ' ' +
          moment.unix(slot.start_hour).format(LocalTimeFormat) + ' ' +
          t('appointment.new.modal.timepicker-to') + ' ' +
          moment.unix(slot.end_hour).format(LocalTimeFormat),
        range: slot
      }
    }
  ) || []
});

const configurationsSelectList = computed(() =>
  Object.values(configurations.value).filter(conf => conf.settings.planning_type === SETTINGS.PLANNING_TYPE.FREE)
);

const items = computed(() => {
  const plannedMissionsPosIds = getFuturePlannedMissionsPosIdsFromMissionPlannings(
      missionsPlannings.value,
      missions.value);
  return groupedPointsOfSaleList(
      pointsOfSale.value,
      plannedMissionsPosIds,
      copyMissionsKeysInsideOfTheObject(missions.value),
      configuration.value?.id,
      t
  );
});

async function validate() {
  const {valid} = await form.value.validate();

  if (valid) saveVisit();
}

function getSlotDate(slotDate) {
  const definedDate = ((configuration.value.settings.free_time_slots && isNow.value) ? moment() : moment(visitDate.value)).startOf('day')
  return definedDate.add(slotDate.hour(), 'hours').add(slotDate.minutes(), 'minutes').unix()
}

function pickFirstConfigurationFromList() {
  if (_.isEmpty(configurations.value)) return null;
  const [confId, confValue] = Object.entries(configurations.value)[0];
  confValue['id'] = confId;
  return confValue;
}

function saveVisit() {
  const visit = {
    date_start: getSlotDate(configuration.value.settings.free_time_slots ? moment(visitFrom.value) : moment.unix(timeSlot.value.start_hour)),
    date_end: getSlotDate(configuration.value.settings.free_time_slots ? moment(visitTo.value) : moment.unix(timeSlot.value.end_hour)),
    state: '3',
    answer_informative: [],
    event_type: appointmentType.value.value,
    mission: {
      id: selectedMissionId.value,
    }
  }

  const missionPlanningId = storeManager.add('mission', 'missions_plannings', visit);
  dialog.value = false;
  if (props.page === PAGE.PLANNING) {
    router.push({
      name: PAGE.MISSION_PLANNING_QUESTIONNAIRES,
      params: {
        id: missionPlanningId,
      },
    });
  }
}

watch(dialog, (visible) => {
  if (!visible) {
    resetForm();
  }
});

onMounted(() => {
  configuration.value = props.pointOfSale ? getConfigurationFromPos() : pickFirstConfigurationFromList();

  selectedMissionId.value =
    props.pointOfSale ? findMissionIdByPos(missions.value, props.pointOfSale?.id) || null : null;

  timeSlot.value = customTimeSlots.value[0]?.range || null;
});
</script>
<template>

  <v-app-bar location="bottom" height="80">
    <div class="messenger-form">
      <v-text-field
        v-model="data.message"
        variant="solo-filled"
        @keydown.enter="sendMessage"
        :placeholder="$t('post-it.thread-details.messenger.text-input')"
      >
        <template v-slot:prepend>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon
                  :icon="mdiMessageSettingsOutline"
                  v-bind="props"
              />
            </template>
            <v-list>
              <v-list-subheader>{{ $t('post-it.message.templates') }}</v-list-subheader>
              <v-list-item
                  v-for="(item, index) in predefinedMessages"
                  :key="index"
                  :value="index"
                  @click="data.message = item.title"
              >
                <v-list-item-title class="wrap-text">{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-file-input
              class="ml-3"
              hide-input
              type="file"
              accept="image/*"
              v-model="image"
              :prepend-icon="mdiMessageImageOutline"
              @change="handleImageInput"
          />
        </template>

        <template v-slot:append>
          <v-btn
              :disabled="!canSendMessage"
              @click="sendMessage"
              :icon="mdiSend"></v-btn>
        </template>

        <template v-slot:append-inner>
          <v-btn
              v-if="data.message.length > 0"
              @click="clearMessage"
              :icon="mdiCloseCircle"></v-btn>
        </template>

      </v-text-field>
    </div>
  </v-app-bar>
  <v-app-bar v-if="Object.keys(pictures).length > 0" location="bottom" height="140" class="bg-grey-lighten-2">
    <div class="d-flex justify-start align-center w-100" style="overflow-x: scroll">
        <v-img
            v-for="[pictureId, picture] of Object.entries(pictures)"
            width="100"
            :src="imagesUrls[picture.file]"
            :aspect-ratio="1"
            class="mx-5 flex-grow-0"
            cover
        >
          <div class="d-flex">
            <v-spacer />
            <v-btn
                density="compact"
                :icon="mdiTrashCan"
                variant="flat"
                color="error"
                @click="deletePicture(picture, pictureId)"
            ></v-btn>
          </div>
        </v-img>
    </div>
  </v-app-bar>
</template>

<script setup>
import { reactive, computed, defineProps, ref, watchEffect } from 'vue';
import {
  mdiMessageSettingsOutline,
  mdiMessageImageOutline,
  mdiTrashCan,
  mdiSend,
  mdiCloseCircle
} from '@mdi/js';
import { useStoreManager } from '@/stores/storeManager';
import { useApplicationStore } from '@/stores/applicationStore';
import dateHandler from '@/utils/dateHandler';
import { useI18n } from 'vue-i18n';
import syncManager from "@/utils/sync/SyncManager";
import { addNewPicture,
  extractUnsentPostitPictures,
  getImagesFromLocalForge,
  removePicture
} from "@/stores/manager/postItManager";

const { t } = useI18n();

const props = defineProps({
  threadId: {
    type: String,
    required: true,
  },
  threadPointOfSale: {
    type: Object,
    required: false
  }
})

const data = reactive({
  message: ''
});

const image = ref(null);
const imagesUrls = ref({});

const storeManager = useStoreManager();
const applicationStore = useApplicationStore();
const userDetails = computed(() => applicationStore.userDetails);

function updateThreadLastMessage(uuid) {
  let thread = storeManager.getByIdAndCategory('postIt', props.threadId, 'threads');
  thread.last_message = {'id': uuid};
  storeManager.update('postIt', 'threads', props.threadId, thread);
}

const predefinedMessages = [
  { title: t('post-it.message.template-1') },
  { title: t('post-it.message.template-2') },
  { title: t('post-it.message.template-3') },
  { title: t('post-it.message.template-4') },
  { title: t('post-it.message.template-5') },
  { title: t('post-it.message.template-6') },
  { title: t('post-it.message.template-7') },
  { title: t('post-it.message.template-8') },
  { title: t('post-it.message.template-9') },
  { title: t('post-it.message.template-10') },
];

const pictureOptions = {
  maxWidth: props.threadPointOfSale.configuration?.settings?.max_width_picture || 600
}

const clearMessage = () => {
  data.message = '';
}
const sendMessage = () => {
  if (canSendMessage.value) {
    const newMessageObject = {
      author: userDetails.value,
      deleted: false,
      message: data.message,
      read: false,
      read_by_user: false,
      threads_ids: [props.threadId],
      configuration_id: props.threadPointOfSale.configuration.id || null,
      published_at: dateHandler.generateNowUnixTimestamp(),
      pictures: Object.keys(pictures.value),
    }
    // loop to update "sent" value to false in pictures.value store
    for (const pictureId in pictures.value) {
      const picture = storeManager.getFromStoreByCategory('postIt', 'picture')[pictureId];
      picture.sent = true;
      storeManager.update('postIt', 'picture', pictureId, picture);
    }

    const uuid = storeManager.add('postIt', 'messages', newMessageObject);

    updateThreadLastMessage(uuid);

    clearMessage();

    //force the postit synchro after message creation
    window.setTimeout( () => {
      syncManager.runPartialCycle();
    }, 500);

  }
}

function handleImageInput(e) {
  const file = image.value;
  if (file) {
    addNewPicture(
        file,
        pictureOptions,
        props.userDetails?.id,
        props.threadPointOfSale.configuration
    );
  }
}

const postitPictures = computed(() =>
    storeManager.getFromStoreByCategory('postIt', 'picture') || {}
);

const canSendMessage = computed(() =>
    data.message.trim() !== '' || Object.keys(pictures.value).length > 0
);

const pictures = computed(() => extractUnsentPostitPictures(postitPictures.value));

const loadImages = async (pictures) => {
  await pictures.value;

  await getImagesFromLocalForge(pictures.value).then(urls => {
    imagesUrls.value = urls;
  })
};

const deletePicture = (picture, pictureId) => {
  removePicture(picture, pictureId);
}

watchEffect(() => {
  loadImages(pictures);
});
</script>

<style scoped>
.messenger-form {
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px 20px 5px;
}
</style>

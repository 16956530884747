{
    "global": {
        "title": {
            "messages": "Messages",
            "planning": "Planification"
        },
        "error": {
            "network": "Erreur de réseau!",
            "not-found": "Cette page n'existe pas!",
            "no-data": "Il n'y a pas de données",
            "no-picture-selected": "Aucune image n'est sélectionnée!"
        },
        "success": {
            "deleted": "L'élément a été supprimé avec succès !"
        },
        "btn": {
            "close": "Fermer",
            "next": "Suivant",
            "refresh-packages": "Actualiser les packages",
            "back-to-main-page": "Retour à la page principale",
            "additional-information": "Informations complémentaires",
            "back": "Retour",
            "delete": "Supprimer",
            "cancel": "Annuler",
            "edit": "Modifier",
            "save": "Enregistrer",
            "add": "Ajouter",
            "send": "Envoyer",
            "submit": "Transmettre",
            "reset": "Réinitialiser"
        },
        "datepicker": {
            "ok": "Ok",
            "cancel": "Annuler"
        },
        "text": {
            "no-data": "Aucun résultat",
            "results": "résultat(s)",
            "all": {
                "f": "Toutes",
                "m": "Tous"
            },
            "others": "autres",
            "none": {
                "f": "Aucune",
                "m": "Aucun"
            },
            "m2": "m2",
            "bool": {
                "true": "Oui",
                "false": "Non",
                "none": " "
            }
        },
        "locale": {
            "fullStdDatePattern": "MM/DD/YYYY - HH:mm:ss",
            "fullDatePattern": "DD/MM/YYYY - HH[h]mm",
            "onlyDatePattern": "DD/MM/YYYY",
            "datePattern": "dddd D MMMM",
            "timePattern": "HH[h]mm"
        },
        "validator": {
            "mandatoryField": "Donnée obligatoire",
            "only-images": "Seules les images sont autorisées"
        },
        "info-modal": {
            "title": "Informations sur l'application",
            "label": {
                "last-sync": "Dernière synchronisation",
                "application-version": "Version de l'application",
                "show-hidden-questions": "Afficher les questions masquées",
                "show-debug-mode": "Mode debug",
                "save-logs": "Enregistrer les logs",
                "send-logs": "Envoyer logs",
                "synchro": "Synchro",
                "save": "Sauvegarder",
                "downloaded-prod-images": "images produits téléchargées"
            }
        },
        "pos-debug-block": {
            "label": {
                "pos-id": "Pos ID",
                "mission-planning-id": "Mission planning ID",
                "mission-id": "Mission ID",
                "reading-id": "Reading ID",
                "reading-history-id": "Reading history ID",
                "concurrent-reading-id": "Concurrent reading ID/date"
            }
        }
    },
    "top-left-menu": {
        "home": "Accueil",
        "planning": "Planning",
        "point-of-sale": "Points de vente",
        "messages": "Messages",
        "debug": "Debug",
        "store-list": "Liste des stores",
        "login": "Connexion",
        "contacts": "Contacts",
        "whaly-link": "Analyse secteur"
    },
    "top-right-menu": {
        "logout": "Déconnexion",
        "debug": "Debug",
        "store-list": "Liste des stores",
        "bo-link": "Espace BO",
        "login": "Se connecter"
    },
    "auth":{
        "login": {
            "title": "Login",
            "form": {
                "label": {
                    "login": "Identifiant",
                    "password": "Mot de passe"
                },
                "btn": "Login",
                "validation_messages": {
                    "email": "Veuillez entrer un email valide",
                    "password": "Veuillez entrer un mot de passe"
                }
            },
            "error": {
                "bad_credentials": "Email ou mot de passe invalide"
            }
        }
    },
    "home": {
        "alert": {
            "readings-not-updated": "Impossible de récupérer les réponses depuis le serveur. Les données locales doivent être envoyées avant !"
        }
    },
    "post-it": {
        "config": {
            "label": {
                "conversations": "conversation",
                "pos_code": "Code POS"
            },
            "message": {
                "no-threads": "Il n'y a pas de fil de discussion"
            }
        },
        "thread-details": {
            "messenger": {
                "text-input": "Aa.."
            },
            "edit-modal": {
                "title": "Modifier le message"
            }
        },
        "point-of-sale": {
            "choose-pos-modal": {
                "title": "Choisir le point de vente",
                "form" : {
                    "labels": {
                        "pos": "Sélection du point de vente"
                    },
                    "errors": {
                        "unselected-pos": "Le point de vente n'a pas été sélectionné!"
                    }
                }
            }
        },
        "message": {
            "author-last-message": "Auteur dernier message :",
            "empty-thread": "Aucun message dans cette discussion",
            "connecting": "En attente de connexion...",
            "author-me": "Moi",
            "templates": "Messages prédéfinis",
            "template-1": "Le client souhaite les infos commerciales du Cycle",
            "template-2": "Demande de passage DMF",
            "template-3": "Le client souhaite passer une commande",
            "template-4": "Le client souhaite des informations sur la livraison de sa commande",
            "template-5": "Le client ne souhaite pas me transmettre les écoulements, merci de ton aide",
            "template-6": "J’ai mis en place un challenge officieux",
            "template-7": "Le client a réussi son challenge",
            "template-8": "Le client a échoué son challenge",
            "template-9": "Le client souhaite modifier/annuler le rendez-vous",
            "template-10": "J’ai négocié des étagères supplémentaires"
        }
    },
    "point-of-sale": {
        "title": "Points de vente pour",
        "filter-form": {
            "label":{
                "search": "Rechercher dans le nom, l'adresse, le code postal",
                "store": "Enseigne",
                "department": "Département"
            }
        }
    },
    "planning": {
        "filter": {
            "label": {
                "select-year": "Sélectionnez l'année",
                "select-week": "Sélectionnez la semaine",
                "today": "Aujourd’hui"
            }
        },
        "list": {
            "week-nb": "Semaine "
        },
        "message": {
            "no-rdv": "Aucun rendez-vous pour cette semaine!",
            "no-reads": "Il n'y a pas de données!",
            "to-remove": "Ce planning n'est pas disponible! il a été supprimé!"
        }
    },
    "mission": {
        "block": {
            "pos": {
                "title": "Infos PDV"
            },
            "plan-a-visit": {
                "title": "Préparer ma visite",
                "text": "Visite PDV le {day}, {date} de {start_hour} à {end_hour}",
                "btn": "Préparer ma visite"
            },
            "my-visit": {
                "title": "Ma visite",
                "start": "Début de ma visite",
                "end": "Fin de ma visite",
                "btn": "Effectuer mon relevé"
            }
        }
    },
    "mission-planning": {
        "top-menu": {
            "questionnaires": "Mes questionnaires",
            "visit-info": "Ma visite",
            "whaly-link": "Focus préparation visite",
            "actions": "Actions",
            "new-visit": "Nouvelle visite",
            "edit-mission-planning": "Modifier",
            "delete-mission-planning": "Supprimer",
            "barcode-title": "Veuillez scanner un code barre"
        },
        "qst": {
            "info-pos": {
                "title": "Infos PDV",
                "label": {
                    "typology": "Typologie",
                    "secondary-typology": "Typologies secondaires",
                    "select-secondary-typology": "Sélect typologie secundaire",
                    "last-visit-date": "Date dernière visite",
                    "frequency": "Fréquence",
                    "pos-contacts": "Contacts liés au point de vente",
                    "sign": "Enseigne",
                    "additional-address": "Complément d’adresse",
                    "central": "Centrale",
                    "warehouse": "Entrepôt",
                    "network": "Réseau",
                    "surface": "Surface",
                    "commercial": "Commercial",
                    "ca": "CA",
                    "sector": "Secteur"
                }
            },
            "statistics" : {
                "last-reading": "Dernier relevé",
                "current-reading": "En cours",
                "labels": {
                    "DETAIL_DN": "DN",
                    "DETAIL_DNO": "DNO"
                }
            },
            "my-questionnaires": {
                "title": "Mes questionnaires",
                "label": {
                    "optional": "Facultatives",
                    "mandatory": "Obligatoires",
                    "total": "Total"
                },
                "qst": {
                    "history-recovery-notification": "{answerCount} réponse(s) récupérée(s) de la visite précédente",
                    "btn": {
                        "validate": "Valider",
                        "history-recovery": "Reprise d'historique"
                    }
                },
                "screen": {
                    "picture": {
                        "add": {
                            "title": "Ajouter une photo",
                            "form": {
                                "file": "Télécharger un fichier (photo)"
                            }
                        },
                        "edit": {
                            "title": "Éditer une photo",
                            "form": {
                                "file": "Télécharger un fichier (photo)"
                            }
                        },
                        "delete": {
                            "title": "Supprimer l'image",
                            "text": "Êtes-vous sûr de vouloir supprimer cette image?"
                        }
                    },
                    "element": {
                        "add": {
                            "title": "Ajouter une nouvelle saisie",
                            "text": "Êtes-vous sûr de vouloir ajouter une nouvelle saisie?",
                            "btn": "Nouvelle saisie"
                        },
                        "delete": {
                            "title": "Supprimer la ligne du questionnaire",
                            "text": "Êtes-vous sûr de vouloir supprimer cette ligne du questionnaire?"
                        }
                    },
                    "product": {
                        "list": {
                            "all": "Toutes les categories"
                        },
                        "filter": {
                            "search": "Rechercher un produit, une marque, un EAN",
                            "tags": "Filtrer par tag",
                            "dno": "Filtrer par DN / DNo",
                            "incomplete-products": "Produits questionnaire incomplet"
                        },
                        "sort": "Trier par"
                    },
                    "next-questionnaire": "Questionnaire suivant",
                    "previous-questionnaire": "Questionnaire précédent"
                }
            },
            "status": {
                "label": {
                    "new": "Transmettre",
                    "toBeTransmitted": "En cours de transmission",
                    "transmitted": "Transmis"
                }
            },
            "total-charts": {
                "title": {
                    "optional": "Facultatives",
                    "mandatory": "Obligatoires",
                    "pictures": "Photos",
                    "total": "Total"
                },
                "label": {
                    "optional-questions": "Questions facultatives",
                    "mandatory-questions": "Questions obligatoires",
                    "total-questions": "Toutes les questions",
                    "answered-questions": "Répondu aux questions"
                }
            }
        },
        "info": {
            "contacts": {
                "title": "Mes contacts PDV",
                "btn": "Gestion des contacts"
            },
            "informative-answer": {
                "title": "Consignes",
                "message": {
                    "no-instructions": "Il n'y a pas de données",
                    "zero-item": "Pas de consignes"
                }
            },
            "last-reading": {
                "title" : "Dernière visite",
                "last-reading-date": "Date du dernier relevé :",
                "message": {
                    "no-data": "Il n'y a pas de données"
                },
                "missing-dno-products": "Produits manquants en DNO",
                "ean": "EAN Produit",
                "product-name": "Nom produit manquant en DNO",
                "not-missing": "Non manquant"
            },
            "flash-news": {
                "title": "Flash news"
            },
            "perfect-store": {
                "title": "Perfect Store",
                "no-data": "Il n'y a pas de données"
            }
        },
        "actions": {
            "edit": {
                "title": "Modifier",
                "form": {
                    "pos": "Point de vente",
                    "date": "Date"
                },
                "validation": {
                    "pos": "Veuillez sélectionner un point de vente",
                    "date": "Veuillez ajouter une date"
                },
                "alert": {
                    "unable-enter-date-in-the-past": "Impossible de saisir une date dans le passé",
                    "unable-enter-date-in-the-future": "Impossible de saisir une date dans le futur"
                }
            },
            "delete": {
                "title": "Supprimer",
                "text": "Êtes-vous sûr de vouloir supprimer cette mission?"
            }
        },
        "filter": {
            "screen-by-category": {
                "brand": "Marque",
                "category": "Catégorie"
            }
        },
        "statement-status": {
            "title": "État de mon relevé :",
            "new": "Non commencé",
            "incomplete": "Incomplet",
            "ready": "À transmettre"
        },
        "warning": {
            "already-started-statement": "Un relevé est déjà commencé en date du {date}"
        }
    },
    "appointment": {
        "new": {
            "modal": {
                "title": "Nouvelle Visite",
                "create": "Créer",
                "pdv": "Point de vente",
                "pdv-group": {
                    "planned": "Planifiés",
                    "not-planned": "Non planifiés"
                },
                "configuration": "Client",
                "configurations": "Configurations",
                "type": "Type de visite",
                "type-options": {
                    "unexpected": "Visite sans RDV",
                    "expected": "Visite avec RDV"
                },
                "time-options": {
                    "now": "Maintenant",
                    "later": "Plus tard"
                },
                "datepicker-label": "Le",
                "timepicker-from": "De",
                "timepicker-to": "à",
                "time-range": "créneau",
                "no-slot": "Aucun créneau disponible",
                "validation" : {
                    "pdv": "Veuillez sélectionner un point de vente",
                    "configuration": "Veuillez sélectionner un client",
                    "type": "Veuillez sélectionner un type de visite",
                    "date": "Veuillez sélectionner une date",
                    "time": "Veuillez sélectionner un créneau",
                    "from": "Veuillez sélectionner une heure de début",
                    "to": "Veuillez sélectionner une heure de fin",
                    "need-higher-time": "L’heure de fin ne peut pas être inférieure à l’heure de début de la visite"
                }
            },
            "btn": {
                "title": "Nouvelle Visite"
            }
        }
    },
    "contact": {
        "list": {
            "title": "Contacts"
        },
        "filter": {
            "label": {
                "search": "Rechercher dans le nom, prénom, le téléphone",
                "pos": "Points de ventes",
                "township": "Communes",
                "department": "Départements"
            }
        },
        "config-select": "Recherche contact(s) pour",
        "detail-title": "Détail du contact",
        "firstname": "Prénom",
        "lastname": "Nom",
        "email": "Email",
        "job": "Fonction",
        "phone": "Téléphone",
        "fax": "Fax",
        "comment": "Commentaire",
        "pos": "Point de vente",
        "shelf": "Rayon",
        "delete-confirm": "Êtes-vous sûr de vouloir supprimer ce contact ?",
        "new-contact": "Nouveau contact",
        "edit-contact": "Modifier le contact",
        "validation": {
            "pos": "Veuillez sélectionner un point de vente",
            "firstname": "Veuillez saisir un prénom",
            "lastname": "Veuillez saisir un nom",
            "job": "Veuillez saisir une fonction",
            "phone": "Le numéro de téléphone saisi n’est pas valide",
            "email": "L’email saisi n’est pas valide"
        }
    },
    "conditions-messages": {
        "or": " ou ",
        "field-date": {
            "future": "Sup : {date}",
            "not-future": "Inf : {date}",
            "pastnday": "Min : {date}",
            "not-pastnday": "Max : {date}",
            "futurnday": "Max : {date}",
            "not-futurnday": "Min : {date}"
        },
        "value-comparison": {
            "eq": "La valeur doit être égale à {value}",
            "not-eq": "La valeur ne doit pas être égale à {value}",
            "gt": "Sup : {value}",
            "not-gt": "Inf : {value}",
            "gteq": "Min : {value}",
            "not-gteq": "Max : {value}",
            "lt": "Inf : {value}",
            "not-lt": "Sup : {value}",
            "lteq": "Max : {value}",
            "not-lteq": "Min : {value}",
            "in": "La valeur doit être comprise dans la liste {value}",
            "not-in": "La valeur ne doit pas être comprise dans la liste {value}",
            "true": "La valeur doit être 'oui'",
            "not-true": "La valeur ne doit pas être 'oui'",
            "int": "Nombre entier",
            "not-int": "Pas de nombre entier",
            "floatn": "La valeur doit être un nombre décimal",
            "not-floatn": "La valeur ne doit pas être un nombre décimal"
        }
    }
}

<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <span
          class="text-h5 font-weight-bold"
          :style="{'color':appStyle.title_text_color}"
        >
          {{ $t('top-left-menu.whaly-link') }}
        </span>

      </v-col>

      <v-col cols="12" md="12">
        <iframe
            class="border-0"
            :src="whalyUrl"
            width="100%"
            height="1024px"></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { computed } from 'vue';
  import { useApplicationStore } from '@/stores/applicationStore';


  const applicationStore = useApplicationStore();

  const appStyle = computed(() => applicationStore.appStyle);

  const whalyUrl = window.env.VITE_WHALY_APP_SECTOR_URL || import.meta.env.VITE_WHALY_APP_SECTOR_URL || '';

</script>

<template>
  <v-btn
      :icon="mdiBarcodeScan"
      :style="style"
      :rounded="rounded"
      :density="density"
      :class="customClass"
      :height="height"
      :width="width"
      :variant="variant"
      @click="dialog = true"
  />

  <v-dialog
      v-model="dialog"
      width="auto"
      min-width="30%"
  >
    <v-card>
      <v-card-title>
        {{ $t('mission-planning.top-menu.barcode-title') }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-container>
          <StreamBarcodeReader
              torch
              @decode="onDecode"
          />
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
            class="float-right"
            color="grey-darken-2"
            :prepend-icon="mdiClose"
            variant="tonal"
            @click="dialog = false"
        >
          {{ $t('global.btn.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { mdiBarcodeScan, mdiClose } from '@mdi/js';
import { StreamBarcodeReader } from "@teckel/vue-barcode-reader";
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
  configuration: {
    type: Object,
    required: true,
  },
  style: {
    type: Object,
    default: undefined,
    required: false
  },
  density: {
    type: String,
    default: 'default',
    required: false
  },
  rounded: {
    type: Boolean,
    default: undefined,
    required: false
  },
  customClass: {
    type: String,
    default: null,
    required: false
  },
  variant: {
    type: String,
    default: 'elevated',
    required: false
  },
  height: {
    default: undefined,
    required: false
  },
  width: {
    default: undefined,
    required: false
  }
});

const router = useRouter();

const dialog = ref(false);

const isInAProductScreen = computed(() => {
  if (!router.currentRoute.value.name.includes("mission-planning-questionnaire-screen")) {
    return false;
  }
  const screenId = router.currentRoute.value.params.screen_id.toString();
  return props.configuration.screen.some(screen =>
      screen.id.toString() === screenId && screen.attachment?.['value'] === "by_product"
  );
});
function onDecode(barcode) {
  dialog.value=false;
  const productScreenId = isInAProductScreen.value ?
      router.currentRoute.value.params?.screen_id?.toString() :
      getFirstProductScreenId();
  router.push({
    name: 'mission-planning-questionnaire-screen-category',
    params: {
      screen_id: productScreenId,
      category_id: 'all',
      id: router.currentRoute.value.params?.id
    },
    query: {
      barcode: barcode
    }
  });
}

function getFirstProductScreenId() {
  return props.configuration?.screen?.find(screen => screen.attachment?.['value'] === "by_product")?.id?.toString();
}
</script>
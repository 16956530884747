import { useReadingConditionStore } from '../conditionStore';
import { APP, SETTINGS } from '@/constants';

export function getGroupsWithoutScreen(groups, readingId = 'default') {
  const conditions = useReadingConditionStore().readingConditions[readingId]?.groups
  return groups
    ? groups
        .filter((group) => group.screen_id === null)
        .map((group) => {
          return {
            ...group,
            visibility: conditions
              ? conditions[group.id]
                ? conditions[group.id].visibility
                : true
              : true
          }
        })
    : []
}

export function getScreenQuestions(questions, screenId) {
  return questions ? questions.filter(qst => qst.group?.screen?.id === parseInt(screenId)) : [];
}

export function countGroupQuestions(questions, id, type, required) {
  return questions?.filter(question => {
    if (required === null) {
      return (type === 'group') ? question.group?.id === id : question.group?.screen?.id === id;
    }
    if (type === 'group') {
      return (required ? question.required !== false : question.required === false) && question.group?.id === id;
    }
    if (type === 'screen') {
      return (required ? question.required !== false : question.required === false) && question.group?.screen?.id === id;
    }
  }).length;
}

export function countTotalGroupQuestions(questions) {
  let counter = {
    optional: 0,
    mandatory: 0,
    total: 0
  };
  if (questions) {
    questions.forEach(question => {
      if (question.required === true) {
        counter.mandatory++;
      } else {
        counter.optional++;
      }
      counter.total++;
    });
  }
  return counter;
}

export function extractScreenQuestions(questions, screens) {
  const result = [];
  if (questions) {
    questions.forEach(question => {
      const groupScreenId = question.group?.screen?.id;
      if (groupScreenId) {
        const screenMatch = screens.find(screen => screen.id === groupScreenId);
        if (screenMatch) {
          result.push(question);
        }
      }
    });
  }
  return result;
}

export function extractGroupQuestions(questions, groups, readingId = 'default') {
  const conditions = useReadingConditionStore().readingConditions[readingId]?.questions
  const result = [];
  questions
  .map((question) => {
    return {
      ...question,
      visibility: conditions
        ? conditions[question.id]
          ? conditions[question.id].visibility
          : true
        : true,
      mandatory: conditions
        ? conditions[question.id]
          ? conditions[question.id].mandatory
          : question.required
        : question.required,
      validity: conditions
        ? conditions[question.id]
          ? conditions[question.id].validity
          : true
        : true
    }
  })
  .forEach((question) => {
    const groupId = question.group.id;
    if (groupId) {
      const screenMatch = groups.find(group => group.id === question.group.id && group.screen_id === null);
      if (screenMatch) {
        result.push(question);
      }
    }
  });
  return result;
}

export function extractQuestionsIds(questions) {
  return questions.map(question => question.id);
}

export function extractFormNamesFromQuestions(questions, foundedAnswers, attachmentType = 'none', attachmentId = null) {
  let formNames = {};
  if (questions) {
    questions.forEach(question => {
      let foundedAnswer = Object.values(foundedAnswers).find((answer) =>
        answer.question === question.id &&
        answer.attachment_type === attachmentType &&
        answer.attachment_id?.toString() === attachmentId?.toString()
      );
      formNames[question.id] = foundedAnswer?.value || question.default_value || null;
    });
  }
  return formNames;
}

export function groupedQuestionsByGroupId(questions, readingId = 'default') {
  const conditions = useReadingConditionStore().readingConditions[readingId]?.groups
  const grouped = {};
  const orderedQuestions = questions.sort((a, b) => a.order - b.order);

  for (const question of orderedQuestions) {
    const groupId = question.group.id;
    if (!grouped[groupId]) {
      grouped[groupId] = {
        id: groupId,
        label: question.group.label,
        order: question.group.order,
        visibility: conditions
          ? conditions[groupId]
            ? conditions[groupId].visibility
            : true
          : true,
        questions: [],
      };
    }
    grouped[groupId].questions.push(question);
  }
  return Object.values(grouped);
}

export function checkIfExistsSomeQuestionsWithHistory(questions, missionReadingHistory, attachmentType = 'none', attachmentId = null) {
  const historyAnswersQuestionIds = [];
  const answers = missionReadingHistory?.answers;

  if (answers) {
    for (const [answerId, answer] of Object.entries(answers)) {
      if (attachmentType === answer.related_to_type && attachmentId === answer.related_to_id) {
        const questionId = answer.question_id;

        if (!historyAnswersQuestionIds.includes(questionId)) {
          historyAnswersQuestionIds.push(questionId);
        }
      }
    }
  }

  return historyAnswersQuestionIds.some((questionId) => {
    const question = questions.find(q => q.id === questionId);
    return question && question.history === true;
  });
}

export function checkIfExistsSomeGroupQuestionsWithHistory(groupedQuestions, readingsHistory, attachmentType = 'none', attachments = []) {
  for (const group of groupedQuestions) {
    for (const attachment of attachments) {
      if (checkIfExistsSomeQuestionsWithHistory(group.questions, readingsHistory, attachmentType, attachment?.id)) {
        return true;
      }
    }
  }
  return false;
}

export function extractConfigActiveAppStyle(obj) {
  let result = {
    active: false,
    button_background_color: APP.STYLE.BTN.BACKGROUND_COLOR,
    button_text_color: APP.STYLE.BTN.TEXT_COLOR,
    global_menu_background_color: APP.STYLE.GLOBAL_MENU.BACKGROUND_COLOR,
    global_menu_text_color: APP.STYLE.GLOBAL_MENU.TEXT_COLOR,
    title_text_color: APP.STYLE.TITLE.TEXT_COLOR,
    visit_menu_background_color: APP.STYLE.VISIT_MENU.BACKGROUND_COLOR,
    visit_menu_text_color: APP.STYLE.VISIT_MENU.TEXT_COLOR,
  }
  if (obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key].app_style?.active === true) {
        for (const [itemKey, itemValue] of Object.entries(obj[key].app_style)) {
          if (itemValue) {
            result[itemKey] = itemValue;
          }
        }
      }
    }
  }
  return result;
}

export function extractConfigActiveAppLogo(obj) {
  if (obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key].logo) {
        return obj[key].logo;
      }
    }
  }
  return null;
}

export function hasAtLeastOnePlanningType(configurations, planningType) {
  for (const key in configurations) {
    if (configurations.hasOwnProperty(key)) {
      if (planningType === configurations[key].settings.planning_type) {
        return true;
      }
    }
  }
  return false;
}

export function hasAtLeastOneReducedTopMenu(configurations) {
  for (const key in configurations) {
    if (configurations.hasOwnProperty(key)) {
      if (true === configurations[key].settings.app_reduced_top_menu) {
        return true;
      }
    }
  }
  return false;
}
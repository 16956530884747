<template>
  <div class="mx-3 mt-2">
    <v-chip color="error" class="mr-1 mb-1">
      <strong class="mr-1">{{ $t('global.pos-debug-block.label.pos-id') }}: </strong> {{ pointOfSale?.id || $t('global.text.none.m') }}
    </v-chip>

    <v-chip color="purple" class="mr-1 mb-1">
      <strong class="mr-1">{{ $t('global.pos-debug-block.label.mission-planning-id') }}: </strong> {{ missionPlanning?.id || $t('global.text.none.m') }}
    </v-chip>

    <v-chip color="deep-purple" class="mr-1 mb-1">
      <strong class="mr-1">{{ $t('global.pos-debug-block.label.mission-id') }}: </strong> {{ missionPlanning?.mission?.id || $t('global.text.none.m') }}
    </v-chip>

    <v-chip color="indigo" class="mr-1 mb-1">
      <strong class="mr-1">{{ $t('global.pos-debug-block.label.reading-id') }}: </strong> {{ reading?.id || $t('global.text.none.m') }}
    </v-chip>

    <v-chip color="blue" class="mr-1 mb-1" v-if="DEBUG.TYPE.MP.MISSION_PLANING_PAGE === debugType">
      <strong class="mr-1">{{ $t('global.pos-debug-block.label.reading-history-id') }}: </strong> {{ readingHistory?.id || $t('global.text.none.m') }}
    </v-chip>

    <v-chip color="blue" class="mr-1 mb-1" v-if="DEBUG.TYPE.MP.PLANNING_LIST === debugType && concurrentReadingString">
      <strong class="mr-1">{{ $t('global.pos-debug-block.label.concurrent-reading-id') }}: </strong> {{ concurrentReadingString }}
    </v-chip>

    <v-divider class="mt-1" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { DEBUG } from '@/constants';
import dateHandler from '@/utils/dateHandler';
import _ from 'lodash';

const props = defineProps({
  debugType: {
    type: String,
    required: true
  },
  missionPlanning: {
    type: Object,
    required: false
  },
  pointOfSale: {
    type: Object,
    required: false
  },
  reading: {
    type: Object,
    required: false
  },
  readingHistory: {
    type: Object,
    required: false
  },
  concurrentReading: {
    type: Object,
    required: false
  }
});

const concurrentReadingString = ref(null);

if (!_.isEmpty(props.concurrentReading)) {
  concurrentReadingString.value =
    props.concurrentReading.id + ' / (' + dateHandler.convertUnixTimestampToHuman(props.concurrentReading?.creation_date) + ')';
}
</script>
import { StoreManagerRepository } from '@/repositories';

export class ApiManager {
    constructor(url, apiPushOverrideFunction = null, postApiPullCallback = null) {
        this.url = url;
        this.apiPushOverrideFunction = apiPushOverrideFunction;
        this.postApiPullCallback = postApiPullCallback;
    }

    async pull(queryParams = {}) {
        try {
            const response = await StoreManagerRepository.pull(this.url, queryParams);
            const images = response.data?.data?.images;
            delete response.data?.data?.images;
            for (const key of Object.keys(response.data.data)) {
                if (Array.isArray(response.data.data[key]) && response.data.data[key].length === 0) {
                    response.data.data[key] = {};
                } else {
                    if (null !== this.postApiPullCallback) {
                        await this.postApiPullCallback(key, images);
                    }
                }
            }
            return response.data.data; // Assuming the response structure has a 'data' property
        } catch (error) {
            console.error('Error:', error.message);
            throw error; // Rethrow the error to be caught by the caller
        }
    }

    async push(payload = {}) {
        try {
            if (null !== this.apiPushOverrideFunction) {
                return this.apiPushOverrideFunction(payload);
            }
            const response = await StoreManagerRepository.push(this.url, payload);
            return response.data.data; // Assuming the response structure has a 'data' property
        } catch (error) {
            console.error('Error:', error.message);
            throw error; // Rethrow the error to be caught by the caller
        }
    }

    async call(options = {}) {
        try {
            const response = await StoreManagerRepository.pull(this.url);
            // empty arrays needs to be converted to empty objects
            Object.keys(response.data.data).forEach(key => {
                if (Array.isArray(response.data.data[key]) && response.data.data[key].length === 0) {
                    response.data.data[key] = {};
                }
            });
            return response.data.data; // Assuming the response structure has a 'data' property
        } catch (error) {
            console.error('Error:', error.message);
            throw error; // Rethrow the error to be caught by the caller
        }
    }
}
<template>
  <delete-logs-confirmation-modal
      :show="dialogDelete"
      @close-dialog="closeDeleteModal"
      @delete-logs="clearLogs"/>

  <v-card
      flat
  >
    <template v-slot:title>
      Logs
      <v-chip
          v-if="isStoreLogs"
          class="mx-2"
          color="success"
          variant="outlined"
      >
        <v-icon :icon="mdiRemoteOff" start></v-icon>
        Live Logs
      </v-chip>

      <v-chip
          v-if="!isStoreLogs"
          class="mx-2"
          color="primary"
          variant="outlined"
      >
        <v-icon :icon="mdiRemote" start></v-icon>
        Remote logs
      </v-chip>
      <v-spacer/>

      <v-btn
          v-if="!isStoreLogs"
          :prepend-icon="mdiArrowLeft"
          density="default"
          variant="text"
          min-height="48"
          @click="switchToStoreLogs"
      >
        Back to live logs
      </v-btn>

      <v-btn
          v-if="isStoreLogs"
          :icon="mdiReload"
          variant="text"
          @click="refreshLogs"
      ></v-btn>

      <v-btn
          :icon="mdiTrayArrowDown"
          variant="text"
          @click="downloadLogs"
      ></v-btn>

      <load-logs-modal
        @log-selected="loadLog"
      ></load-logs-modal>

      <v-btn
          v-if="isStoreLogs"
          :icon="mdiDeleteSweep"
          variant="text"
          color="red"
          @click="openDeleteModal"
      ></v-btn>
    </template>
    <template v-slot:text>
      <v-text-field
          v-model="search"
          :prepend-inner-icon="mdiMagnify"
          variant="outlined"
          hide-details
          single-line
      ></v-text-field>
    </template>

    <v-data-table
        :headers="headers"
        :items="userLogs"
        :search="search"
        :loading="loadingLogs"
        multi-sort
    ></v-data-table>
  </v-card>
</template>

<script setup>
import { computed, ref, onMounted, toRaw } from 'vue';
import {useLogStore} from "@/stores/logStore";
import {useApplicationStore} from "@/stores/applicationStore";
import {
  mdiMagnify,
  mdiReload,
  mdiTrayArrowDown,
  mdiDeleteSweep,
  mdiArrowLeft,
  mdiRemote,
  mdiRemoteOff
} from '@mdi/js';
import * as XLSX from 'xlsx';
import DeleteLogsConfirmationModal from "../components/global/deleteLogsConfirmationModal.vue";
import LoadLogsModal from "../components/global/LoadLogsModal.vue";

const logStore = useLogStore();
const applicationStore = useApplicationStore();
const userLogs = ref([]);
const search = ref("");
const dialogDelete = ref(false);
const isStoreLogs = ref(true);
const loadingLogs = ref(false);

const appStyle = computed(() => applicationStore.appStyle);

const userDetails = computed(() => applicationStore.userDetails || false);

const headers = computed(() => {
  const allKeys = userLogs.value.flatMap(Object.keys);
  const uniqueKeysSet = new Set(allKeys);
  let uniqueKeysArray = [...uniqueKeysSet];
  if (uniqueKeysArray.includes("logRef")) {
    uniqueKeysArray = uniqueKeysArray.filter(key => key !== "logRef");
    uniqueKeysArray.unshift("logRef");
  }
  return uniqueKeysArray.map((key) => {
    return {
      title: key,
      key: key
    };
  });
});

const getUserLogsSnapshot = () => {
  return structuredClone(toRaw(logStore.logs[userDetails.value.id] || []));
};

function openDeleteModal() {
  dialogDelete.value = true
}
function closeDeleteModal() {
  dialogDelete.value = false
}
const refreshLogs = () => {
  userLogs.value = getUserLogsSnapshot();
};

const downloadExcel = (data, fileName) => {
  // Create a worksheet from the JSON data
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Create a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate a binary string representation of the workbook
  const binaryString = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

  // Create a Blob from the binary string
  const blob = new Blob([s2ab(binaryString)], { type: "application/octet-stream" });

  // Create a download link and trigger a download
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.xlsx`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  return buf;
};

const downloadLogs = () => {
  downloadExcel(userLogs.value, 'magellan_logs');
};

const clearLogs = () => {
  logStore.clearLogs();
  userLogs.value = getUserLogsSnapshot();
};

const loadLog = async (log) => {
  loadingLogs.value = true;
  try {
    const output = await logStore.getRemoteLog(log);
    isStoreLogs.value = false;
    userLogs.value = output?.data;
    loadingLogs.value = false;
  } catch (e) {
    console.error('Error loading log', e);
    loadingLogs.value = false;
  }

};

const switchToStoreLogs = () => {
  isStoreLogs.value = true;
  userLogs.value = getUserLogsSnapshot();
};

onMounted(() => {
  userLogs.value = getUserLogsSnapshot();
})
</script>

<template>
  <v-chip :color="buttonStyle?undefined:colorCapsule" :style="styleObject" class="ml-1 counter-capsule" density="default">
      <v-icon v-if="icon" start :icon="icon" />
    <span v-if="!icon">
        <v-icon v-if="type !== QST.COUNTER.TYPE.TOTAL" :icon="counterIcon()" />

        <span v-if="type === QST.COUNTER.TYPE.TOTAL">
          {{ $t('mission-planning.qst.my-questionnaires.label.total') }}
        </span>
      </span>

      <span class="font-weight-bold" style="position: absolute; right: 10px">
        {{ `${valueCurrent}/${valueTotal}` }}
      </span>
  </v-chip>
</template>

<script setup>
import { mdiStarCheck, mdiStarCheckOutline } from '@mdi/js';
import { QST } from '@/constants';
import {computed} from "vue";
import {useApplicationStore} from "@/stores/applicationStore";

const props = defineProps({
  type: {
    type: String,
    required: false
  },
  icon: {
    type: String,
    required: false
  },
  colorCapsule: {
    type: String,
    required: false,
    default: 'default'
  },
  colorCounter: {
    type: String,
    required: false,
    default: 'default'
  },
  valueCurrent: {
    type: Number,
    required: false,
    default: 0
  },
  valueTotal: {
    type: Number,
    required: false,
    default: 0
  },
  buttonStyle: {
    type: Boolean,
    required: false,
    default: false
  }
});
const applicationStore = useApplicationStore();

const appStyle = computed(() => applicationStore.appStyle);

const counterIcon = () => {
  let icon = null;
  switch (props.type) {
    case QST.COUNTER.TYPE.OPTIONAL:
      icon = mdiStarCheckOutline;
      break;
    case QST.COUNTER.TYPE.MANDATORY:
      icon = mdiStarCheck;
      break;
  }
  return icon;
}

const styleObject = computed(() => {
  if (appStyle.value.button_background_color && props.buttonStyle) {
    return {
      'background-color': appStyle.value.button_background_color,
      'color': appStyle.value.button_text_color
    }
  }
  return {}
});
</script>
import dateHandler from '@/utils/dateHandler';
import { useI18n } from 'vue-i18n';

export function filterMissionsByYearAndWeekDays(missions, week, year) {
  let weekDates = dateHandler.splitStringInDates(week);
  let startDate = dateHandler.transformHumanDateToUnix(weekDates[0]);
  let endDate = dateHandler.transformHumanDateToUnix(weekDates[1]) + 86399; // end of the day;
  return Object.fromEntries(
    Object.entries(missions)
      .map(([key, value]) => [
        key,
        { id: key, ...value }
      ])
      .filter(([key, value]) =>
        dateHandler.convertUnixTimestampToYear(value.date_start) === `${year}`
        && (value.date_start >= startDate && value.date_start <= endDate)
      )
  );
}

export function groupMissionsByWeekDays(missions, filter) {
  const weekDates = dateHandler.splitStringInDates(filter.selectedWeek.value);
  const startWeekDay = weekDates[0];
  const endWeekDay = weekDates[1];
  const weekDays = dateHandler.getLocaleWeekDays(startWeekDay, endWeekDay);
  const sortedMissions = missions.sort((a, b) => a.date_start - b.date_start);
  let result = {};
  Object.keys(weekDays).forEach((weekDayDate) => {
    result[weekDays[weekDayDate]] = [];
    Object.values(sortedMissions).forEach((mission) => {
      let missionDate = dateHandler.convertUnixTimestampToDate(mission.date_start);
      if (missionDate === weekDayDate) {
        result[weekDays[weekDayDate]].push(mission);
      }
    });
  });
  return result;
}

export function extractMissionsIdByPosId(missions, posId) {
  const matchingKeys = [];
  for (const key in missions) {
    if (missions.hasOwnProperty(key) && missions[key]?.pos?.id === posId) {
      matchingKeys.push(parseInt(key));
    }
  }
  return matchingKeys;
}

// assuming there's only one configuration per pos id
export function extractConfigurationIdByPosId(missions, posId) {
  let configurationId = null;
  for (const key in missions) {
    if (missions.hasOwnProperty(key) && missions[key]?.pos?.id.toString() === posId.toString()) {
      configurationId = missions[key]['configuration']['id'];
    }
  }
  return configurationId;
}

export function extractMissionsPlanningsByMission(missionsPlannings, missionIds) {
  const matchingKeys = [];
  for (const key in missionsPlannings) {
    if (missionsPlannings.hasOwnProperty(key) && missionsPlannings[key] && missionIds.includes(missionsPlannings[key]?.mission?.id)) {
      matchingKeys.push({id:key, ...missionsPlannings[key]});
    }
  }
  return Object.values(matchingKeys);
}

export function copyMissionsKeysInsideOfTheObject(missions)
{
  const resultObject = [];
  for (const key in missions) {
    resultObject.push({ id: Number(key), ...missions[key] });
  }
  return resultObject;
}

export function filterMissionsByConfigurationId(missions, configurationId) {
  return Object.keys(missions).filter((missionId) => {
    const mission = missions[missionId];
    return mission.configuration && mission.configuration.id == configurationId;
  }).reduce((result, missionId) => {
    result[missionId] = missions[missionId];
    return result;
  }, {});
}

export function posItemTitle(pos, mission, tr) {
  return `${pos?.name} - ${dateHandler.convertUnixTimestampToHumanDate(mission?.last_reading_date || 0) || tr('global.text.none.m')}`;
}

export function groupedPointsOfSaleList(pointsOfSale, plannedPosIds, missions, configurationId, t) {
  const plannedVisits = Object.fromEntries(
    Object.entries(pointsOfSale).filter(([key, val]) => plannedPosIds.includes(key.toString()))
  );
  const notPlannedVisits = Object.fromEntries(
    Object.entries(pointsOfSale).filter(([key, val]) => !plannedPosIds.includes(key.toString()))
  );

  let items= [];
  if (Object.values(plannedVisits).length) {
    items.push({ props: { header: t('appointment.new.modal.pdv-group.planned') }});
    items.push(...Object.values(plannedVisits).filter((pos) => {
    return Object.values(missions).some(m => m?.pos?.id?.toString() === pos?.id?.toString() &&
      m?.configuration?.id.toString() === configurationId.toString())
    }).map((pos) => {
      const posMission = Object.values(missions).find(m => m?.pos?.id?.toString() === pos?.id?.toString());
      if (posMission) {
        return { title: posItemTitle(pos, posMission, t), value: posMission.id};
      }
    }))
  }

  if (Object.values(notPlannedVisits).length) {
    items.push({ props: { header: t('appointment.new.modal.pdv-group.not-planned') }});
    items.push(...Object.values(notPlannedVisits).filter((pos) => {
      return Object.values(missions).some(m => m?.pos?.id?.toString() === pos?.id?.toString() &&
        m?.configuration?.id.toString() === configurationId.toString())
    }).map((pos) => {
      const posMission = Object.values(missions).find(m => m?.pos?.id?.toString() === pos?.id?.toString());
      if (posMission) {
        return { title: posItemTitle(pos, posMission, t), value: posMission.id.toString()};
      }
    }))
  }
  return items;
}

export function findMissionIdByPos(missions, targetPosId) {
  for (const key in missions) {
    if (missions[key]?.pos && missions[key]?.pos?.id?.toString() === targetPosId?.toString()) {
      return key;
    }
  }
  return null;
}

export function getFuturePlannedMissionsPosIdsFromMissionPlannings(missionsPlannings, missions) {
  const missionsIdsHavingAMissionPlanning = Object.values(missionsPlannings)
    .filter(missionPlanning => parseInt(missionPlanning.date_start || 0) > window.$moment().unix())
    .map(missionPlanning => missionPlanning.mission.id.toString());
  return Object.entries(missions)
    ?.filter(([missionId, mission]) => missionsIdsHavingAMissionPlanning.includes(missionId.toString()))
    ?.map(([missionId, mission]) => mission.pos.id?.toString()) || [];
}
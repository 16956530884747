<template>
  <h3 class="my-3 font-weight-bold">{{ count }} {{ $t('global.text.results') }}</h3>

  <point-of-sale
    v-for="pos in visiblePosList"
    :key="pos.id"
    :pointOfSale="pos"
    :readingsHistory="filterReadingsHistoryByPos(pos.id)"
    :missionMissionsPlannings="filterMissionsPlanningsByPos(pos.id)"
    :configuration="data.currentConfiguration"
  />

  <v-pagination
    v-show="showPagination"
    v-model="page"
    :length="pages"
    :total-visible="6"
  />
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import PointOfSale from './PointOfSale.vue';
  import stringHandler from '@/utils/stringHandler';
  import { extractMissionsIdByPosId, extractMissionsPlanningsByMission } from '@/stores/manager/missionManager';
  import { filterReadingsByMissions } from '@/stores/manager/readingHistoryManager';

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
    readingsHistory: {
      type: Object,
      required: true
    },
    missionsPlannings: {
      type: Object,
      required: true
    },
    missions: {
      type: Object,
      required: true
    },
  });

  const page = ref(1);
  const itemsPerPage = 50;

  const filteredPosList = computed(() => {
    return filterIsSet() ? filterPointsOfSale() : props.data.posList
  });

  const count = computed(() => {
    return filteredPosList.value.length;
  });

  const pages = computed(() => {
    return Math.ceil(filteredPosList.value.length / itemsPerPage)
  });

  const showPagination = computed(() => {
    return !(filteredPosList.value.length <= itemsPerPage)
  });

  const visiblePosList = computed(() => {
    let offset = (page.value - 1) * itemsPerPage;
    let limit = offset + itemsPerPage;
    return filteredPosList.value.slice(offset, limit);
  });

  function filterIsSet() {
    return props.data.filter.search !== '' || props.data.filter.store.length !== 0 || props.data.filter.department.length !== 0
  }

  function filterPointsOfSale() {
    return props.data.posList.filter(pos =>
        (
          props.data.filter.search === ''
          || stringHandler.substringFoundIn(
            props.data.filter.search,
            [pos.name, pos.city, pos.address, pos.postal_code, pos.code, pos.pos_code]
          )
        ) && (
          props.data.filter.store.length === 0
          || props.data.filter.store.some(store => store.id === pos.store?.id)
        ) && (
          props.data.filter.department.length === 0
          || props.data.filter.department.includes(pos.postal_code?.slice(0, 2) || '')
        )
    );
  }

  function filterReadingsHistoryByPos(pointOfSales) {
    return filterReadingsByMissions(
      props.readingsHistory,
      extractMissionsIdByPosId(
        props.missions,
        pointOfSales
      )
    );
  }

  function filterMissionsPlanningsByPos(pointOfSales) {
    return extractMissionsPlanningsByMission(
      props.missionsPlannings,
      extractMissionsIdByPosId(
        props.missions,
        pointOfSales
      )
    )
  }

  watch(props.data, () => {
    page.value = 1;
  });
</script>

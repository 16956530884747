<template>
  <v-card class="pa-5 my-0" variant="tonal">
    <v-text-field
      clearable
      :prepend-inner-icon="mdiMagnify"
      v-model="filter.search"
      single-line
      density="compact"
      @update:modelValue="filterChanged"
    >
      <template v-slot:label>
        <span>{{ $t('contact.filter.label.search') }}</span>
      </template>
    </v-text-field>

    <v-combobox
        clearable
        multiple
        :label="$t('contact.filter.label.pos')"
        :placeholder="$t('global.text.all.m')"
        v-model="filter.pointOfSale"
        :items="pointOfSales"
        item-title="name"
        return-object
        single-line
        density="compact"
        @update:modelValue="filterChanged"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip v-if="index < 1">
          <span>{{ item.title }}</span>
        </v-chip>
        <span
          v-if="index === 1"
          class="text-grey text-caption align-self-center"
        >
          (+{{ filter.pointOfSale.length - 1 }} {{ $t('global.text.others') }})
        </span>
      </template>
    </v-combobox>

    <v-sheet class="d-flex flex-column flex-md-row">
      <v-combobox
        clearable
        multiple
        :label="$t('contact.filter.label.township')"
        :placeholder="$t('global.text.all.f')"
        class="mr-md-5 text-black w-md-50"
        v-model="filter.township"
        :items="townships"
        item-title="name"
        return-object
        single-line
        density="compact"
        @update:modelValue="filterChanged"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index < 1">
            <span>{{ item.title }}</span>
          </v-chip>
          <span
            v-if="index === 1"
            class="text-grey text-caption align-self-center"
          >
            (+{{ filter.township.length - 1 }} {{ $t('global.text.others') }})
          </span>
        </template>
      </v-combobox>

      <v-combobox
        clearable
        multiple
        class="mr-md-5 text-black w-md-50"
        :label="$t('contact.filter.label.department')"
        :placeholder="$t('global.text.all.m')"
        v-model="filter.department"
        :items="departments"
        item-title="name"
        return-object
        single-line
        density="compact"
        @update:modelValue="filterChanged"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index < 1">
            <span>{{ item.title }}</span>
          </v-chip>
          <span
            v-if="index === 1"
            class="text-grey text-caption align-self-center"
          >
            (+{{ filter.department.length - 1 }} {{ $t('global.text.others') }})
          </span>
        </template>
      </v-combobox>

      <v-btn
        :prepend-icon="mdiRefresh"
        variant="tonal"
        class="my-md-1"
        @click="resetFilter()"
      >
        {{ $t('global.btn.reset') }}
      </v-btn>
    </v-sheet>
  </v-card>
</template>

<script setup>
import { reactive, toRefs, watch } from 'vue';
import { mdiMagnify, mdiRefresh } from '@mdi/js';

const props = defineProps({
  pointOfSales: {
    type: Array,
    required: true
  },
  departments: {
    type: Array,
    required: true
  },
  townships: {
    type: Array,
    required: true
  },
  resetFilterFlag: {
    type: Boolean,
    required: true
  }
});

const emit = defineEmits(['filterChanged']);

const { pointOfSales, departments, townships, resetFilterFlag } = toRefs(props);

const filter = reactive({
  search: '',
  pointOfSale: [],
  department: [],
  township: []
});

function resetFilter() {
  filter.search = '';
  filter.pointOfSale = [];
  filter.department = [];
  filter.township = [];
  emit('filterChanged', filter);
}

function filterChanged() {
  if (!filter.search) {
    filter.search = '';
  }
  emit('filterChanged', filter);
}

watch(resetFilterFlag, () => {
  resetFilter();
});

</script>
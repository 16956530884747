<template>
  <questionnaire-screen />
</template>

<script setup>
import { useRoute } from 'vue-router';
import { computed, provide } from 'vue';
import { useStoreManager } from '@/stores/storeManager';
import { getScreenQuestions } from '@/stores/manager/configurationManager';
import QuestionnaireScreen from '@/components/mission-planning/questionnaires/QuestionnaireScreen.vue';
import { useReadingConditionStore } from '@/stores/conditionStore';
import {useApplicationStore} from "@/stores/applicationStore";
import {extractReadingIdByParams} from "@/stores/manager/readingManager";
import { READING } from "@/constants";
import {StatisticIndicatorCalculator} from "@/utils/statisticIndicatorCalculator/StatisticIndicatorCalculator";
import {getQuestionsConditionsWithAnswers} from "@/stores/manager/conditionsManager";

const route = useRoute();
const currentMissionPlanningId = computed(() => route.params.id);
const currentScreenId = computed(() => route.params.screen_id);
const storeManager = useStoreManager();
const readingConditionStore = useReadingConditionStore();
const applicationStore = useApplicationStore();
const statsCalculator = new StatisticIndicatorCalculator();
provide('currentMissionPlanningId', currentMissionPlanningId);

const missionPlanning = computed(
  () =>
  storeManager.getFromStoreByCategory('mission', 'missionsPlannings')[currentMissionPlanningId.value] || {}
);

const mission = computed(
  () =>
  storeManager.getFromStoreByCategory('mission', 'missions')[missionPlanning.value?.mission?.id] || {}
);

const pointsOfSale = computed(() =>
    storeManager.getFromStoreByCategory('mission', 'pointsOfSale') || {}
);

const pointOfSale = computed(() =>
{
  const pos = pointsOfSale?.value[mission.value?.pos?.id] || {}
  if (pos === {}) {return pos;}
  pos.id = mission.value?.pos?.id
  return pos
})
provide('currentPointOfSale', pointOfSale)

const configuration = computed(
  () =>
  storeManager.getFromStoreByCategory('configuration', 'configurations')[mission.value?.configuration?.id] || {}
);
provide('currentConfiguration', configuration);

const readings = computed(() => storeManager.getFromStoreByCategory('reading', 'reading') || {})

const userDetails = computed(() => applicationStore.userDetails)

const currentReadingId = computed(() =>
  extractReadingIdByParams(
    readings.value,
    configuration.value?.id,
    currentMissionPlanningId.value,
    userDetails.value?.id
  )
)
provide('currentReadingId', currentReadingId);

const answers = computed(
  () =>
    Object.values(storeManager.getFromStoreByCategory('reading', 'answer'))
        .filter(answer => answer.reading?.toString() === currentReadingId.value?.toString())
    || {});

const questionsConditions = computed(() => readingConditionStore.readingConditions[currentReadingId.value || 'default']?.questions);
provide('questionsConditions', questionsConditions);

const questionsConditionsWithAnswers = computed(() => {
  return getQuestionsConditionsWithAnswers(currentReadingId.value, answers.value);
})
provide('questionsConditionsWithAnswers', questionsConditionsWithAnswers);

const statisticIndicators = computed(() => {
  return statsCalculator.calculate( Object.values(configuration.value?.statisticIndicators || {}),{
    questionsConditionsWithAnswers: questionsConditionsWithAnswers.value,
    answers: answers.value,
    statisticIndicatorsProductIds: pointOfSale.value?.statisticIndicators
  });
})
provide('statisticIndicators', statisticIndicators);

const totalCount = computed(() => {
  return questionsConditionsWithAnswers.value.length;
});
provide('totalCount', totalCount);

const totalAnswersCount = computed(() => {
  return questionsConditionsWithAnswers.value.filter(conditionItem => conditionItem.hasAnswer).length;
});
provide('totalAnswersCount', totalAnswersCount);

const totalMandatoryCount = computed(() => {
  return questionsConditionsWithAnswers.value.filter(conditionItem => conditionItem.mandatory).length;
});
provide('totalMandatoryCount', totalMandatoryCount);

const totalMandatoryAnswersCount = computed(() => {
  return questionsConditionsWithAnswers.value.filter(conditionItem => conditionItem.mandatory && conditionItem.hasAnswer).length;
});
provide('totalMandatoryAnswersCount', totalMandatoryAnswersCount);

const totalOptionalCount = computed(() => {
  return totalCount.value - totalMandatoryCount.value;
});
provide('totalOptionalCount', totalOptionalCount);

const totalOptionalAnswersCount = computed(() => {
  return totalAnswersCount.value - totalMandatoryAnswersCount.value;
});
provide('totalOptionalAnswersCount', totalOptionalAnswersCount);

const currentPictures = computed(
  () =>
    Object.entries(storeManager.getFromStoreByCategory('reading', 'picture'))
      .filter(
        ([key, value]) =>
          currentReadingId.value &&
          !value.to_remove &&
          value.reading?.toString() === currentReadingId.value?.toString()
      )
      .map(([key, value]) => {
        return { id: key, ...value }
        })|| {});
provide('currentPictures', currentPictures);

const screen = computed(() => {
  const currentScreen =
    configuration?.value?.screen?.filter((scr) => scr.id.toString() === currentScreenId.value)[0] ??
    []

  const conditions =
    readingConditionStore.readingConditions[currentReadingId.value || 'default']?.screens

  if (!!conditions)
    currentScreen.visibility = conditions[currentScreen.id]
      ? conditions[currentScreen.id].visibility
      : true

  return currentScreen
})
provide('currentScreen', screen);

const attachmentLoopItems = computed(() => {
  const attachments = screen.value?.attachment?.value;
  if (attachments === READING.ATTACHMENT_TYPE.CATEGORY) {
    return { items: Object.values(configuration.value.catalog.category), labelKey: 'name', type: READING.ATTACHMENT_TYPE.CATEGORY };
  }

  if (attachments === READING.ATTACHMENT_TYPE.PRODUCT) {
    return { items: Object.values(configuration.value.catalog.category), labelKey: 'name', type: READING.ATTACHMENT_TYPE.PRODUCT };
  }

  if (attachments === READING.ATTACHMENT_TYPE.PICTURE) {
    return { items: [], labelKey: 'name', type: READING.ATTACHMENT_TYPE.PICTURE };
  }

  if (attachments === READING.ATTACHMENT_TYPE.ELEMENT) {
    return { items: [], labelKey: 'name', type: READING.ATTACHMENT_TYPE.ELEMENT };
  }

  if (!attachments) {
    return { items: [], labelKey: 'name', type: 'none' };
  }

  return undefined;
});
provide('attachmentLoopItems', attachmentLoopItems);

const screenQuestions = computed(() => {
  const items = getScreenQuestions(
    Object.values(configuration?.value?.questions || {}),
    currentScreenId.value
  )
  const conditions =
    readingConditionStore.readingConditions[currentReadingId.value || 'default']?.questions
  if (!conditions) return items
  return items.map((item) => {
    return {
      ...item,
      visibility: conditions[item.id] ? conditions[item.id].visibility : true,
      mandatory: conditions[item.id] ? conditions[item.id].mandatory : item.required,
      validity: conditions[item.id] ? conditions[item.id].validity : true
    }
  })
})
provide('screenQuestions', screenQuestions);
</script>
<template>
  <v-dialog max-width="500">
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn
          v-bind="activatorProps"
          :icon="mdiCloudArrowUpOutline"
          variant="text"
      ></v-btn>
    </template>

    <template v-slot:default="{ isActive }">
      <v-card title="Choose log to show">
        <v-card-text>
          <v-autocomplete
              v-model="selectedLog"
              :items="logsList"
              item-title="createdAt"
              item-value="id"
              dense
              outlined
              @update:modelValue="selectLog(isActive)"
          ></v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text="Cancel"
              @click="isActive.value = false"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup>
import {
  mdiCloudArrowUpOutline
} from '@mdi/js';
import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useApplicationStore } from '@/stores/applicationStore';
import { useLogStore } from "@/stores/logStore";

const emit = defineEmits(['logSelected']);
const props = defineProps({
  btnColor: {
    type: String,
    required: false
  }
});

const { t } = useI18n();
const applicationStore = useApplicationStore();
const logStore = useLogStore();

const logsList = ref([]);
const selectedLog = ref(null);

const userDetails = computed(() => applicationStore.userDetails || false);

const getRemoteLogsList = async () => {
  logsList.value = await logStore.getRemoteLogsList();
}

const selectLog = (isActive) => {
  emit('logSelected', selectedLog.value);
  isActive.value = false;
}

onMounted(() => {
  getRemoteLogsList();
})
</script>
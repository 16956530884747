<template>
  <v-btn
    class="bg-green-darken-3 text-white"
    variant="tonal"
    :prepend-icon="mdiSendVariant"
    @click="submitReport"
  >
    {{ $t('mission-planning.qst.status.label.new') }}
  </v-btn>

</template>

<script setup>
import { mdiSendVariant } from '@mdi/js';
import { useStoreManager } from '@/stores/storeManager';
import { READING } from '@/constants';
import { useRoute, useRouter } from "vue-router";
import dateHandler from "@/utils/dateHandler";

const props = defineProps({
  reading: {
    type: Object,
    required: true,
  },
  readingId: {
    type: String,
    required: true,
  },
});

const storeManager = useStoreManager();
const route = useRoute();
const router = useRouter();


const submitReport = () => {

  if (props.reading) {
    props.reading.status = READING.STATUS.TO_BE_TRANSMITTED;
    storeManager.update('reading', 'reading', props.readingId, props.reading);
  }
  redirectToPlanningIfNeeded();
}


const redirectToPlanningIfNeeded = () => {
  if (route.name !== 'planning') {
    const missionPlanning = storeManager.getFromStoreByCategory('mission', 'missionsPlannings')[props.reading.mission_planning]
    if (missionPlanning?.date_start) {
      const missionPlanningWeek = dateHandler.convertUnixTimestampToMoment(missionPlanning.date_start).week()
      if (missionPlanningWeek) {
        router.push({ name: 'planning', query: { week: missionPlanningWeek } });
      }
    }
  }
}
</script>

export class ProductItemCheck {
    types = {
        "PDT-BRAND": ( ctxProduct, itemVal ) => itemVal === ctxProduct?.brand?.id?.toString(),
        "PDT-CATO": ( ctxProduct, itemVal ) => itemVal === ctxProduct?.category?.id?.toString(),
        "PDT-TAG": ( ctxProduct, itemVal ) =>  ctxProduct?.tags?.some(tag => tag?.toString() === itemVal?.toString()),
    }

    messages = {
        "PDT-BRAND": ( reverse ) => reverse ? "not brand" : "brand",
        "PDT-CATO": ( reverse ) => reverse ? "not category" : "category",
        "PDT-TAG": ( reverse ) =>  reverse ? "not tag" : "tag",
    }

    informations = {}

    constructor() {

    }

    process(item, context) {
        this.checkContext(context);
        const checkResult = this.types[item.type](context.prod, item.value.toString());
        return {
            result: item.reverse ? !checkResult : checkResult,
            message: this.messages[item.type](item.reverse)
        };
    }

    checkContext(context) {
        if (!context.hasOwnProperty("prod")) {
            throw new Error("Context doesn't match requirements");
        }
    }
}
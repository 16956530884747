import {createApp} from 'vue';
import {createPinia} from 'pinia';
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2'

import App from './App.vue'
import router from './router'
import localforage from "localforage";
import i18n from "./i18n"

// Vuetify
import 'vuetify/styles';
import {createVuetify} from 'vuetify';
import {fr, en} from 'vuetify/locale'
import {aliases, mdi} from 'vuetify/iconsets/mdi-svg';

// to replace when vuetify timepicker will be released
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment';

// last import so that it overrides css
import './assets/main.css';

// global variables
window.$moment = moment;
const locale = JSON.parse(localStorage.getItem('applicationStore'))?.data.locale || window.env.VITE_DEFAULT_LOCALE || import.meta.env.VITE_DEFAULT_LOCALE;
// vuetify
const vuetify = createVuetify({
  theme: {
    defaultTheme: 'light',
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  locale: {
    locale: locale,
    fallback: 'en',
    messages: {fr, en},
  },
  date: {
    locale: {
      firstDayOfWeek: 1,
      formatLocale: locale,
      titleDateFormat: 'YYYY MMMM',
      weekdays: moment.weekdays(),
      months: moment.months(),
    }
  },
  components: {
    VueDatePicker
  },
})
localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'stores',
  version: 1,
  storeName: 'appstores',
  description: 'App storage'
});

const indexedDBStorage = {
  getItem: async (key) => {
    return await localforage.getItem(key);
  },
  setItem: async (key, value) => {
    return await localforage.setItem(key, value);
  },
  removeItem: async (key) => {
    return await localforage.removeItem(key);
  }
};

const app = createApp(App)
const pinia = createPinia()
const installPersistedStatePlugin = createPersistedStatePlugin({
  storage: indexedDBStorage,
  serialize: (value) => JSON.parse(JSON.stringify(value)),
  deserialize: (value) => value,
  persist: true
});

localforage.ready(() => {
  console.log('[localforage] ready');
  pinia.use((context) => installPersistedStatePlugin(context));
  app.use(pinia)
  app.use(router)
  app.use(vuetify)
  app.use(i18n)

  app.mount('#app');
})


import _ from 'lodash'
import dateHandler from '@/utils/dateHandler';
import { useStoreManager } from "@/stores/storeManager";
import { READING } from '@/constants';
import {computed} from "vue";
import { useRoute } from 'vue-router';

export function extractReadingIdByParams(readings, configurationId, missionPlanning, userId) {
  
  if (!configurationId || !missionPlanning || !userId) {
    return null
  }

  for (const uuid in readings) {
    if (
      readings[uuid]?.configuration_id?.toString() === configurationId.toString() &&
      readings[uuid]?.mission_planning?.toString() === missionPlanning.toString() &&
      readings[uuid]?.user?.toString() === userId.toString()
    ) {
      return uuid;
    }
  }
  return null;
}

export function extractReadingAnswersByReadingId(readingAnswers, readingId, questionsIds) {
  let result = {};
  if (readingAnswers) {
    for (const uuid in readingAnswers) {
      if (
        readingAnswers[uuid].reading === readingId &&
        _.includes(questionsIds, readingAnswers[uuid].question)
      ) {
        result[uuid] = readingAnswers[uuid];
      }
    }
  }
  return result;
}

export function extractReadingPicturesByReadingId(readingPictures, readingId) {
  let result = {};
  if (readingPictures) {
    for (const uuid in readingPictures) {
      if (readingPictures[uuid].reading === readingId) {
        result[uuid] = readingPictures[uuid];
      }
    }
  }
  return result;
}

export function extractReadingAnswersByQuestionIdAndAttachment(readingAnswers, questionId, attachmentType = 'none', attachmentId = null) {
  if (readingAnswers) {
    for (const uuid in readingAnswers) {
      if (
          readingAnswers[uuid].question?.toString() === questionId?.toString() &&
          readingAnswers[uuid].attachment_type === attachmentType &&
          readingAnswers[uuid].attachment_id?.toString() === attachmentId?.toString()
      ) {
        return { key: uuid, obj: readingAnswers[uuid] };
      }
    }
  }
  return {};
}

export const createReadingIfNotExists = async (
  currentReadingId,
  configurationId,
  currentMissionPlanningId,
  userId,
  currentConfigurationData) => {
  if (!currentReadingId) {
    const storeManager = useStoreManager();
    const creationDate = dateHandler.generateNowUnixTimestamp();
    let mp = storeManager.getFromStoreByCategory('mission', 'missionsPlannings')[currentMissionPlanningId ?? ''];
    if (mp) {
      currentConfigurationData.initialMissionPlanningStartDate = new Date(parseInt(mp?.date_start) * 1000).toISOString().replace('T', ' ').substring(0, 19);
      const mission = storeManager.getFromStoreByCategory('mission', 'missions')[mp?.mission?.id];
      if (mission) {
        currentConfigurationData.initialPosId = mission?.pos?.id;
      }
    }
    return await useStoreManager()
      .add('reading', 'reading', {
        configuration_id: configurationId,
        mission_planning: currentMissionPlanningId,
        creation_date: creationDate,
        user: userId,
        status: READING.STATUS.NEW,
        data: currentConfigurationData
      });
  }
  return currentReadingId;
}

<template>
  <v-card variant="tonal">
    <v-card-text v-if="currentConfiguration.questions">
      <v-row>
        <v-col class="align-self-center">
          <div class="d-flex flex-column align-center">
            <counter-pie
              :label="$t('mission-planning.qst.total-charts.title.optional')"
              :value-current="totalOptionalAnswersCount"
              :value-total="totalOptionalCount"
              :color="appStyle.title_text_color"
            />
          </div>
        </v-col>

        <v-col class="align-self-center">
          <div class="d-flex flex-column align-center">
            <counter-pie
              :label="$t('mission-planning.qst.total-charts.title.mandatory')"
              :value-current="totalMandatoryAnswersCount"
              :value-total="totalMandatoryCount"
              :color="appStyle.title_text_color"
            />
          </div>
        </v-col>

        <v-col class="align-self-center">
          <div class="d-flex flex-column align-center">
            <counter-pie
              :label="$t('mission-planning.qst.total-charts.title.pictures')"
              :value-current="currentPictures.length"
              :value-total="currentConfiguration?.settings?.nb_required_picture || 0"
              :color="appStyle.title_text_color"
            />
          </div>
        </v-col>

        <v-col class="align-self-center">
          <div class="d-flex flex-column align-center">
            <counter-pie
              :label="$t('mission-planning.qst.total-charts.title.total')"
              :value-current="totalAnswersCount"
              :value-total="totalCount"
              :color="appStyle.title_text_color"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-if="!currentConfiguration.questions">
      <v-alert
        variant="tonal"
        type="warning"
        :text="$t('global.error.no-data')"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import CounterPie from '@/components/common/CounterPie.vue';
import { useApplicationStore } from '@/stores/applicationStore';
import { inject, computed } from 'vue';

const { t } = useI18n();
const applicationStore = useApplicationStore();
const appStyle = computed(() => applicationStore.appStyle);
const currentConfiguration = inject('currentConfiguration');
const totalCount = inject('totalCount');
const totalAnswersCount = inject('totalAnswersCount');
const totalMandatoryCount = inject('totalMandatoryCount');
const totalMandatoryAnswersCount = inject('totalMandatoryAnswersCount')
const totalOptionalCount = inject('totalOptionalCount');
const totalOptionalAnswersCount = inject('totalOptionalAnswersCount');
const currentPictures = inject('currentPictures');
</script>
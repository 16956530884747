<template>
  <v-card variant="tonal" class="mb-5">
    <v-card-title :style="{'color': appStyle.title_text_color}">
      <v-icon class="mx-3 my-1" size="small" :icon="mdiInformation" />
      {{ $t('mission-planning.qst.info-pos.title') }}
    </v-card-title>

    <v-divider />

    <v-card-text v-if="pointOfSale?.name">
      <v-row>
        <v-col cols="12" sm="2" md="1">
          <v-icon class="contact-icon" :icon="mdiStore"/>
        </v-col>
        <v-col cols="12" sm="10" md="5">
          <div class="text-body-1 font-weight-bold">{{ pointOfSale.code }}</div>
          <div class="text-body-1 font-weight-bold">{{ pointOfSale.address }}</div>
          <div class="text-body-1 font-weight-bold">{{ `${pointOfSale.postal_code} ${pointOfSale.city}` }}</div>
          <div class="text-body-1 font-weight-bold">
            <v-icon size="small" :icon="mdiPhone"/>
            {{ pointOfSale.phone }}
          </div>
        </v-col>

        <v-col cols="12" sm="2" md="1" />

        <v-col cols="12" sm="10" md="5">
          <div class="text-body-1 mb-2">
            <span class="font-weight-bold">
              {{ $t('mission-planning.qst.info-pos.label.typology') }}:
            </span>
            {{ pointOfSale.typology?.name || "" }}
          </div>

          <div class="text-body-1">
            <span class="font-weight-bold">
              {{ $t('mission-planning.qst.info-pos.label.secondary-typology') }}:
            </span>

            <span v-if="!pointOfSale.shelf_typology?.length">

            </span>

            <div class="max-height-block">
              <div
                  v-for="shelfTypology in pointOfSale.shelf_typology"
              >
                {{ ` - ${shelfTypology?.typology?.name}` }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center">
          <v-btn
            variant="tonal"
            size="small"
            class="text-right"
            :prepend-icon="!expandedDetails ? mdiChevronDown : mdiChevronUp"
            @click="expandedDetails = !expandedDetails"
          >
            <strong>
              {{ $t('global.btn.additional-information') }}
            </strong>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-if="!pointOfSale?.name">
      <v-row>
        <v-col>
          <v-alert variant="tonal" type="warning" :text="$t('global.error.no-data')" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-expand-transition>
      <div v-show="expandedDetails">
        <v-divider />

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="2" md="1" />

            <v-col cols="12" sm="10" md="5">
              <div class="text-body-1">
                <span class="font-weight-bold">
                  {{ $t('mission-planning.qst.info-pos.label.last-visit-date') }}:
                </span>
                <span>
                  {{ dateHandler.convertUnixTimestampToHumanDate(lastReadingHistory?.date) || $t('global.text.no-data') }}
                </span>
              </div>

              <div class="text-body-1">
                <span class="font-weight-bold">
                  {{ $t('mission-planning.qst.info-pos.label.frequency') }}:
                </span>
                <span>{{ mission?.frequency || $t('global.text.no-data') }}</span>
              </div>

              <div class="text-body-1">
                <span class="font-weight-bold">
                  {{ $t('mission-planning.qst.info-pos.label.commercial') }}:
                </span>
                <span>{{ pointOfSale?.commercial_name || $t('global.text.no-data') }}</span>
              </div>

              <v-divider class="my-2" />

              <div class="text-body-1">
                <v-btn
                  :prepend-icon="mdiContacts"
                  @click="goToContacts()"
                >
                  {{ $t('mission-planning.info.contacts.title') }}
                </v-btn>

                <span v-if="!pointOfSale.comment">
                </span>
              </div>

              <v-tooltip
                  v-if="pointOfSale.comment"
                  location="top"
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                      variant="tonal"
                      size="small"
                      class="mt-2"
                      icon
                      v-bind="props"
                  >
                    <v-icon :icon="mdiMessageText" />
                  </v-btn>
                </template>
                <span>{{ pointOfSale.comment }}</span>
              </v-tooltip>
            </v-col>

            <v-col cols="12" sm="2" md="1" />

            <v-col cols="12" sm="10" md="5">
              <div class="text-body-1">
                <span class="font-weight-bold">
                  {{ $t('mission-planning.qst.info-pos.label.sign') }}:
                </span>
                <span>{{ pointOfSale.store?.name || $t('global.text.no-data') }}</span>
              </div>

              <div class="text-body-1">
                <span class="font-weight-bold">
                  {{ $t('mission-planning.qst.info-pos.label.additional-address') }}:
                </span>
                <span>{{ `${pointOfSale.address1 || ""} ${pointOfSale.address2 || ""}` || $t('global.text.no-data') }}</span>
              </div>

              <div class="text-body-1">
                <span class="font-weight-bold">
                  {{ $t('mission-planning.qst.info-pos.label.central') }}:
                </span>
                <span>{{ pointOfSale.store?.sign?.name || $t('global.text.no-data') }}</span>
              </div>

              <div class="text-body-1">
                <span class="font-weight-bold">
                  {{ $t('mission-planning.qst.info-pos.label.warehouse') }}:
                </span>
                <span>{{ pointOfSale.central?.name || $t('global.text.no-data') }}</span>
              </div>

              <div class="text-body-1">
                <span class="font-weight-bold">
                  {{ $t('mission-planning.qst.info-pos.label.network') }}:
                </span>
                <span>{{ pointOfSale.network?.name || $t('global.text.no-data') }}</span>
              </div>

              <div class="text-body-1">
                <span class="font-weight-bold">
                  {{ $t('mission-planning.qst.info-pos.label.surface') }}:
                </span>
                <span>{{ pointOfSale.surface || 0 }} {{ $t('global.text.m2') }}</span>
              </div>

              <div class="text-body-1">
                <span class="font-weight-bold">
                  {{ $t('mission-planning.qst.info-pos.label.ca') }}:
                </span>
                <span>{{ pointOfSale.sales || $t('global.text.no-data') }}</span>
              </div>

              <div class="text-body-1">
                <span class="font-weight-bold">
                  {{ $t('mission-planning.qst.info-pos.label.sector') }}:
                </span>
                <span>{{ `${pointOfSale.area?.name || $t('global.text.no-data')} / ${pointOfSale.area?.area?.name || $t('global.text.no-data')}` }}</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script setup>
import { mdiStore, mdiPhone } from '@mdi/js';
import { ref, defineProps, inject, computed } from 'vue';
import {
  mdiChevronDown,
  mdiChevronUp,
  mdiInformation,
  mdiMessageText,
  mdiContacts
} from '@mdi/js';
import dateHandler from '@/utils/dateHandler';
import { useRouter } from 'vue-router';
import { useApplicationStore } from '@/stores/applicationStore';

const props = defineProps({
  pointOfSale: {
    type: Object,
    required: true,
    default: []
  },
  mission: {
    type: Object,
    required: false,
    default: {}
  }
});

const applicationStore = useApplicationStore();
const appStyle = computed(() => applicationStore.appStyle);

const router = useRouter();

function goToContacts() {
  router.push({ path: 'contacts' });
}

const expandedDetails = ref(false);

const lastReadingHistory = inject('lastReadingHistory', {});
</script>

<style scoped>
  .contact-icon {
    font-size: 50px;
    margin-top: 0.5rem;
  }

  .v-list-item--density-default {
    min-height: 0;
  }

 .text-block {
   display: flex;
   justify-content: space-between;
 }

 .max-height-block {
   max-height: 120px;
   overflow: auto;
 }
</style>
<template>
  <v-card
    v-for="item in itemWithConditionsAndMandatoryAnswerStatus"
    variant="tonal"
    :class="{
      'mb-3': true,
      'text-disabled pointer-none': !item.visibility,
      'd-none': !showHiddenQuestions && !item.visibility,
    }"
  >
    <v-card-text>
      <div class="d-flex flex-wrap w-100 align-center justify-space-between">
        <span class="font-weight-bold text-uppercase my-1">
          <v-icon :color="getItemColor(item)" class="mr-3" :icon="mdiPlayCircle" />
          <strong
              class="text-uppercase cursor-pointer mt-1"
              @click="goToQuestionnaireFormPage(item)"
          >
            {{ item.label }}
          </strong>
        </span>
        <div class="ml-auto">
          <questionnaire-capsules
            questionnaire-type="screen"
            :questionnaire-type-id="item.id"
            :attachment-type="item.attachment?.value"
          />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { computed, defineProps, inject } from 'vue';
import { mdiPlayCircle } from '@mdi/js';
import { useRouter } from 'vue-router';
import QuestionnaireCapsules from '@/components/mission-planning/questionnaires/parts/QuestionnaireCapsules.vue';
import { useReadingConditionStore } from '@/stores/conditionStore';
import { useApplicationStore } from '@/stores/applicationStore';
import { SCREEN } from '@/constants/components/screens';
import { getScreensWithConditionsAndMandatoryAnswerStatus } from '@/stores/manager/missionPlanningManager';

const props = defineProps({
  items: {
    type: Object,
    required: true
  },
  currentReadingId: {
    required: true
  },
  questions: {
    type: Object,
    required: false
  }
});

const router = useRouter();

const readingConditionStore = useReadingConditionStore();
const applicationStore = useApplicationStore();
const currentMissionPlanningId = inject('currentMissionPlanningId');
const configuration = inject('currentConfiguration');
const questionsConditionsWithAnswers = inject('questionsConditionsWithAnswers');

const showHiddenQuestions = computed(() => applicationStore.showHiddenQuestions);

function goToQuestionnaireFormPage(item) {
  if (!item.id) {
    return;
  }
  router.push({
    name: 'mission-planning-questionnaire-screen',
    params: {
      id: currentMissionPlanningId.value,
      screen_id: item.id
    },
  });
}

const allQuestions = computed(() => Object.values(configuration.value.questions || {}));

function getItemColor(item) {
  if (item.answerStatus === SCREEN.STATUS.IN_PROGRESS) {
    return 'deep-orange-darken-1';
  }
  if (item.answerStatus === SCREEN.STATUS.FINISHED) {
    return 'green-darken-1';
  }
  return undefined;
}

const itemWithConditionsAndMandatoryAnswerStatus = computed(() =>
  getScreensWithConditionsAndMandatoryAnswerStatus(props.currentReadingId, props.items, allQuestions.value, questionsConditionsWithAnswers.value)
)
</script>
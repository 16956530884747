<template>
  <v-card
    v-if="!message.to_remove"
    :class="{
      'user-message': messageFromAuthUser(message),
      'other-message': !messageFromAuthUser(message),
    }"
  >
    <v-card-subtitle class="opacity-none">
      <div class="d-flex flex-no-wrap justify-space-between">
        <div class="align-start text-wrap">
          <h5 v-if="!messageFromAuthUser(message)" class="font-weight-bold">
            <span class="text-uppercase font-weight-bold opacity-06">
              {{ message.author.first_name }} {{ message.author.last_name }}
            </span>
          </h5>

          <v-icon
            v-if="message.read"
            :icon="mdiCheck"
            color="default"
          />

          <v-btn
            v-if="messageFromAuthUser(message) && message.is_new && !message.to_remove"
            :icon="mdiCloseBox"
            density="compact"
            size="small"
            variant="text"
            color="grey-lighten-4"
            class="mr-2"
            @click="deleteMessage"
          />

          <!-- TODO: this button is temporarily hidden, task: RPX-521
          <edit-message-modal
            v-if="messageFromAuthUser(message) && message.is_new"
            :message-id="messageId"
            :message="message"
          />
          -->
        </div>

        <div class="align-end text-wrap">
          <div class="d-flex flex-no-wrap justify-space-between opacity-06">
            {{ publishedDate() }}
          </div>
        </div>
      </div>
    </v-card-subtitle>
    <p>{{ message.message }}</p>
    <message-images :message-images-srcs="messageImagesSrcs" />
  </v-card>
</template>

<script setup>
  import { computed, watchEffect, ref } from 'vue';
  import { mdiCheck, mdiCloseBox } from '@mdi/js';
  import { useStoreManager } from '@/stores/storeManager';
  import { useApplicationStore } from '@/stores/applicationStore';
  import { useI18n } from 'vue-i18n';
  import dateHandler from '@/utils/dateHandler';
  // import EditMessageModal from './parts/EditMessageModal.vue';
  import {getImagesFromLocalForge, removePicture} from "@/stores/manager/postItManager";
  import MessageImages from "./parts/MessageImages.vue";

  const props = defineProps({
    messageId: {
      type: String,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    }
  });

  const { t } = useI18n();

  const applicationStore = useApplicationStore();
  const userDetails = computed(() => applicationStore.userDetails);
  const storeManager = useStoreManager();
  const imagesUrls = ref({});


  const deleteMessage = async () => {
    await deletePictures(pictures.value);
    storeManager.remove('postIt', 'messages', props.messageId);
  }

  const deletePictures = async (pictures) => {
    for (const [pictureId, picture] of Object.entries(pictures)) {
      await removePicture(picture, pictureId);
    }
    return true;
  }

  const messageFromAuthUser = () => {
    return props.message.author.id === userDetails.value.id;
  }

  const publishedDate = () => {
    if (props.message.published_at) {
      return dateHandler.convertUnixTimestampToHuman(props.message.published_at);
    }
    return '';
  }
  const postitPictures = computed(() =>
      storeManager.getFromStoreByCategory('postIt', 'picture') || {}
  );

  const pictures = computed(() => {
    return Object.fromEntries(
        Object.entries(postitPictures.value).filter(([key, value]) => props.message.pictures?.includes(key))
    );
  });

  const loadImages = async (pictures) => {
    await pictures.value;

    await getImagesFromLocalForge(pictures.value).then(urls => {
      imagesUrls.value = urls;
    })
  };

  const messageImagesSrcs = computed(() => {
    const picturesUrl = Object.keys(pictures.value).length > 0 ?
        Object.values(pictures.value).map(picture => imagesUrls.value[picture.file]) : [];
    const uploadedImagesUrls = props.message?.image_url_list || [];

    return [...picturesUrl, ...uploadedImagesUrls];
  });

  watchEffect(() => {
    loadImages(pictures);
  });

</script>

<style scoped>
.user-message, .other-message {
  border-radius: 8px;
  padding: 8px 12px;
  margin: 4px 15px;
}

.user-message {
  background-color: #2196f3;
  color: white;
}

.other-message {
  background-color: #e0e0e0;
}

@media (max-width: 480px) {
  .user-message, .other-message {
  }
}

@media (min-width: 480px) {
  .user-message {
    float: right;
  }

  .other-message {
    float: left;
  }
}

@media (min-width: 480px) and (max-width: 800px) {
  .user-message, .other-message {
    width: 430px;
  }
}

@media (min-width: 800px) and (max-width: 1024px) {
  .user-message, .other-message {
    width: 720px;
  }
}

@media (min-width: 1024px) {
  .user-message, .other-message {
    width: 980px;
  }
}
</style>
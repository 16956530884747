import {defineStore} from 'pinia'
import {computed, ref} from 'vue'
import {ApiManager} from "./api/ApiManager";
import {useApplicationStore} from './applicationStore';
import {useStoreManager} from "./storeManager";
import {useRoute, useRouter} from "vue-router";

export const useMissionStore = defineStore('missionStore',
  () => {
    const applicationStore = useApplicationStore();
    const userDetails = computed(() => applicationStore.userDetails || false);
    const route = useRoute();
    const router = useRouter();

    const storeCategories = {
      [userDetails.value.id]: {
        missions: {},
        points_of_sale: {},
        missions_plannings: {},
        contacts: {},
        package_date: null,
        perfect_store: {}
      }
    };
    const options = {
      apiManager: new ApiManager('packages/mission'),
      readonly: false,
      sync: true,
      allTime: false,
      persistentLocalId: false
    }
    const data = ref(storeCategories);

    const missions = computed(() => data.value[userDetails.value.id]?.missions || {});
    const pointsOfSale = computed(() => data.value[userDetails.value.id]?.points_of_sale || {});
    const missionsPlannings = computed(() => data.value[userDetails.value.id]?.missions_plannings || {});
    const contacts = computed(() => data.value[userDetails.value.id]?.contacts || {});
    const packageDate = computed(() => data.value[userDetails.value.id]?.package_date || null);
    const perfectStore = computed(() => data.value[userDetails.value.id]?.perfect_store || {});

    function updateMissionPlanningIdsInReadings(oldKey, newKey) {
      Object.entries(useStoreManager().getFromStoreByCategory('reading', 'reading')).forEach(
        ([readingId, reading]) => {
          if (reading.mission_planning === oldKey) {
            useStoreManager().getFromStoreByCategory('reading', 'reading')[readingId].mission_planning = newKey;
          }
        }
      )
    }
    function redirectToNewMissionPlanningIfNecessary(oldKey, newKey) {
      if (oldKey !== newKey && route.matched.some(matchedRoute => matchedRoute.name === 'mission-planning')) {
        const newPath = route.path.replace(oldKey, newKey);
        router.replace(newPath);
      }
    }

    function removeDuplicates(oldKey, newKey) {
      const missionPlannings = useStoreManager().getFromStoreByCategory('mission', 'missionsPlannings');
      if (oldKey in missionPlannings) {
        console.log('removing duplicate mission planning', oldKey);
        delete missionPlannings[oldKey];
      }
    }

    const postPushActions = (receivedData, sentData) => {
      if (receivedData.missions_plannings) {
        const missionPlannings = receivedData.missions_plannings;
        Object.entries(missionPlannings).forEach(([newKey, missionPlanning]) => {
          const oldKey = missionPlanning.sync_id
          if (oldKey) {
            updateMissionPlanningIdsInReadings(oldKey, newKey);
            redirectToNewMissionPlanningIfNecessary(oldKey, newKey);
          }
        })
      }
    }
    const postPullActions = (pulledData) => {
      if (pulledData.missions_plannings) {
        const missionPlannings = pulledData.missions_plannings;
        Object.entries(missionPlannings).forEach(([newKey, missionPlanning]) => {
          const oldKey = missionPlanning.sync_id
          if (oldKey) {
            updateMissionPlanningIdsInReadings(oldKey, newKey);
            removeDuplicates(oldKey, newKey);
            redirectToNewMissionPlanningIfNecessary(oldKey, newKey);
          }
        })
      }
    }

    return {
      data,
      options,
      missions,
      pointsOfSale,
      missionsPlannings,
      contacts,
      packageDate,
      perfectStore,
      postPushActions,
      postPullActions
    }
  },
  {
    persistedState: {
      overwrite: true
    }
  }
);

<template>
  <td
      v-if="!indexes"
      class="px-1"
      v-for="(statisticIndicator, idx) in statisticIndicators"
  >
    <v-chip
        :color="chipColor(statisticIndicators?.[idx])" class="mx-1 my-1">
      {{ buildLabel(statisticIndicator) }}&nbsp;<span :class="'font-weight-bold '+statColor(statisticIndicator, idx)">{{ statisticIndicator?.result }} {{ statisticIndicator.unit }}</span>
    </v-chip>
  </td>
  <td
      v-else
      class="px-1"
      v-for="idx of indexes"
  >
    <v-chip
        v-if="statisticIndicators?.[idx]"
        :color="chipColor(statisticIndicators?.[idx])"
        class="mx-1 my-1">
      {{ buildLabel(statisticIndicators?.[idx]) }}&nbsp;<span :class="'font-weight-bold '+statColor(statisticIndicators?.[idx], idx)">{{ statisticIndicators?.[idx]?.result }} {{ statisticIndicators?.[idx]?.unit }}</span>
    </v-chip>
    <v-chip
        v-else
        class="mx-1 my-1">n/a</v-chip>
  </td>
</template>

<script setup>

import { computed } from 'vue';
import {useI18n} from 'vue-i18n';

const {t} = useI18n();
const props = defineProps({
  counters: {
    required: false,
    default: []
  },
  type: {
    required: false,
    default: ''
  },
  indexes: {
    required: false
  },
  decimals: {
    required: false,
    default: 0
  }
});

const statisticIndicators = computed(() =>
  Object.fromEntries(
      Object.entries(props.counters?.statisticIndicators || {}).filter(
          ([key, value]) => value.type === props.type
      )
  )
)

const previousStatsIndicators = computed(() =>
    Object.fromEntries(
        Object.entries(props.counters?.lastReadingHistoryCounters?.statisticIndicators || {}).filter(
            ([key, value]) => value.type === props.type
        )
    )
)

const statColor = (stat, idx) => {
  if (props.type !== '') {
    return '';
  }
  const previousResult = previousStatsIndicators.value?.[idx]?.result;
  if (!previousResult && previousResult !== 0) {
    return '';
  }
  if (parseFloat(stat?.result) >= parseFloat(previousResult)) return "text-green-darken-2"
  else if (parseFloat(stat?.result) >= parseFloat(previousResult)/1.1) return "text-orange-darken-1"
  else return "text-red-darken-1"
}

const chipColor = (stat) => {
  if (props.type !== 'detail') {
    return '';
  }
  if (stat?.result >= (stat?.thresholds?.[0]||0) ) return "green-darken-2"
  else if (stat?.result >= (stat?.thresholds?.[1]||0) ) return "orange-darken-1"
  else return "red-darken-1"
}

function buildLabel(stat) {
  return props.type !== 'detail'? stat?.label||'' : t(`mission-planning.qst.statistics.labels.${stat?.label}`)
}

</script>
<template>
  <div class="d-flex justify-start">
      <v-btn
          variant="tonal"
          class="mr-1"
          color="default"
          @click="onSelectingWeek('decrement')"
      >
        <v-icon :icon="mdiChevronLeft"/>
      </v-btn>

      <v-btn
          v-if=" !(parseInt(filter.selectedYear) > parseInt(currentYear)) &&
          (filter.selectedWeek.week < currentWeekNumber || parseInt(filter.selectedYear) < parseInt(currentYear))"
          variant="text"
          class="mx-1"
      >
        {{ filter.selectedWeek.label }} {{yearDiffDisplay}}
      </v-btn>

      <v-btn
          class="mx-1"
          :variant="(filter.selectedWeek.week === currentWeekNumber && parseInt(filter.selectedYear) === parseInt(currentYear)) ? `text` : `tonal`"
          @click="onSelectingWeek"
      >
        <div class="d-flex flex-column">
          <span style="font-size: 80%">{{ listOfWeeks[currentWeekNumber].label }}</span>
          <span>{{$t('planning.filter.label.today')}}</span>
        </div>
      </v-btn>

      <v-btn
          v-if=" !(parseInt(filter.selectedYear) < parseInt(currentYear)) &&
          (filter.selectedWeek.week > currentWeekNumber || parseInt(filter.selectedYear) > parseInt(currentYear))"
          variant="text"
          class="mx-1"
      >
        {{ filter.selectedWeek.label }} {{yearDiffDisplay}}
      </v-btn>

      <v-btn
          variant="tonal"
          class="ml-1"
          color="default"
          @click="onSelectingWeek('increment')"
      >
        <v-icon :icon="mdiChevronRight"/>
      </v-btn>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import dateHandler from '@/utils/dateHandler';
import { useRoute } from "vue-router";

const props = defineProps({
  filter: {
    type: Object,
    required: true,
  },
  missionsCount: {
    type: Number,
    required: true,
  }
});

const { t } = useI18n();
const route = useRoute();

const currentYear = window.$moment().year();

const currentWeekNumber = dateHandler.getWeekNumberForYear(currentYear);

const weeks = computed(() => dateHandler.generateWeeksForYear(props.filter.selectedYear));

const listOfWeeks = computed(() => populateWeeksList(weeks));

props.filter.selectedWeek = listOfWeeks.value[route?.query?.week || currentWeekNumber];

function onSelectingWeek(mode = '') {
  const week = props.filter.selectedWeek.week
  if (mode === 'increment') {
    if (week === Object.keys(listOfWeeks.value).length) {
      props.filter.selectedYear = (parseInt(props.filter.selectedYear) + 1).toString();
      props.filter.selectedWeek = listOfWeeks.value[1];
    }
    else props.filter.selectedWeek = listOfWeeks.value[week + 1];
  } else if (mode === 'decrement') {
    if (week === 1) {
      props.filter.selectedYear = (parseInt(props.filter.selectedYear) - 1).toString();
      props.filter.selectedWeek = listOfWeeks.value[Object.keys(listOfWeeks.value).length];
    }
    else props.filter.selectedWeek = listOfWeeks.value[week - 1];
  } else {
    props.filter.selectedYear = currentYear
    props.filter.selectedWeek = listOfWeeks.value[currentWeekNumber];
  }

}

function populateWeeksList(weeks) {
  let result = {};
  weeks.value.forEach(function (week) {
    result[week.weekNumber] = {
      'week': week.weekNumber,
      'value': `${week.startDate},${week.endDate}`,
      'label': `${t('planning.list.week-nb')}${week.weekNumber}`
    }
  });
  return result
}

const yearDiffDisplay = computed(() => {
  const diff = parseInt(props.filter.selectedYear) - currentYear;
  return diff !== 0 ? " (n" + (diff > 0 ? "+" : "") + diff + ")" : "";
});
</script>
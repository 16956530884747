export default {

  substringFoundIn: function (substring, values) {
    for (let value of values) {
      if (value && value.toLowerCase().includes(substring.toLowerCase())) {
        return true;
      }
    }

    return false;
  },

}
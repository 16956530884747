<template>
  <v-img
      class="mx-1 my-1 flex-grow-0"
      :height="height"
      :width="width"
      :lazy-src="imageUrl"
      :src="imageUrl"
  >
      <div v-if="isDownloading" class="d-flex align-center justify-center fill-height" style="background-color: rgba(0,0,0,0.8)">
        <v-progress-circular
            color="grey-lighten-4"
            indeterminate
        ></v-progress-circular>
      </div>
    <template v-slot:error>
      <v-img
          :height="height"
          :width="width"
          src="/assets/no_image.png"
      ></v-img>
    </template>
  </v-img>
</template>

<script setup>
  import { computed, onMounted } from 'vue';
  import { db } from '@/db.js';
  import { liveQuery } from "dexie";
  import { useObservable }  from '@vueuse/rxjs';
  import offlineImageHandler from "@/utils/offlineImageHandler";

  const props = defineProps({
    offlineImageUrl: {
      required: false
    },
    offlineImageKey: {
      required: true
    },
    width: {
      type: Number,
      required: false,
      default: 60
    },
    height: {
      type: Number,
      required: false,
      default: 60
    }
  });

  const defaultImageUrl = "/assets/no_image.png";

  const offlineImage = useObservable(
      liveQuery(() => db.offlineImage.get(props.offlineImageKey)),
      {
        onError: (err) => {
          console.log(err.message)
        },
      });

  const imageUrl = computed(() => {
    if (!props.offlineImageUrl || !offlineImage.value?.blob) {
      return defaultImageUrl;
    }
    return URL.createObjectURL(offlineImage.value.blob);
  });

  const isDownloading = computed(() => {
    return offlineImage.value?.downloading;
  });

  onMounted( async () => {
    let mountedOfflineImage = await db.offlineImage.get(props.offlineImageKey);
    if (props.offlineImageUrl && !mountedOfflineImage) {
      await offlineImageHandler.addNew({
        key: props.offlineImageKey,
        url: props.offlineImageUrl,
        downloaded: 0,
        downloading: 1
      })
    }
    mountedOfflineImage = await db.offlineImage.get(props.offlineImageKey); // need to get updated data from db
    if (!mountedOfflineImage?.downloaded) {
      await offlineImageHandler.updateBlob(mountedOfflineImage);
    }
  });

</script>

<template>
  <v-card variant="tonal" class="mb-5">
    <v-card-title :style="{'color': appStyle.title_text_color}">
      <v-icon class="mx-3 my-1" size="small" :icon="mdiListBox" />
      {{ $t('mission-planning.info.perfect-store.title') }}
    </v-card-title>

    <v-divider />

    <v-card-text>
      <div>
        <template v-if="chartData.labels.length > 0 && chartData.datasets[0].data.length > 0">
          <Bar
              id="perfect-store"
              :options="chartOptions"
              :data="chartData"
          />
          <v-divider></v-divider>
        </template>

        <template v-else>
          <v-alert
              variant="tonal"
              type="warning"
              :text="$t('mission-planning.info.perfect-store.no-data')"
          />
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>
<script setup>

import {mdiListBox} from "@mdi/js";
import { defineProps, computed } from 'vue';
import { useApplicationStore } from '@/stores/applicationStore';
import { ref } from 'vue'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
import { useStoreManager } from '@/stores/storeManager';

const applicationStore = useApplicationStore();
const appStyle = computed(() => applicationStore.appStyle);
const storeManager = useStoreManager();


const props = defineProps({
  missionPlanning: {
    type: Object,
    required: true
  }
});

const chartOptions = ref({
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  },
});

const chartData = computed(() => {
  const perfectStore = storeManager.getFromStoreByCategory('mission', 'perfectStore');
  const missionId = props.missionPlanning?.mission?.id;

  const relevantItems = Object.entries(perfectStore).filter(([key]) => {
    return parseInt(key) === missionId;
  });

  if (relevantItems.length === 0) {
    return {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: 'rgb(253, 145, 8)',
        barThickness: 30
      }]
    };
  }

  const items = relevantItems[0][1];
  return {
    labels: items.map(item => `${item.reading}`),
    datasets: [{
      data: items.map(item => item.score),
      backgroundColor: 'rgb(253, 145, 8)',
      barThickness: 30
    }]
  };
});


</script>
<template>
  <v-container>
    <choose-configuration :title="$t('point-of-sale.title')" @configuration-selected="onConfigurationSelect" />

    <point-of-sale-filter
      :stores="data.filterStores"
      :departments="data.filterDepartments"
      @filter-changed="onFilterChange"
    />

    <point-of-sale-list
      :data="data"
      :missionsPlannings="missionsPlannings"
      :missions="missions"
      :readingsHistory="readingsHistory"
    />
  </v-container>
</template>

<script setup>
import { computed, reactive } from 'vue';
import ChooseConfiguration from '@/components/global/ChooseConfiguration.vue';
import PointOfSaleList from '@/components/point-of-sale/PointOfSaleList.vue';
import PointOfSaleFilter from '@/components/point-of-sale/PointOfSaleFilter.vue';
import { useStoreManager } from '@/stores/storeManager';
import { extractOptionsForSelects } from '@/stores/manager/pointOfSalesManager';
import { filterMissionsByConfigurationId } from "@/stores/manager/missionManager";

const storeManager = useStoreManager();

const storePosList = computed(() =>
    storeManager.getFromStoreByCategory('mission', 'pointsOfSale')
);

const missionsPlannings = computed(() =>
  storeManager.getFromStoreByCategory('mission', 'missionsPlannings')
);

const missions = computed(() => {
  const rawMissions = storeManager.getFromStoreByCategory('mission', 'missions');
  return filterMissionsByConfigurationId(rawMissions, data.currentConfiguration?.id);
});

const readingsHistory = computed(() =>
  storeManager.getFromStoreByCategory('readingHistory', 'reading')
);

const data = reactive({
  posList: [],
  filterStores: [],
  filterDepartments: [],
  filter: {
    search: '',
    store: [],
    department: []
  },
  currentConfiguration: []
});

function onConfigurationSelect(selectedConfiguration) {
    data.posList = [];
    data.filterStores = [];
    data.filterDepartments = [];

    data.currentConfiguration = selectedConfiguration;
    if (storePosList.value) {
      extractOptionsForSelects(storePosList, selectedConfiguration, data)
    }
}

function onFilterChange(filter) {
    data.filter = filter;
}

</script>

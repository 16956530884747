import localforage from 'localforage';

const localForageService = {
  instance: null,

  createInstance(config) {
    this.instance = localforage.createInstance(config);
  },

  async storeData(instance, itemId, itemValue) {
    await this.createInstance({ name: instance});
    return this.instance.setItem(itemId, itemValue);
  },

  async retrieveData(instance, itemId) {
    await this.createInstance({ name: instance });
    return this.instance.getItem(itemId);
  },

  async removeData(instance) {
    await this.createInstance({ name: instance });
    return this.instance.clear();
  },

  async removeItem(instance, itemId) {
    await this.createInstance({ name: instance });
    return this.instance.removeItem(itemId);
  },

};

export default localForageService;
<template>
  <div class="d-flex justify-center mt-0">
    <v-btn
      v-show="currentCategory?.id !== firstCategory?.id"
      variant="tonal"
      class="mr-1"
      color="default"
      @click="onFirstCategorySelect()"
    >
      <v-icon :icon="mdiChevronDoubleLeft"/>
    </v-btn>

    <v-btn
      v-show="currentCategory?.id !== firstCategory?.id"
      variant="tonal"
      class="mr-1"
      color="default"
      @click="onPrevCategorySelect()"
    >
      <v-icon :icon="mdiChevronLeft"/>
    </v-btn>

    <v-btn
      variant="text"
      class="mx-1"
    >
      <strong>{{ currentCategory?.name }}</strong>
    </v-btn>

    <v-btn
      v-show="currentCategory?.id !== lastCategory?.id"
      variant="tonal"
      class="ml-1"
      color="default"
      @click="onNextCategorySelect()"
    >
      <v-icon :icon="mdiChevronRight"/>
    </v-btn>

    <v-btn
      v-show="currentCategory?.id !== lastCategory?.id"
      variant="tonal"
      class="ml-1"
      color="default"
      @click="onLastCategorySelect()"
    >
      <v-icon :icon="mdiChevronDoubleRight"/>
    </v-btn>
  </div>
</template>

<script setup>
import { inject, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { mdiChevronLeft, mdiChevronRight, mdiChevronDoubleLeft, mdiChevronDoubleRight } from '@mdi/js';
import objectHandler from '@/utils/objectHandler';

const emit = defineEmits(['categoryChanged']);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const currentMissionPlanningId = inject('currentMissionPlanningId');
const currentScreenId = inject('currentScreenId');
const currentCategoryId = inject('currentCategoryId');
const currentConfiguration = inject('currentConfiguration');

const categories = computed(() => currentConfiguration.value?.catalog?.category || {});
const currentCategory = computed(() => Object.values(categories.value).filter((c) => c?.id?.toString() === currentCategoryId.value?.toString())[0] || {});
const firstCategory = computed(() => objectHandler.getFirstElement(categories.value));
const lastCategory = computed(() => objectHandler.getLastElement(categories.value));

const onFirstCategorySelect = () => {
  goToQuestionnaireCategory(firstCategory.value?.id);
}

const onLastCategorySelect = () => {
  goToQuestionnaireCategory(lastCategory.value?.id);
}

const onPrevCategorySelect = () => {
  goToQuestionnaireCategory(objectHandler.getPreviousElement(categories.value, currentCategory.value?.id)?.id);
}

const onNextCategorySelect = () => {
  goToQuestionnaireCategory(objectHandler.getNextElement(categories.value, currentCategory.value?.id)?.id);
}

const goToQuestionnaireCategory = (catId) => {
  emit('categoryChanged', true);
  router.push({
    name: 'mission-planning-questionnaire-screen-category',
    params: {
      id: currentMissionPlanningId.value,
      screen_id: currentScreenId.value,
      category_id: catId
    },
  });
}
</script>
<template>
  <v-dialog
    :model-value="modelValue"
    width="auto"
    min-width="30%"
  >
    <v-card>
      <v-card-title>
        {{ $t('mission-planning.actions.delete.title') }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <p>{{ $t('mission-planning.actions.delete.text') }}</p>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn
          class="float-right"
          color="grey-darken-2"
          :prepend-icon="mdiClose"
          variant="tonal"
          @click="closeModal"
        >
          {{ $t('global.btn.cancel') }}
        </v-btn>

        <v-btn
          class="float-right"
          color="success"
          :prepend-icon="mdiCheck"
          variant="tonal"
          @click="deleteMissionPlanning"
        >
          {{ $t('global.btn.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { mdiCheck, mdiClose } from '@mdi/js';
import { useStoreManager } from '@/stores/storeManager';

const props = defineProps({
  missionPlanningId: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Boolean,
    required: false,
    default: false
  }
});

const emit = defineEmits(['update:modelValue']);

const storeManager = useStoreManager();

const deleteMissionPlanning = () => {
  storeManager.remove('mission', 'missions_plannings', props.missionPlanningId);
  closeModal();
}

const closeModal = () => {
  emit('update:modelValue', false);
}

</script>

<template>
  <div class="messenger-header">
    <div class="text-h5">
      {{ threadPointOfSale.pos_code }} - {{ threadPointOfSale.name }}
    </div>
  </div>

  <v-card class="messenger px-2 py-6" color="grey-lighten-4">
    <div class="d-flex flex-column flex-1-1-100">
      <v-row class="d-block" v-for="(value, index) in threadMessages" :key="index">
        <message
          :message="value"
          :messageId="index"
        />
      </v-row>
    </div>
  </v-card>
  <message-form
      v-if="current"
      :threadId="threadId"
      :threadPointOfSale="threadPointOfSale"
  />
</template>

<script setup>
import Message from './Message.vue';
import MessageForm from './MessageForm.vue';
import { computed, onMounted } from 'vue';
import { useApplicationStore } from '@/stores/applicationStore';
import { useStoreManager } from '@/stores/storeManager';

const props = defineProps({
  threadId: {
    type: String,
    required: true,
  },
  thread: {
    type: Object,
    required: false,
  },
  threadMessages: {
    type: Object,
    required: true,
  },
  pointOfSales: {
    type: Object,
    required: true,
  },
  current: {
    type: Boolean,
    required: true,
  }
});

const applicationStore = useApplicationStore();
const userDetails = computed(() => applicationStore.userDetails);
const storeManager = useStoreManager();

const threadPointOfSale = computed(() =>
  Object.keys(props.thread).length > 0 ? props.pointOfSales[props.thread.pos.id] : {}
);

function markAsReadIncomingMessages() {
  let result = {};
  for (const key in props.threadMessages) {
    if (props.threadMessages.hasOwnProperty(key)) {
      if (
        props.threadMessages[key].read_by_user === false &&
        props.threadMessages[key].author.id !== userDetails.value.id
      ) {
        props.threadMessages[key].read_by_user = true;
        storeManager.update('postIt', 'messages', key, props.threadMessages[key]);
      }
    }
  }
  return result;
}

onMounted(() => {
  markAsReadIncomingMessages();
});
</script>

<style scoped>
.messenger-header {
  padding: 0 15px 15px;
}

.messenger, .messenger-header {
  width: 100%;
}
</style>
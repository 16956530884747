<template>
  <td
    v-for="(question, index) in questions" :key="question.id"
    :class="{
      'text-center': true,
      'row-selected': !productBlockedInEdition && productSelected && attachmentId === productSelected,
      'in-editing-process': productBlockedInEdition && attachmentId === productBlockedInEdition
    }"
  >
    <v-form :ref="form[attachmentId]" :data-is-visible="questionConditions[question.id]?.visibility">
      <div
        v-if="question.data_type?.value === QST.INPUT.TYPE.NUMERIC"
        :class="{
          'text-disabled pointer-none': !questionConditions[question.id]?.visibility || (productBlockedInEdition && attachmentId !== productBlockedInEdition),
          'd-none': !showHiddenQuestions && !questionConditions[question.id]?.visibility
        }"
      >
        <v-progress-circular v-if="loadingProcess(question.id)" size="x-small" indeterminate />
        <history-value
            block
            :question="question"
            :missionReadingHistory="missionReadingHistory"
            :attachment-type="props.attachmentType"
            :attachment-id="props.attachmentId" />
        <v-text-field
          class="mt-2"
          :min-width="minFieldWidth"
          v-model="formData[question.id]"
          type="number"
          density="compact"
          single-line
          :rules="inputRules(questionConditions[question.id], question)"
          v-bind="getQuestionInformations(question, formData[question.id])"
          :return-object="true"
          @update:model-value="() => { debounceSubmit(index, question.id) }"
        />
      </div>

      <div
        v-if="question.data_type?.value === QST.INPUT.TYPE.TEXTAREA || question.data_type?.value === QST.INPUT.TYPE.TEXT"
        :class="{
          'text-disabled pointer-none': !questionConditions[question.id]?.visibility || (productBlockedInEdition && attachmentId !== productBlockedInEdition),
          'd-none': !showHiddenQuestions && !questionConditions[question.id]?.visibility
        }"
      >
        <v-progress-circular v-if="loadingProcess(question.id)" size="x-small" indeterminate />
        <history-value
            block
            :question="question"
            :missionReadingHistory="missionReadingHistory"
            :attachment-type="props.attachmentType"
            :attachment-id="props.attachmentId" />
        <v-textarea
          class="mt-2"
          :min-width="minFieldWidth"
          v-model="formData[question.id]"
          density="compact"
          rows="1"
          :rules="inputRules(questionConditions[question.id], question)"
          :return-object="true"
          @update:model-value="() => { debounceSubmit(index, question.id) }"
        />
      </div>

      <div
        v-if="question.data_type?.value === QST.INPUT.TYPE.YES_NO"
        :class="{
          'opacity-06 pointer-none': !questionConditions[question.id]?.visibility || (productBlockedInEdition && attachmentId !== productBlockedInEdition),
          'd-none': !showHiddenQuestions && !questionConditions[question.id]?.visibility
        }"
      >
        <v-progress-circular v-if="loadingProcess(question.id)" size="x-small" indeterminate />
        <history-value
            block
            :question="question"
            :missionReadingHistory="missionReadingHistory"
            :attachment-type="props.attachmentType"
            :attachment-id="props.attachmentId" />
        <yes-no-toggle-input
          :compact="true"
          v-model="formData[question.id]"
          :rules="inputRules(questionConditions[question.id], question)"
          @update:model-value="() => { submitField(index, question.id) }"
        />
      </div>

      <div
        v-if="question.data_type?.value === QST.INPUT.TYPE.DROPDOWN"
        :class="{
          'text-disabled pointer-none': !questionConditions[question.id]?.visibility || (productBlockedInEdition && attachmentId !== productBlockedInEdition),
          'd-none': !showHiddenQuestions && !questionConditions[question.id]?.visibility
        }"
      >
        <v-progress-circular v-if="loadingProcess(question.id)" size="x-small" indeterminate />
        <history-value
            block
            :question="question"
            :missionReadingHistory="missionReadingHistory"
            :attachment-type="props.attachmentType"
            :attachment-id="props.attachmentId" />
        <v-select
          v-model="formData[question.id]"
          :items="question.value && question.value.split(',').map(item => item.trim()) || []"
          density="compact"
          :min-width="minFieldWidth"
          single-line
          :rules="inputRules(questionConditions[question.id], question)"
          :return-object="true"
          :value="question.default_value"
          @update:model-value="() => { submitField(index, question.id) }"
        />
      </div>

      <div
        v-if="question.data_type?.value === QST.INPUT.TYPE.DATE"
        :class="{
          'text-disabled pointer-none': !questionConditions[question.id]?.visibility || (productBlockedInEdition && attachmentId !== productBlockedInEdition),
          'd-none': !showHiddenQuestions && !questionConditions[question.id]?.visibility
        }"
      >
        <v-progress-circular v-if="loadingProcess(question.id)" size="x-small" indeterminate />
        <history-value
            block
            :question="question"
            :missionReadingHistory="missionReadingHistory"
            :attachment-type="props.attachmentType"
            :attachment-id="props.attachmentId" />
        <date-picker-input
          v-model="formData[question.id]"
          :rules="inputRules(questionConditions[question.id], question)"
          :to-iso="true"
          :min-width="minFieldWidth"
          v-bind="getQuestionInformations(question, formData[question.id])"
          @update:model-value="() => { submitField(index, question.id) }"
        />
      </div>
    </v-form>
  </td>
</template>

<script setup>
import { defineProps, ref, reactive, inject, nextTick, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { extractFormNamesFromQuestions } from '@/stores/manager/configurationManager';
import { useStoreManager } from '@/stores/storeManager';
import { READING, QST } from '@/constants';
import {
  extractReadingAnswersByReadingId,
  extractReadingAnswersByQuestionIdAndAttachment,
  createReadingIfNotExists
} from '@/stores/manager/readingManager';
import {
  extractQuestionsIds
} from '@/stores/manager/configurationManager';
import DatePickerInput from '@/components/common/DatePickerInput.vue';
import dateHandler from '@/utils/dateHandler';
import _ from 'lodash';
import { createOrUpdateConditions, saveReadingCounters } from '@/stores/manager/conditionsManager';
import { QuestionnaireConditionChecker } from "@/utils/conditionChecker/QuestionnaireConditionChecker";
import YesNoToggleInput from "@/components/common/YesNoToggleInput.vue";
import HistoryValue from "./HistoryValue.vue";
import Timer from '@/utils/timer';
import questionsHandler from "@/utils/questionsHandler";

const { t } = useI18n();

const props = defineProps({
  questions: {
    type: Object,
    required: true
  },
  groupId: {
    type: [Number, String],
    required: true
  },
  attachmentType: {
    type: String,
    required: true
  },
  attachmentId: {
    type: [Number, String],
    required: true
  },
  historyRecoveryData: {
    type: Object,
    required: false
  },
  productSelected: {
    type: Number,
    required: false
  },
  productBlockedInEdition: {
    type: Number,
    required: false
  },
  showMissingMandatoryAnswersFlag: {
    type: Boolean,
    required: false,
    default: false
  },
  triggerReload: {
    type: Boolean,
    required: false,
    default: false
  }
});

const emit = defineEmits(['answeredToAQuestion', 'productHasInvalidAnswer']);

const storeManager = useStoreManager();
const currentMissionPlanningId = inject('currentMissionPlanningId');

const currentConfiguration = inject('currentConfiguration');
const currentPointOfSale = inject('currentPointOfSale');
const currentReadingId = inject('currentReadingId');
const questionLoadingState = reactive([]);

const missionPlanning = inject('missionPlanning');
const mission = inject('mission');
const readingsHistory = inject('readingsHistory');
const readingAnswers = inject('readingAnswers');
const userDetails = inject('userDetails');
const showHiddenQuestions = inject('showHiddenQuestions');
const questionsConditionsWithAnswers = inject('questionsConditionsWithAnswers');

const form = {[props.attachmentId]: ref()};
const minFieldWidth = 144;

const missionReadingHistory = computed(() => {
  return Object.values(readingsHistory.value).find((rh) => parseInt(rh.mission) === parseInt(missionPlanning.value?.mission?.id))
});
const foundAnswers = (readingId) => {
  return extractReadingAnswersByReadingId(
    readingAnswers.value,
    readingId,
    extractQuestionsIds(props.questions)
  );
}
const invalidCheckedFields = ref(new Map());

const formData = ref(
  extractFormNamesFromQuestions(
    props.questions,
    foundAnswers(currentReadingId.value),
    props.attachmentType,
    props.attachmentId
  )
);

const addNewReadingAnswer = async (questionId, readingId, creationDate) => {
  return await storeManager
    .add('reading', 'answer', {
      reading: readingId,
      question: parseInt(questionId),
      value: formData.value[questionId],
      attachment_type: props.attachmentType || READING.ATTACHMENT_TYPE.NONE,
      attachment_id: props.attachmentId || null,
      creation_date: creationDate
    });
}

let debounceSubmit = _.debounce(submitField, 300);

async function submitField(index, questionId) {
  const submitTimer = new Timer("Submit");
  questionLoadingState[questionId] = true;
  const builtId = questionsHandler.buildId(questionId, props.attachmentType, props.attachmentId)
  submitTimer.addStep();
  await nextTick();
  submitTimer.echoStep("first nextTick()");
  const { valid } = await form[props.attachmentId]?.value[index].validate();
  submitTimer.echoStep("test input validity");
  if (valid) {
    invalidCheckedFields.value.delete(builtId);
    let readingId;
    const creationDate = dateHandler.generateNowUnixTimestamp();
    if (!currentReadingId.value) {
      submitTimer.addStep();
      readingId = await createReadingIfNotExists(
          '',
          mission.value?.configuration?.id,
          currentMissionPlanningId.value || null,
          userDetails.value.id,
          currentConfiguration.value?.data || null
      );
      submitTimer.echoStep("reading creation");
      await addNewReadingAnswer(questionId, readingId, creationDate);
      submitTimer.echoStep("New Reading Add Answer");
    } else {
      readingId = currentReadingId.value;
      submitTimer.addStep();
      const foundAnswerForQuestion = await extractReadingAnswersByQuestionIdAndAttachment(
        foundAnswers(readingId),
        parseInt(questionId),
        props.attachmentType,
        props.attachmentId
      );
      submitTimer.echoStep("extractReadingAnswersByQuestionIdAndAttachment");
      if (_.isEmpty(foundAnswerForQuestion)) {
        await addNewReadingAnswer(questionId, readingId, creationDate);
      } else {
        const clonedFoundAnswerForQuestion = {
          ...foundAnswerForQuestion.obj
        };
        clonedFoundAnswerForQuestion.value = formData.value[questionId];
        storeManager.update('reading', 'answer', foundAnswerForQuestion.key, clonedFoundAnswerForQuestion);
      }
      submitTimer.echoStep("Add reading Answer");
    }
    submitTimer.addStep();
    updateConditions(readingId, questionId);
    submitTimer.echoStep("Update conditions");

    if (props.attachmentType === READING.ATTACHMENT_TYPE.PRODUCT) {
      submitTimer.addStep();
      emit('answeredToAQuestion', props.attachmentId);
      submitTimer.echoStep("emit answeredToAQuestion");
    }

  } else {
    invalidCheckedFields.value.set(builtId, true);
    questionLoadingState[questionId] = false;
  }
  submitTimer.addStep();
  const productHasInvalidAnswer = checkIfProductHasInvalidAnswer();
  if (productHasInvalidAnswer) {
    emit('productHasInvalidAnswer', props.attachmentId);
  }
  submitTimer.echoStep("productHasInvalidAnswer check");
  submitTimer.echo();
}

const checkIfProductHasInvalidAnswer = () => {
  const questionsLinkedToAttachment = Object.values(questionConditions.value)
  for (let i = 0; i < questionsLinkedToAttachment.length; i++) {
    if (
        questionsLinkedToAttachment[i].mandatory &&
        (
            !questionsLinkedToAttachment[i].validity ||
            invalidCheckedFields.value.has(questionsLinkedToAttachment[i].code)
        )
    ) {
      return true;
    }
  }
  return false;
}

const inputRules = (cond, qst) => {
  return [
    (v) => cond?.mandatory ? (!!v || v === false || t('global.validator.mandatoryField')) : true,
    (v) => conditionCheckerRule(qst, v)
  ];
}

const updateConditions = (readingId, questionId) => {
  const updateConditionsTimer = new Timer("Update Conditions", 1);
  let initialContext = {
    currentValue: null,
    answers: Object.values(readingAnswers.value).filter((ra) => ra.reading === readingId),
    pos: currentPointOfSale.value,
    attachementType: props.attachmentType || READING.ATTACHMENT_TYPE.NONE,
    attachementId: props.attachmentId,
    prod: null,
    nowDatetime: new Date()
  };
  updateConditionsTimer.echoStep("build initialContext");
  createOrUpdateConditions(
    currentConfiguration.value,
    initialContext,
    readingId
  );
  updateConditionsTimer.echoStep("createOrUpdateConditions");
  saveReadingCounters(readingId, currentConfiguration.value)
  updateConditionsTimer.echoStep("saveReadingCounters");
  questionLoadingState[questionId] = false;
  updateConditionsTimer.echo();
}

async function conditionCheckerRule(question, value) {
  return new Promise(resolve => {
    const questionnaireConditionChecker = new QuestionnaireConditionChecker()
    const context = buildContext(value)
    const questionValidityCondition = question.conditions['validity'];
    const validationResult = questionnaireConditionChecker.check(questionValidityCondition, context);
    return resolve(validationResult?.result === true || !value || validationResult?.messages?.join(t('conditions-messages.or')));
  })
}

const questionConditions = computed(() => {
  return questionsConditionsWithAnswers.value.filter((qc) => {
    if (props.attachmentType && props.attachmentType !== READING.ATTACHMENT_TYPE.NONE) {
      return qc?.code?.includes(`:${props.attachmentType}|${props.attachmentId}`)
    }
    else {
      return qc?.code.toString() === qc.question_id.toString();
    }
  }).reduce((a, qc) => ({ ...a, [qc.question_id]: qc}), {}) || {};
});

async function focusNextMandatoryField() {
  const forms = form[props.attachmentId]?.value;
  for (let i = 0; i < forms.length; i++) {
    const { valid } = await forms[i].validate();
    if (!valid) {
      forms[i].focus();
      forms[i].scrollIntoView({ behavior: "smooth", block: "center" });
      break;
    }
  }
}

function getQuestionInformations(question, value) {
  const questionnaireConditionChecker = new QuestionnaireConditionChecker()
  const context = buildContext(value)
  const questionValidityCondition = question.conditions['validity'];
  return questionnaireConditionChecker.getInformations(questionValidityCondition, context)
}

function buildContext(value) {
  const products = currentConfiguration.value?.catalog?.product || []
  return {
    currentValue: value,
    answers: [],
    pos: currentPointOfSale.value,
    attachementType: props.attachmentType || READING.ATTACHMENT_TYPE.NONE,
    attachementId: props.attachmentId,
    prod: props.attachmentType === READING.ATTACHMENT_TYPE.PRODUCT ? products[props.attachmentId] : null,
    nowDatetime: new Date()
  }
}

const loadingProcess = (questionId) => questionId ? questionLoadingState[questionId] : false;

watch(props.historyRecoveryData, () => {
  if (props.historyRecoveryData.hasOwnProperty(props.groupId)) {
    props.questions.forEach((question, index) => {
      const answerValue = props.historyRecoveryData[props.groupId][question.id] || null;
      if (answerValue && !formData.value[question.id]) {
        formData.value[question.id] = answerValue || null;
        submitField(index, question.id);
      }
    });
  }
});
watch(() => props.showMissingMandatoryAnswersFlag, () => {
  if (props.attachmentId !== props.productBlockedInEdition) {
    return;
  }
  focusNextMandatoryField();
});

watch(() => props.triggerReload, () => {
  formData.value =
      extractFormNamesFromQuestions(
          props.questions,
          foundAnswers(currentReadingId.value),
          props.attachmentType,
          props.attachmentId

  );
});
</script>

<style scoped>
.screen-element-table td {
  border: 1px solid #d1d1d1;
  background-color: white;
}
</style>
import { Capacitor } from '@capacitor/core';

export class OneSignalCapacitorNotifications {


  isAvailable(){
    return Capacitor.isNativePlatform();
  }

  log(line){
    console.log(`[notifications onesignal capacitor] ${line}`)
  }

  init(callback) {
    this.log("initializing...")

    const OneSignalInit = () => {
      //window.plugins.OneSignal.Debug.setLogLevel(6);
      window.plugins.OneSignal.initialize(window.env.VITE_APP_ONESIGNAL_APP_ID || import.meta.env.VITE_APP_ONESIGNAL_APP_ID);
      
      this.notificationPermissions();
      this.notificationEvent(callback);
    }

    document.addEventListener("deviceready", OneSignalInit, false);
  }

  login(userId) {
    this.log("login...")
    window.plugins.OneSignal.login(userId);
  }

  logout() {
    this.log("logout...")
    window.plugins.OneSignal.logout();
  }

  notificationPermissions() {
    this.log("requesting permissions...")
    window.plugins.OneSignal.Notifications.requestPermission(true, function(accepted) {
        console.log("user acceptation: " + (accepted ? "yes" : "no"))
    });
  }

  notificationEvent(callback) {
    this.log("registering event...")
      
      /*window.plugins.OneSignal.setNotificationOpenedHandler(function(jsonData) {
        let notificationData = JSON.stringify(jsonData);
        console.log('notificationOpenedCallback: ' + notificationData);
        
        if (callback) {
          callback(notificationData)
        }
      });*/
  }

}

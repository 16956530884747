<template>
  <div class="mt-5">
    <v-card variant="tonal" class="mb-5">
      <v-card-title :style="{'color': appStyle.title_text_color}">
        <v-icon class="mx-3 my-1" size="small" :icon="mdiContacts"/>
        {{ contactsListTitle || $t('mission-planning.qst.info-pos.label.pos-contacts') }}
        <v-spacer></v-spacer>
        <v-btn
            @click="openNewModal"
            size="small"
            variant="text"
            :title="$t('contact.new-contact')"
            :aria-label="$t('contact.new-contact')"
            :icon="mdiPlus"></v-btn>

      </v-card-title>

      <v-divider/>

      <delete-contact-confirmation-modal
          :show="dialogDelete"
          @close-dialog="closeDeleteModal"
          @delete-contact="confirmDelete"/>

      <contact-view-modal
          :contact="editedItem"
          :show="showDialog"
          @close-dialog="closeViewModal()"/>

      <contact-edit-modal
          :configuration="configuration"
          :contact="editedItem"
          :point-of-sale="pointOfSale"
          :show="editDialog"
          @close-dialog="closeEditModal()"/>

      <v-data-table
          items-per-page="-1"
          :headers="headers"
          :items="contacts"
          item-value="name"
          class="elevation-1 no-footer"
      >
        <template v-slot:item="{ item }">
          <tr class="v-data-table__tr">
            <td class="v-data-table__td v-data-table-column--align-start cursor-pointer" @click="openViewModal(item)">{{item.lastname}}</td>
            <td class="v-data-table__td v-data-table-column--align-start cursor-pointer" @click="openViewModal(item)">{{item.firstname}}</td>
            <td class="v-data-table__td v-data-table-column--align-start cursor-pointer" @click="openViewModal(item)">{{item.job}}</td>
            <td class="v-data-table__td v-data-table-column--align-start cursor-pointer" @click="openViewModal(item)">{{item.phone}}</td>
            <td class="v-data-table__td v-data-table-column--align-start">
              <v-icon
                  size="small"
                  class="me-2"
                  :icon="mdiPencil"
                  @click.prevent="openEditModal(item)"
              />
              <v-icon
                  size="small"
                  :icon="mdiDelete"
                  @click.prevent="openDeleteModal(item)"
              />
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <div v-if="showBackButton" class="d-flex flex-row-reverse mb-6">
      <v-btn
          variant="tonal"
          :prepend-icon="mdiArrowLeft"
          class="float-right"
          @click="router.go(-1)"
      >
        {{ $t('global.btn.back') }}
      </v-btn>
    </div>
  </div>
</template>

<script setup>
import {
  mdiContacts,
  mdiArrowLeft,
  mdiPencil,
  mdiPlus,
  mdiDelete,
} from '@mdi/js';
import { ref, watch, computed } from 'vue';
import ContactViewModal from "@/components/contact/contactViewModal.vue";
import ContactEditModal from "@/components/contact/contactEditModal.vue";
import {useStoreManager} from '@/stores/storeManager';
import {useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n';
import { useApplicationStore } from '@/stores/applicationStore';
import DeleteContactConfirmationModal
  from "@/components/contact/deleteContactConfirmationModal.vue";

const {t} = useI18n();

const props = defineProps({
  contacts: {
    type: Object,
    required: true,
    default: {}
  },
  contactsListTitle: {
    type: String,
    required: false,
  },
  showBackButton: {
    type: Boolean,
    required: false,
    default: true
  },
  pointOfSale: {
    type: Object,
    required: false
  },
  configuration: {
    required: false
  }
});

const headers = [
  {title: t('contact.lastname'), align: 'start', key: 'lastname'},
  {title: t('contact.firstname'), align: 'start', key: 'firstname'},
  {title: t('contact.job'), align: 'start', key: 'job'},
  {title: t('contact.phone'), align: 'start', key: 'phone'},
  {title: 'Actions', key: 'actions', sortable: false}
];

const applicationStore = useApplicationStore();
const appStyle = computed(() => applicationStore.appStyle);

const storeManager = useStoreManager();
const router = useRouter();

const showDialog = ref(false);
const editDialog = ref(false);
const dialogDelete = ref(false);
const editedItem = ref({});

function openViewModal(item) {
  editedItem.value = item;
  showDialog.value = true;
}

function closeViewModal() {
  showDialog.value = false
}

function openEditModal(item) {
  editedItem.value = item;
  editDialog.value = true;
}

function openNewModal() {
  editDialog.value = true;
}

function closeEditModal() {
  editDialog.value = false
}

function openDeleteModal(item) {
  editedItem.value = item
  dialogDelete.value = true
}

function closeDeleteModal() {
  dialogDelete.value = false
}

function confirmDelete() {
  storeManager.remove('mission', 'contacts', editedItem.value.id);
  closeDeleteModal()
}

watch(editDialog, (visible) => {
  if (!visible) {
    editedItem.value = {};
  }
})

watch(dialogDelete, (visible) => {
  if (!visible) {
    editedItem.value = {};
  }
})

watch(showDialog, (visible) => {
  if (!visible) {
    editedItem.value = {};
  }
})
</script>
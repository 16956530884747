import { createRouter, createWebHistory } from 'vue-router';
import NotFound from '@/views/error/NotFound.vue';
import LoginView from '../views/LoginView.vue';
import PostItPageView from '../views/PostItPageView.vue';
import DebugView from '../views/DebugView.vue';
import PostItThreadDetailsPageView from '../views/PostItThreadDetailsPageView.vue';
import StoreListView from "../views/StoreListView.vue";
import { useApplicationStore } from "../stores/applicationStore";
import { computed } from 'vue';
import PointOfSalePageView from '@/views/PointOfSalePageView.vue';
import ContactsPageView from '@/views/ContactsPageView.vue';
import PlanningPageView from '@/views/PlanningPageView.vue';
import MissionPlanningPageView from "@/views/MissionPlanningPageView.vue";
import InformationSubPageView from "@/views/sub-pages/InformationSubPageView.vue";
import WhalyFocusPageView from "@/views/sub-pages/WhalyFocusPageView.vue";
import QuestionnairesSubPageView from "@/views/sub-pages/QuestionnairesSubPageView.vue";
import ContactsList from "@/components/contact/ContactsList.vue";
import MissionPageView from "@/views/MissionPageView.vue";
import QuestionnaireScreenSubPageView from "@/views/sub-pages/QuestionnaireScreenSubPageView.vue";
import {useStoreManager} from "../stores/storeManager";
import {hasAtLeastOneReducedTopMenu} from "../stores/manager/configurationManager";
import LogsPageView from "@/views/LogsPageView.vue";
import WhalySectorPageView from "@/views/WhalySectorPageView.vue";
import QuestionnaireScreenCategorySubPageView from '@/views/sub-pages/QuestionnaireScreenCategorySubPageView.vue';


const ifLoggedIn = (_to, _from, next) => {
  const applicationStore = useApplicationStore();
  const isLoggedIn = computed(() => applicationStore.isLoggedIn || false);

  if (isLoggedIn.value) {
    next();
    return;
  }

  next('/login');
};


const ifNotReducedMenu = (_to, _from, next) => {
  const storeManager = useStoreManager();
  const configurations = computed(() => storeManager.getFromStoreByCategory('configuration', 'configurations') || {});

  if (!hasAtLeastOneReducedTopMenu(configurations.value)) {
    next();
    return;
  }

  next('/messages');
};

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/planning'
    },
    {
      path: '/messages',
      name: 'post',
      beforeEnter: ifLoggedIn,
      component: PostItPageView
    },
    {
      path: '/debug',
      name: 'debug',
      component: DebugView
    },
    {
      path: '/store-list',
      name: 'store-list',
      component: StoreListView
    },
    {
      path: '/messages/thread-details/:id',
      name: 'thread-details',
      beforeEnter: ifLoggedIn,
      component: PostItThreadDetailsPageView
    },
    {
      path: '/points-of-sale',
      name: 'pos-list',
      beforeEnter: [ifLoggedIn,ifNotReducedMenu],
      component: PointOfSalePageView
    },
    {
      path: '/planning',
      name: 'planning',
      beforeEnter: [ifLoggedIn,ifNotReducedMenu],
      component: PlanningPageView
    },
    {
      path: '/whaly-sector',
      name: 'whaly-sector',
      beforeEnter: [ifLoggedIn,ifNotReducedMenu],
      component: WhalySectorPageView
    },
    {
      path: '/mission/:id',
      name: 'mission',
      beforeEnter: [ifLoggedIn,ifNotReducedMenu],
      component: MissionPageView
    },
    {
      path: '/mission-planning/:id',
      name: 'mission-planning',
      beforeEnter: [ifLoggedIn,ifNotReducedMenu],
      component: MissionPlanningPageView,
      children: [
        {
          path: 'information',
          name: 'mission-planning-information',
          component: InformationSubPageView
        },
        {
          path: 'whaly-focus',
          name: 'mission-planning-whaly-focus',
          component: WhalyFocusPageView
        },
        {
          path: 'questionnaires',
          children: [
            {
              path: '',
              name: 'mission-planning-questionnaires',
              component: QuestionnairesSubPageView,
            },
            {
              path: 'screen/:screen_id',
              name: 'mission-planning-questionnaire-screen',
              component: QuestionnaireScreenSubPageView,
            },
            {
              path: 'screen/:screen_id/category/:category_id',
              name: 'mission-planning-questionnaire-screen-category',
              component: QuestionnaireScreenCategorySubPageView,
              props: route => ({ barcode: route.query.barcode })
            }
          ]
        },
        {
          path: 'contacts',
          name: 'mission-planning-contacts',
          component: ContactsList
        }
      ]
    },
    {
      path: '/contacts',
      name: 'contacts',
      beforeEnter: [ifLoggedIn,ifNotReducedMenu],
      component: ContactsPageView
    },
    {
      path: '/logs',
      name: 'logs',
      beforeEnter: [ifLoggedIn],
      component: LogsPageView
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/404',
      name: 'not-found',
      component: NotFound,
    },
    {
      path: '/:catchAll(.*)',
      redirect: '/404'
    }
  ]
})

export default router

<template>
  <div class="mt-5" v-if="currentScreenId">
    <router-view />
  </div>

  <div class="my-5" v-if="!currentScreenId">
    <info-pos
      :pointOfSale="pointOfSale"
      :mission="mission"
    />
    <v-sheet class="p-0 m-0 mb-5" width="100%">
      <statistic-indicators-wrapper
          v-if="currentReading?.counters?.statisticIndicators"
          :counters="currentReading?.counters"
          :show-label="true"
          :show-previous-reading="true"
      />
    </v-sheet>
    <my-questionnaires
      :configuration="configuration"
      :screens="screens"
      :groups="groups"
      :screenQuestions="screenQuestions"
      :groupQuestions="groupQuestions"
      :pointOfSale="pointOfSale"
      :current-reading-id="currentReadingId"
    />

    <reading-status-report
      :readings="readings"
    />

    <total-charts
      :configuration="configuration"
    />
  </div>
</template>

<script setup>
import InfoPos from '@/components/mission-planning/questionnaires/InfoPos.vue';
import MyQuestionnaires from '@/components/mission-planning/questionnaires/MyQuestionnaires.vue';
import TotalCharts from '@/components/mission-planning/questionnaires/TotalCharts.vue';
import ReadingStatusReport from '@/components/mission-planning/questionnaires/ReadingStatusReport.vue';
import { getGroupsWithoutScreen, extractScreenQuestions, extractGroupQuestions } from '@/stores/manager/configurationManager';
import { useRoute } from 'vue-router';
import { computed, inject, defineOptions } from 'vue';
import { useStoreManager } from '@/stores/storeManager';
import { extractReadingIdByParams } from '@/stores/manager/readingManager';
import StatisticIndicatorsWrapper from "@/components/common/StatisticIndicatorsWrapper.vue";

const props = defineProps({
  missionPlanning: {
    type: Object,
    required: false
  },
  mission: {
    type: Object,
    required: true
  },
  pointOfSale: {
    type: Object,
    required: true
  },
  configuration: {
    type: Object,
    required: true
  },
  readings: {
    type: Object,
    required: true,
  },
  flashNews: {
    type: Object,
    required: false
  },
  contacts: {
    type: Object,
    required: false
  },
  title: {
    type: String,
    required: false
  }
});
defineOptions({
  inheritAttrs: false
})
const route = useRoute();
const currentMissionPlanningId = computed(() => route.params.id);
const currentScreenId = computed(() => route.params.screen_id);
const storeManager = useStoreManager();

const screens = computed(() => props.configuration?.screen?.filter((screen) => screen.active) || [])

const screenQuestions = computed(() => extractScreenQuestions(Object.values(props.configuration?.questions || {}), screens.value));

const readings = computed(() => storeManager.getFromStoreByCategory('reading', 'reading') || {})

const userDetails = inject('userDetails', {});

const currentReadingId = computed(() =>
  extractReadingIdByParams(readings.value, props.configuration?.id, currentMissionPlanningId.value, userDetails.value?.id)
)
const currentReading = computed(() => readings?.value[currentReadingId.value] || {});

const groupQuestions = computed(() => extractGroupQuestions(
    Object.values(props.configuration?.questions || {}).sort((a, b) => a.order - b.order),
    props.configuration?.group?.filter((group) => group.active) || [],
    currentReadingId.value || 'default'
));

const groups = computed(() => getGroupsWithoutScreen(props.configuration?.group?.filter((group) => group.active), currentReadingId.value || 'default'));
</script>

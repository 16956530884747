<template>
  <v-offline
    online-class="online"
    offline-class="offline"
    @detected-condition="setConnected"
  >
    <v-btn
      :icon="connectionModeIcon()"
      :color="btnColor"
      @click="switchConnectionMode()"
    />
  </v-offline>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useApplicationStore } from '@/stores/applicationStore';
import {
  mdiAirplane,
  mdiWifi,
  mdiWifiOff,
} from '@mdi/js'
import { VOffline } from 'v-offline';

const props = defineProps({
  btnColor: {
    required: true
  }
});

const isOnline = ref(navigator.onLine);

const emit = defineEmits(['switchOnlineStatus']);

const setConnected = (e) => {
  isOnline.value = e;
  emit('switchOnlineStatus', e);
};

const applicationStore = useApplicationStore();
const airplaneMode = computed(() => applicationStore.airplaneMode || false);

const connectionModeIcon = () => {
  if (airplaneMode.value === true) {
    return mdiAirplane;
  } else {
    return isOnline.value ? mdiWifi : mdiWifiOff;
  }
};

const switchConnectionMode = () => {
  if (airplaneMode.value === true) {
    applicationStore.airplaneMode = false;
  } else {
    applicationStore.airplaneMode = true;
  }
}
</script>

<template>
  <v-container :class="{'opacity-08 pointer-none': readonlyPage()}">
    <v-alert v-if="missionPlanning.to_remove"
       class="mb-5"
       variant="tonal"
       type="error"
       :text="$t('planning.message.to-remove')"
    />
    <mission-planning-debug-block
      v-if="showDebugMode"
      :debug-type="DEBUG.TYPE.MP.MISSION_PLANING_PAGE"
      :mission-planning="missionPlanning"
      :point-of-sale="pointOfSale"
      :reading="currentReading"
      :lastReadingHistory="lastReadingHistory"
    />

    <template v-if="!missionPlanning.to_remove">
      <div class="planning-menu"
           v-if="canShowPlanningMenu">
        <mission-planning-menu
            :pointOfSale="pointOfSale"
            :pointsOfSale="pointsOfSale"
            :missionPlanning="missionPlanning"
            :missionPlanningId="currentMissionPlanningId"
            :mission="mission"
            :configuration="configuration"
        />
      </div>

      <router-view
        :missionPlanning="missionPlanning"
        :mission="mission"
        :pointOfSale="pointOfSale"
        :configuration="configuration"
        :readings="readings"
        :flashNews="flashNews"
        :contacts="contacts"
      />
    </template>
  </v-container>
</template>

<script setup>
import MissionPlanningMenu from '@/components/mission-planning/MissionPlanningMenu.vue';
import MissionPlanningDebugBlock from '@/components/global/MissionPlanningDebugBlock.vue';
import { extractLastReadingByDateAndMission } from '@/stores/manager/readingHistoryManager';
import { computed, provide } from 'vue';
import { useStoreManager } from '@/stores/storeManager';
import { useRoute } from 'vue-router';
import {extractReadingIdByParams} from '@/stores/manager/readingManager';
import {useApplicationStore} from '@/stores/applicationStore';
import {useReadingConditionStore} from '@/stores/conditionStore';
import { READING, DEBUG } from '@/constants';
import _ from 'lodash';
import {StatisticIndicatorCalculator} from "@/utils/statisticIndicatorCalculator/StatisticIndicatorCalculator";
import {getQuestionsConditionsWithAnswers} from "@/stores/manager/conditionsManager";


const route = useRoute();
const currentMissionPlanningId = computed(() => route.params.id);
provide('currentMissionPlanningId', currentMissionPlanningId);

const storeManager = useStoreManager();
const applicationStore = useApplicationStore();
const readingConditionStore = useReadingConditionStore();
const statsCalculator = new StatisticIndicatorCalculator();

const canShowPlanningMenu = computed(() => {
  return !(route.name === 'mission-planning-questionnaire-screen-category' && applicationStore.data?.condensedForm);
});
const userDetails = computed(() =>
  storeManager.getFromStoreByCategory('application', 'userDetails')
);
provide('userDetails', userDetails);

const showDebugMode = computed(() => applicationStore.showDebugMode);

const missionPlanning = computed(() => {
  let mp = storeManager.getFromStoreByCategory('mission', 'missionsPlannings')[currentMissionPlanningId.value]
  return mp ? { ...mp, ['id']: currentMissionPlanningId.value } : {};
});

const mission = computed(() =>
  storeManager.getFromStoreByCategory('mission', 'missions')[missionPlanning.value?.mission?.id] || {}
);

const pointsOfSale = computed(() =>
  storeManager.getFromStoreByCategory('mission', 'pointsOfSale') || {}
);

const pointOfSale = computed(() =>
{
  const pos = pointsOfSale?.value[mission.value?.pos?.id] || {}
  if (pos === {}) {return pos;}
  pos.id = mission.value?.pos?.id
  return pos
})
provide('currentPointOfSale', pointOfSale);

const configuration = computed(() =>
  storeManager.getFromStoreByCategory('configuration', 'configurations')[mission.value?.configuration?.id] || {}
);
provide('currentConfiguration', configuration);

const readingsHistory = computed(() =>
  storeManager.getFromStoreByCategory('readingHistory', 'reading') || {}
);

const readings = computed(() =>
  storeManager.getFromStoreByCategory('reading', 'reading') || {}
);

const currentReadingId = computed(() =>
  extractReadingIdByParams(readings.value, configuration.value?.id, currentMissionPlanningId.value, userDetails.value?.id)
)
provide('currentReadingId', currentReadingId);

const currentReading = computed(() => readings?.value[currentReadingId.value] || {});

const answers = computed(() =>
  Object.values(storeManager.getFromStoreByCategory('reading', 'answer'))
    .filter(answer => answer.reading?.toString() === currentReadingId.value?.toString())
  || {});

const questionsConditions = computed(() => readingConditionStore.readingConditions[currentReadingId.value || 'default']?.questions);
provide('questionsConditions', questionsConditions);

const questionsConditionsWithAnswers = computed(() => {
  return getQuestionsConditionsWithAnswers(currentReadingId.value, answers.value);
})
provide('questionsConditionsWithAnswers', questionsConditionsWithAnswers);

const statisticIndicators = computed(() => {
  return statsCalculator.calculate( Object.values(configuration.value?.statisticIndicators || {}),{
    questionsConditionsWithAnswers: questionsConditionsWithAnswers.value,
    answers: answers.value,
    statisticIndicatorsProductIds: pointOfSale.value?.statisticIndicators
  });
})
provide('statisticIndicators', statisticIndicators);

const totalCount = computed(() => {
  return questionsConditionsWithAnswers.value.length;
});
provide('totalCount', totalCount);

const totalAnswersCount = computed(() => {
  return questionsConditionsWithAnswers.value.filter(conditionItem => conditionItem.hasAnswer).length;
});
provide('totalAnswersCount', totalAnswersCount);

const totalMandatoryCount = computed(() => {
  return questionsConditionsWithAnswers.value.filter(conditionItem => conditionItem.mandatory).length;
});
provide('totalMandatoryCount', totalMandatoryCount);

const totalMandatoryAnswersCount = computed(() => {
  return questionsConditionsWithAnswers.value.filter(conditionItem => conditionItem.mandatory && conditionItem.hasAnswer).length;
});
provide('totalMandatoryAnswersCount', totalMandatoryAnswersCount);

const totalOptionalCount = computed(() => {
  return totalCount.value - totalMandatoryCount.value;
});
provide('totalOptionalCount', totalOptionalCount);

const totalOptionalAnswersCount = computed(() => {
  return totalAnswersCount.value - totalMandatoryAnswersCount.value;
});
provide('totalOptionalAnswersCount', totalOptionalAnswersCount);

const currentPictures = computed(() =>
    Object.entries(storeManager.getFromStoreByCategory('reading', 'picture'))
        .filter(([key, value]) => currentReadingId.value && !value.to_remove && value.reading?.toString() === currentReadingId.value?.toString())
        .map(([key, value]) => {
          return { id: key, ...value }
        })|| {});
provide('currentPictures', currentPictures);

const flashNews = computed(() =>
  storeManager.getFromStoreByCategory('flashNews', 'flashNews') || {}
);

const contacts = computed(() =>
    Object.values(storeManager.getFromStoreByCategory('mission', 'contacts'))
        .filter(
            contact => contact?.point_of_sale?.id.toString() === mission.value?.pos?.id.toString() &&
                !contact?.to_remove
        ) || {}
);

const lastReadingHistory = computed(() =>
  extractLastReadingByDateAndMission(
    readingsHistory.value,
    missionPlanning.value?.mission?.id
  ) || {}
);

provide('lastReadingHistory', lastReadingHistory);

const readonlyPage = () =>
  _.includes(
    [READING.STATUS.TRANSMITTED, READING.STATUS.TO_BE_TRANSMITTED, READING.STATUS.DELETED],
    currentReading.value?.status
  );

</script>
<template>
  <div class="d-flex flex-wrap justify-space-between">
    <v-sheet class="d-flex flex-wrap mr-5">
      <span
        class="text-h5 font-weight-bold mr-3 my-2"
        :style="{'color':appStyle.title_text_color}"
      >
        {{ props.title }}
      </span>

      <v-select
        v-model="selectedConfiguration"
        :items="configurations"
        item-title="customer.name"
        return-object
        single-line
        density="compact"
        @update:modelValue="configurationSelected"
      />
    </v-sheet>
  </div>
</template>

<script setup>
import { computed, ref, defineEmits, defineProps } from "vue";
import { useStoreManager } from '@/stores/storeManager';
import { useApplicationStore } from '@/stores/applicationStore';

const props = defineProps({
  title: {
    type: String,
    required: true
  }
});

const storeManager = useStoreManager();
const emit = defineEmits(['configurationSelected']);

const applicationStore = useApplicationStore();
const appStyle = computed(() => applicationStore.appStyle);

const selectedConfiguration = ref({});

const configurations = computed(() => {
    const rawConfigurations = storeManager.getFromStoreByCategory('configuration', 'configurations');
    Object.keys(rawConfigurations).forEach(function (key) {
      rawConfigurations[key]["id"] = key;
    });
    return Object.values(rawConfigurations)
  }
);

init();

function init() {
  selectedConfiguration.value = configurations.value[0];
  configurationSelected();
}

function configurationSelected() {
  emit('configurationSelected', selectedConfiguration.value);
}
</script>

export const READING = {
  STATUS: {
    NEW: 'new',
    TO_BE_TRANSMITTED: 'toBeTransmitted',
    TRANSMITTED: 'transmitted',
    DELETED: 'deleted',
  },
  ATTACHMENT_TYPE: {
    NONE: 'none',
    CATEGORY: 'by_category',
    PRODUCT: 'by_product',
    PICTURE: 'by_picture',
    ELEMENT: 'by_element'
  },
}

export const COUNTER = {
  STATUS: {
    NEW: 'new',
    READY: 'ready',
    NOT_READY: 'notReady'
  },
}
<template>
  <v-expansion-panels>
    <v-expansion-panel
      v-if="!!attachmentLoopItems"
      v-for="attachmentItem in attachmentLoopItems.items"
      :class="{
        'text-disabled pointer-none': !screen.visibility,
        'd-none': !showHiddenQuestions && !screen.visibility
      }"
    >
      <v-expansion-panel-title>
        <div class="d-flex flex-wrap w-100 align-center justify-space-between">
          <div class="my-1 expansion-panel-title">
            {{ attachmentItem[attachmentLoopItems.labelKey] }}
          </div>
          <div class="ml-auto">
            <questionnaire-capsules
                questionnaire-type="screen"
                :questionnaire-type-id="screen.id"
                :attachment-type="attachmentLoopItems.type"
                :attachment-id="attachmentItem.id"
            />
          </div>
        </div>
      </v-expansion-panel-title>

      <v-expansion-panel-text>
        <questionnaire-group-wrapper
          :screen="screen"
          :questions="groupedQuestions"
          questionnaireType="screen"
          :attachment-type="attachmentLoopItems.type"
          :attachment-id="attachmentItem.id"
        />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import { computed } from 'vue';
import { useApplicationStore } from '@/stores/applicationStore';
import QuestionnaireCapsules from '@/components/mission-planning/questionnaires/parts/QuestionnaireCapsules.vue';
import QuestionnaireGroupWrapper from '@/components/mission-planning/questionnaires/QuestionnaireGroupWrapper.vue';


const props = defineProps({
  screen: {
    type: Object,
    required: true
  },
  groupedQuestions: {
    type: Object,
    required: false
  },
  attachmentLoopItems: {
    type: Object,
    required: false
  },
});

const applicationStore = useApplicationStore();

const showHiddenQuestions = computed(() => applicationStore.showHiddenQuestions);
</script>



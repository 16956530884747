import { useApplicationStore } from '@/stores/applicationStore';
import { useStoreManager } from '@/stores/storeManager';

class SyncManager {

  init(){
    console.log("[Sync] init...")

    this.applicationStore = useApplicationStore();
    this.storeManager = useStoreManager();

    this.partialCycleFrequency = 5*60;
    this.completeCycleFrequency = 15*60;
    this.completeCyclePushDelay = 3;

    this.initPartialCycle();
    this.initCompleteCycle();
  }

  checkProcessSync() {
    if (this.applicationStore.airplaneMode) {
      console.log("[Sync] airplaneMode in ON : skip sync.")
      return false 
    }

    if (! this.applicationStore.isLoggedIn) {
        console.log("[Sync] no logged user : skip sync.")
        return false 
    } 

    return true;
  }

  initPartialCycle() {
    console.log(`[Sync] ...init 'partial' cycle (${this.partialCycleFrequency}s)...`)
    setInterval(async () => {
      this.runPartialCycle();
    }, this.partialCycleFrequency * 1000);
  }


  initCompleteCycle(){
    console.log(`[Sync] ...init 'complete' cycle (${this.completeCycleFrequency}s + ${this.completeCyclePushDelay}s)...`)

    setInterval(async () => {
      this.runCompleteCycle();
    }, this.completeCycleFrequency * 1000);
  }

  async runPartialCycle(callback){
    if (!this.checkProcessSync()){
      return;
    }

    this.applicationStore.syncStatus.partialCycle = true; 
    await this.pushAll();
    await this.pullPartial();
    this.applicationStore.syncStatus.partialCycle = false; 

    if (callback) {
      callback()
    }
  }

  async runCompleteCycle(callback){
    
    if (!this.checkProcessSync()) {
      return false;
    }

    this.applicationStore.syncStatus.completeCycle = true; 
    await this.pushAll();

    setTimeout(async () => {
      await this.pullAll();
      this.applicationStore.syncStatus.completeCycle = false; 
      this.applicationStore.updateLastSyncDate();

      if (callback) {
        callback()
      }

    }, this.completeCyclePushDelay * 1000);
    return true;
  }


  async pushAll(){
    await this.push([
      'mission', 
      'reading', 
      'postIt'
    ])
  }

  async pullPartial(){
      await this.pull([
        'postIt',
        'flashNews'
      ]);
  }

  async pullAll(){
      await this.pull([
        'postIt',
        'readingHistory',
        'reading',
        'flashNews',
        'mission',
        'configuration' // important to be the last one for post pull actions
      ])
  }


/*
  resetLog(){
    
  }

  addLog(action, item){
    
  }
*/


async push(stores) {
  console.log("[Sync] ...push...")

  for(const store of stores) {
    console.log(`[Sync] ...pushing ${store}...`)
    await this.storeManager.push(store);  
  }  
}

async pull(stores) {
  console.log("[Sync] ...pull...")

  for(const store of stores) {
    console.log(`[Sync] ...pulling ${store}...`)
    await this.storeManager.pull(store);  
  }  

}


  
};

export default new SyncManager();

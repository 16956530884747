import {OneSignalWebNotifications} from './OneSignal/OneSignalWebNotifications';
import {OneSignalCapacitorNotifications} from './OneSignal/OneSignalCapacitorNotifications';

class NotificationService {

  constructor(){

    let capacitor = new OneSignalCapacitorNotifications();

    if (capacitor.isAvailable()) {
      console.log("[notifications] Enabling capacitor notifications")
      this.notification = capacitor;  
      return;
    }

    console.log("[notifications] Enabling web notifications")
    this.notification = new OneSignalWebNotifications();
  }

  init(callback) {
    this.notification.init(callback);
  }

  login(userId) {
    this.notification.login(userId)
  }

  logout() {
    this.notification.logout()
  }

}

export default new NotificationService()
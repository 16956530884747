<template>
  <v-card variant="tonal" class="mb-3">
    <mission-planning-debug-block
      v-if="showDebugMode"
      :debug-type="DEBUG.TYPE.MP.POS_LIST"
      :missionPlanning="targetMissionsPlanning"
      :point-of-sale="pointOfSale"
      :reading="currentReading"
    />

    <v-card-title class="pos-tile-title">
      <visit-new-modal
        :page="PAGE.PLANNING"
        :pointOfSale="pointOfSale"
        v-if="!targetMissionPlanningId && configuration?.settings?.planning_type === SETTINGS.PLANNING_TYPE.FREE"
      >
        <template #trigger="slotProps">
          <span
              class="cursor-pointer"
              @click="slotProps.triggerAction"
          >
            {{ (pointOfSale?.code || $t('global.text.none.f')) }} -
            {{ (pointOfSale?.store?.name || $t('global.text.none.f')) + ' - ' + (pointOfSale?.name || $t('global.text.none.f')) }}
          </span>
        </template>
      </visit-new-modal>

      <span
        v-else
        :class="{ 'cursor-pointer': !!targetMissionPlanningId }"
        @click="goToMissionPlanningPage()"
      >
        {{ (pointOfSale?.code || $t('global.text.none.f')) }} -
        {{ (pointOfSale?.store?.name || $t('global.text.none.f')) + ' - ' + (pointOfSale?.name || $t('global.text.none.f')) }}
      </span>
    </v-card-title>

    <v-card-subtitle>
      {{ generatePosAddressInfo(pointOfSale) || $t('global.text.none.f') }}
      <span v-if="pointOfSaleMission">
        - {{ (pointOfSaleMission.total_readings || 0) + '/' + (pointOfSaleMission.frequency || 1)}}
      </span>
    </v-card-subtitle>

    <v-card-text>
      <div class="reading-block pt-2">
        <v-spacer />
        <v-btn
          v-if="allowExpandPosHistory"
          class="pos-tile-caret"
          variant="tonal"
          size="x-small"
          :icon="!expandedDetails ? mdiChevronDown : mdiChevronUp"
          @click="expandedDetails = !expandedDetails"
        />
      </div>
    </v-card-text>

    <v-expand-transition>
      <div v-show="expandedDetails">
        <v-divider />

        <v-card-text>
          <v-table density="compact" v-if="readingsHistory">
            <tbody>
            <tr v-for="readingHistory in readingsHistory" :key="readingHistory.id">
              <td>
                <strong>
                  {{ dateHandler.convertUnixTimestampToHumanDate(readingHistory.date) }}
                </strong>
                <v-icon :icon="mdiCheckCircle" color="grey-darken-1" class="ml-5" />
              </td>
              <td>
                <statistic-indicators-wrapper
                    :counters="readingHistory?.counters"
                    :show-label="false"
                    :show-previous-reading="false"
                />
              </td>
            </tr>
            </tbody>
          </v-table>

          <v-divider v-if="readingsHistory.length && showCurrentReadingLine" />
          <v-table density="compact" v-if="showCurrentReadingLine">
            <tbody>
            <tr :key="currentReading.id">
              <td>
                <strong>
                  {{ dateHandler.convertUnixTimestampToHumanDate(currentReadingMissionPlanning?.date_start) }}
                </strong>
                <v-icon :icon="mdiSquareRoundedOutline" color="grey-darken-1" class="ml-5" />
              </td>
              <td>
                <statistic-indicators-wrapper
                    :counters="currentReading?.counters"
                    :show-label="false"
                    :show-previous-reading="false"
                />
              </td>
            </tr>
            </tbody>
          </v-table>

          <v-divider v-if="missionMissionsPlanningsList.length" />

          <v-table density="compact" v-if="missionMissionsPlanningsList.length">
            <tbody>
            <tr v-for="mMissionPlanning in missionMissionsPlanningsList" :key="mMissionPlanning.id">
              <td>
                <strong>
                  {{ dateHandler.convertUnixTimestampToHumanDate(mMissionPlanning.date_start) }}
                </strong>

                <v-icon
                  v-if="mMissionPlanning.date_start <= dateHandler.generateNowUnixTimestamp()"
                  :icon="mdiSquareRoundedOutline"
                  color="grey-darken-1"
                  class="ml-5"
                />

                <v-icon
                  v-else
                  :icon="mdiCalendarMonth"
                  color="grey-darken-1"
                  class="ml-5"
                />
              </td>
              <td>
                <div class="mx-5"></div>
              </td>
              <td>
<!--                <v-chip-->
<!--                  variant="tonal"-->
<!--                  color="error"-->
<!--                  class="mx-1 my-1"-->
<!--                  v-for="kpi in [' - ']"-->
<!--                >-->
<!--                  {{ kpi }}-->
<!--                </v-chip>-->
              </td>
              <td v-for="n in 2"></td>
            </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import {
    mdiChevronDown,
    mdiChevronUp,
    mdiCheckCircle,
    mdiSquareRoundedOutline,
    mdiCalendarMonth,
    mdiCamera,
    mdiFormatListChecks
  } from '@mdi/js';
  import dateHandler from '@/utils/dateHandler';
  import { generatePosAddressInfo } from "@/stores/manager/pointOfSalesManager";
  import { useStoreManager } from '@/stores/storeManager';
  import { useApplicationStore } from '@/stores/applicationStore';
  import MissionPlanningDebugBlock from '@/components/global/MissionPlanningDebugBlock.vue';
  import VisitNewModal from "../mission/appointments/VisitNewModal.vue";
  import { SETTINGS, READING, DEBUG, PAGE } from '@/constants';
  import _ from "lodash";
  import StatisticIndicatorsWrapper from "@/components/common/StatisticIndicatorsWrapper.vue";

  const props = defineProps({
    pointOfSale: {
      type: Object,
      required: true,
    },
    readingsHistory: {
      type: Object,
      required: true,
    },
    missionMissionsPlannings: {
      type: Object,
      required: true,
    },
    configuration: {
      type: Object,
      required: false,
    },
  });

  const expandedDetails = ref(false);

  const router = useRouter();
  const storeManager = useStoreManager();
  const applicationStore = useApplicationStore();

  const missions = computed(() =>
    storeManager.getFromStoreByCategory('mission', 'missions') || {}
  );

  const readings = computed(() =>
    storeManager.getFromStoreByCategory('reading', 'reading') || {}
  );

  const missionsPlannings = computed(() =>
    storeManager.getFromStoreByCategory('mission', 'missionsPlannings') || {}
  );

  const showDebugMode = computed(() => applicationStore.showDebugMode);

  function allowExpandPosHistory() {
    return !(
      Object.values(props.readingsHistory).length > 0 ||
      Object.values(props.missionMissionsPlannings).length > 0
    );
  }

  function goToMissionPlanningPage() {
    if (!targetMissionPlanningId.value) {
      return;
    }
    router.push({
      name: 'mission-planning-questionnaires',
      params: {
        id: targetMissionPlanningId.value
      },
    });
  }

  function getMissionsIdsFromPointOfSaleId(pointOfSaleId) {
    return Object.entries(missions.value)
        .filter(([key, mission]) => mission.pos.id.toString() === pointOfSaleId.toString())
        .map(([key, mission]) => key);
  }

  function getMissionsPlanningsFromMissionsIds(missionIds) {
    if (missionIds) {
      return Object.entries(missionsPlannings.value)
          .filter(([key, missionPlanning]) => _.includes(missionIds, missionPlanning?.mission?.id?.toString()))
          .map(([key, missionPlanning]) =>
          {
            return {
              id: key,
              ...missionPlanning
            }
          });
    }
    return {};
  }

  function getReadingMissionPlanningIdFromMissionPlanningIds(missionPlanningsIds) {
    return Object.entries(readings.value)
        .filter(([key, reading]) => reading.status === READING.STATUS.NEW && missionPlanningsIds.includes(reading.mission_planning.toString()))
        .map(([key, reading]) => reading)[0]?.mission_planning || null;
  }

  const missionsIds = computed(() => {
    return props.pointOfSale ? getMissionsIdsFromPointOfSaleId(props.pointOfSale.id) : {};
  })

  const posMissionsPlannings = computed(() => {
    return getMissionsPlanningsFromMissionsIds(missionsIds.value);
  })

  const missionsPlanningsIds = computed(() => {
    return posMissionsPlannings.value?.map(missionPlanning => missionPlanning.id);
  })

  const showCurrentReadingLine = computed(() => {
    return currentReading.value && (currentReading.value.status === READING.STATUS.TO_BE_TRANSMITTED || currentReading.value.status === READING.STATUS.NEW) ;
  })

  const currentReadingMissionPlanningId = computed(() => {
    return getReadingMissionPlanningIdFromMissionPlanningIds(missionsPlanningsIds.value);
  })

  const currentReadingMissionPlanning = computed(() => {
    return missionsPlannings.value[currentReadingMissionPlanningId.value];
  })

  const currentReading = computed(() => {
    return Object.entries(readings.value)
        .map(([key, reading]) => {return {id: key, ...reading}})
        .find(iteratedReading => {
          return iteratedReading.mission_planning.toString() === currentReadingMissionPlanningId.value?.toString()
        })
  })

  const pointOfSaleMission = computed(() =>
    Object.values(missions.value).find(mission => mission.pos.id.toString() === props.pointOfSale?.id?.toString()));

  const missionMissionsPlanningsList = computed(() => {
    return props.missionMissionsPlannings
      .filter((mp) =>
          Number(mp.date_start) > dateHandler.generateNowUnixTimestamp() &&
          !mp.transmittedReading &&
          !(showCurrentReadingLine.value && currentReading.value?.mission_planning?.toString() === mp.id.toString())
      )
      .sort((a, b) => a.date_start - b.date_start);

  });

  const nextMissionPlanningWithNoReading = computed(() => {
    return posMissionsPlannings.value
        .filter((mp) => Number(mp.date_start) > dateHandler.generateNowUnixTimestamp() && !mp.transmittedReading)
        .sort((a, b) => a.date_start - b.date_start)
        .find((mp) => {
          return !Object.values(readings.value).find((reading) => reading.mission_planning.toString() === mp.id.toString())
        });
  })

  const targetMissionPlanningId = computed(() => {
    return currentReadingMissionPlanningId.value || nextMissionPlanningWithNoReading.value?.id;
  });

  const targetMissionsPlanning = computed(() => {
    return targetMissionPlanningId.value ? {
      ... missionsPlannings.value[targetMissionPlanningId.value],
      ... {id: targetMissionPlanningId.value}
    } : null;
  });

</script>

<style scoped>
.reading-block {
  display: flex;
  justify-content: space-between;
}

.v-card-text {
  padding: 0.5rem 1rem;
  line-height: 2rem;
}
</style>

<template>
  <v-badge
    v-if="count"
    color="success"
    :content="count"
  >
    <v-icon :icon="mdiMessageOutline" size="small" />
  </v-badge>
  <v-icon v-else :icon="mdiMessageOutline" />
</template>

<script setup>
  import { computed } from 'vue';
  import { mdiMessageOutline } from '@mdi/js';
  import { useStoreManager } from '@/stores/storeManager';
  import { countUnreadMessagesForUser } from '@/stores/manager/postItManager';

  const storeManager = useStoreManager();
  const userDetails = computed(() => storeManager.getFromStoreByCategory('application', 'userDetails'));

  const postItMessages = computed(() =>
    storeManager.getFromStoreByCategory('postIt', 'messages')
  );

  const postItThreads = computed(() =>
    storeManager.getFromStoreByCategory('postIt', 'threads')
  );

  const count = computed(() => countUnreadMessagesForUser(postItMessages.value, userDetails.value, postItThreads.value));

</script>

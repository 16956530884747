<template>
  <h4 class="mb-3 font-weight-bold" :style="{'color': color}">
    {{ label }}
  </h4>
  <v-progress-circular :model-value="currentPercentValue" :size="size" :width="width" :color="color">
    <div class="pie-chart-inner" :style="{'color': color}">{{valueCurrent}}/{{valueTotal}}</div>
  </v-progress-circular>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  color: {
    type: String,
    required: false,
    default: 'secondary'
  },
  valueCurrent: {
    type: Number,
    required: false,
    default: 0
  },
  valueTotal: {
    type: Number,
    required: false,
    default: 0
  },
  size: {
    type: Number,
    required: false,
    default: 100
  },
  width: {
    type: Number,
    required: false,
    default: 15
  }
});

const { t } = useI18n();

const data = {
  labels: [
    t('mission-planning.qst.total-charts.label.answered-questions'),
    props.label,
  ],
};

const currentPercentValue = computed(() => {
  return props.valueCurrent  * 100 / props.valueTotal;
});
const options = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: props.label
    }
  }
};
</script>
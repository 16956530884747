<template>
  <v-btn
    color="surface-variant"
    variant="tonal"
    :prepend-icon="mdiPlus"
    @click="createNewElement()"
  >
    {{ $t('mission-planning.qst.my-questionnaires.screen.element.add.btn') }}
  </v-btn>

</template>

<script setup>
import { inject } from 'vue';
import { mdiPlus } from '@mdi/js';
import { addNewElement } from '@/stores/manager/readingElementsManager';
import { useStoreManager } from '@/stores/storeManager';
import { READING } from '@/constants';
import { createOrUpdateConditions, saveReadingCounters } from '@/stores/manager/conditionsManager';
import { createReadingIfNotExists } from "@/stores/manager/readingManager";

const storeManager = useStoreManager();

const currentMissionPlanningId = inject('currentMissionPlanningId');
const currentPointOfSale = inject('currentPointOfSale');
const currentConfiguration = inject('currentConfiguration');
const currentReadingId = inject('currentReadingId');
const userDetails = inject('userDetails');
const missionPlanning = inject('missionPlanning');
const mission = inject('mission');
const configuration = inject('configuration');
const readingAnswers = inject('readingAnswers');

const createNewElement = async () => {
  await createReadingIfNotExists(
      currentReadingId.value,
      mission.value?.configuration?.id,
      currentMissionPlanningId.value || null,
      userDetails.value.id,
      currentConfiguration.value?.data || null
  );
  addNewElement(
    currentReadingId.value,
    userDetails.value?.id,
    currentMissionPlanningId,
    mission.value?.configuration?.id,
    configuration.value
  ).then((result) => {
    if (result !== false) {
      updateConditions(result);
    }
  });
}

const updateConditions = (readingElementId) => {
  let initialContext = {
    currentValue: null,
    answers: Object.values(readingAnswers.value).filter((ra) => ra.reading === currentReadingId.value),
    pos: currentPointOfSale.value,
    attachementType: READING.ATTACHMENT_TYPE.ELEMENT,
    attachementId: readingElementId,
    prod: null,
    nowDatetime: new Date()
  };

  createOrUpdateConditions(
    currentConfiguration.value,
    initialContext,
    currentReadingId.value
  );
  saveReadingCounters(currentReadingId.value, currentConfiguration.value)
}

</script>
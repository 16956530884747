import { db } from '@/db.js';
import BOImageService from '@/services/boImage';

class OfflineImageHandler {

  OFFLINE_IMAGE_TABLES= {
    PRODUCT: "product-image"
  }

  CHUNK_SIZE= 5;

  buildKey(tableName, entityId, configurationId) {
    return `${tableName}|${entityId}:${configurationId}`;
  }

  async getByKey(key) {
    return await db.offlineImage.get(key).catch((e) => {
      console.error('Error while fetching image entity ', e);
    });
  }

  async addNew(imageData) {
    try {
      await db.offlineImage.add(imageData);
    } catch (error) {
      console.error('Error while adding new image entity ', error);
    }
  }

  async updateUrl(key, url) {
    try {
      await db.offlineImage.update(key, {url: url, blob: null, downloaded: 0});
    } catch (error) {
      console.error('Error while updating image entity ', error);
    }
  }

  async getNotDownloadedChunk() {
    return await db.offlineImage.where(
    {
      'downloaded': 0,
      'downloading': 0
    }).limit(this.CHUNK_SIZE).toArray();
  }

  async initChunkDownloadState(currentChunk) {
    const changes = {downloading: 1, downloaded: 0, blob: null}
    let bulkUpdateArray = [];
    for (let i = 0; i < currentChunk.length; i++) {
      bulkUpdateArray.push({key: currentChunk[i].key, changes: changes});
    }
    try {
      await db.offlineImage.bulkUpdate(bulkUpdateArray);
    } catch (error) {
      console.error('Error on chunk init download state ', error);
    }
  }

  async updateBlob(currentChunkElement) {
    if (!currentChunkElement) {
      return;
    }
    try {
      // remove '/api' because is already contained by VITE_APP_API_URL
      const blob = await BOImageService.getProductImageBlob(currentChunkElement.url.replace('/api', ''));
      await db.offlineImage.update(currentChunkElement.key, {downloading: 0, downloaded: 1, blob: blob.size?blob:null});
    } catch (error) {
      console.error('Error while updating blob ', error);
    }
  }

  async startMassDownloadProcess() {
    const currentChunk = await this.getNotDownloadedChunk();
    if (currentChunk.length > 0) {
      await this.initChunkDownloadState(currentChunk);
      for (let i = 0; i < currentChunk.length; i++) {
        await this.updateBlob(currentChunk[i]);
      }
      await this.startMassDownloadProcess();
    }
  }
}
export default new OfflineImageHandler();
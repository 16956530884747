import dateHandler from '@/utils/dateHandler';
import { SCREEN } from "@/constants/components/screens";
import {useReadingConditionStore} from "../conditionStore";

export function checkMissionPlanningDatePastEditAccess(unixTime, accessControl) {
  const diffDays = dateHandler.daysDifference(unixTime);
  return (diffDays < 0 && accessControl.edit_delete_visit_past);
}

export function checkMissionPlanningDateFutureEditAccess(unixTime, accessControl) {
  const diffDays = dateHandler.daysDifference(unixTime);
  return (diffDays > 0 && accessControl.edit_delete_visit_future);
}

export function getScreensWithConditionsAndMandatoryAnswerStatus(readingId, screens, allQuestions, questionsConditionsWithAnswers) {
  const conditions = useReadingConditionStore().readingConditions[readingId || 'default']?.screens
  if (!conditions) return screens.map((item) => {
    return {
      ...item,
      answerStatus: SCREEN.STATUS.NEW
    }
  })
  return screens.map((item) => {
    return {
      ...item,
      visibility: conditions[item.id] ? conditions[item.id].visibility : true,
      answerStatus: getAnswerStatus(item, allQuestions, questionsConditionsWithAnswers)
    }
  }).sort((a, b) => {
    if (a.answerStatus !== b.answerStatus && (a.answerStatus === SCREEN.STATUS.FINISHED || b.answerStatus === SCREEN.STATUS.FINISHED)) {
      return a.answerStatus - b.answerStatus
    }
    else {
      return a.order - b.order
    }
  })
}

function getAnswerStatus(item, allQuestions, questionsConditionsWithAnswers) {
  if (allQuestions.length === 0) {
    return SCREEN.STATUS.NEW;
  }
  const questionsIds = allQuestions.filter(question => question?.group?.screen?.id === item.id)
    .map(question => question.id.toString());
  const filteredQuestionsConditionsWithAnswers = questionsConditionsWithAnswers.filter(conditionItem => {
    return questionsIds.includes(conditionItem.question_id)
  });

  const totalMandatoryAnswersCount = filteredQuestionsConditionsWithAnswers.filter(conditionItem => conditionItem.mandatory && conditionItem.hasAnswer).length;

  if (totalMandatoryAnswersCount === 0) {
    return SCREEN.STATUS.NEW;
  }
  const totalMandatoryCount = filteredQuestionsConditionsWithAnswers.filter(conditionItem => conditionItem.mandatory).length;

  if (totalMandatoryCount === totalMandatoryAnswersCount) {
    return SCREEN.STATUS.FINISHED;
  }
  return SCREEN.STATUS.IN_PROGRESS;
}
<template>
  <counter-capsule
    :type="QST.COUNTER.TYPE.OPTIONAL"
    :value-total="totalOptionalCount"
    :value-current="totalOptionalAnswersCount"
    :color-capsule="`grey-darken-5`"
    :color-counter="`grey-darken-1`"
  />
  <counter-capsule
    :type="QST.COUNTER.TYPE.MANDATORY"
    :value-total="totalMandatoryCount"
    :value-current="totalMandatoryAnswersCount"
    :color-capsule="`grey-darken-5`"
    :color-counter="`grey-darken-1`"
  />
  <counter-capsule
    :type="QST.COUNTER.TYPE.TOTAL"
    :value-total="totalCount"
    :value-current="totalAnswersCount"
    :color-capsule="`black`"
    :color-counter="`black`"
    :button-style="true"
  />
</template>

<script setup>
import { defineProps, computed, inject, ref } from 'vue';
import CounterCapsule from '@/components/common/CounterCapsule.vue';
import { useStoreManager } from '@/stores/storeManager';
import { QST, READING } from '@/constants';

const props = defineProps({
  questionnaireType: {
    type: String,
    required: true,
  },
  questionnaireTypeId: {
    required: false
  },
  attachmentType: {
    type: String,
    required: false,
    default: ''
  },
  attachmentId: {
    required: false,
    default: null
  },
  productCategoryId: {
    required: false,
    default: null
  }
});

const configuration = inject('currentConfiguration');
const questionsConditionsWithAnswers = inject('questionsConditionsWithAnswers');

const storeManager = useStoreManager();

const questions = computed(() => {
  const allQuestions = Object.values(configuration.value.questions || {});

  if (allQuestions.length === 0) {
    return [];
  }
  let targetQuestions = allQuestions;
  if (props.questionnaireType === QST.TYPE.GROUP) {
    targetQuestions = allQuestions.filter(question => question?.group?.id === props.questionnaireTypeId);
  }
  if (props.questionnaireType === QST.TYPE.SCREEN) {
    targetQuestions = allQuestions.filter(question => question?.group?.screen?.id === props.questionnaireTypeId);
  }

  const questionsIds = targetQuestions.map(question => question.id.toString());
  return questionsConditionsWithAnswers.value.filter(conditionItem => {
    let attachmentCondition = true;
    if (props.attachmentType !== '' && (props.attachmentId !== null || props.productCategoryId !== null)) {
      if (props.productCategoryId !== null) {
        const fullCode = conditionItem.code.split(':')[1] || null;
        if (fullCode) {
          const productId = fullCode.split('|')[1];
          const allProducts = configuration.value?.catalog?.product || {};
          attachmentCondition = allProducts[productId]?.category.id === props.productCategoryId;
        }
      } else {
        attachmentCondition = conditionItem.code.split(':')[1] === props.attachmentType+'|'+props.attachmentId;
      }
    }
    return attachmentCondition && questionsIds.includes(conditionItem.question_id)
  });
});

const totalCount = computed(() => {
  return questions.value.length;
});

const totalAnswersCount = computed(() => {
  return questions.value.filter(conditionItem => conditionItem.hasAnswer).length;
});

const totalMandatoryCount = computed(() => {
  return questions.value.filter(conditionItem => conditionItem.mandatory).length;
});

const totalMandatoryAnswersCount = computed(() => {
  return questions.value.filter(conditionItem => conditionItem.mandatory && conditionItem.hasAnswer).length;
});

const totalOptionalCount = computed(() => {
  return totalCount.value - totalMandatoryCount.value;
});

const totalOptionalAnswersCount = computed(() => {
  return totalAnswersCount.value - totalMandatoryAnswersCount.value;
});

</script>
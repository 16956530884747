import { createI18n } from "vue-i18n";
import fr from "./locales/fr.json"
import en from "./locales/en.json"

const locale = JSON.parse(localStorage.getItem('applicationStore'))?.data.locale || window.env.VITE_DEFAULT_LOCALE || import.meta.env.VITE_DEFAULT_LOCALE;

export default createI18n({
    locale: locale,
    fallbackLocale: window.env.VITE_FALLBACK_LOCALE || import.meta.env.VITE_FALLBACK_LOCALE,
    legacy: false,
    messages: {
        fr, en
    },
})

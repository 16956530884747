<template>
  <v-card variant="tonal" class="mb-2">
    <v-card-title>
      <div class="d-flex flex-no-wrap justify-space-between">
        <div class="align-start text-wrap">
          <h5 @click="goToThreadMessagesPage()">
            <span class="cursor-pointer font-weight-bold text-uppercase">
              {{ pointOfSale.pos_code }} - {{ pointOfSale.name }}
            </span>
          </h5>
        </div>

        <div class="align-end text-wrap">
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-btn
              v-if="unreadMessagesCount"
              variant="text"
              class="text-none"
              @click="goToThreadMessagesPage()"
            >
              <v-badge color="success" :content="unreadMessagesCount">
                <v-icon :icon="messageIcon" size="x-large"/>
              </v-badge>
            </v-btn>
          </div>
        </div>
      </div>
    </v-card-title>

    <v-card-text v-if="Object.keys(lastMessage).length > 0">
      <v-sheet elevation="1" class="pa-4" color="grey-lighten-2">
        <h4 class="font-weight-bold">
          {{$t("post-it.message.author-last-message")}} {{ displayAuthorName }}
        </h4>
        <p class="pa-3 font-italic">{{ lastMessage.message }}</p>
        <p class="text-medium-emphasis">{{ dateHandler.convertUnixTimestampToHuman(lastMessage.published_at) }}</p>
      </v-sheet>
    </v-card-text>
    <v-card-text v-else>
      <v-sheet>
        {{$t("post-it.message.empty-thread")}}
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { mdiMessageOutline } from '@mdi/js';
import { useApplicationStore } from '@/stores/applicationStore';
import { useI18n } from 'vue-i18n';
import dateHandler from '@/utils/dateHandler';

const props = defineProps({
  thread: {
    type: Object,
    required: true,
  },
  threadId: {
    type: String,
    required: true,
  },
  lastMessage: {
    type: Object,
    required: true,
  },
  pointOfSale: {
    type: Object,
    required: true,
  },
  unreadMessagesCount: {
    type: Number,
    required: true,
  }
});

const { t } = useI18n();
const messageIcon = ref(mdiMessageOutline);
const applicationStore = useApplicationStore();
const router = useRouter();

const goToThreadMessagesPage = () => {
  router.push({
    name: 'thread-details',
    params: {
      id: props.threadId,
    },
  });
}

const authUser = applicationStore.userDetails;

const displayAuthorName = computed(() =>
    parseInt(authUser.id) === parseInt(props.lastMessage.author.id)
        ? t('post-it.message.author-me')
        :
    `${props.lastMessage.author.first_name} ${props.lastMessage.author.last_name}`);

</script>

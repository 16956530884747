import imageHandler from '@/utils/imageHandler';
import dateHandler from '@/utils/dateHandler';
import watermark from 'watermarkjs';
import localForageService from '@/services/localForage';
import { useStoreManager } from '@/stores/storeManager';
import { TABLE } from '@/constants';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { createReadingIfNotExists } from "./readingManager";

const addNewReadingPicture = (localForageFileId, readingId, creationDate, configuration) => {
  const storeManager = useStoreManager();
  const readingPicture = {
    reading: readingId,
    creation_date: creationDate,
    latitude: 0,
    longitude: 0,
    configuration_id: configuration?.id,
    customer_id: configuration?.customer?.id,
    file: localForageFileId
  };
  return storeManager.add('reading', 'picture', readingPicture);
}

export async function addNewPicture(
  readingId,
  file,
  pointOfSaleName,
  pictureOptions,
  userId,
  missionPlanningId,
  configId,
  config
) {
  let result = false;
  try {
    const creationDate = dateHandler.generateNowUnixTimestamp();
    const uuid = `${moment().format("YYYY_MM_DD")}__${uuidv4()}`;
    const resizedFile = await imageHandler.resizeImage(file, pictureOptions.maxWidth);
    const blob = await addWatermark(resizedFile, creationDate, pointOfSaleName, pictureOptions);
    const newFile = new File([blob], resizedFile.name, {type: resizedFile.type});
    await localForageService.storeData(TABLE.QUESTION_PICTURES, uuid, newFile);

    const readingIdOutput = await createReadingIfNotExists(
      readingId,
      configId,
      missionPlanningId || null,
      userId,
      config.data || null
    );

    result = await addNewReadingPicture(uuid, readingIdOutput, creationDate, config)
  } catch (err) {
    console.log(err);
  }

  return result;
}

export async function editExistingPicture(readingPicture, file, pointOfSaleName, pictureOptions, readingPictureId) {
  let result = false;

  try {
    await localForageService.removeItem(TABLE.QUESTION_PICTURES, readingPicture.file);
    const resizedFile = await imageHandler.resizeImage(file, pictureOptions.maxWidth);
    readingPicture.creation_date = dateHandler.generateNowUnixTimestamp();
    const blob = await addWatermark(resizedFile, readingPicture.creation_date, pointOfSaleName, pictureOptions);
    const newFile = new File([blob], resizedFile.name, {type: resizedFile.type});
    await localForageService.storeData(TABLE.QUESTION_PICTURES, readingPicture.file, newFile);

    const storeManager = useStoreManager();
    storeManager.update('reading', 'picture', readingPictureId, readingPicture);

    result = true;
  } catch (err) {
    console.log(err);
  }

  return result;
}

export async function removeExistingPicture(readingPicture, readingPictureId) {
  let result = false;

  try {
    const storeManager = useStoreManager();
    await localForageService.removeItem(TABLE.QUESTION_PICTURES, readingPicture.file)
    if (await storeManager.remove('reading', 'picture', readingPictureId)) {
      result = true;
    } else {
      console.error('An error occurred while deleting!');
    }
  } catch (err) {
    console.log(err);
  }

  return result;
}

export async function getImagesFromLocalForge(pictures) {
  let result = {};

  try {
    for (const [id, picture] of Object.entries(pictures)) {
      await localForageService
        .retrieveData(TABLE.QUESTION_PICTURES, picture.file)
        .then(function (img) {
          let blob = new Blob([img]);
          result[picture.file] = blob ? window.URL.createObjectURL(blob) : null;
        });
    }
  } catch (err) {
    console.log(err);
  }
  return result;
}

export async function getImageFromLocalForge(picture) {
  let response = null;
  try {
    response = await localForageService
      .retrieveData(TABLE.QUESTION_PICTURES, picture.file);
  } catch (err) {
    console.log(err);
  }
  return response;
}


async function addWatermark(resizedFile, creationDate, pointOfSaleName, pictureOptions) {
  return watermark([resizedFile])
    .blob(watermark.text.lowerRight(
      window.$moment.unix(creationDate).format('MM/DD/YYYY - HH:mm:ss') + ' - ' + pointOfSaleName,
      pictureOptions.watermark.font,
      pictureOptions.watermark.color,
      pictureOptions.watermark.opacity
    ));
}
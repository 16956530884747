<template>
  <v-divider class="my-2" v-if="messageImagesSrcs.length > 0" />
  <div v-if="messageImagesSrcs.length > 0" class="d-flex justify-start flex-wrap w-100 ma-2">
    <v-img
        v-for="pUrl of messageImagesSrcs"
        width="100"
        :src="pUrl"
        :aspect-ratio="1"
        class="flex-grow-0 cursor-pointer"
        cover
        @click="selectedImage = pUrl; dialog = true"
    >
      <template v-slot:error>
        <div
            class="d-flex align-center flex-column flex-grow-0 cursor-default"
            @click.stop="">
        <v-icon
            :icon="mdiImageOffOutline"
            size="70"
        ></v-icon>
        <span style="text-align:center; font-size:xx-small;">{{$t("post-it.message.connecting")}}</span>
        </div>
      </template>
    </v-img>
  </div>
  <v-dialog
      v-model="dialog"
  >
    <v-card>
      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn
            :icon="mdiClose"
            @click="dialog = false"
        ></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-img
            max-height="80vh"
            :src="selectedImage"
        ></v-img>
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<script setup>
import { mdiClose, mdiImageOffOutline } from '@mdi/js';
import { ref } from 'vue';

const props = defineProps({
  messageImagesSrcs: {
    required: false,
  },
});

const dialog = ref(false);
const selectedImage = ref(null);

</script>
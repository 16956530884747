<template>
  <v-container class="fluid pt-15">
    <v-img
      height="200"
      src="./assets/logo.png"
    />

    <v-card
      class="mx-auto text-center"
      width="400"
      :prepend-icon="mdiAccount"
      :loading="operationInProgress"
  >
    <template v-slot:title>
      {{ $t('auth.login.title') }}
    </template>
    <v-form validate-on="blur lazy" v-model="loginForm.valid" @submit.prevent="onSubmit">
      <v-card-text>
        <v-text-field
            v-model="loginForm.fields.login"
            :label="$t('auth.login.form.label.login')"
            variant="underlined"
        />

        <v-text-field
            v-model="loginForm.fields.password"
            :rules="loginForm.rules.password"
            :label="$t('auth.login.form.label.password')"
            :type="'password'"
            variant="underlined"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn type="submit">{{ $t("auth.login.form.btn") }}</v-btn>
      </v-card-actions>
    </v-form>

      <v-footer class="d-flex justify-center">
        <v-chip
            density="compact"
            size="small"
            label
        >
          Version: {{buildDate}}
        </v-chip>

      </v-footer>
  </v-card>
  </v-container>
</template>

<script setup>
import { reactive, ref, computed } from 'vue';
import router from '@/router';
import { useToast } from 'vue-toast-notification';
import { useI18n } from 'vue-i18n';
import { mdiAccount } from '@mdi/js';
import syncManager from "@/utils/sync/SyncManager";
import { useApplicationStore } from '@/stores/applicationStore';
import { useStoreManager } from '@/stores/storeManager';
import notificationService from '@/services/notification';
import json from '@/buildinfo.json';
import dateHandler from "@/utils/dateHandler";

const { t } = useI18n();
const toast = useToast();
const applicationStore = useApplicationStore();
const storeManager = useStoreManager();

const buildDate = dateHandler.convertUnixTimestampToDateTime(json?.buildDateMs/1000)
const loginForm = reactive({
  valid: ref(false),
  fields: {
    login: ref(''),
    password: ref('')
  },
  rules: {
    password: [
      value => {
        if (value) return true;
        return t('auth.login.form.validation_messages.password');
      }
    ]
  }
});

const onSubmit = async () => {
  if (loginForm.valid) {
    operationInProgress.value = true;
    try {
      const response = await applicationStore.loginUser(loginForm.fields.login, loginForm.fields.password);
      if (response) {
        const configurations = computed(() =>
          storeManager.getFromStoreByCategory('configuration', 'configurations') || {}
        );

        const hasBeenSynced = await syncManager.runCompleteCycle(function() {
          // implemented the styles and logo from server
          applicationStore.updateAppStyle(configurations.value);
          router.push({ path: '/planning' });
          operationInProgress.value = false;
        });

        if (!hasBeenSynced) {
          // if the synchronization did not happen, then are implemented the styles and logo found in the store
          applicationStore.updateAppStyle(configurations.value);
        }

        const user = computed(() => applicationStore.userDetails);
        if (user.value) {
          notificationService.login(user.value.id.toString());
        }
      } else {
        toast.error(t('auth.login.error.bad_credentials'));
        operationInProgress.value = false;
      }
    } catch (error) {
      console.log(error);
      toast.error(t('global.error.network'));
      operationInProgress.value = false;
    }
  }
};

const operationInProgress = ref(false);
</script>
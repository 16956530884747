<template>
  <v-card variant="tonal" class="mb-5">
    <v-card-title :style="{'color': appStyle.title_text_color}">
      <v-icon class="mx-3 my-1" size="small" :icon="mdiInformation" />
      {{ $t('mission-planning.info.informative-answer.title') }}
    </v-card-title>

    <v-divider />

    <v-card-text>
      <div
        v-if="missionPlanning?.answer_informative?.length"
        v-for="item in missionPlanning?.answer_informative"
      >
        <v-container class="pt-0">
          <v-row>
            <v-col cols="12" xs="12" sm="6" md="5">
              <div class="text-body-1 font-weight-bold text-teal-darken-3">
                {{ `${item.question.label}:` }}
              </div>
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="7">
              <div class="text-body-1">
                {{ item.answer && item.answer !== '0' ? item.answer : $t('mission-planning.info.informative-answer.message.zero-item') }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-alert
        v-if="!missionPlanning?.answer_informative?.length"
        variant="tonal"
        type="warning"
        :text="$t('mission-planning.info.informative-answer.message.no-instructions')"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { mdiInformation } from '@mdi/js';
import { useApplicationStore } from '@/stores/applicationStore';

const applicationStore = useApplicationStore();
const appStyle = computed(() => applicationStore.appStyle);

const props = defineProps({
  missionPlanning: {
    type: Object,
    required: true
  }
});
</script>

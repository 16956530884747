export const ACTION = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
};

export const PAGE = {
  PLANNING: 'planning',
  CONTACTS: 'contacts',
  MESSAGES: 'messages',
  POINTS_OF_SALE: 'points-of-sale',
  MISSION_PLANNING: 'mission-planning',
  MISSION_PLANNING_QUESTIONNAIRES: 'mission-planning-questionnaires'
};

export const VALUE = {
  DEFAULT: 'default'
}
<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <span
          class="text-h5 font-weight-bold"
          :style="{'color': appStyle.title_text_color}"
        >
          {{ $t('global.title.messages') }}
        </span>
      </v-col>

      <v-col cols="12" md="12">
        <post-it-configs :un-read-only="true" />
        <post-it-configs />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { computed, provide } from 'vue';
import PostItConfigs from '@/components/post-it/PostItConfigs.vue';
import { useStoreManager } from '@/stores/storeManager';
import { useApplicationStore } from '@/stores/applicationStore';
import { useRouter } from 'vue-router';
import syncManager from "@/utils/sync/SyncManager";

const router = useRouter();

const storeManager = useStoreManager();

const applicationStore = useApplicationStore();
const appStyle = computed(() => applicationStore.appStyle);

const threads = computed(() =>
  storeManager.getFromStoreByCategory('postIt', 'threads')
);
provide('threads', threads);

const messages = computed(() =>
  storeManager.getFromStoreByCategory('postIt', 'messages')
);
provide('messages', messages);

const pointOfSales = computed(() =>
  storeManager.getFromStoreByCategory('postIt', 'pointOfSales')
);
provide('pointOfSales', pointOfSales);

const configurations = computed(() =>
  storeManager.getFromStoreByCategory('configuration', 'configurations')
);
provide('configurations', configurations);

syncManager.runPartialCycle()

</script>
<template>
  <v-card variant="tonal" class="mb-5">
    <v-card-title :style="{'color': appStyle.title_text_color}">
      <v-icon class="mx-3 my-1" size="small" :icon="mdiContacts" />
      {{ $t('mission-planning.info.contacts.title') }}
    </v-card-title>

    <v-divider />

    <v-card-text
        class="px-7 py-4"
        v-if="contacts.length"
        v-for="contact in contacts"
    >
      <span class="text-body-1 font-weight-bold text-teal-darken-3">
        {{ `${contact.job || "-"}:` }}
      </span>
      <span class="text-body-1">
        &nbsp;&nbsp;{{ `${contact.firstname || ""} ${contact.lastname || ""}` }}
      </span>
      <span class="text-body-1">
        &nbsp;&nbsp;{{ `${contact.phone || ""}` }}
      </span>
    </v-card-text>

    <v-divider />

    <v-card-text>
      <v-btn
        :prepend-icon="mdiContacts"
        @click="goToContacts()"
      >
        {{ $t('mission-planning.info.contacts.btn') }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { mdiContacts } from '@mdi/js';
import { useRouter } from 'vue-router';
import { useApplicationStore } from '@/stores/applicationStore';

const props = defineProps({
  contacts: {
    type: Object,
    required: false,
    default: {}
  }
});

const applicationStore = useApplicationStore();
const appStyle = computed(() => applicationStore.appStyle);

const router = useRouter();

function goToContacts() {
  router.push({ path: 'contacts' });
}
</script>

import moment from "moment";

export class ItemDateItemCheck {
    types = {
        "FROMDATE": ( ctx, itemValue, reverse ) => reverse ?
            ctx.nowDatetime < moment(itemValue).toDate() :
            ctx.nowDatetime > moment(itemValue).toDate(),
        "TODATE": ( ctx, itemValue, reverse ) => reverse ?
            ctx.nowDatetime > moment(itemValue).toDate() :
            ctx.nowDatetime < moment(itemValue).toDate(),
    }

    messages = {
        "FROMDATE": ( ctx, itemValue, reverse ) => reverse ?
          "not from date" :
          "from date",
        "TODATE": ( ctx, itemValue, reverse ) => reverse ?
          "not to date" :
          "to date",
    }

    informations = {}

    constructor() {

    }

    process(item, context) {
        this.checkContext(context);
        return {
            result: this.types[item.type](context, item.value, item.reverse),
            message: this.messages[item.type](context, item.value, item.reverse)
        };
    }

    checkContext(context) {
        if (!(context.hasOwnProperty("nowDatetime") && context.nowDatetime instanceof Date)) {
            throw new Error("Context doesn't match requirements");
        }
    }
}
import arrayHandler from '@/utils/arrayHandler';

export function filterReadingsByMissions(readings, missionsIds) {
  const result = {};
  for (const key in readings) {
    if (readings.hasOwnProperty(key)) {
      const readingMissionId = parseInt(readings[key].mission);
      if (arrayHandler.isInArray(readingMissionId, missionsIds)) {
        result[key] = readings[key];
      }
    }
  }
  return Object.values(result);
}

export function extractLastReadingByDateAndMission(readings, mission) {
  let lastElement = null;
  for (const key in readings) {
    if (readings.hasOwnProperty(key)) {
      const reading = readings[key];

      if(!reading.mission || !mission) {
        continue;
      }

      if (reading.mission.toString() === mission.toString() && (!lastElement || reading.date > lastElement.date)) {
        lastElement = reading;
      }
    }
  }
  return lastElement;
}

export function extractQuestionsWithRole(questions, role) {
  return questions.filter((question) => question.question_statistic?.role === role);
}

export function remapConfigQuestions(questions) {
  return questions.reduce((result, item) => {
    const { id, name, role } = item.question_statistic;
    const extractedItem = {
      id: item.id,
      label: item.label,
      value: item.value,
      required: item.required
    };
    const existingItem = result.find((q) => q.id === id);
    if (existingItem) {
      existingItem.questions.push(extractedItem);
    } else {
      result.push({id, name, role, questions: [extractedItem]});
    }
    return result;
  }, []);
}

export function attachAnswersToQuestions(configQuestions, lastReadingAnswers) {
  const questionIdToAnswerMap = {};
  for (const key in lastReadingAnswers) {
    const entry = lastReadingAnswers[key];
    questionIdToAnswerMap[entry.question_id] = entry.value;
  }
  const result = configQuestions.map((item) => {
    const questionsWithAnswers = item.questions.map((question) => {
      const answer = questionIdToAnswerMap[question.id];
      return { ...question, answer: answer || null };
    });
    return { ...item, questions: questionsWithAnswers };
  });
  return result;
}

export function extractAnswerFromReadingHistoryAnswers(missionReadingHistoryAnswers, questionId, attachmentType = 'none', attachmentId = null) {
  return missionReadingHistoryAnswers
    ? Object.values(missionReadingHistoryAnswers).find((a) =>
      a.question_id === questionId &&
      (attachmentType === 'none' || a.related_to_type === attachmentType) &&
      (attachmentId === null || a.related_to_id === attachmentId)
  ) || null
    : null;
}

export function hasQuestionsNonNullValue(questions) {
  return questions && questions.some(question => question.answer !== null);
}
<template>
  <v-btn
    v-if="action === 'add'"
    class="float-right"
    color="success"
    :prepend-icon="mdiPlus"
    variant="tonal"
    @click="dialog = true"
  >
    {{ $t(`global.btn.${action}`) }}
  </v-btn>

  <v-btn
    v-if="action === 'edit'"
    size="small"
    color="surface-variant"
    variant="tonal"
    :icon="mdiPencil"
    @click="dialog = true"
  />

  <v-dialog
    v-model="dialog"
    width="auto"
    min-width="70%"
  >
    <v-card>
      <v-card-title>
        {{ $t(`mission-planning.qst.my-questionnaires.screen.picture.${action}.title`) }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-img
              v-if="imageUrl"
              :src="imageUrl"
              max-height="300"
              max-width="100%"
              class="uploaded-image mb-3"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-card-actions class="float-right">
              <v-btn
                class="float-right"
                color="grey-darken-2"
                :prepend-icon="mdiClose"
                variant="tonal"
                @click="closeModal"
              >
                {{ $t('global.btn.close') }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>

        <v-divider class="mb-5" />

        <v-row>
          <v-col cols="12" sm="9">

            <v-file-input
              ref="fileInput"
              clearable
              show-size
              accept="image/*,application/pdf"
              :rules="rules"
              v-model="image"
              density="compact"
              :prepend-icon="mdiCamera"
              :label="$t(`mission-planning.qst.my-questionnaires.screen.picture.${action}.form.file`)"
              @change="handleImageInput"
              @click:clear="clearImageInput"
            />
          </v-col>

        </v-row>

        <v-divider class="mb-5" />

        <questionnaire-group-wrapper
          v-if="imageUrl"
          :screen="screen"
          :questions="groupedQuestions"
          questionnaireType="screen"
          :attachmentType="READING.ATTACHMENT_TYPE.PICTURE"
          :attachmentId="computedPictureAttachmentId"
        />

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { mdiClose, mdiPlus, mdiPencil, mdiCamera } from '@mdi/js';
import {ref, computed, inject} from 'vue';
import { useStoreManager } from '@/stores/storeManager';
import { getImageFromLocalForge, addNewPicture, editExistingPicture } from '@/stores/manager/readingPicturesManager';
import QuestionnaireGroupWrapper from '../QuestionnaireGroupWrapper.vue';
import {createOrUpdateConditions,saveReadingCounters} from "@/stores/manager/conditionsManager";
import {removeExistingPicture} from "@/stores/manager/readingPicturesManager";
import { READING } from '@/constants';

const props = defineProps({
  action: {
    type: String,
    required: true,
  },
  missionPlanningId: {
    type: String,
    required: true,
  },
  mission: {
    type: Object,
    required: true,
  },
  userDetails: {
    type: Object,
    required: true,
  },
  screen: {
    type: Object,
    required: true,
  },
  groupedQuestions: {
    type: Object,
    required: true,
  },
  readingPictureId: {
    type: String,
    required: false,
  },
  readingPicture: {
    type: Object,
    required: false,
  }
});

const emit = defineEmits(['picture-changed']);
const currentReadingId = inject('currentReadingId');
const storeManager = useStoreManager();
const dialog = ref(false);
const image = ref(null);
const imageUrl = ref(null);
const pictureAttachmentId = ref(null);
const currentPointOfSale = inject('currentPointOfSale');
const currentConfiguration = inject('currentConfiguration');
const fileInput = ref(null);
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const computedPictureAttachmentId = computed(() => {
  if (props.readingPictureId) {
    return props.readingPictureId;
  }
  return pictureAttachmentId.value;
});

const pictureOptions = {
  maxWidth: currentConfiguration.value?.settings?.max_width_picture || 600,
  watermark: {
    color: '#ffffff',
    opacity: 0.9,
    font: '16px serif'
  }
}

const pointOfSaleName = currentPointOfSale.value?.name || '';

if (props.action === 'edit') {
  updateVisibleImage();
}

const readings = computed(() =>
    storeManager.getFromStoreByCategory('reading', 'reading') || {}
);

const rules = [
  (value) => {
    return !value || !value.length || value[0].type !== "application/pdf" || t('global.validator.only-images');
  },
];
async function handleImageInput(e) {
  const uploadErrorMessages = await fileInput.value.validate();
  if (uploadErrorMessages.length > 0) {
    return;
  }
  if (props.action === 'add') {
    addNewImage(e);
  } else {
    editExistingImage();
  }
}
function updateConditions() {
  let initialContext = {
    currentValue: null,
    answers: [],
    pos: currentPointOfSale.value,
    attachementType: READING.ATTACHMENT_TYPE.PICTURE,
    attachementId: computedPictureAttachmentId.value,
    prod: null,
    nowDatetime: new Date()
  }
  createOrUpdateConditions(
      currentConfiguration.value,
      initialContext,
      currentReadingId.value
  );
  saveReadingCounters(currentReadingId.value, currentConfiguration.value)
}
const addNewImage = (e) => {
  const fileUrl = e.target.files[0];
  imageUrl.value = fileUrl ? URL.createObjectURL(fileUrl) : null;
  const file = image.value;
  if (file) {
    addNewPicture(
      currentReadingId.value,
      file,
      pointOfSaleName,
      pictureOptions,
      props.userDetails?.id,
      props.missionPlanningId,
      props.mission?.configuration?.id,
      currentConfiguration.value
    ).then((result) => {
      if (result !== false) {
        pictureAttachmentId.value = result;
        updateConditions();
      }
    });
  }
}

function updateVisibleImage() {
  getImageFromLocalForge(props.readingPicture)
      .then(img => {
        if (img) {
          image.value = [img];
          const blob = new Blob([img]);
          imageUrl.value = blob ? window.URL.createObjectURL(blob) : null;
        }
      });
}

const editExistingImage = () => {
  const file = image.value;
  if (file) {
    editExistingPicture(props.readingPicture, file, pointOfSaleName, pictureOptions, computedPictureAttachmentId.value)
      .then((result) => {
        if (result === true) {
          updateVisibleImage()
          emit('picture-changed', true);
        }
      });
  }
}

const clearImageInput = () => {
  const picture = props.readingPicture || storeManager.getFromStoreByCategory('reading', 'picture')[computedPictureAttachmentId.value]
  const answersIds = Object.entries(storeManager.getFromStoreByCategory('reading', 'answer'))
      .filter(([key, value]) =>
          value?.reading.toString() === picture?.reading.toString()
          && value?.attachment_type?.toString() === READING.ATTACHMENT_TYPE.PICTURE
          && value?.attachment_id.toString() === computedPictureAttachmentId.value.toString()
      )
      .map(([key, value]) => key);
  for (const answerId of answersIds) {
    storeManager.remove('reading', 'answer', answerId);
  }
  removeExistingPicture(picture, computedPictureAttachmentId.value)
      .then((result) => {
        if (result === true) {
          imageUrl.value = null;
          updateConditions();
        }
      });
}

const closeModal = () => {
  dialog.value = false;
  pictureAttachmentId.value = null;
  if (props.action === 'add') {
    image.value = null;
    imageUrl.value = null;
  }
}
</script>

<template>
  <v-input ref="input"
      v-model="value"
      :rules="rules"
      :class="`mt-2 mb-0 ${compact? 'd-flex align-center flex-column' : ''}`"
  >
    <v-btn-toggle
        :style="input?.isValid === false?'border: 1px solid rgb(var(--v-theme-error))':''"
        :density="compact? 'compact' : 'default'"
        v-model="value"
        mandatory
        divided
        variant="outlined"
        selected-class="success"
    >
      <v-btn
          :size="compact? 'x-small' : 'default'">
        {{$t('global.text.bool.false')}}
      </v-btn>

      <v-btn
          :size="compact? 'x-small' : 'default'">
        {{$t('global.text.bool.true')}}
      </v-btn>

    </v-btn-toggle>
  </v-input>
</template>

<script setup>
import { computed, ref } from 'vue'

const props = defineProps(['modelValue', 'compact', 'rules'])
const emit = defineEmits(['update:modelValue'])
const input = ref(null);

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value.toString())
  }
})

</script>
<style scoped>
.success {
  background-color: green;
  color: white;
}
.error {
  background-color: red;
  color: white;
}
</style>
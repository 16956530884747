<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <h1 class="green text-center">
          {{ $t('global.error.not-found') }}
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>
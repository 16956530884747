<template>
  <v-expansion-panels>
    <v-expansion-panel
      v-for="group in groups"
      :class="{
        'text-disabled pointer-none': !group.visibility,
        'd-none': !showHiddenQuestions && !group.visibility
      }"
    >
      <v-expansion-panel-title hide-actions>
        <div class="d-flex flex-wrap w-100 align-center justify-space-between">
          <div class="my-1 expansion-panel-title">
            {{group.label}}
          </div>
          <div class="ml-auto">
            <questionnaire-capsules
              questionnaire-type="group"
              :questionnaire-type-id="group.id"
            />
          </div>
        </div>
      </v-expansion-panel-title>

      <questionnaire-group
        :questions="questions"
      />
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useApplicationStore } from '@/stores/applicationStore';
import QuestionnaireGroup from '@/components/mission-planning/questionnaires/QuestionnaireGroup.vue';
import QuestionnaireCapsules from "@/components/mission-planning/questionnaires/parts/QuestionnaireCapsules.vue";

const props = defineProps({
  groups: {
    type: Object,
    required: true
  },
  questions: {
    type: Object,
    required: false
  }
});

const applicationStore = useApplicationStore();

const showHiddenQuestions = computed(() => applicationStore.showHiddenQuestions);

</script>
<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <span
          class="text-h5 font-weight-bold"
          :style="{'color':appStyle.title_text_color}"
        >
          {{ $t('global.title.planning') }} {{filter.selectedYear}}
        </span>

        <visit-new-modal v-if="hasAtLeastOnePlanningTypeFree">
          <template #trigger="slotProps">
            <v-btn
              variant="tonal"
              :prepend-icon="mdiCalendarPlus"
              :title="$t('appointment.new.btn.title')"
              :aria-label="$t('appointment.new.btn.title')"
              @click="slotProps.triggerAction"
              class="float-right"
            >
              {{ $t('appointment.new.btn.title') }}
            </v-btn>
          </template>
        </visit-new-modal>
      </v-col>

      <v-col cols="12" md="12">
        <missions-list
          :missions="missions"
          :missionsPlannings="missionsPlannings"
          :pointOfSales="pointOfSales"
          :readingsHistory="readingsHistory"
          :filter="filter"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { computed } from 'vue';
  import { useStoreManager } from '@/stores/storeManager';
  import { useRouter } from 'vue-router';
  import { mdiCalendarPlus } from '@mdi/js';
  import { useApplicationStore } from '@/stores/applicationStore';
  import MissionsList from '@/components/planning/MissionsList.vue';
  import VisitNewModal from '@/components/mission/appointments/VisitNewModal.vue';
  import { hasAtLeastOnePlanningType } from '@/stores/manager/configurationManager';
  import { SETTINGS } from '@/constants';
  import {reactive} from "vue";
  import moment from 'moment';
  import dateHandler from '@/utils/dateHandler';

  const router = useRouter();

  const filter = reactive({
    selectedYear: moment().format('Y'),
    selectedWeek: { value: dateHandler.getCurrentWeekMondayAndSunday() }
  });

  const applicationStore = useApplicationStore();
  const storeManager = useStoreManager();

  const appStyle = computed(() => applicationStore.appStyle);

  const missionsPlannings = computed(() =>
    storeManager.getFromStoreByCategory('mission', 'missionsPlannings')
  );

  const missions = computed(() =>
    storeManager.getFromStoreByCategory('mission', 'missions')
  );

  const pointOfSales = computed(() => {
    const rawPOS = storeManager.getFromStoreByCategory('mission', 'pointsOfSale');
    Object.keys(rawPOS).forEach(function (key) {
      rawPOS[key]["id"] = key;
    });
    return rawPOS;
  });

  const readingsHistory = computed(() =>
    storeManager.getFromStoreByCategory('readingHistory', 'reading')
  );

  const configurations = computed(() =>
    storeManager.getFromStoreByCategory('configuration', 'configurations') || {}
  );

  const hasAtLeastOnePlanningTypeFree = computed(() =>
    hasAtLeastOnePlanningType(configurations.value, SETTINGS.PLANNING_TYPE.FREE)
  );


</script>

<template>
  <v-card variant="tonal" class="mb-5">
    <v-card-title :style="{'color': appStyle.title_text_color}">
      <v-icon class="mx-3 my-1" size="small" :icon="mdiHistory" />
      {{ $t('mission-planning.info.last-reading.title') }}
    </v-card-title>

    <v-divider />

    <v-card-text>
      <div v-if="lastReading">
        <span class="text-body-1 font-weight-bold">
          {{ $t('mission-planning.info.last-reading.last-reading-date') }}
        </span>
        <span class="text-body-1">
        &nbsp;&nbsp;{{ dateHandler.convertUnixTimestampToHuman(lastReading?.date) }}
        </span>&nbsp;
        <statistic-indicators-wrapper
            :counters="currentReading?.counters?.lastReadingHistoryCounters"
            :show-label="false"
            :show-previous-reading="false"
            type="detail"
            :inline="true"
        />
      </div>
      <statistic-indicators-wrapper
          :counters="currentReading?.counters"
          :show-label="true"
          :show-previous-reading="true"
      />
    </v-card-text>
    <v-divider></v-divider>
    <last-visit-products :reading="lastReading"/>
  </v-card>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { mdiHistory } from '@mdi/js';
import { useApplicationStore } from '@/stores/applicationStore';
import dateHandler from '@/utils/dateHandler';
import LastVisitProducts from "./LastVisitProducts.vue";
import StatisticIndicatorsWrapper from "@/components/common/StatisticIndicatorsWrapper.vue";

const props = defineProps({
  currentReading: {
    required: false
  },
  lastReading: {
    required: false
  },
});
const applicationStore = useApplicationStore();
const appStyle = computed(() => applicationStore.appStyle);
</script>
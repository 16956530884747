import dateHandler from '@/utils/dateHandler';
import { useStoreManager } from '@/stores/storeManager';
import {createReadingIfNotExists} from "./readingManager";

const addNewReadingElement = (readingId, creationDate) => {
  const storeManager = useStoreManager();
  const readingElement = {
    reading: readingId,
    creation_date: creationDate
  };
  return storeManager.add('reading', 'element', readingElement);
}

export async function addNewElement(
  readingId,
  userId,
  missionPlanningId,
  configId,
  config
) {
  let result = false;
  try {
    await createReadingIfNotExists(
      readingId,
      configId,
      missionPlanningId || null,
      userId,
      config.data || null
    );
    const creationDate = dateHandler.generateNowUnixTimestamp();
    result = await addNewReadingElement(readingId, creationDate);
  } catch (err) {
    console.log(err);
  }
  return result;
}

export async function removeExistingElement(readingElement, readingElementId) {
  let result = false;
  try {
    const storeManager = useStoreManager();
    if (await storeManager.remove('reading', 'element', readingElementId)) {
      result = true;
    } else {
      console.error('An error occurred while deleting!');
    }
  } catch (err) {
    console.log(err);
  }
  return result;
}
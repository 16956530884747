import { PosItemCheck } from "./PosItemCheck";
import { ValueComparisonItemCheck } from "./ValueComparisonItemCheck";
import { ProductItemCheck } from "./ProductItemCheck";
import { FieldDateItemCheck } from "./FieldDateItemCheck";
import { ItemDateItemCheck } from "./ItemDateItemCheck";

export class QuestionnaireConditionChecker {
  checkers = [
    new ValueComparisonItemCheck(),
    new PosItemCheck(),
    new ProductItemCheck(),
    new FieldDateItemCheck(),
    new ItemDateItemCheck()
  ]

    check(condition, context) {
        let messages = [];
        if (condition.length === 0 || condition.items.length === 0) {
          return {
            result: true,
            messages: []
          };
        }
        if (this.isIterable(condition.items)) {
            for (const item of condition.items) {
                for (const checker of this.checkers) {
                    if (!this.isCheckerConcernedByItem(checker, item)) {
                        continue;
                    }
                    const isCheckerReturnsTrue = checker.process(item, context)
                    if (condition.mode === "MIN1" && isCheckerReturnsTrue?.result) {
                        return {
                          result: true,
                          messages: []
                        };
                    }
                    else if (condition.mode === "MIN1" && !isCheckerReturnsTrue?.result) {
                        messages.push(isCheckerReturnsTrue?.message);
                    }
                    else if (condition.mode === "ALL" && !isCheckerReturnsTrue?.result) {
                        messages.push(isCheckerReturnsTrue?.message);
                      return {
                        result: false,
                        messages: messages
                      };
                    }
                }
            }
        }
        return {
          result: condition.mode === "ALL",
          messages: messages
        };
    }

  getInformations(condition, context) {
    let informations = {};
    if (condition.length === 0 || condition.items.length === 0) {
      return informations;
    }
    if (this.isIterable(condition.items)) {
      for (const item of condition.items) {
        for (const checker of this.checkers) {
          if (!this.isInfoCheckerConcernedByItem(checker, item)) {
            continue;
          }
          informations = checker.buildInformations(item, context, informations)
        }
      }
    }
    return informations;
  }

    isIterable(obj) {
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
    }

    isCheckerConcernedByItem(checker, item) {
        return checker.types.hasOwnProperty(item?.type);
    }

    isInfoCheckerConcernedByItem(checker, item) {
        return checker.informations.hasOwnProperty(item?.type);
    }

}
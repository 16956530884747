import { useReadingConditionStore } from '@/stores/conditionStore'
import { QuestionnaireConditionChecker } from '@/utils/conditionChecker/QuestionnaireConditionChecker'
import { useReadingStore } from '@/stores/readingStore';
import { useMissionStore} from "@/stores/missionStore";
import { useStoreManager} from "@/stores/storeManager";
import { READING, COUNTER, VALUE} from "@/constants";
import {StatisticIndicatorCalculator} from "@/utils/statisticIndicatorCalculator/StatisticIndicatorCalculator";
import { extractLastReadingByDateAndMission } from '@/stores/manager/readingHistoryManager';
import Timer from '@/utils/timer'
import questionsHandler from "@/utils/questionsHandler";

let screensWithAttachmentsList = {}
let invisibleScreens = new Map();
let invisibleGroups = new Map();
let invisibleQuestions = new Map();
const questionnaireConditionChecker = new QuestionnaireConditionChecker()
let attachmentListToParseForConditions = {};
let screensWithAtLeastOneVisibleQuestion = new Set();
let groupsWithAtLeastOneVisibleQuestion = new Set();

function buildCategorySourceIds(configuration) {
  return (Object.values(configuration?.catalog?.category || []) || {}).map(
    (attachment) => attachment.id
  )
}

function buildProductSourceIds(configuration, dnProductIndicators) {
  return (
    Object.values(configuration?.catalog?.product || []).filter((product) => {
      if (dnProductIndicators && dnProductIndicators.length > 0) {
        return dnProductIndicators.includes(product?.id?.toString())
      } else return true
    }) || {}
  ).map((attachment) => attachment.id)
}

function buildPictureSourceIds(readingId) {
  return Object.entries(useReadingStore().picture)
    .filter(([key, value]) => readingId && !value.to_remove && value.reading === readingId)
    .map(([key, value]) => key)
}

function buildElementSourceIds(readingId) {
  return Object.entries(useReadingStore().element)
    .filter(([key, value]) => readingId && !value.to_remove && value.reading === readingId)
    .map(([key, value]) => key)
}

function buildAttachmentReferential(configuration, readingId, context) {
  const dnProductIndicators = useMissionStore()?.pointsOfSale[context?.pos?.id]?.productIndicators?.dn
  const categorySourceIds = buildCategorySourceIds(configuration);
  const productSourceIds = buildProductSourceIds(configuration, dnProductIndicators);
  const pictureSourceIds = buildPictureSourceIds(readingId)
  const elementSourceIds = buildElementSourceIds(readingId);
  attachmentListToParseForConditions = {
    by_category: {
      key: 'category',
      minimumRequired: 0,
      attachmentIds: categorySourceIds
    },
    by_product: {
      key: 'product',
      minimumRequired: 0,
      attachmentIds: productSourceIds
    },
    by_picture: {
      key: 'picture',
      minimumRequired: configuration?.settings?.nb_required_picture || 0,
      attachmentIds: pictureSourceIds
    },
    by_element: {
      key: 'element',
      minimumRequired: 0,
      attachmentIds: elementSourceIds
    }
  }
}

export function createOrUpdateConditions(configuration, context, readingId) {
  const conditionsTimer = new Timer("createOrUpdateConditions", 2);
  if (readingId === null) { readingId = "default" }
  useReadingConditionStore().initReading(readingId);
  conditionsTimer.echoStep("initReading");
  buildAttachmentReferential(configuration, readingId, context);
  conditionsTimer.echoStep("buildAttachmentReferential");
  screensWithAttachmentsList = {}
  invisibleScreens = new Map();
  invisibleGroups = new Map();
  invisibleQuestions = new Map();
  const screenContext = {...context}
  const groupContext = {...context}
  const questionsContext = {...context}
  conditionsTimer.addStep();
  createOrUpdateScreenConditions(configuration, screenContext, readingId)
  conditionsTimer.echoStep("createOrUpdateScreenConditions");
  createOrUpdateGroupConditions(configuration, groupContext, readingId)
  conditionsTimer.echoStep("createOrUpdateGroupConditions");
  createOrUpdateQuestionConditions(configuration, questionsContext, readingId)
  conditionsTimer.echoStep("createOrUpdateQuestionConditions");
  updateEmptyScreenVisibility(configuration, context, readingId)
  conditionsTimer.echoStep("updateEmptyScreenVisibility");
  updateEmptyGroupVisibility(configuration, context, readingId)
  conditionsTimer.echoStep("updateEmptyGroupVisibility");
  conditionsTimer.echo();
}

function createOrUpdateScreenConditions(configuration, context, readingId) {
  if (configuration.hasOwnProperty('screen')) {
    const screens = Object.values(configuration.screen).filter((screen) => screen.active)
    const screensToInsert = {
      readingId: readingId,
      screens: new Map()
    }
    screens.forEach(function (screen) {
      const screenId = screen.id
      const screenConditions = Object.entries(screen.conditions)
      if (attachmentListToParseForConditions.hasOwnProperty(screen.attachment?.value)) {
        const currentAttachmentList = attachmentListToParseForConditions[screen.attachment.value]
        const attachmentIds = currentAttachmentList.attachmentIds;
        addScreenToScreenWithAttachmentsList(screenId, screen.attachment.value, attachmentIds)
        if (currentAttachmentList.minimumRequired > 0) {
          const nbAttachmentsLeftToCompleteMinimumRequired = currentAttachmentList.minimumRequired - screensWithAttachmentsList[screenId].attachmentIds.length;
          if (nbAttachmentsLeftToCompleteMinimumRequired > 0) {
            for (let i = 0; i < nbAttachmentsLeftToCompleteMinimumRequired; i++) {
              screensWithAttachmentsList[screenId].attachmentIds.push("dummy_item_"+i)
            }
          }
        }
      }
      context.attachementType = 'none'
      context.attachementId = null
      screenConditions.forEach(function ([conditionType, conditionValue]) {
        const conditionResult = questionnaireConditionChecker.check(conditionValue, context)?.result || false
        const currentScreenMap = screensToInsert.screens.get(screenId.toString()) || new Map()
        currentScreenMap.set(conditionType, conditionResult)
        screensToInsert.screens.set(screenId.toString(), currentScreenMap)
        addInvisibleScreenToInvisibleList(conditionResult, conditionType, screenId)
      })
    })
    if (screensToInsert.screens.size > 0) {
      useReadingConditionStore().massUpsert("screens",screensToInsert)
    }
  }
}

function createOrUpdateGroupConditions(configuration, context, readingId) {
  if (configuration.hasOwnProperty('group')) {
    const groups = Object.values(configuration.group).filter((group) => group.active)
    const groupsToInsert = {
      readingId: readingId,
      groups: new Map()
    }
    groups.forEach(function (group) {
      const groupId = group.id
      const groupConditions = Object.entries(group.conditions)
      if (screenHasAttachments(group.screen_id)) {
        const screensAttachments = screensWithAttachmentsList[group.screen_id]
        screensAttachments.attachmentIds.forEach(function (attachmentId) {
          context.attachementType = screensAttachments.attachmentType
          context.attachementId = attachmentId
          context.prod = setProdContext(
            configuration,
            screensAttachments.attachmentType,
            attachmentId
          )
          const itemBuiltId = questionsHandler.buildId(groupId, screensAttachments.attachmentType, attachmentId)
          groupConditions.forEach(function ([conditionType, conditionValue]) {
            let conditionResult = false;
            if (!groupBelongsToInvisibleScreen(group.screen_id)) {
              conditionResult = questionnaireConditionChecker.check(conditionValue, context)?.result || false
            }
            const currentGroupMap = groupsToInsert.groups.get(itemBuiltId) || new Map();
            currentGroupMap.set(conditionType, conditionResult);
            groupsToInsert.groups.set(itemBuiltId, currentGroupMap);
            addInvisibleGroupToInvisibleList(
              conditionResult,
              conditionType,
              groupId,
              screensAttachments.attachmentType,
              attachmentId
            )
          })
        })
      } else {
        context.attachementType = 'none'
        context.attachementId = null
        context.prod = null
        groupConditions.forEach(function ([conditionType, conditionValue]) {
          let conditionResult = false;
          if (!(group.screen_id && groupBelongsToInvisibleScreen(group.screen_id))) {
            conditionResult = questionnaireConditionChecker.check(conditionValue, context)?.result || false
          }
          const currentGroupMap = groupsToInsert.groups.get(groupId.toString()) || new Map();
          currentGroupMap.set(conditionType, conditionResult);
          groupsToInsert.groups.set(groupId.toString(), currentGroupMap);
          addInvisibleGroupToInvisibleList(conditionResult, conditionType, groupId)
        })
      }
    })
    if (groupsToInsert.groups.size > 0) {
      useReadingConditionStore().massUpsert("groups",groupsToInsert)
    }
  }
}

function generateAnswerValuePerCode(context) {
  const answerValuePerCode = {}
  context?.answers?.forEach((answer) => {
    let code = answer.question.toString()
    if (answer.attachment_id) {
      code += `:${answer.attachment_type}|${answer.attachment_id}`
    }
    answerValuePerCode[code] = answer.value
  })
  return answerValuePerCode
}

function createOrUpdateQuestionConditions(configuration, context, readingId) {
  screensWithAtLeastOneVisibleQuestion.clear();
  groupsWithAtLeastOneVisibleQuestion.clear();
  if (configuration.hasOwnProperty('questions')) {
    let questionIsVisible;
    const questions = Object.values(configuration.questions).sort((a, b) => a.order - b.order);
    const questionsToInsert = {
      readingId: readingId,
      questions: new Map()
    }
    const answerValuePerCode = generateAnswerValuePerCode(context)
    questions.forEach(function (question) {
      const questionId = question.id;
      const questionConditions = Object.entries(question.conditions);
      const screenId = question.group?.screen?.id
      const groupId = question.group?.id
      if (screenHasAttachments(screenId)) {
        const screensAttachments = screensWithAttachmentsList[screenId]
        screensAttachments.attachmentIds.forEach(function (attachmentId) {
          const itemBuiltId = questionsHandler.buildId(questionId, screensAttachments.attachmentType, attachmentId)
          questionIsVisible = true;
          context.attachementType = screensAttachments.attachmentType
          context.attachementId = attachmentId
          context.prod = setProdContext(
            configuration,
            screensAttachments.attachmentType,
            attachmentId
          )
          context.currentValue = answerValuePerCode?.[itemBuiltId]
          questionConditions.forEach(function ([conditionType, conditionValue]) {
            // console.log(itemBuiltId, conditionType)
            const conditionResult = calculateQuestionConditionResult(
              conditionType,
              conditionValue,
              question,
              context,
              screensAttachments.attachmentType,
              attachmentId
            )
            const currentQuestionsMap = questionsToInsert.questions.get(itemBuiltId) || new Map()
            currentQuestionsMap.set(conditionType, conditionResult)
            questionsToInsert.questions.set(itemBuiltId, currentQuestionsMap)
            if (conditionType === 'visibility' && !conditionResult) {
              questionIsVisible = false
            }
            addInvisibleQuestionToInvisibleList(
              conditionResult,
              conditionType,
              questionId,
              screensAttachments.attachmentType,
              attachmentId
            )
          })
          if (!questionIsVisible) {
            const currentQuestionsMap = questionsToInsert.questions.get(itemBuiltId) || new Map()
            currentQuestionsMap.set('mandatory', false)
            questionsToInsert.questions.set(itemBuiltId, currentQuestionsMap)
          }
          else {
            screensWithAtLeastOneVisibleQuestion.add(screenId);
            groupsWithAtLeastOneVisibleQuestion.add(`${groupId}${screensAttachments.attachmentType}${attachmentId}`);
          }
        })
      } else {
        questionIsVisible = true;
        context.attachementType = 'none'
        context.attachementId = null
        context.prod = null
        context.currentValue = answerValuePerCode?.[questionId.toString()]
        questionConditions.forEach(function ([conditionType, conditionValue]) {
          // console.log(questionId, conditionType)
          const conditionResult = calculateQuestionConditionResult(
            conditionType,
            conditionValue,
            question,
            context
          )
          const currentQuestionsMap = questionsToInsert.questions.get(questionId.toString()) || new Map()
          currentQuestionsMap.set(conditionType, conditionResult)
          questionsToInsert.questions.set(questionId.toString(), currentQuestionsMap)
          if (conditionType === 'visibility' && !conditionResult) {
            questionIsVisible = false
          }
          addInvisibleQuestionToInvisibleList(conditionResult, conditionType, questionId)
        })
        if (!questionIsVisible) {
          const currentQuestionsMap = questionsToInsert.questions.get(questionId.toString()) || new Map()
          currentQuestionsMap.set('mandatory', false)
          questionsToInsert.questions.set(questionId.toString(), currentQuestionsMap)
        }
        else {
          screensWithAtLeastOneVisibleQuestion.add(screenId);
          groupsWithAtLeastOneVisibleQuestion.add(groupId);
        }
      }
    })
    if (questionsToInsert.questions.size > 0) {
      useReadingConditionStore().massUpsert("questions",questionsToInsert)
    }
  }
}

function groupBelongsToInvisibleScreen(screenId) {
  return invisibleScreens.has(screenId);
}

function questionBelongsToInvisibleGroup(groupId, attachmentType = 'none', attachmentId = null) {
  const builtId = questionsHandler.buildId(groupId, attachmentType, attachmentId)
  return invisibleGroups.has(builtId);
}

function addInvisibleScreenToInvisibleList(conditionResult, conditionType, screenId) {
  if (!conditionResult && conditionType === 'visibility') {
    invisibleScreens.set(screenId, true)
  }
}

function addInvisibleGroupToInvisibleList(
  conditionResult,
  conditionType,
  groupId,
  attachmentType = 'none',
  attachmentId = null
) {
  if (!conditionResult && conditionType === 'visibility') {
    const builtId = questionsHandler.buildId(groupId, attachmentType, attachmentId)
    invisibleGroups.set(builtId, true)
  }
}

function addInvisibleQuestionToInvisibleList(
  conditionResult,
  conditionType,
  questionId,
  attachmentType = 'none',
  attachmentId = null
) {
  if (!conditionResult && conditionType === 'visibility') {
    const builtId = questionsHandler.buildId(questionId, attachmentType, attachmentId)
    invisibleQuestions.set(builtId, true);
  }
}

function addScreenToScreenWithAttachmentsList(screenId, attachmentType, attachmentIds) {
  screensWithAttachmentsList[screenId] = {
    attachmentType: attachmentType,
    attachmentIds: attachmentIds
  }
}

function screenHasAttachments(screenId) {
  return screenId !== null && screensWithAttachmentsList.hasOwnProperty(screenId)
}

const calculateQuestionConditionResult = (
  conditionType,
  conditionValue,
  question,
  context,
  attachmentType = 'none',
  attachmentId = null
) => {
  let conditionResult;
  if (questionBelongsToInvisibleGroup(question.group?.id, attachmentType, attachmentId)) {
    conditionResult = false;
  } else if (
    conditionType === 'mandatory' &&
    (conditionValue.length === 0 || conditionValue.items.length === 0)
  ) {
    conditionResult = question.required
  } else {
    conditionResult = questionnaireConditionChecker.check(
      conditionValue, {invisibleQuestions:invisibleQuestions,...context}
    )?.result || false
  }

  return conditionResult
}

function setProdContext(configuration, attachmentType, attachmentId) {
  if (attachmentType === READING.ATTACHMENT_TYPE.PRODUCT) {
    return configuration.catalog.product[attachmentId]
  }
  return null
}

function updateEmptyScreenVisibility(configuration, context, readingId) {
  if (configuration.hasOwnProperty('screen')) {
    const screens = Object.values(configuration.screen)
    const screensToInsert = {
      readingId: readingId,
      screens: new Map()
    }
    screens.forEach(function (screen) {
      const screenId = screen.id
      if (!screensWithAtLeastOneVisibleQuestion.has(screenId) &&
        ![READING.ATTACHMENT_TYPE.PICTURE, READING.ATTACHMENT_TYPE.ELEMENT].includes(screen.attachment?.value)
      ) {
        const currentScreenMap = screensToInsert.screens.get(screenId.toString()) || new Map()
        currentScreenMap.set('visibility', false)
        screensToInsert.screens.set(screenId.toString(), currentScreenMap)
      }
    })
    if (screensToInsert.screens.size > 0) {
      useReadingConditionStore().massUpsert("screens",screensToInsert)
    }
  }
}

function updateEmptyGroupVisibility(configuration, context, readingId) {
  if (configuration.hasOwnProperty('group')) {
    const groups = Object.values(configuration.group)
    const groupsToInsert = {
      readingId: readingId,
      groups: new Map()
    }
    for ( const group of groups) {
      const groupId = group.id
      if (configuration.hasOwnProperty('screen')) {
        const groupScreen = configuration.screen.find((screen) => screen.id === group.screen_id)
        if (attachmentListToParseForConditions.hasOwnProperty(groupScreen?.attachment?.value)) {
          const attachmentIds = attachmentListToParseForConditions[groupScreen?.attachment.value].attachmentIds
          for (const attachmentId of attachmentIds) {
            if (!groupsWithAtLeastOneVisibleQuestion.has(`${groupId}${groupScreen?.attachment.value}${attachmentId}`)) {
              const itemBuiltId = questionsHandler.buildId(groupId, groupScreen?.attachment.value, attachmentId)
              const currentGroupMap = groupsToInsert.groups.get(itemBuiltId) || new Map();
              currentGroupMap.set('visibility', false);
              groupsToInsert.groups.set(itemBuiltId, currentGroupMap);
            }
          }
          continue;
        }
      }
      if (!groupsWithAtLeastOneVisibleQuestion.has(groupId)) {
        const currentGroupMap = groupsToInsert.groups.get(groupId.toString()) || new Map();
        currentGroupMap.set('visibility', false);
        groupsToInsert.groups.set(groupId.toString(), currentGroupMap);
      }
    }
    if (groupsToInsert.groups.size > 0) {
      useReadingConditionStore().massUpsert("groups",groupsToInsert)
    }
  }
}

export function saveReadingCounters(readingId, configuration) {
  const counterTimer = new Timer("saveReadingCounters", 2);
  const readings = useStoreManager().getFromStoreByCategory('reading', 'reading');
  counterTimer.echoStep("get readings");
  const reading = readings[readingId];
  if (reading.status !== READING.STATUS.NEW) {
    return;
  }
  const readingsHistory = useStoreManager().getFromStoreByCategory('readingHistory', 'reading') || {}
  counterTimer.echoStep("get readingsHistory");
  const missionPlannings = useStoreManager().getFromStoreByCategory('mission', 'missionsPlannings');
  counterTimer.echoStep("get missionplannings");
  const pointOfSale = getPosFromMissionPlanningId(reading?.mission_planning, missionPlannings)
  counterTimer.echoStep("get pointOfSale");
  const lastReadingHistory = extractLastReadingByDateAndMission(
    readingsHistory,
    missionPlannings[reading?.mission_planning]?.mission?.id,
  ) || {}
  counterTimer.echoStep("get lastReadingHistory");
  const lastReadingHistoryCounters = keepOnly3NestedCounters(lastReadingHistory?.counters || {});
  counterTimer.echoStep("get lastReadingHistoryCounters");
  const readingAnswers = useStoreManager().getFromStoreByCategory('reading', 'answer');
  counterTimer.echoStep("get readingAnswers");
  const answers = Object.values(readingAnswers).filter((ra) => ra.reading.toString() === readingId.toString())
  counterTimer.echoStep("get answers");
  const requiredPictures = configuration?.settings?.nb_required_picture || 0;
  counterTimer.echoStep("get requiredPictures");
  const questionsConditionsWithAnswers = getQuestionsConditionsWithAnswers(readingId, answers);
  counterTimer.echoStep("get questionsConditionsWithAnswers");
  const totalCount = questionsConditionsWithAnswers.length;
  counterTimer.echoStep("get totalCount");
  const totalAnswersCount = questionsConditionsWithAnswers.filter(conditionItem => conditionItem.hasAnswer).length;
  counterTimer.echoStep("get totalAnswersCount");
  const totalMandatoryCount = questionsConditionsWithAnswers.filter(conditionItem => conditionItem.mandatory).length;
  counterTimer.echoStep("get totalMandatoryCount");
  const statisticIndicators = new StatisticIndicatorCalculator().calculate( Object.entries(configuration?.statisticIndicators || {}),{
    questionsConditionsWithAnswers: questionsConditionsWithAnswers,
    answers: answers,
    statisticIndicatorsProductIds: pointOfSale?.statisticIndicators
  });
  counterTimer.echoStep("get statisticIndicators");
  const totalMandatoryAnswersCount = questionsConditionsWithAnswers.filter(conditionItem => conditionItem.mandatory && conditionItem.hasAnswer).length;
  counterTimer.echoStep("get totalMandatoryAnswersCount");
  const currentPictures = Object.entries(useStoreManager().getFromStoreByCategory('reading', 'picture'))
    .filter(([key, value]) => readingId && !value.to_remove && value.reading?.toString() === readingId?.toString())
    .map(([key, value]) => {
      return { id: key, ...value }
    })|| {};
  counterTimer.echoStep("get currentPictures");
  reading.counters = {
    total: totalCount,
    answered: totalAnswersCount,
    mandatory: totalMandatoryCount,
    mandatoryAnswered: totalMandatoryAnswersCount,
    optional: totalCount - totalMandatoryCount,
    optionalAnswered: totalAnswersCount - totalMandatoryAnswersCount,
    requiredPictures: requiredPictures,
    pictures: currentPictures.length,
    countStatus: calculateCountStatus(totalAnswersCount, totalMandatoryAnswersCount, totalMandatoryCount),
    statisticIndicators: statisticIndicators,
    lastReadingHistoryCounters: lastReadingHistoryCounters,
    presentDNProductIds: getPresentProductIdsInDNProductIndicatorsProductIds(pointOfSale, questionsConditionsWithAnswers, configuration)
  }
  useStoreManager().update('reading', 'reading', readingId, reading);
  counterTimer.echoStep("update reading");
  counterTimer.echo();
}

function getPosFromMissionPlanningId(missionPlanningId, missionPlannings) {
  const missions = useStoreManager().getFromStoreByCategory('mission', 'missions');
  const pointsOfSale = useStoreManager().getFromStoreByCategory('mission', 'pointsOfSale');
  const missionId = missionPlannings[missionPlanningId]?.mission?.id;
  let posId = null;
  let pos = null;
  if (missionId) {
    posId = missions[missionId]?.pos?.id;
  }
  if (posId) {
    pos = pointsOfSale[posId];
  }
  return pos ? { id: posId, ...pos } : null;
}
function getQuestionsConditions(readingId) {
  return useReadingConditionStore().readingConditions[readingId || VALUE.DEFAULT]?.questions
}

function buildQuestionsWithAnswer(answers) {
  return answers
    .map((answer) => {
      let code = answer.question.toString()
      if (answer.attachment_id) {
        code += `:${answer.attachment_type}|${answer.attachment_id}`
      }
      return {
        [code]: true
      }
    })
    .reduce((acc, obj) => {
      const key = Object.keys(obj)[0]
      acc[key] = obj[key]
      return acc
    }, {})
}

export function getQuestionsConditionsWithAnswers(readingId, answers)  {
  const cwaTimer = new Timer("getQuestionsConditionsWithAnswers", 3);
  const questionsConditions = getQuestionsConditions(readingId);
  cwaTimer.echoStep("getQuestionsConditions");
  if (!questionsConditions) {
    return [];
  }
  const trueAnswers = answers.filter(answer => {
    return ["true", "1"].includes(answer.value?.toString().toLowerCase())
  })
  cwaTimer.echoStep("get trueAnswers");
  const outputArray = [];
  const questionsWithAnswers = buildQuestionsWithAnswer(answers);
  const questionsWithTrueAnswers = buildQuestionsWithAnswer(trueAnswers);
  for (const [questionCode, conditions] of Object.entries(questionsConditions)) {
    if (conditions && conditions.visibility) {
      outputArray.push({
        question_id: questionCode.split(':')[0],
        code:questionCode,
        hasAnswer: questionsWithAnswers[questionCode] || false,
        hasTrueAnswer: questionsWithTrueAnswers[questionCode] || false,
        ...conditions
      })
    }
  }
  cwaTimer.echoStep("build outputArray");
  return outputArray;

}
function calculateCountStatus(totalAnswersCount, totalMandatoryAnswersCount, totalMandatoryCount) {
  if (totalAnswersCount === 0) {
    return COUNTER.STATUS.NEW;
  }
  else if (totalMandatoryAnswersCount < totalMandatoryCount) {
    return COUNTER.STATUS.NOT_READY;
  }
  else {
    return COUNTER.STATUS.READY;
  }
}

function keepOnly3NestedCounters(lastReadingHistoryCounters) {
  if (lastReadingHistoryCounters?.lastReadingHistoryCounters?.lastReadingHistoryCounters) {
    lastReadingHistoryCounters.lastReadingHistoryCounters.lastReadingHistoryCounters = {}
  }
  return lastReadingHistoryCounters;
}

function getPresentProductIdsInDNProductIndicatorsProductIds(pointOfSale, questionsConditionsWithAnswers, configuration) {
  let output = [];
  const dnProductIndicatorsProductIds = useMissionStore()?.pointsOfSale?.[pointOfSale?.id]?.productIndicators?.dn || []
  const statPresenceDNQuestions = Object.values(configuration?.questions)?.filter(
    question => question?.isPresenceType
  )?.map(question => question?.id?.toString())
  for (const questionsCondition of questionsConditionsWithAnswers) {
    if (!statPresenceDNQuestions.includes(questionsCondition.question_id) || !questionsCondition.hasTrueAnswer) {
      continue;
    }
    for (const productId of dnProductIndicatorsProductIds) {
      if (questionsCondition.code === `${questionsCondition.question_id}:${READING.ATTACHMENT_TYPE.PRODUCT}|${productId}`)
      output.push(productId)
    }
  }
  return output;
}

export async function clearInvalidAnswers(readingId) {
  let answersHaveBeenDeleted = false;
  const readingAnswers = useStoreManager().getFromStoreByCategory('reading', 'answer');
  const answers = Object.entries(readingAnswers).filter(([raKey, ra]) => ra.reading.toString() === readingId.toString())
  const questionsWithValidity = getQuestionsConditionsWithAnswers(readingId, answers.map(([key, value]) => value));
  for (const [answerKey, answer] of answers) {
    const answerCode = answer.attachment_id ?
      `${answer.question}:${answer.attachment_type}|${answer.attachment_id}` :
      answer.question.toString();
    const questionWithValidity = questionsWithValidity.find(question => question.code === answerCode)
    if (questionWithValidity && questionWithValidity.hasAnswer && !questionWithValidity.validity) {
      await useStoreManager().hardDelete('reading', 'answer', answerKey);
      answersHaveBeenDeleted = true;
    }
  }
  return answersHaveBeenDeleted;
}
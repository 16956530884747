export const SETTINGS = {
  PLANNING_TYPE: {
    NONE: 'NONE',
    FREE: 'FREE',
    TOP_DOWN: 'TOP_DOWN',
  },
};

export const APP = {
  STYLE: {
    BTN: {
      BACKGROUND_COLOR: '#E6E8EA',
      TEXT_COLOR: '#181818'
    },
    GLOBAL_MENU: {
      BACKGROUND_COLOR: '#FFFFFF',
      TEXT_COLOR: '#2E6D95'
    },
    VISIT_MENU: {
      BACKGROUND_COLOR: '#616161',
      TEXT_COLOR: '#FFFFFF'
    },
    TITLE: {
      TEXT_COLOR: '#2E6D95'
    },
  },
}
<template>
  <div class="scroll-container">
    <div class="scroll-content">
      <v-table fixed-header class="screen-element-table" style="max-height:400px">
        <thead>
          <tr>
            <th class="border-0 bg-grey-lighten-1"></th>

            <template v-for="group in groupedQuestions" :key="group.id">
              <th
                :colspan="group.questions.length"
                class="text-center text-uppercase font-weight-black bg-grey-lighten-1"
              >
                {{ group.label }}
              </th>
            </template>
          </tr>
          <tr>
            <th class="bg-grey-lighten-2 border-0"></th>

            <template v-for="group in groupedQuestions" :key="group.id">
              <th
                v-for="(question, questionKey) in group.questions"
                :key="questionKey"
                class="bg-grey-lighten-2"
              >
                <div class="text-body-1 font-weight-bold text-center question-cell-header">
                  <v-icon size="small" :icon="mdiStar" v-if="question.mandatory" />
                  {{ question.label }} {{ question.mandatory && '*' || '' }}
                </div>
              </th>
            </template>
          </tr>
        </thead>

        <tbody>
          <template
            v-for="readingElement of readingElements"
            :key="readingElement.id"
          >
            <tr v-if="!readingElement?.to_remove">
              <td class="bg-grey-lighten-3 cell-none border-0 pl-0 pr-1">
                <delete-screen-by-element
                  :readingElement="readingElement"
                  :readingElementId="readingElement.id"
                />
              </td>

              <template v-for="group in groupedQuestions" :key="group.id">
                <questionnaire-condensed-form-constructor
                  :questions="group.questions"
                  :groupId="group.id"
                  :attachmentType="READING.ATTACHMENT_TYPE.ELEMENT"
                  :attachmentId="readingElement.id"
                />
              </template>
            </tr>
          </template>
        </tbody>
      </v-table>
    </div>
  </div>

  <div class="mx-3 my-5">
    <add-screen-by-element />
  </div>
</template>

<script setup>
import { computed, inject, provide } from 'vue';
import { mdiStar } from '@mdi/js';
import { useApplicationStore } from '@/stores/applicationStore';
import { useStoreManager } from '@/stores/storeManager';
import { extractReadingIdByParams } from '@/stores/manager/readingManager';
import { READING } from '@/constants';
import QuestionnaireCondensedFormConstructor from '@/components/mission-planning/questionnaires/parts/QuestionnaireCondensedFormConstructor.vue';
import AddScreenByElement from '@/components/mission-planning/questionnaires/modals/AddScreenByElement.vue';
import DeleteScreenByElement from '@/components/mission-planning/questionnaires/modals/DeleteScreenByElement.vue';

const props = defineProps({
  screen: {
    type: Object,
    required: true
  },
  groupedQuestions: {
    type: Object,
    required: false
  },
  attachmentLoopItems: {
    type: Object,
    required: false
  },
});

const storeManager = useStoreManager();
const applicationStore = useApplicationStore();

const currentMissionPlanningId = inject('currentMissionPlanningId');

const userDetails = computed(() => applicationStore.userDetails);
provide('userDetails', userDetails);

const showHiddenQuestions = computed(() => applicationStore.showHiddenQuestions);
provide('showHiddenQuestions', showHiddenQuestions);

const missionPlanning = computed(
  () =>
  storeManager.getFromStoreByCategory('mission', 'missionsPlannings')[currentMissionPlanningId.value] || {}
);
provide('missionPlanning', missionPlanning);

const mission = computed(
  () =>
  storeManager.getFromStoreByCategory('mission', 'missions')[missionPlanning.value?.mission?.id] || {}
);
provide('mission', mission);

const configuration = computed(
  () =>
  storeManager.getFromStoreByCategory('configuration', 'configurations')[mission.value?.configuration?.id] || {}
);
provide('configuration', configuration);

const readings = computed(() =>
  storeManager.getFromStoreByCategory('reading', 'reading') || {}
);
provide('readings', readings);

const readingAnswers = computed(() =>
  storeManager.getFromStoreByCategory('reading', 'answer') || {}
);
provide('readingAnswers', readingAnswers);

const readingsHistory = computed(() =>
  storeManager.getFromStoreByCategory('readingHistory', 'reading') || {}
);
provide('readingsHistory', readingsHistory);

const currentReadingId = computed(() =>
  extractReadingIdByParams(
    readings.value,
    mission.value?.configuration?.id,
    currentMissionPlanningId.value,
    userDetails.value?.id
  )
);
provide('currentReadingId', currentReadingId);

const readingElements = computed(() =>
    Object.entries(storeManager.getFromStoreByCategory('reading', 'element'))?.map(([elId,el]) => {
      return {
        ...el,
        id: elId
      }
    })?.sort(
        (a, b) => a.creation_date - b.creation_date
    ).filter(el => el.reading === currentReadingId.value) || []
)
provide('readingElements', readingElements)

</script>

<style scoped>
.screen-element-table {
  border-collapse: collapse;
}

.screen-element-table th,
td {
  border: 1px solid #d1d1d1;
  background-color: white;
}

.question-cell-header {
  width: 250px !important;
  white-space: initial;
}
</style>
import {defineStore} from 'pinia'
import {computed, ref} from 'vue'
import {MockApiManager} from "./api/MockApiManager";


export const useMockStore = defineStore('mockStore', () => {
    const storeCategories = { messages: {}, config: {}};
    const options = {
        apiManager: new MockApiManager(),
        readonly: false,
        sync: true,
        allTime: false,
        persistentLocalId: false
    }

    const data = ref(storeCategories);
    const since = ref('');

    const items = computed(() => data.value);
    const sinceValue = computed(() => since.value);

    return {
        items,
        data,
        options,
        since,
        sinceValue
    }
})
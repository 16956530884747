export default {

  getElementById: function (object, id) {
    return object[id] || null;
  },

  getPreviousElement: function (object, id) {
    const keys = Object.keys(object);
    const index = keys.indexOf(id.toString());
    if (index > 0) {
      const prevId = keys[index - 1];
      return object[prevId];
    } else {
      return null;
    }
  },

  getNextElement: function (object, id) {
    const keys = Object.keys(object);
    const index = keys.indexOf(id.toString());
    if (index !== -1 && index < keys.length - 1) {
      const nextId = keys[index + 1];
      return object[nextId];
    } else {
      return null;
    }
  },

  getFirstElement: function (object) {
    const keys = Object.keys(object);
    const firstId = keys[0];
    return object[firstId] || null;
  },

  getLastElement: function (object) {
    const keys = Object.keys(object);
    const lastId = keys[keys.length - 1];
    return object[lastId] || null;
  },

  mapToObject: function (map) {
    const obj = {};
    for (const [key, value] of map.entries()) {
      obj[key] = value instanceof Map ? this.mapToObject(value) : value;
    }
    return obj;
  }
}

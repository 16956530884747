{
    "global": {
        "title": {
            "messages": "Messages",
            "planning": "Planning"
        },
        "error": {
            "network": "Network error!",
            "not-found": "Page not found!",
            "no-data": "There is no data",
            "no-picture-selected": "There is no image selected!"
        },
        "success": {
            "deleted": "It was successfully deleted!"
        },
        "btn": {
            "close": "Close",
            "next": "Next",
            "refresh-packages": "Refresh packages",
            "back-to-main-page": "Back to main page",
            "additional-information": "Additional information",
            "back": "Back",
            "delete": "Delete",
            "cancel": "Cancel",
            "edit": "Edit",
            "save": "Save",
            "add": "Add",
            "send": "Envoyer",
            "submit": "Submit",
            "reset": "Reset"
        },
        "datepicker": {
            "ok": "Ok",
            "cancel": "Cancel"
        },
        "text": {
            "no-data": "no data",
            "results": "results",
            "all": {
                "f": "All",
                "m": "All"
            },
            "others": "others",
            "none": {
                "f": "None",
                "m": "None"
            },
            "m2": "m2",
            "bool": {
                "true": "Yes",
                "false": "No",
                "none": " "
            }
        },
        "locale": {
            "fullStdDatePattern": "MM/DD/YYYY - HH:mm:ss",
            "fullDatePattern": "MM/DD/YYYY - HH:mm",
            "onlyDatePattern": "MM/DD/YYYY",
            "datePattern": "dddd, MMMM D",
            "timePattern": "HH:mm"
        },
        "validator": {
            "mandatoryField": "Please enter data for this field! it's mandatory!",
            "only-images": "Only images are allowed"
        },
        "info-modal": {
            "title": "Information about the web application",
            "label": {
                "last-sync": "Last synchronization",
                "application-version": "App version",
                "show-hidden-questions": "Show hidden questions",
                "show-debug-mode": "Debug mode",
                "save-logs": "Save logs",
                "send-logs": "Send logs",
                "synchro": "Sync",
                "save": "Save",
                "downloaded-prod-images": "downloaded product pictures"
            }
        },
        "pos-debug-block": {
            "label": {
                "pos-id": "Pos ID",
                "mission-planning-id": "Mission planning ID",
                "mission-id": "Mission ID",
                "reading-id": "Reading ID",
                "reading-history-id": "Reading history ID",
                "concurrent-reading-id": "Concurrent reading ID/date"
            }
        }
    },
    "top-left-menu": {
        "home": "Home",
        "planning": "Planning",
        "point-of-sale": "Points of sale",
        "messages": "Messages",
        "debug": "Debug",
        "store-list": "Store list",
        "login": "Login",
        "contacts": "Contacts",
        "whaly-link": "Sector analysis"
    },
    "top-right-menu": {
        "logout": "Logout",
        "debug": "Debug",
        "store-list": "Store list",
        "bo-link": "BO space",
        "login": "Log in"
    },
    "auth":{
        "login": {
            "title": "Login",
            "form": {
                "label": {
                    "login": "Login",
                    "password": "Password"
                },
                "btn": "Login",
                "validation_messages": {
                    "email": "Please enter a valid email",
                    "password": "Please enter a password"
                }
            },
            "error": {
                "bad_credentials": "Invalid email or password"
            }
        }
    },
    "home": {
        "alert": {
            "readings-not-updated": "'Reading data' cannot be pulled! On the local site contains new data and has not been pushed yet!"
        }
    },
    "post-it": {
        "config": {
            "label": {
                "conversations": "conversation",
                "pos_code": "Code POS"
            },
            "message": {
                "no-threads": "There are no threads"
            }
        },
        "thread-details": {
            "messenger": {
                "text-input": "Aa.."
            },
            "edit-modal": {
                "title": "Edit message"
            }
        },
        "point-of-sale": {
            "choose-pos-modal": {
                "title": "Choose the point of sale",
                "form" : {
                    "labels": {
                        "pos": "Selection of the point of sale"
                    },
                    "errors": {
                        "unselected-pos": "The point of sale has not been selected!"
                    }
                }
            }
        },
        "message": {
            "author-last-message": "Author last message:",
            "empty-thread": "Empty thread",
            "connecting": "Connecting...",
            "author-me": "Me",
            "templates": "Messages templates",
            "template-1": "Cycle commercial information requested",
            "template-2": "DMF request",
            "template-3": "The customer wants to place an order",
            "template-4": "The customer wants informations about his order's delivery",
            "template-5": "The client doesn't want to give me his data, please help",
            "template-6": "I've set up an unofficial challenge",
            "template-7": "The customer has achieved the challenge successfully",
            "template-8": "The customer has failed the challenge",
            "template-9": "The customer wants to cancel/modify the appointment",
            "template-10": "I've negociated extra shelves"
        }
    },
    "point-of-sale": {
        "title": "Points of sale for",
        "filter-form": {
            "label": {
                "search": "Search in name, address, postal code",
                "store": "Store",
                "department": "Department"
            }
        }
    },
    "planning": {
        "filter": {
            "label": {
                "select-year": "Select year",
                "select-week": "Select the week",
                "today": "Today"
            }
        },
        "list": {
            "week-nb": "Week "
        },
        "message": {
            "no-rdv": "No appointments for this week!",
            "no-reads": "There are no data to display!",
            "to-remove": "This planning is not available! it was deleted!"
        }
    },
    "mission": {
        "block": {
            "pos": {
                "title": "Infos POS"
            },
            "plan-a-visit": {
                "title": "Plan a visit",
                "text": "POS visit on {day}, {date} from {start_hour} to {end_hour}",
                "btn": "Plan a visit"
            },
            "my-visit": {
                "title": "My visit",
                "start": "Start my visit",
                "end": "End my visit",
                "btn": "Complete my statement"
            }
        }
    },
    "mission-planning": {
        "top-menu": {
            "questionnaires": "My questionnaires",
            "visit-info": "My visit",
            "whaly-link": "Visit preparation focus",
            "actions": "Actions",
            "new-visit": "New visit",
            "edit-mission-planning": "Modify",
            "delete-mission-planning": "Delete",
            "barcode-title": "Please scan a bar code"
        },
        "qst": {
            "info-pos": {
                "title": "Infos POS",
                "label": {
                    "typology": "Typology",
                    "secondary-typology": "Secondary typology",
                    "select-secondary-typology": "Select secondary typology",
                    "last-visit-date": "Last visit date",
                    "frequency": "Frequency",
                    "pos-contacts": "My POS contacts",
                    "sign": "Sign",
                    "additional-address": "Additional address",
                    "central": "Central",
                    "warehouse": "Warehouse",
                    "network": "Network",
                    "surface": "Surface",
                    "commercial": "Commercial",
                    "ca": "CA",
                    "sector": "Sector"
                }
            },
            "statistics" : {
                "last-reading": "Previous visit",
                "current-reading": "In progress",
                "labels": {
                    "DETAIL_DN": "DN",
                    "DETAIL_DNO": "DNO"
                }
            },
            "my-questionnaires": {
                "title": "My questionnaires",
                "label": {
                    "optional": "Optional",
                    "mandatory": "Mandatory",
                    "total": "Total"
                },
                "qst": {
                    "history-recovery-notification": "{answerCount} answer(s) recovered from the previous visit",
                    "btn": {
                        "validate": "Validate",
                        "history-recovery": "History recovery"
                    }
                },
                "screen": {
                    "picture": {
                        "add": {
                            "title": "Upload image",
                            "form": {
                                "file": "Upload a file (photo)"
                            }
                        },
                        "edit": {
                            "title": "Edit image",
                            "form": {
                                "file": "Upload a file (photo)"
                            }
                        },
                        "delete": {
                            "title": "Delete image",
                            "text": "Are you sure you want to delete this image?"
                        }
                    },
                    "element": {
                        "add": {
                            "title": "Add a new entry",
                            "text": "Are you sure you want to add a new entry?",
                            "btn": "New entry"
                        },
                        "delete": {
                            "title": "Delete the questionnaire line",
                            "text": "Are you sure you want to remove this line from the questionnaire?"
                        }
                    },
                    "product": {
                        "list": {
                            "all": "All the category"
                        },
                        "filter": {
                            "search": "Search for a product, a brand, an EAN",
                            "tags": "Filter by tag",
                            "dno": "Filter by DN/DNo",
                            "incomplete-products": "Products with incomplete questionnaire"
                        },
                        "sort": "Sort by"
                    },
                    "next-questionnaire": "Next questionnaire",
                    "previous-questionnaire": "Previous questionnaire"
                }
            },
            "status": {
                "label": {
                    "new": "Submit",
                    "toBeTransmitted": "Awaiting transmission",
                    "transmitted": "Transmitted"
                }
            },
            "total-charts": {
                "title": {
                    "optional": "Optional",
                    "mandatory": "Mandatory",
                    "pictures": "Pictures",
                    "total": "Total"
                },
                "label": {
                    "optional-questions": "Optional questions",
                    "mandatory-questions": "Mandatory questions",
                    "total-questions": "Total questions",
                    "answered-questions": "Answered questions"
                }
            }
        },
        "info": {
            "contacts": {
                "title": "My POS contacts",
                "btn": "Contacts management"
            },
            "informative-answer": {
                "title": "Informative Answers",
                "message": {
                    "no-instructions": "There is no data",
                    "zero-item": "No informative answers"
                }
            },
            "last-reading": {
                "title" : "Last visit",
                "last-reading-date": "Last reading date:",
                "message": {
                    "no-data": "There is no data"
                },
                "missing-dno-products": "DNO Missing Products",
                "ean": "Product EAN",
                "product-name": "Product Name",
                "not-missing": "Not missing"
            },
            "flash-news": {
                "title": "Flash news"
            },
            "perfect-store": {
                "title": "Perfect Store",
                "no-data": "No data"
            }
        },
        "actions": {
            "edit": {
                "title": "Modification",
                "form": {
                    "pos": "Point of sale",
                    "date": "Date"
                },
                "validation": {
                    "pos": "Please select a point of sale",
                    "date": "Please add a date"
                },
                "alert": {
                    "unable-enter-date-in-the-past": "Unable to enter a date in the past",
                    "unable-enter-date-in-the-future": "Unable to enter a date in the future"
                }
            },
            "delete": {
                "title": "Delete",
                "text": "Are you sure you want to delete this mission?"
            }
        },
        "filter": {
            "screen-by-category": {
                "brand": "Brand",
                "category": "Category"
            }
        },
        "statement-status": {
            "title": "Statement status:",
            "new": "Not started",
            "incomplete": "Incomplete",
            "ready": "To be transmitted"
        },
        "warning": {
            "already-started-statement": "A statement has already been started on {date}"
        }
    },
    "appointment": {
        "new": {
            "modal": {
                "title": "New visit",
                "create": "Create",
                "pdv": "Point of sale",
                "pdv-group": {
                    "planned": "Planned",
                    "not-planned": "Not planned"
                },
                "configuration": "Client",
                "configurations": "Configurations",
                "type": "Visit type",
                "type-options": {
                    "unexpected": "Without appointment",
                    "expected": "With appointment"
                },
                "time-options": {
                    "now": "Now",
                    "later": "Later"
                },
                "datepicker-label": "On",
                "timepicker-from": "From",
                "timepicker-to": "to",
                "time-range": "time range",
                "no-slot": "No slot available",
                "validation" : {
                    "pdv": "Point of sale is required",
                    "configuration": "Client is required",
                    "type": "Visit type is required",
                    "date": "Date is required",
                    "time": "Time Slot is required",
                    "from": "Start time is required",
                    "to": "End time is required",
                    "need-higher-time": "This time must be greater than the first"
                }
            },
            "btn": {
                "title": "New appointment"
            }
        }
    },
    "contact": {
        "list": {
            "title": "Contacts"
        },
        "filter": {
            "label": {
                "search": "Search in first name, last name and phone number",
                "pos": "Points of sales",
                "township": "Townships",
                "department": "Departments"
            }
        },
        "config-select": "Contacts for",
        "detail-title": "Contact detail",
        "firstname": "First name",
        "lastname": "Last name",
        "email": "Email",
        "job": "Job",
        "phone": "Phone",
        "fax": "Fax",
        "comment": "Comment",
        "pos": "Point of sale",
        "shelf": "Shelf",
        "delete-confirm": "Are you sure you want to delete this contact?",
        "new-contact": "New contact",
        "edit-contact": "Edit contact",
        "validation": {
            "pos": "Point of sale is required",
            "firstname": "First name is required",
            "lastname": "Last name is required",
            "job": "Job is required",
            "phone": "Phone number is invalid (10 digits only)",
            "email": "E-mail is invalid"
        }
    },
    "conditions-messages": {
        "or": " or ",
        "field-date": {
            "future": "Greater : {date}",
            "not-future": "Lower : {date}",
            "pastnday": "Min : {date}",
            "not-pastnday": "Max : {date}",
            "futurnday": "Max : {date}",
            "not-futurnday": "Min : {date}"
        },
        "value-comparison": {
            "eq": "The value must be equal to {value}",
            "not-eq": "The value must not be equal to {value}",
            "gt": "Greater : {value}",
            "not-gt": "Lower : {value}",
            "gteq": "Min : {value}",
            "not-gteq": "Max : {value}",
            "lt": "Lower : {value}",
            "not-lt": "Greater : {value}",
            "lteq": "Max : {value}",
            "not-lteq": "Min : {value}",
            "in": "The value must be included in the list {value}",
            "not-in": "The value must not be included in the list {value}",
            "true": "The value must be 'yes'",
            "not-true": "The value must not be 'yes'",
            "int": "Integer",
            "not-int": "No integer",
            "floatn": "The value must be a decimal number",
            "not-floatn": "The value must not be a decimal number"
        }
    }
}

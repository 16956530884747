<template>
  <v-card
    class="pa-5 mb-10 mission-block"
    variant="outlined"
  >
    <div class="block-title">{{ $t('mission.block.pos.title') }}</div>
    <div class="d-flex">
      <v-icon :icon="mdiStore"/>
      <v-card-title>
        {{ pos.name }} <br>
        {{ pos.address }} <br>
        {{ pos.postal_code + ', ' + pos.city }} <br>
        {{ pos.phone }}
      </v-card-title>
    </div>
  </v-card>
</template>
<script setup>
import { mdiStore } from '@mdi/js';
import { toRefs } from "vue";
const props = defineProps({
  pos: {
    type: Object,
    required: true
  },
});
const { pos } = toRefs(props);
</script>
<style scoped>
i {
  font-size: 50px;
  margin-top: 0.5rem
}
</style>
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import {ApiManager} from "./api/ApiManager";
import {useApplicationStore} from './applicationStore';
import {useStoreManager} from "./storeManager";
import {createOrUpdateConditions, saveReadingCounters} from "./manager/conditionsManager";
import {READING} from "../constants";
import offlineImageHandler from '@/utils/offlineImageHandler';
import {useRoute, useRouter} from "vue-router";
import {hasAtLeastOneReducedTopMenu} from "./manager/configurationManager";

function reduceProducts(configuration, configurationId, nbProducts) {
  configuration.catalog.product = Object.fromEntries(
    Object.entries(configuration?.catalog?.product).slice(0, nbProducts) || []
  )
  useStoreManager().update('configuration', 'configurations', configurationId, configuration)
}

export const useConfigurationStore = defineStore(
  'configurationStore',
  () => {
    const applicationStore = useApplicationStore();
    const userDetails = computed(() => applicationStore.userDetails || false);

    const storeCategories = {
      [userDetails.value.id]: {
        configurations: {},
      }
    };
    const options = {
      apiManager: new ApiManager('packages/configuration'),
      readonly: false,
      sync: true,
      allTime: false,
      persistentLocalId: false
    }
    const data = ref(storeCategories);
    const route = useRoute();
    const router = useRouter();

    const configurations = computed(() => data.value[userDetails.value.id]?.configurations || {});

    const handleOfflineProductImages = async (configurationId, configuration) => {
      const allProducts = configuration?.catalog?.product;
      if (allProducts) {
        for (const [productId, product] of Object.entries(allProducts)) {
          if (product?.image) {
            const key = offlineImageHandler.buildKey(offlineImageHandler.OFFLINE_IMAGE_TABLES.PRODUCT, productId, configurationId);
            const imageEntity = await offlineImageHandler.getByKey(key);
            if (!imageEntity) {
              await offlineImageHandler.addNew({
                key: key,
                url: product.image,
                downloaded: 0,
                downloading: 0
              })
            }
            else if (imageEntity?.url !== product.image) {
              await offlineImageHandler.updateUrl(imageEntity.key, product.image);
          }
        }
        }
        await offlineImageHandler.startMassDownloadProcess();
      }
    }
    const postPullActions = (pulledData) => {
      if (pulledData?.configurations) {
        console.log('Starting post-pull actions for configurations');
        const missionPlannings = useStoreManager().getFromStoreByCategory('mission', 'missionsPlannings');
        const readingAnswers = useStoreManager().getFromStoreByCategory('reading', 'answer');
        const readings = useStoreManager().getFromStoreByCategory('reading', 'reading');
        let initialContext = {
          currentValue: null,
          answers: [],
          pos: null,
          attachementType: 'none',
          attachementId: null,
          prod: null,
          nowDatetime: new Date()
        }
        Object.entries(pulledData.configurations).forEach(([configurationId, configuration]) => {
          if (route.matched.some((matchedRoute) => matchedRoute.name === 'mission-planning')) {
            console.log('Recreating default conditions')
            const missionPlanningId = route.params.id
            initialContext.pos = getPosFromMissionPlanningId(missionPlanningId)
            createOrUpdateConditions(configuration, initialContext, 'default')
          }
          if (readings) {
            Object.entries(readings)
              .filter(
                ([readingId, reading]) =>
                  reading?.mission_planning in missionPlannings &&
                  reading.status === READING.STATUS.NEW &&
                  reading.configuration_id.toString() === configurationId.toString()
              )
              .forEach(([readingId, reading]) => {
                initialContext.answers = Object.values(readingAnswers).filter((ra) => ra.reading.toString() === readingId.toString());
                initialContext.pos = getPosFromMissionPlanningId(reading.mission_planning);
                console.log('Recreating conditions for reading ['+readingId+']');
                createOrUpdateConditions(
                  configuration,
                  initialContext,
                  readingId
                );
                console.log('Saving counters for reading ['+readingId+']');
                saveReadingCounters(readingId, configuration);
              })
          }
          handleOfflineProductImages(configurationId, configuration).then(() =>
            console.log('Offline images handled for configuration [' + configurationId + ']')
          )
        })

        if (hasAtLeastOneReducedTopMenu(pulledData.configurations)) {
          router.replace('/messages');
        }

        console.log('Post-pull actions for configurations finished');
      }
    }

    function getPosFromMissionPlanningId(missionPlanningId) {
      const missionPlannings = useStoreManager().getFromStoreByCategory('mission', 'missionsPlannings');
      const missions = useStoreManager().getFromStoreByCategory('mission', 'missions');
      const pointsOfSale = useStoreManager().getFromStoreByCategory('mission', 'pointsOfSale');
      const missionId = missionPlannings[missionPlanningId]?.mission?.id;
      let posId = null;
      let pos = null;
      if (missionId) {
        posId = missions[missionId]?.pos?.id;
      }
      if (posId) {
        pos = pointsOfSale[posId];
      }
      return pos ? { id: posId, ...pos } : null;
    }

    return {
      data,
      options,
      configurations,
      postPullActions,
    }
  },
  {
    persistedState: {
      overwrite: true
    }
  }
);

<template>
  <v-layout class="rounded rounded-md">
    <v-navigation-drawer
        class="show-on-x-small"
        v-model="drawer"
        temporary
    >
      <v-list>
        <v-list-item
            v-if="isLoggedIn && !isReducedTopMenu">
          <RouterLink
              class="mx-4 my-3 py-0 d-block"
              to="/whaly-sector"
              exact
              active-class="active"
          >
            <div class="d-flex align-center" :style="{'color':'black'}">
              <v-icon :icon="mdiChartBar" />
              <span class="ml-2">{{ $t('top-left-menu.whaly-link') }}</span>
            </div>
          </RouterLink>
        </v-list-item>

        <v-list-item
            v-if="isLoggedIn && !isReducedTopMenu">
          <RouterLink
              class="mx-4 my-3 py-0 d-block"
              to="/planning"
              exact
              active-class="active"
          >
            <div class="d-flex align-center" :style="{'color':'black'}">
              <v-icon :icon="mdiCalendar" />
              <span class="ml-2">{{ $t('top-left-menu.planning') }}</span>
            </div>
          </RouterLink>
        </v-list-item>

        <v-list-item
            v-if="isLoggedIn && !isReducedTopMenu && hasAtLeastOnePlanningTypeFree">
          <RouterLink
              class="mx-4 my-3 py-0 d-block"
              to="/points-of-sale"
              exact
              active-class="active"
          >
            <div class="d-flex align-center" :style="{'color':'black'}">
              <v-icon :icon="mdiWarehouse" />
              <span class="ml-2">{{ $t('top-left-menu.point-of-sale') }}</span>
            </div>
          </RouterLink>
        </v-list-item>

        <v-list-item
            v-if="isLoggedIn && !isReducedTopMenu">
          <RouterLink
              class="mx-4 my-3 py-0 d-block"
              to="/contacts"
              exact
              active-class="active"
          >
            <div class="d-flex align-center" :style="{'color':'black'}">
              <v-icon :icon="mdiContacts" />
              <span class="ml-2">{{ $t('top-left-menu.contacts') }}</span>
            </div>
          </RouterLink>
        </v-list-item>

        <v-list-item
            v-if="isLoggedIn">
          <RouterLink
              class="mx-4 my-3 py-0 d-block"
              to="/messages"
              exact
              active-class="active"
          >
            <div class="d-flex align-center" :style="{'color':'black'}"><post-it-counter />
              <span class="ml-2">{{ $t('top-left-menu.messages') }}</span>
            </div>
          </RouterLink>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :color="appStyle.global_menu_background_color">

      <v-img
        v-if="appStyle.active && appLogo"
        class="mx-1"
        :src="`data:image/jpeg;base64, ${appLogo}`"
        max-height="50"
        contain
      />

      <v-app-bar-title>
        <span :style="{'color':appStyle.global_menu_text_color}">
          {{ ENV.APP.NAME }}
        </span>
      </v-app-bar-title>

      <v-spacer />

      <v-spacer />

      <template v-slot:append>
        <v-btn
            v-if="isLoggedIn"
            size="medium"
            :prepend-icon="mdiMenu"
            variant="text"
            class="mx-3 show-on-x-small"
            @click.stop="drawer = !drawer"
            :color="appStyle.global_menu_text_color"
        ></v-btn>
        <RouterLink
            class="mx-4 hide-on-x-small"
            to="/whaly-sector"
            v-if="isLoggedIn && !isReducedTopMenu"
            exact
            active-class="active"
        >
          <div class="d-flex align-center" :style="{'color':appStyle.global_menu_text_color}">
            <v-icon :icon="mdiChartBar" />
            <span class="hide-on-small ml-2">{{ $t('top-left-menu.whaly-link') }}</span>
          </div>
        </RouterLink>

        <RouterLink
          class="mx-4 hide-on-x-small"
          to="/planning"
          v-if="isLoggedIn && !isReducedTopMenu"
          exact
          active-class="active"
        >
          <div class="d-flex align-center" :style="{'color':appStyle.global_menu_text_color}">
            <v-icon :icon="mdiCalendar" />
            <span class="hide-on-small ml-2">{{ $t('top-left-menu.planning') }}</span>
          </div>
        </RouterLink>

        <RouterLink
          class="mx-4 hide-on-x-small"
          to="/points-of-sale"
          v-if="isLoggedIn && !isReducedTopMenu && hasAtLeastOnePlanningTypeFree"
          exact
          active-class="active"
        >
          <div class="d-flex align-center" :style="{'color':appStyle.global_menu_text_color}">
            <v-icon :icon="mdiWarehouse" />
            <span class="hide-on-small ml-2">{{ $t('top-left-menu.point-of-sale') }}</span>
          </div>
        </RouterLink>

        <RouterLink
          class="mx-4 hide-on-x-small"
          to="/contacts"
          v-if="isLoggedIn && !isReducedTopMenu"
          exact
          active-class="active"
        >
          <div class="d-flex align-center" :style="{'color':appStyle.global_menu_text_color}">
            <v-icon :icon="mdiContacts" />
            <span class="hide-on-small ml-2">{{ $t('top-left-menu.contacts') }}</span>
          </div>
        </RouterLink>

        <RouterLink
          class="mx-4 hide-on-x-small"
          to="/messages"
          v-if="isLoggedIn"
          exact
          active-class="active"
        >
          <div class="d-flex align-center" :style="{'color':appStyle.global_menu_text_color}"><post-it-counter />
            <span class="hide-on-small ml-2">{{ $t('top-left-menu.messages') }}</span>
          </div>
        </RouterLink>

        <RouterLink
          class="ml-5"
          to="/login"
          v-if="!isLoggedIn"
          exact
          active-class="active-link"
        >
          <div class="d-flex align-center" :style="{'color':appStyle.global_menu_text_color}">
            <v-icon :icon="mdiLogin" />
            <span class="hide-on-small ml-2">{{ $t('top-left-menu.login') }}</span>
          </div>
        </RouterLink>

        <v-divider class="ms-3" inset vertical />

        <!-- info -->
        <v-menu>
          <template v-slot:activator="{ props }">
            <app-info-modal :is-online="isOnline" :btnColor="appStyle.global_menu_text_color" />
          </template>
        </v-menu>

        <!-- connection mode -->
        <v-menu>
          <template v-slot:activator="{ props }">
            <connection-mode :btnColor="appStyle.global_menu_text_color" @switch-online-status="updateOnlineStatus" />
          </template>
        </v-menu>

        <v-divider class="ms-3" inset vertical />

        <!-- user -->
        <v-menu v-if="isLoggedIn" @update:modelValue="handleMenuClose">
          <template v-slot:activator="{ props }">
            <v-btn
              size="medium"
              :prepend-icon="mdiAccount"
              variant="text"
              v-bind="props"
              class="mx-3"
              :color="appStyle.global_menu_text_color"
            ></v-btn>
          </template>

          <v-list>
            <v-list-item>
              {{ userDetails.first_name + ' ' + userDetails.last_name }}
            </v-list-item>
            <v-list-item>
              <div v-for="locale in $i18n.availableLocales" :key="locale">
                <v-btn
                  size="large"
                  :prepend-icon="mdiWeb"
                  variant="text"
                  v-if="locale !== applicationStore.locale"
                  @click="updateLocale(locale)"
                  block
                >
                  {{ locale.toUpperCase() }}
                </v-btn>
              </div>
            </v-list-item>

            <v-list-item>
              <v-btn
                  block
                  size="large"
                  variant="text"
                  @click.stop.prevent="magicLink()"
                  :prepend-icon="mdiLink"
                  :loading="magicLinkGenerationInProgress"
              >
                {{ $t('top-right-menu.bo-link') }}
              </v-btn>
              <v-expand-transition>
                <div
                    v-show="showBoLogin">
                  <v-btn
                      block
                      size="small"
                      variant="tonal"
                      :href="boUrl"
                      :prepend-icon="mdiLogin"
                      target="_blank"
                  >
                    {{ $t('top-right-menu.login') }}
                  </v-btn>
                </div>
              </v-expand-transition>
            </v-list-item>

            <v-list-item>
              <v-btn
                block
                size="large"
                variant="text"
                :prepend-icon="mdiLogout"
                target="_blank"
                @click="applicationStore.handleLogout()"
              >
                {{ $t('top-right-menu.logout') }}
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>

    <v-main class="d-flex align-center justify-center">
      <RouterView />
    </v-main>
  </v-layout>
</template>

<script setup>
import PostItCounter from './components/global/PostItCounter.vue';
import {computed, onMounted, ref} from 'vue';
import { useApplicationStore } from './stores/applicationStore';
import dateHandler from "./utils/dateHandler";
import syncManager from "./utils/sync/SyncManager";
import { useLocale } from 'vuetify';
import { useI18n } from 'vue-i18n';
import { hasAtLeastOneReducedTopMenu, hasAtLeastOnePlanningType } from '@/stores/manager/configurationManager';
import notificationService from '@/services/notification';

import {
  mdiAccount,
  mdiCalendar,
  mdiWarehouse,
  mdiLogout,
  mdiLogin,
  mdiContacts,
  mdiWeb,
  mdiLink,
  mdiChartBar,
  mdiMenu
} from '@mdi/js';
import { useStoreManager } from '@/stores/storeManager';
import { ENV, SETTINGS } from '@/constants';
import ConnectionMode from '@/components/global/ConnectionMode.vue';
import AppInfoModal from '@/components/global/AppInfoModal.vue';
import BOAuthService from '@/services/boAuth';
import router from '@/router';


const { t } = useI18n();

//initialize the translator for the dateHandler singleton
dateHandler.setTranslator(t)

const applicationStore = useApplicationStore();

const showBoLogin = ref(false);
const drawer = ref(false);
const magicLinkGenerationInProgress = ref(false);
const boUrl = ref('');

const isLoggedIn = computed(() => applicationStore.isLoggedIn);
const userDetails = computed(() => applicationStore.userDetails);
const appStyle = computed(() => applicationStore.appStyle);
const appLogo = computed(() => applicationStore.appLogo);
const { current } = useLocale()

const storeManager = useStoreManager();
const isOnline = ref(navigator.onLine);

const configurations = computed(() =>
  storeManager.getFromStoreByCategory('configuration', 'configurations') || {}
);

const isReducedTopMenu = computed(() =>
  hasAtLeastOneReducedTopMenu(configurations.value)
);

const hasAtLeastOnePlanningTypeFree = computed(() =>
  hasAtLeastOnePlanningType(configurations.value, SETTINGS.PLANNING_TYPE.FREE)
);


const magicLink = async () => {
  magicLinkGenerationInProgress.value = true;
  const link = await BOAuthService.getMagicLink();
  if (link.data) {
    showBoLogin.value = true;
    boUrl.value = link.data;
  }
  magicLinkGenerationInProgress.value = false;
}

function updateLocale(locale) {
  applicationStore.updateLocale(locale);
  current.value = locale
}

function handleMenuClose(e) {
  if (!e) {
    showBoLogin.value = false;
    magicLinkGenerationInProgress.value = false;
    boUrl.value = '';
  }
}
/*navigator.geolocation.getCurrentPosition(
  position => {
    applicationStore.updateLocation({
      date: dateHandler.generateNowUnixTimestamp(),
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    });
  },
  error => {
    console.log(error.message);
  },
);
*/

function updateOnlineStatus(online) {
  isOnline.value = online;
}

onMounted(() => {
  syncManager.init()

  notificationService.init((notification => {
    console.log("NOTIFICATION OPENED ", notification)
    syncManager.runPartialCycle()

    router.push({ path: '/messages' });
  }));
});
</script>

<template>
  <v-btn
    @click="openModal(true)"
    size="x-small"
    :icon="mdiPlus"
    variant="tonal"
    class="ml-3"
  />


  <v-dialog
    v-model="params.dialog"
    persistent
    max-width="500"
  >
    <v-card>
      <v-toolbar
        color="grey-lighten-2"
        :title="$t('post-it.point-of-sale.choose-pos-modal.title')"
      />

      <v-card-text>
        <v-autocomplete
          :label="$t('post-it.point-of-sale.choose-pos-modal.form.labels.pos')"
          :items="posItemsForSelect"
          :error-messages="form.errors.pos"
          item-title="name"
          item-value="id"
          variant="solo-filled"
          density="compact"
          single-line
          :auto-select-first="true"
          v-model="form.data.pos"
          required
          :custom-filter="customFilter"
          @update:modelValue="onSelectPos"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey-darken-1"
          variant="tonal"
          @click="openModal(false)"
        >
          {{ $t('global.btn.close') }}
        </v-btn>
        <v-btn
          color="teal-darken-1"
          variant="tonal"
          @click="nextStep()"
        >
          {{ $t('global.btn.next') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { mdiPlus } from '@mdi/js';
import {reactive, inject, computed} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { extractThreadByPosId, addNewThread } from '@/stores/manager/postItManager';
import {useStoreManager} from "@/stores/storeManager";

const { t } = useI18n();
const router = useRouter();
const storeManager = useStoreManager();

const props = defineProps({
  configuration: {
    type: Object,
    required: true,
  }
});
const params = reactive({
  dialog: false
});

const form = reactive({
  data: {
    pos: null
  },
  errors: {
    pos: ''
  }
});

const threads = inject('threads');
const pointOfSales = inject('pointOfSales');
const filteredPOS = computed(() => Object.fromEntries(
    Object.entries(pointOfSales.value || {}).filter(([posId, pos]) => {
      return pos?.configuration?.id?.toString() === props.configuration?.id?.toString();
    })) || {}
);

const posItemsForSelect = computed(() =>
    Object.entries(storeManager.getFromStoreByCategory('mission', 'pointsOfSale'))
        .filter(([key, value]) => Object.keys(filteredPOS.value).includes(key))
        .map(([key, value]) => {
          return {
            id: key,
            ...value,
            ...pointOfSales.value[key]
          }
        })
);

const openModal = (truth) => {
  params.dialog = truth;
  form.data.pos = null;
  form.errors.pos = '';
}

const nextStep = () => {
  if (validateForm()) {
    let threadId = extractThreadByPosId(threads.value, form.data.pos);

    if (!threadId) {
      threadId = addNewThread(form.data.pos, pointOfSales.value[form.data.pos]);
    }
    router.push({
      name: 'thread-details',
      params: {
        id: threadId,
      },
    });

    openModal(false);
  }
}

const validateForm = () => {
  let valid = true;
  if (!form.data.pos) {
    form.errors.pos = t('post-it.point-of-sale.choose-pos-modal.form.errors.unselected-pos');
    valid = false;
  } else {
    form.errors.pos = '';
  }
  return valid;
}
function customFilter(itemTitle, queryText, item) {
  const name = item.raw.name.toLowerCase()
  const code = item.raw.code.toLowerCase()
  const posCode = item.raw.pos_code.toLowerCase()
  const city = item.raw.city.toLowerCase()
  const postalCode = item.raw.postal_code.toLowerCase()
  const searchText = queryText.toLowerCase()

  return name.indexOf(searchText) > -1 ||
      code.indexOf(searchText) > -1 ||
      posCode.indexOf(searchText) > -1 ||
      city.indexOf(searchText) > -1 ||
      postalCode.indexOf(searchText) > -1
}
const onSelectPos = () => {
  form.errors.pos = '';
}
</script>

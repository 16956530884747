<template>
  <v-card
    variant="tonal"
    :class="{
      'my-5': true,
      'text-disabled pointer-none': !currentScreen.visibility,
      'd-none': !showHiddenQuestions && !currentScreen.visibility
    }"
  >
    <v-card-title class="my-4 d-flex flex-wrap w-100 align-center justify-space-between" >
      <span class="font-weight-bold text-uppercase ml-3 my-1">
        <v-icon size="small" class="mr-2" :icon="mdiPlayCircle" />
        {{ currentScreen.label }}
      </span>

      <div class="ml-auto">
      <questionnaire-capsules
        v-if="attachmentLoopItems.type !== READING.ATTACHMENT_TYPE.PRODUCT"
        questionnaire-type="screen"
        :questionnaire-type-id="currentScreen.id"
      />
      </div>
    </v-card-title>

    <v-card-text>
      <screen-by-category
        v-if="attachmentLoopItems.type === READING.ATTACHMENT_TYPE.CATEGORY"
        :screen="currentScreen"
        :groupedQuestions="groupedQuestions"
        :attachmentLoopItems="attachmentLoopItems"
      />

      <screen-by-product
        v-if="attachmentLoopItems.type === READING.ATTACHMENT_TYPE.PRODUCT"
        :screen="currentScreen"
        :groupedQuestions="groupedQuestions"
        :attachmentLoopItems="attachmentLoopItems"
      />

      <screen-by-picture
        v-if="attachmentLoopItems.type === READING.ATTACHMENT_TYPE.PICTURE"
        :screen="currentScreen"
        :groupedQuestions="groupedQuestions"
        :attachmentLoopItems="attachmentLoopItems"
      />

      <screen-by-element
        v-if="attachmentLoopItems.type === READING.ATTACHMENT_TYPE.ELEMENT"
        :screen="currentScreen"
        :groupedQuestions="groupedQuestions"
        :attachmentLoopItems="attachmentLoopItems"
      />

      <questionnaire-group-wrapper
        v-if="attachmentLoopItems.type === READING.ATTACHMENT_TYPE.NONE"
        :screen="currentScreen"
        :questions="groupedQuestions"
        :questionnaireType="QST.TYPE.SCREEN"
      />
    </v-card-text>
  </v-card>
<!--  TODO: Find out why the following app bar throws a warning in the console -->
  <v-app-bar
    location="bottom"
  >
    <v-spacer />
    <v-btn
        class="mr-4"
        :style="{'background-color': appStyle.button_background_color, 'color': appStyle.button_text_color}"
        :disabled="currentQuestionnaireIndex === 0"
        @click="goToPreviousScreen()"
    >
      <v-icon :icon="mdiChevronLeft"></v-icon>
      <span>{{ $t('mission-planning.qst.my-questionnaires.screen.previous-questionnaire') }}</span>
    </v-btn>
    <v-btn
        :style="{'background-color': appStyle.button_background_color, 'color': appStyle.button_text_color}"
        :disabled="currentQuestionnaireIndex === visibleScreensIds.length - 1"
        @click="goToNextScreen()"
    >
      <span>{{ $t('mission-planning.qst.my-questionnaires.screen.next-questionnaire') }}</span>
      <v-icon :icon="mdiChevronRight"></v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script setup>
import {computed, inject, onMounted, defineOptions} from 'vue';
import { mdiPlayCircle, mdiChevronRight, mdiChevronLeft } from '@mdi/js';
import { groupedQuestionsByGroupId } from '@/stores/manager/configurationManager';
import QuestionnaireGroupWrapper from '@/components/mission-planning/questionnaires/QuestionnaireGroupWrapper.vue';
import QuestionnaireCapsules from '@/components/mission-planning/questionnaires/parts/QuestionnaireCapsules.vue';
import ScreenByPicture from '@/components/mission-planning/questionnaires/screens/ScreenByPicture.vue';
import ScreenByCategory from '@/components/mission-planning/questionnaires/screens/ScreenByCategory.vue';
import ScreenByProduct from '@/components/mission-planning/questionnaires/screens/ScreenByProduct.vue';
import ScreenByElement from '@/components/mission-planning/questionnaires/screens/ScreenByElement.vue';
import { createOrUpdateConditions } from '@/stores/manager/conditionsManager';
import { useStoreManager } from '@/stores/storeManager';
import { useApplicationStore } from '@/stores/applicationStore';
import { READING, QST } from '@/constants';
import { getScreensWithConditionsAndMandatoryAnswerStatus } from '@/stores/manager/missionPlanningManager';
import { useRoute, useRouter } from 'vue-router';

defineOptions({
  inheritAttrs: false
})
const storeManager = useStoreManager();
const applicationStore = useApplicationStore();
const currentReadingId = inject('currentReadingId');
const currentPointOfSale = inject('currentPointOfSale');
const currentScreen = inject('currentScreen');
const questionsConditionsWithAnswers = inject('questionsConditionsWithAnswers');
const attachmentLoopItems = inject('attachmentLoopItems');
const screenQuestions = inject('screenQuestions');
const currentConfiguration = inject('currentConfiguration');
const route = useRoute();
const router = useRouter();

const showHiddenQuestions = computed(() => applicationStore.showHiddenQuestions);
const appStyle = computed(() => applicationStore.appStyle);

const groupedQuestions = computed(() =>
  groupedQuestionsByGroupId(screenQuestions.value, currentReadingId.value || 'default')
);
const allQuestions = computed(() => Object.values(currentConfiguration.value.questions || {}));
const screens = computed(() => currentConfiguration.value?.screen?.filter((cscreen) => cscreen.active) || [])
const itemWithConditionsAndMandatoryAnswerStatus = computed(() =>
    getScreensWithConditionsAndMandatoryAnswerStatus(currentReadingId.value, screens.value, allQuestions.value, questionsConditionsWithAnswers.value)
        .sort((a, b) => a.order - b.order)
)
const visibleScreensIds = computed(() => itemWithConditionsAndMandatoryAnswerStatus.value?.filter((cscreen) => cscreen.visibility).map(cscreen => cscreen.id) || [])

const currentQuestionnaireIndex = computed(() => visibleScreensIds.value.indexOf(currentScreen.value.id));
function createOrUpdateDefaultConditions() {
  if (currentReadingId.value) {
    return
  }
  const context = {
    currentValue: null,
    answers: [],
    pos: currentPointOfSale.value,
    attachementType: 'none',
    attachementId: null,
    prod: null,
    nowDatetime: new Date()
  }
  createOrUpdateConditions(currentConfiguration.value, context, 'default')
}
function goToPreviousScreen() {
  const previousScreenId = visibleScreensIds.value[currentQuestionnaireIndex.value - 1]
  if (previousScreenId) {
    goToScreen(previousScreenId)
  }
}
function goToNextScreen() {
  const nextScreenId = visibleScreensIds.value[currentQuestionnaireIndex.value + 1]
  if (nextScreenId) {
    goToScreen(nextScreenId)
  }
}
function goToScreen(screenId) {
  router.push({
    name: 'mission-planning-questionnaire-screen',
    params: {
      id: route.params.id,
      screen_id: screenId
    },
  });
}

onMounted(() => {
  createOrUpdateDefaultConditions()
});
</script>

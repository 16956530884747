<template>
  <v-bottom-sheet>
    <template v-slot:activator="{ props }">
      <v-badge
          v-if="filteredItemsCount > 0"
          color="success"
          :offset-x="10"
          :offset-y="5"
          :content="filteredItemsCount"
      >
        <v-btn
            v-bind="props"
            :icon="mdiFilter"
            class="pa-1 mr-2"
            :rounded="true"
            :height="36"
            :width="36"
            variant="text"
        />
      </v-badge>
      <v-btn
          v-bind="props"
          v-else
          :icon="mdiFilter"
          class="pa-1 mr-2"
          :rounded="true"
          :height="36"
          :width="36"
          variant="text"
      />
    </template>

    <v-sheet variant="tonal" class="pa-5">
      <div class="d-flex flex-column flex-md-row">
        <v-text-field
            class="mr-md-5 text-black w-md-50"
            clearable
            :prepend-inner-icon="mdiMagnify"
            v-model="data.filter.search"
            single-line
            density="compact"
        >
          <template v-slot:label>
            <span> {{ $t('mission-planning.qst.my-questionnaires.screen.product.filter.search') }} </span>
          </template>
        </v-text-field>
        <v-checkbox
            single-line
            v-model="data.filter.incompleteOnly"
            density="compact"
            class="mr-md-5 text-black w-md-50"
            @click="checkUnresolvedOnly()"
            :label="$t('mission-planning.qst.my-questionnaires.screen.product.filter.incomplete-products')"></v-checkbox>
      </div>

      <div class="d-flex flex-column flex-md-row">
        <v-select
            clearable
            multiple
            :label="$t('mission-planning.qst.my-questionnaires.screen.product.filter.tags')"
            :placeholder="$t('global.text.all.f')"
            class="mr-md-5 text-black w-md-50"
            v-model="data.filter.tags"
            :items="tagsSelectList"
            item-title="label"
            item-value="id"
            single-line
            density="compact"
        />
        <template v-if="showDnTypeSelect">
          <v-select
              clearable
              :label="$t('mission-planning.qst.my-questionnaires.screen.product.filter.dno')"
              :placeholder="$t('global.text.all.f')"
              class="mr-md-5 text-black w-md-50"
              v-model="data.filter.dnType"
              :items="pdtSelectList"
              item-title="label"
              item-value="id"
              single-line
              density="compact"
          />
        </template>

        <v-btn
            :prepend-icon="mdiRefresh"
            class="my-md-1"
            variant="tonal"
            @click="resetFilter()"
        >
          {{ $t('global.btn.reset') }}
        </v-btn>
      </div>
    </v-sheet>
  </v-bottom-sheet>

</template>

<script setup>
import { inject, computed, ref } from 'vue';
import { mdiMagnify, mdiRefresh, mdiFilter } from '@mdi/js';
import { PDT } from '@/constants';

const emit = defineEmits(['resetFilter', 'checkUnresolvedOnly']);
const currentConfiguration = inject('currentConfiguration');
const currentPointOfSale = inject('currentPointOfSale');
const tagsSelectList = Object.values(currentConfiguration.value?.catalog?.tag || {});
const pdtSelectList = Object.entries(PDT.TYPE).map(([key, value]) => ({
  label: key,
  id: value,
}));

const props = defineProps({
  data: {
    type: Object,
    required: true,
  }
});

function resetFilter() {
  emit('resetFilter');
}
function checkUnresolvedOnly() {
  emit('checkUnresolvedOnly');
}
const filteredItemsCount = computed(() => {
  let count = 0;
  for (const filter of Object.values(props.data?.filter || {})) {
    if (filter && (filter.length > 0 || filter === true)) {
      count++;
    }
  }
  return count;
});

const showDnTypeSelect = computed(() => {
  const productIndicators = currentPointOfSale.value.productIndicators;
  if (!productIndicators) {
    return false;
  }
  const dn = productIndicators.dn || [];
  const dno = productIndicators.dno || [];
  return dn.length > 0 || dno.length > 0;
});

</script>
<template>
  <v-card-text>
    <v-tabs v-model="tab" align-tabs="center">
      <v-tab value="missingProducts">{{ $t('mission-planning.info.last-reading.missing-dno-products') }}</v-tab>
    </v-tabs>
    <v-tabs-window v-model="tab">
      <v-tabs-window-item value="missingProducts">
        <v-card flat>
          <template v-slot:text>
            <v-text-field
                v-if="missingProducts.length > 0"
                v-model="search"
                :prepend-inner-icon="mdiMagnify"
                variant="outlined"
                hide-details
                single-line
            ></v-text-field>
          </template>

          <v-data-table
              :headers="headers"
              :items="missingProducts"
              :search="search"
          >
            <template v-slot:item="{ item }">
              <tr :class="!item.isMissing?'text-disabled':''">
                <td v-if="showDebugMode">
                  <v-chip v-if="!item.isMissing">
                    {{ $t('mission-planning.info.last-reading.not-missing') }}
                  </v-chip>
                </td>
                <td>{{ item.ean }}</td>
                <td>{{ item.name }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-tabs-window-item>
    </v-tabs-window>
  </v-card-text>
</template>

<script setup>
import { defineProps, computed, ref, inject } from 'vue';
import { mdiMagnify } from '@mdi/js';
import { useI18n } from 'vue-i18n';
import {useStoreManager} from "@/stores/storeManager";
import {useApplicationStore} from '@/stores/applicationStore';

const props = defineProps({
  reading: {
    required: false,
    default: []
  },
});
const { t } = useI18n();
const storeManager = useStoreManager();
const applicationStore = useApplicationStore();
const showDebugMode = computed(() => applicationStore.showDebugMode);
const tab = ref(null)
const search = ref('');
const headers = computed(() => {
  let output = [
    { align: 'start', key: 'ean', title: t('mission-planning.info.last-reading.ean') },
    { key: 'name', title: t('mission-planning.info.last-reading.product-name') },
  ];
  if (showDebugMode.value) {
    output.unshift({ key: 'isMissing', title: '' })
  }
  return output;
})
const currentConfiguration = inject('currentConfiguration');

const missingProducts = computed(() => {
  const pointOfSaleProductIndicators = getPosFromMissionPlanningId(
      props.reading?.missionPlanningId
  )?.productIndicators?.dn || []
  const allProducts = currentConfiguration.value?.catalog?.product;
  const prevProductsIds = props.reading?.counters?.presentDNProductIds || []
  return Object.entries(allProducts).filter(
          ([key, val]) => pointOfSaleProductIndicators.includes(key.toString()) &&
              (!prevProductsIds.includes(key.toString()) || showDebugMode.value)
      ).map(
          ([key, val]) => {
            return {
              ...val,
              isMissing: !prevProductsIds.includes(key.toString())
            }
          }
      );
})

function getPosFromMissionPlanningId(missionPlanningId) {
  const missionPlannings = storeManager.getFromStoreByCategory('mission', 'missionsPlannings');
  const missions = storeManager.getFromStoreByCategory('mission', 'missions');
  const pointsOfSale = storeManager.getFromStoreByCategory('mission', 'pointsOfSale');
  const missionId = missionPlannings?.[missionPlanningId]?.mission?.id;
  let posId = null;
  let pos = null;
  if (missionId) {
    posId = missions[missionId]?.pos?.id;
  }
  if (posId) {
    pos = pointsOfSale[posId];
  }
  return pos ? { id: posId, ...pos } : null;
}

</script>
<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
      <template v-slot:activator="{ props }">
        <v-text-field
            v-bind="props"
            :label="label"
            :rules="rules"
            :min-width="minWidth"
            :modelValue="dateFormatted"
            :prepend-inner-icon="mdiCalendar"
            readonly
        />
      </template>
        <v-date-picker
            color="primary"
            title=""
            @click:cancel="menu = false"
            v-model="datePickerModel"
            :min="min"
            :max="max"
        >
          <template v-slot:header>
          </template>
        </v-date-picker>
    </v-menu>
</template>

<script setup>
import {ref, computed, toRef, watch, defineModel} from 'vue'
import {useI18n} from "vue-i18n";
import moment from 'moment';
import {mdiCalendar} from '@mdi/js';
import { useDate } from 'vuetify'

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: ""
  },
  rules: {},
  toIso: {
    type: Boolean,
    required: false,
    default: false
  },
  min: {
    required: false,
    default: null
  },
  max: {
    required: false,
    default: null
  },
  minWidth: {
    required: false,
    default: undefined
  }
});
const emit = defineEmits(['update:modelValue'])

const model = defineModel()
const toIso = toRef(props, 'toIso');
const {t} = useI18n();

const menu = ref(false);
const adapter = useDate();

function updateDate(val) {
  model.value = toIso.value ? adapter.toISO(val) : val;
  menu.value = false;
}

const datePickerModel = computed({
  get: () => toIso.value ? (model.value?adapter.parseISO(model.value):null) : model.value,
  set: newValue => updateDate(newValue)
})

const dateFormatted = computed(() => {
  return model.value ? moment(model.value).format(LocalDateFormat) : "";
});

const LocalDateFormat = t('global.locale.onlyDatePattern');

watch(model, (value) => {
  datePickerModel.value = toIso.value ? (value?adapter.parseISO(value):null) : value;
})
</script>

<template>
  <v-dialog
      :model-value="showModal"
      @update:model-value="closeDialog()"
      max-width="500px"
  >
    <v-card>
      <v-card-text>{{ $t("contact.delete-confirm")}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="closeDialog">{{ $t("global.btn.cancel")}}</v-btn>
        <v-btn color="red-darken-4" variant="text" @click="deleteItemConfirm">{{ $t("global.btn.delete")}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
  show: {
    type: Boolean,
    required: true
  }
});

const emit = defineEmits(['closeDialog', 'deleteContact']);

function closeDialog() {
  emit('closeDialog');
}

function deleteItemConfirm() {
  emit('deleteContact');
}

const showModal = computed(() =>
    props.show
);

</script>

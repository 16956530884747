<template>
  <v-card
    :color="appStyle.visit_menu_background_color"
    class="mx-auto"
  >
    <v-card-title :style="{'color':appStyle.visit_menu_text_color}">
      <div class="flex-wrap d-flex">
        <span>{{ configuration.name || $t('global.text.none.m') }},&nbsp;</span><span> {{ pointOfSale.name }}</span>
      </div>
    </v-card-title>

    <v-card-text :style="{'color':appStyle.visit_menu_text_color}">
      <div class="float-end">
        <bar-code-reader-modal
            v-if="canShowBarcodeBtn"
            :configuration="configuration"
            :style="{'background-color': appStyle.button_background_color, 'color': appStyle.button_text_color, 'position':'absolute', 'right':'15px'}"
        />
      </div>
      <div class="text-subtitle-1 text-wrap">
        {{ DateHandler.convertUnixTimestampToHumanWordsDate(missionPlanning.date_start)}},
        {{ DateHandler.convertUnixTimestampToHumanTime(missionPlanning.date_start)}} -
        {{ DateHandler.convertUnixTimestampToHumanTime(missionPlanning.date_end)}}
      </div>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-btn
        @click="goToQuestionnaires"
        :prepend-icon="mdiListBox"
        :color="appStyle.visit_menu_text_color"
      >
        <span class="hide-on-small">{{ $t('mission-planning.top-menu.questionnaires') }}</span>
      </v-btn>

      <v-btn
          @click="goToInfo"
          :prepend-icon="mdiCalendarAccount"
          :color="appStyle.visit_menu_text_color"
      >
        <span class="hide-on-small">{{ $t('mission-planning.top-menu.visit-info') }}</span>
      </v-btn>

      <v-btn
          @click="goToWhaly"
          :prepend-icon="mdiChartBar"
          :color="appStyle.visit_menu_text_color"
      >
        <span class="hide-on-small">{{ $t('mission-planning.top-menu.whaly-link') }}</span>
      </v-btn>

      <visit-new-modal v-if="planningTypeIsFree" :point-of-sale="pointOfSale">
        <template v-slot:trigger="slotProps">
          <v-btn
            :prepend-icon="mdiCalendarPlus"
            :color="appStyle.visit_menu_text_color"
            @click="slotProps.triggerAction"
            :title="$t('appointment.new.btn.title')"
            :aria-label="$t('appointment.new.btn.title')"
            variant="text"
          >
            <span class="hide-on-small">{{ $t('appointment.new.btn.title') }}</span>
          </v-btn>
        </template>
      </visit-new-modal>

      <v-spacer />

      <v-menu
        class="float-right"
        v-if="showActionsMenu"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            :prepend-icon="mdiDotsVertical"
            :color="appStyle.visit_menu_text_color"
            v-bind="props"
          >
            <span class="hide-on-small">{{ $t('mission-planning.top-menu.actions') }}</span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="(accessToAction(ACTION.EDIT, 'past') || accessToAction(ACTION.EDIT, 'future'))">
            <v-btn variant="text" :prepend-icon="mdiPencil" @click="openModalEditMissionPlanning">
              {{ $t('mission-planning.top-menu.edit-mission-planning') }}
            </v-btn>
          </v-list-item>

          <v-list-item v-if="!hasCurrentReading && (accessToAction(ACTION.DELETE, 'past') || accessToAction(ACTION.DELETE, 'future'))">
            <v-btn variant="text" :prepend-icon="mdiTrashCan" @click="openModalDeleteMissionPlanning">
              {{ $t('mission-planning.top-menu.delete-mission-planning') }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>
  </v-card>

  <delete-mission-planning
    v-model="modalDeleteMissionPlanning"
    :missionPlanningId="missionPlanningId"
  />

  <edit-mission-planning
    v-model="modalEditMissionPlanning"
    :missionPlanningId="missionPlanningId"
    :missionPlanning="missionPlanning"
    :mission="mission"
    :pointsOfSale="pointsOfSale"
    :accessControl="accessControl[props.mission?.configuration?.id]"
    :has-reading="hasCurrentReading"
  />
</template>

<script setup>
import { ref, computed, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useApplicationStore } from '@/stores/applicationStore';
import { checkMissionPlanningAccessControl } from '@/stores/manager/accessControlerManager';
import {
  mdiCalendarAccount,
  mdiListBox,
  mdiCalendarPlus,
  mdiDotsVertical,
  mdiPencil,
  mdiTrashCan,
  mdiChartBar
} from '@mdi/js';
import { SETTINGS, ACTION } from '@/constants';
import DateHandler from '@/utils/dateHandler';
import VisitNewModal from '../mission/appointments/VisitNewModal.vue';
import DeleteMissionPlanning from './modals/DeleteMissionPlanning.vue';
import EditMissionPlanning from './modals/EditMissionPlanning.vue';
import BarCodeReaderModal from "../global/BarCodeReaderModal.vue";

const props = defineProps({
  pointOfSale: {
    type: Object,
    required: true,
  },
  pointsOfSale: {
    type: Object,
    required: true,
  },
  missionPlanning: {
    type: Object,
    required: true,
  },
  missionPlanningId: {
    type: String,
    required: true,
  },
  mission: {
    type: Object,
    required: true,
  },
  configuration: {
    type: Object,
    required: true,
  }
});

const router = useRouter();

const applicationStore = useApplicationStore();
const accessControl = computed(() => applicationStore.accessControl || false);
const appStyle = computed(() => applicationStore.appStyle);

const modalEditMissionPlanning = ref(false);
const modalDeleteMissionPlanning = ref(false);

const currentReadingId = inject('currentReadingId', {});

const openModalEditMissionPlanning = () => {
  modalEditMissionPlanning.value = !modalEditMissionPlanning.value;
}

const openModalDeleteMissionPlanning = () => {
  modalDeleteMissionPlanning.value = !modalDeleteMissionPlanning.value;
}

const goToQuestionnaires = () => {
  router.push(`/mission-planning/${props.missionPlanningId}/questionnaires`);
}

const goToInfo = () => {
  router.push(`/mission-planning/${props.missionPlanningId}/information`);
}

const goToWhaly = () => {
  router.push(`/mission-planning/${props.missionPlanningId}/whaly-focus`);
}

const planningTypeIsFree = SETTINGS.PLANNING_TYPE.FREE === props.configuration?.settings?.planning_type;

const showActionsMenu = () =>
  planningTypeIsFree && (
      (accessToAction(ACTION.EDIT, 'past') || accessToAction(ACTION.EDIT, 'future')) ||
      (accessToAction(ACTION.DELETE, 'past') || accessToAction(ACTION.DELETE, 'future'))
  );

const hasCurrentReading = computed(() => !!currentReadingId.value);

const accessToAction = (action, time) => {
  return checkMissionPlanningAccessControl(
    action,
    time,
    accessControl.value[props.mission.configuration.id],
    props.missionPlanning.date_start
  );
}

const canShowBarcodeBtn = computed(() => {
  return props.configuration?.screen?.some(screen => screen.attachment?.['value'] === "by_product");
});

</script>
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { ApiManager } from "./api/ApiManager";

export const useFlashNewsStore = defineStore('flashNewsStore',
  () => {
    const storeCategories = {
      flash_news: {},
    };

    const options = {
      apiManager: new ApiManager('flash-news'),
      readonly: true,
      sync: true,
      allTime: false,
      persistentLocalId: false
    }
    const data = ref(storeCategories);

    const flashNews = computed(() => data.value?.flash_news || {});

    return {
      data,
      options,
      flashNews,
    }
  },
  {
    persistedState: {
      overwrite: true
    }
  }
);
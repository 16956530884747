export function extractFlashNewsByPosData(flashNews, pos) {
  const storeId = pos.store?.id || false;
  const signId  = pos.store?.sign?.id || false;

  // Initialize an empty array to store the filtered results
  const filteredResults = [];

  // Iterate through each element in objA
  for (const element of Object.values(flashNews)) {
      // Check if store_id matches any of the stores' id
      const storeMatch = element.stores.some(store => store.id === storeId);

      // Check if sign_id matches any of the signs' id
      const signMatch = element.signs.some(sign => sign.id === signId);

      // If either store_id or sign_id matches, add the element to the filtered results
      if (storeMatch || signMatch) {
        filteredResults.push(element);
      }
  }

  return filteredResults;
}
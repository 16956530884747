<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
  >
    <template v-slot:activator="{ props }">
      <v-text-field
          :class="componentClass"
          v-bind="props"
          :label="label || 'Date'"
          :rules="rules"
          :modelValue="dateFormatted"
          :prepend-inner-icon="mdiClockOutline"
          readonly
          @click.stop="openMenu"
      />
    </template>

    <vue-date-picker
        time-picker
        inline
        :modelValue="modelValue"
        :model-type="`timestamp`"
        :minutes-increment="minutesIncrement"
        :minutes-grid-increment="minutesGridIncrement"
        @update:modelValue="updateTime"
    >
      <template #action-row="{ selectDate }">
        <v-container class="action-row">
          <v-row class="d-flex justify-center">
          <v-btn size="small" @click="selectDate">OK</v-btn>
          </v-row>
        </v-container>
      </template>
    </vue-date-picker>
  </v-menu>
</template>

<script setup>
import { ref, computed, watchEffect, toRef, nextTick } from 'vue'
import {useI18n} from "vue-i18n";
import moment from 'moment';
import {mdiClockOutline} from '@mdi/js';

const props = defineProps({
  modelValue: {},
  label: {
    type: String,
    default: ''
  },
  componentClass: {
    type: String,
    default: ''
  },
  rules: {
    default: null
  },
  minutesIncrement: {
    type: Number,
    default: 1
  },
  minutesGridIncrement: {
    type: Number,
    default: 1
  }
});

const emit = defineEmits(['update:modelValue'])

const modelValue = toRef(props, 'modelValue');
const { t } = useI18n();

const menu = ref(false);
const input = ref(null);

function updateTime(val) {
  input.value = val;
  emit('update:modelValue', val);
  menu.value = false;
}
function openMenu() {
  menu.value = true;
  nextTick(() => {
    const activeElement = document.activeElement;
    if (activeElement) {
      activeElement.blur();
    }
  });
}

const dateFormatted = computed(() => {
  return input.value ? moment(input.value).format(localTimeFormat) : '';
});

const localTimeFormat = t('global.locale.timePattern');

watchEffect(() => {
  updateTime(props.modelValue);
});
</script>

import { ApiManager } from './ApiManager'; // Update the path accordingly
import {generateFakeData} from "../../models/item.model";

export class MockApiManager extends ApiManager {
    constructor(url = '') {
        super(url); // Call the base class constructor with super
    }
    async pull(options = {}) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const shouldError = Math.random() < 0.2;
                if (shouldError) {
                    reject(new Error('Failed to fetch data from the API.'));
                } else {
                    let data;
                    data = {
                        messages: {
                            "1": generateFakeData(),
                            "2": generateFakeData(),
                            "3": generateFakeData(),
                        },
                        config: {
                            "4": generateFakeData(),
                            "5": generateFakeData(),
                            "6": generateFakeData(),
                        },
                    };

                    resolve(data); // Resolve with the data
                }
            }, 3000);
        });
    }

    async push(payload = {}) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const data = {
                    "11": generateFakeData(),
                    "12": generateFakeData(),
                    "13": generateFakeData(),
                };

                resolve(data); // Resolve with the data
            }, 3000);
        });
    }
}
<template>
  <div class="mt-5">
  <iframe
      class="border-0"
      :src="whalyUrl"
      width="100%"
      height="1024px"></iframe>
  </div>
</template>

<script setup>

const whalyUrl = window.env.VITE_WHALY_APP_FOCUS_URL || import.meta.env.VITE_WHALY_APP_FOCUS_URL || '';
</script>
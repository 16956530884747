<template>
  <v-container>
    <v-form
        ref="form"
        validate-on="submit"
    >
      <v-text-field
          :label="$t('contact.firstname')"
          v-model="editedContact.firstname"
          :rules="[v => !!v?.trim() || $t('contact.validation.firstname')]"
          :readonly="readOnly"
          :variant="readOnly?'underlined':'filled'"
          density="compact"
          >
      </v-text-field>
      <v-text-field
          :label="$t('contact.lastname')"
          :rules="[v => !!v?.trim() || $t('contact.validation.lastname')]"
          v-model="editedContact.lastname"
          :readonly="readOnly"
          :variant="readOnly?'underlined':'filled'"
          density="compact"
          >
      </v-text-field>
      <v-select
          v-model="editedContact.point_of_sale"
          :items="pointOfSales"
          :prepend-inner-icon="mdiWarehouse"
          :rules="[v => !!v || $t('contact.validation.pos')]"
          :label="$t('contact.pos')"
          :disabled="pointOfSaleIsReadOnly && !readOnly"
          :readonly="readOnly"
          :variant="readOnly?'underlined':'filled'"
          density="compact"
          @update:modelValue="resetShelf"
      ></v-select>
      <v-select
          v-model="editedContact.shelf"
          :items="shelvesList"
          :prepend-inner-icon="mdiBookshelf"
          :label="$t('contact.shelf')"
          :readonly="readOnly"
          :variant="readOnly?'underlined':'filled'"
          density="compact"
      ></v-select>
      <v-text-field
          :label="$t('contact.job')"
          :prepend-inner-icon="mdiAccountHardHat"
          :rules="[v => !!v?.trim() || $t('contact.validation.job')]"
          v-model="editedContact.job"
          :readonly="readOnly"
          :variant="readOnly?'underlined':'filled'"
          density="compact"
          >
      </v-text-field>
      <v-text-field
          :label="$t('contact.phone')"
          :prepend-inner-icon="mdiPhone"
          :rules="[v => !v?.trim() || /^[0-9]{10}$/.test(v.trim()) || $t('contact.validation.phone')]"
          v-model="editedContact.phone"
          :readonly="readOnly"
          :variant="readOnly?'underlined':'filled'"
          density="compact"
          >
      </v-text-field>
      <v-text-field
          :label="$t('contact.email')"
          :prepend-inner-icon="mdiEmail"
          :rules="[v => !v?.trim() || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v.trim()) || $t('contact.validation.email')]"
          v-model="editedContact.email"
          :readonly="readOnly"
          :variant="readOnly?'underlined':'filled'"
          density="compact"
          >
      </v-text-field>
      <v-text-field
          :label="$t('contact.comment')"
          :prepend-inner-icon="mdiInformation"
          v-model="editedContact.comment"
          :readonly="readOnly"
          :variant="readOnly?'underlined':'filled'"
          density="compact"
          >
      </v-text-field>
    </v-form>
  </v-container>
</template>

<script setup>
import {
  defineProps,
  ref,
  onMounted,
  watch,
  defineEmits,
  defineExpose,
  computed } from 'vue';
import {
  mdiPhone,
  mdiEmail,
  mdiInformation,
  mdiAccountHardHat,
  mdiWarehouse,
  mdiBookshelf
} from '@mdi/js';
import { useStoreManager } from '@/stores/storeManager';

const props = defineProps({
  contact: {
    type: Object,
    required: false
  },
  readOnly: {
    type: Boolean,
    default: false,
    required: false
  },
  pointOfSale: {
    type: Object,
    default: {},
    required: false
  }
});
defineExpose({ validate })
const emit = defineEmits(['updateContact'])

const storeManager = useStoreManager();
const editedContact = ref({});
const form = ref(null);

function initForm() {
  editedContact.value.id = props.contact?.id || undefined;
  editedContact.value.firstname = props.contact?.firstname || "";
  editedContact.value.lastname = props.contact?.lastname || "";
  editedContact.value.job = props.contact?.job || "";
  editedContact.value.phone = props.contact?.phone || "";
  editedContact.value.email = props.contact?.email || "";
  editedContact.value.comment = props.contact?.comment || "";
  editedContact.value.point_of_sale = props.contact?.point_of_sale ||
      (props.pointOfSale.id && pointOfSales.value.filter(pos => pos.value.id.toString() === props.pointOfSale.id.toString())[0]?.value) ||
      null;
  editedContact.value.shelf = props.contact?.shelf || null;
}

const pointOfSales = computed(() =>
    Object.entries(storeManager.getFromStoreByCategory('mission', 'pointsOfSale'))
        .map(([key, contact]) => ({ value: { id: parseInt(key) }, title: contact.name }))
    || {}
);

function validate() {
  return form.value.validate();
}

function resetShelf() {
  editedContact.value.shelf = null;
}

const pointOfSaleIsReadOnly = computed(() =>
    props.pointOfSale?.id !== undefined
);

const shelvesList = computed(() => {
  const shelfList = props.pointOfSale?.store?.sign?.shelf ||
  storeManager.getFromStoreByCategory('mission', 'pointsOfSale')[editedContact.value.point_of_sale?.id]?.store?.sign?.shelf

  return shelfList?.map(shelf => ({ value: {id: shelf.id}, title: shelf.name })) || [];
});

watch(editedContact, (edited) => {
  emit('updateContact', edited);
}, { deep: true })

onMounted(() => {
  initForm();
});

</script>

import imageCompression from 'browser-image-compression';
import localForageService from "@/services/localForage";

export default {
  resizeImage: async function (inputFile, size) {
    try {
      const options = {
        maxWidthOrHeight: size
      };
      const compressedFile = await imageCompression(inputFile, options);
      const resizedFile = new File([compressedFile], inputFile.name, {
        type: compressedFile.type,
        lastModified: Date.now(),
      });
      return await resizedFile;
    } catch (error) {
      console.error('Error resizing image:', error);
      return null;
    }
  },

  toBase64: async function (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  },

  fromBase64: async function (base64String, filename) {
    const binaryData = atob(base64String);

    // Create a Uint8Array from the binary data
    const arrayBuffer = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      arrayBuffer[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([arrayBuffer], { type: "image/png" });

    // Create a File from the Blob
    return new File([blob], filename, { type: blob.type });
  },

  extractImagesFromLocalForage: async function (payload, pictureTable) {
    const images = {};
    if (payload.picture) {
      const promiseArray = Object.values(payload.picture).map(picture => {
        return localForageService.retrieveData(pictureTable, picture.file)
          .then(img => {
            return this.toBase64(img);
          });
      });

      await Promise.all(promiseArray)
        .then(base64Pictures => {
          Object.values(payload.picture).forEach((picture, index) => {
            images[picture.file] = base64Pictures[index];
          });
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
    return images;
  }
}
<template>
  <v-container class="bg-surface-variant">
    <v-btn
        block
        class="text-none mb-4"
        color="indigo-darken-3"
        size="x-large"
        variant="flat"
        @click="pull"
    >
      Pull Data
    </v-btn>
    <v-btn
        block
        class="text-none mb-4"
        color="indigo-darken-3"
        size="x-large"
        variant="flat"
        @click="push"
    >
      Push Data
    </v-btn>
    <v-select
        v-model="category"
        label="Select category to add element"
        :items="['messages', 'config']"
    ></v-select>
    <v-btn
        block
        class="text-none mb-4"
        color="indigo-darken-3"
        size="x-large"
        variant="flat"
        @click="saveCategory"
    >
      Save
    </v-btn>
    <v-row no-gutters>
      <v-card width="1000">
        <v-card-text>
          <div class="font-weight-bold ms-1 mb-2">
            Messages
          </div>

          <v-table>
            <thead>
            <tr>
              <th class="text-left">Id</th>
              <th class="text-left">Name</th>
              <th class="text-left">Quantity</th>
              <th class="text-left">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
              <td colspan="4" style="text-align: center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </td>
            </tr>
            <tr
                v-for="(item, key) in messages"
                :key="key"
                :style="colorBasedOnStatus(item)"
                v-if="!loading"
            >
              <td>{{ key }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.quantity }}</td>
              <td>
                <v-btn
                    size="small"
                    variant="text"
                    @click="compose('messages', key)"
                >Edit</v-btn>
                <v-btn
                    class="ml-10"
                    size="small"
                    variant="text"
                    @click="deleteItem('messages', key)"
                >Delete</v-btn>
              </td>
            </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="pt-10" no-gutters>
      <v-card width="1000">
        <v-card-text>
          <div class="font-weight-bold ms-1 mb-2">
            Config
          </div>

          <v-table>
            <thead>
            <tr>
              <th class="text-left">Id</th>
              <th class="text-left">Name</th>
              <th class="text-left">Quantity</th>
              <th class="text-left">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
              <td colspan="4" style="text-align: center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </td>
            </tr>
            <tr
                v-for="(item, key) in config"
                :key="key"
                :style="colorBasedOnStatus(key)"
                v-if="!loading"
            >
              <td>{{ key }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.quantity }}</td>
              <td>
                <v-btn
                    size="small"
                    variant="text"
                    @click="compose('messages', key)"
                >Edit</v-btn>
                <v-btn
                    class="ml-10"
                    size="small"
                    variant="text"
                    @click="deleteItem('messages', key)"
                >Delete</v-btn>
              </td>
            </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </v-card>
    </v-row>

    <v-dialog
        v-model="dialogCompose"
        width="500">
      <v-card>
        <v-card-title class="headline black" primary-title>
          Compose Message
        </v-card-title>
        <v-card-text class="pa-5">
          <v-form ref="sendForm" lazy-validation>
            <v-text-field v-model="mockForm.id" label="Id" disabled></v-text-field>
            <v-text-field v-model="mockForm.fields.name" label="Name" ></v-text-field>
            <v-text-field v-model="mockForm.fields.quantity" label="Quantity"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-btn class="ml-auto" @click="save()" outlined color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>




</template>


<script>

import {useStoreManager} from '../stores/storeManager'
import {ref, reactive, computed} from 'vue'
import {generateFakeData} from "../models/item.model.js";

export default {
  setup() {
    const category = ref();
    const dialogCompose = ref(false);
    const storeManager = useStoreManager();

    const loading = ref(false);

    const all = computed(() => storeManager.all('mock').value);

    const messages = computed(() => all.value?.messages);
    const config = computed(() => all.value?.config);


    const mockForm = reactive({
      fields: {
        id: {
          name: ref(''),
          quantity: ref('')
        },
      },
    });

    function saveCategory() {
      if (category.value) {
        const message = generateFakeData();
        storeManager.add('mock', category.value, message)
      }
    }

    function deleteItem(category, id) {
      storeManager.remove('mock', category, id);
    }

    function colorBasedOnStatus(item) {
      if (item.to_sync || item.is_new) {
        return 'background-color:yellow';
      }
      if (item.to_remove) {
        return 'background-color:red';
      }

      return '';
    }

    function save() {
      storeManager.update('mock', 'messages', mockForm);
      dialogCompose.value = false;
    }

    function compose(category, id) {
      dialogCompose.value = true;
      const element = storeManager.getByIdAndCategory('mock', id, category);
      mockForm.fields = JSON.parse(JSON.stringify(element)); // Clone the object
      mockForm.id = id;
    }

    async function pull() {
      loading.value = true; // Set loading to true before the asynchronous operation starts
      await storeManager.pull('mock');
      loading.value = false; // Set loading to false after the asynchronous operation is completed
    }

    async function push() {
      loading.value = true;
      await storeManager.push('mock', 'messages');
      loading.value = false;
    }


    return {
      messages,
      config,
      category,
      mockForm,
      dialogCompose,
      loading,
      saveCategory,
      deleteItem,
      colorBasedOnStatus,
      save,
      compose,
      pull,
      push
    };
  },
};
</script>
<template>
  <v-btn
    size="small"
    color="surface-variant"
    variant="tonal"
    :icon="mdiTrashCan"
    @click="dialog = true"
  />

  <v-dialog
    v-model="dialog"
    width="auto"
    min-width="30%"
  >
    <v-card>
      <v-card-title>
        {{ $t('mission-planning.qst.my-questionnaires.screen.picture.delete.title') }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <p>{{ $t('mission-planning.qst.my-questionnaires.screen.picture.delete.text') }}</p>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          class="float-right"
          color="grey-darken-2"
          :prepend-icon="mdiClose"
          variant="tonal"
          @click="dialog = false"
        >
          {{ $t('global.btn.cancel') }}
        </v-btn>

        <v-btn
          class="float-right"
          color="success"
          :prepend-icon="mdiCheck"
          variant="tonal"
          @click="deletePicture"
        >
          {{ $t('global.btn.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { mdiCheck, mdiClose, mdiTrashCan } from '@mdi/js';
import {computed, inject, ref} from 'vue';
import { useStoreManager } from '@/stores/storeManager';
import { removeExistingPicture } from '@/stores/manager/readingPicturesManager';
import {createOrUpdateConditions,saveReadingCounters} from "@/stores/manager/conditionsManager";
import { READING, COUNTER } from '@/constants';

const props = defineProps({
  readingPictureId: {
    type: String,
    required: true,
  },
  readingPicture: {
    type: Object,
    required: true,
  }
});

const storeManager = useStoreManager();
const dialog = ref(false);
const currentReadingId = inject('currentReadingId');
const currentPointOfSale = inject('currentPointOfSale');
const currentConfiguration = inject('currentConfiguration');

const deletePicture = () => {
  Object.entries(storeManager.getFromStoreByCategory('reading', 'answer')).forEach(([key, value]) => {
    console.log(key,value?.attachment_id);
  });
  const answersIds = Object.entries(storeManager.getFromStoreByCategory('reading', 'answer'))
      .filter(([key, value]) =>
          value?.reading.toString() === props.readingPicture?.reading.toString()
          && (value?.attachment_type?value?.attachment_type?.toString():"") === READING.ATTACHMENT_TYPE.PICTURE
          && (value?.attachment_id?value?.attachment_id.toString():"") === props.readingPictureId.toString()
      )
      .map(([key, value]) => key);
  for (const answerId of answersIds) {
    storeManager.remove('reading', 'answer', answerId);
  }
  removeExistingPicture(props.readingPicture, props.readingPictureId)
    .then((result) => {
      if (result === true) {
        updateConditions();
        closeModal();
      }
    });
}

function updateConditions() {
  let initialContext = {
    currentValue: null,
    answers: [],
    pos: currentPointOfSale.value,
    attachementType: READING.ATTACHMENT_TYPE.PICTURE,
    attachementId: props.readingPictureId,
    prod: null,
    nowDatetime: new Date()
  }
  createOrUpdateConditions(
      currentConfiguration.value,
      initialContext,
      currentReadingId.value
  );
  saveReadingCounters(currentReadingId.value, currentConfiguration.value)
}
const closeModal = () => {
  dialog.value = false;
}
</script>

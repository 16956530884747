<template>
  <table :class="{ 'inline-stats': inline }" v-for="StatisticsIndexes of chunkedStatisticsIndexes">
    <tr v-if="showPreviousReading">
      <td v-if="showLabel" class="font-weight-bold">{{ $t('mission-planning.qst.statistics.last-reading') }}</td>
      <statistic-indicators
          :counters="counters?.lastReadingHistoryCounters"
          :indexes="StatisticsIndexes"
          :type="type"
      />
    </tr>
    <tr>
      <td v-if="showLabel" class="font-weight-bold">{{ $t('mission-planning.qst.statistics.current-reading') }}</td>
      <statistic-indicators
          :counters="counters"
          :indexes="showPreviousReading?StatisticsIndexes:false"
          :type="type"
      />
    </tr>
  </table>
</template>

<script setup>

import { computed } from 'vue';
import StatisticIndicators from "./StatisticIndicators.vue";

const props = defineProps({
  counters: {
    required: false,
    default: []
  },
  type: {
    required: false,
    default: ''
  },
  showLabel: {
    required: false,
    default: true
  },
  showPreviousReading: {
    required: false,
    default: true
  },
  inline: {
    required: false,
    default: false
  },
  chunkSize: {
    required: false,
    default: 5
  }
});

const filteredStatisticIndicators = computed(() =>
  Object.fromEntries(Object.entries(props.counters?.statisticIndicators || {}).filter(([key, value]) => value.type === props.type))
)
const filteredPreviousStatisticIndicators = computed(() =>
    Object.fromEntries(Object.entries(props.counters?.lastReadingHistoryCounters?.statisticIndicators || {}).filter(([key, value]) => value.type === props.type))
)
const chunkedStatisticsIndexes = computed(() => {
  if (!props.showPreviousReading) {
    return 1;
  }
  const readingStatsKeys = Object.keys(filteredStatisticIndicators.value || []) || []
  const historyStatsKeys = Object.keys(filteredPreviousStatisticIndicators.value || []) || []
  const indexesSet = new Set([...readingStatsKeys, ...historyStatsKeys])
  return chunkArray([...indexesSet], props.chunkSize)
})

function chunkArray(arr, chunkSize) {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index/chunkSize)
    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }
    resultArray[chunkIndex].push(item)
    return resultArray
  }, [])
}

</script>
<template>
  <category-product-switcher
    v-if="currentCategoryId !== SCREEN.CATEGORY.ALL"
    @category-changed="onCategoryChange"
  />

  <strong
    v-if="currentCategoryId === SCREEN.CATEGORY.ALL"
    class="d-flex justify-center text-uppercase pt-4"
  >
    {{ $t('mission-planning.qst.my-questionnaires.screen.product.list.all') }}
  </strong>

  <div class="d-flex pt-2 pb-1 justify-end" ref="actionBarRef">
    <category-product-filter-modal :data="data" @reset-filter="onFilterReset" @check-unresolved-only="setUnAnsweredProductQuestions" />
    <bar-code-reader-modal
      v-if="isCondensedView"
      :configuration="configuration"
      :rounded="true"
      :height="actionBarButtonSize"
      :width="actionBarButtonSize"
      variant="text"
      custom-class="pa-1 mr-2"
    />
    <v-btn-toggle
      v-model="isCondensedView"
      divided
      class="d-flex reduced-height"
      @update:modelValue="changeFormView"
    >
      <v-btn
        :icon="mdiFormatListText"
        :height="actionBarButtonSize"
        variant="outlined"
        :width="actionBarButtonSize"
      ></v-btn>
      <v-btn
        :icon="mdiTable"
        :height="actionBarButtonSize"
        variant="outlined"
        :width="actionBarButtonSize"
      ></v-btn>
    </v-btn-toggle>
  </div>
  <v-table
    v-if="isCondensedView && paginatedItems.length > 0"
    class="screen-product-table"
    :page="page"
    :items-per-page="itemsPerPage"
    :height="productTableComputedHeight"
    fixed-header
  >
    <thead>
      <!--      <tr>-->
      <!--        <th class="border-color-none table-sticky-col product-col"></th>-->

      <!--        <template v-for="group in groupedQuestions" :key="group.id">-->
      <!--          <th-->
      <!--            :colspan="group.questions.length"-->
      <!--            class="text-center text-uppercase font-weight-black bg-grey-lighten-1"-->
      <!--          >-->
      <!--            {{ group.label }}-->
      <!--          </th>-->
      <!--        </template>-->
      <!--      </tr>-->

      <tr>
        <th class="border-color-none table-sticky-col product-col" style="width: 400px;">
          <div class="d-flex align-center" v-if="currentCategoryId === SCREEN.CATEGORY.ALL">
            <strong class="mr-2">
              {{ $t('mission-planning.qst.my-questionnaires.screen.product.sort') }}:
            </strong>

            <v-select
              :items="filterList"
              density="compact"
              single-line
              hide-details
              v-model="data.sort"
              item-title="title"
              item-value="value"
              @update:modelValue="onSortChange"
            />
          </div>
        </th>

        <template v-for="group in groupedQuestions" :key="group.id">
          <th
            v-for="(question, questionKey) in group.questions"
            :key="questionKey"
            class="bg-grey-lighten-2"
          >
            <div class="text-body-1 font-weight-bold text-center question-cell-header">
              {{ question.label }} {{ question.required && '*' || '' }}
            </div>
          </th>
        </template>
      </tr>
    </thead>

    <tbody>
      <template v-for="product of paginatedItems" :key="product.id">
        <tr @click="tryToChangeRowSelection(product.id)">
          <td class="border-color-none table-sticky-col product-col">
            <v-card variant="tonal" class="my-1" style="width: 350px">
              <v-card-title class="text-pre-wrap bg-grey-lighten-3">
                <strong> {{ product.category?.name }} - {{ product.brand?.name }} </strong>
              </v-card-title>

              <div class="d-flex flex-no-wrap">
                <offline-image
                  :offline-image-url="product.image"
                  :offline-image-key="
                    offlineImageHandler.buildKey(
                      offlineImageHandler.OFFLINE_IMAGE_TABLES.PRODUCT,
                      product.id,
                      configuration?.id
                    )
                  "
                />

                <v-card-text class="text-pre-wrap pa-1 d-flex flex-column justify-center">
                  <v-tooltip
                    :text="$t('mission-planning.qst.my-questionnaires.qst.btn.history-recovery')"
                    location="top"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-if="existsGroupQuestionsWithHistory"
                        v-bind="props"
                        :icon="mdiHistory"
                        variant="text"
                        color="info"
                        size="x-small"
                        class="history-recovery-icon-btn"
                        @click="triggerHistoryRecoveryProcess(product.id)"
                      ></v-btn>
                    </template>
                  </v-tooltip>
                  <div :class="`font-weight-bold ${productColorClass(product)}`">
                    {{ product.name }}
                  </div>
                  <div>{{ product.ean }}</div>
                  <div v-if="productTags(product.tags)?.length > 0">
                    <v-chip
                      size="small"
                      v-for="tag of productTags(product.tags)"
                      class="mr-1 mb-1"
                      :color="tag.color"
                      label
                    >
                      {{ tag.label }}
                    </v-chip>
                  </div>
                </v-card-text>
              </div>
            </v-card>
          </td>

          <template v-for="group in groupedQuestions" :key="group.id">
            <questionnaire-condensed-form-constructor
              :questions="group.questions"
              :groupId="group.id"
              :attachmentType="READING.ATTACHMENT_TYPE.PRODUCT"
              :attachmentId="product.id"
              :historyRecoveryData="historyRecoveryData"
              :product-selected="productSelected"
              :product-blocked-in-edition="productBlockedInEdition"
              :show-missing-mandatory-answers-flag="showMissingMandatoryAnswersFlag"
              :trigger-reload="triggerReload"
              @answeredToAQuestion="answeredToAQuestion"
              @productHasInvalidAnswer="productHasInvalidAnswer"
            />
          </template>
        </tr>
      </template>
    </tbody>
  </v-table>
  <div v-else class="d-flex justify-center align-center text-body-1 font-weight-bold my-5">
    {{ $t('global.text.no-data') }}
  </div>

  <screen-by-category
    v-else
    class="mt-5"
    :screen="screen"
    :groupedQuestions="groupedQuestions"
    :attachmentLoopItems="attachmentLoopItems"
  />

  <v-app-bar location="bottom" ref="bottomAppBarRef">
    <v-spacer />

    <div class="text-center">
      <v-pagination
        v-if="isCondensedView"
        :disabled="!!productBlockedInEdition"
        v-model="page"
        :length="totalPages"
        :total-visible="5"
      />
    </div>

    <v-spacer />

    <v-btn
      v-if="existsGroupQuestionsWithHistory && isCondensedView"
      :prepend-icon="mdiHistory"
      :loading="globalHistoryRetrieveInProgress"
      variant="tonal"
      color="info"
      class="mx-1"
      @click="triggerHistoryRecoveryProcess()"
    >
      {{ $t('mission-planning.qst.my-questionnaires.qst.btn.history-recovery') }}
    </v-btn>

    <v-btn
      :prepend-icon="mdiArrowLeft"
      variant="tonal"
      @click="goToQuestionnaireCategories()"
      class="mx-1"
    >
      {{ $t('global.btn.back') }}
    </v-btn>
  </v-app-bar>
  <v-snackbar v-model="snackbar" :timeout="3000" variant="elevated">
    <div class="d-flex justify-center">
      {{
        $t('mission-planning.qst.my-questionnaires.qst.history-recovery-notification', {
          answerCount: insertedAnswersFromHistoryCount
        })
      }}
    </div>
  </v-snackbar>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { computed, provide, ref, reactive, watch, defineOptions, onMounted } from 'vue';
import { useElementBounding } from '@vueuse/core'
import { mdiArrowLeft, mdiHistory, mdiTable, mdiFormatListText } from '@mdi/js';
import { useI18n } from 'vue-i18n';
import {
  extractScreenQuestions,
  groupedQuestionsByGroupId,
  checkIfExistsSomeGroupQuestionsWithHistory
} from '@/stores/manager/configurationManager';
import { extractReadingIdByParams } from '@/stores/manager/readingManager';
import { READING, SCREEN, VALUE, PDT } from '@/constants';
import { useStoreManager } from '@/stores/storeManager';
import { useApplicationStore } from '@/stores/applicationStore';
import { useReadingConditionStore } from '@/stores/conditionStore';
import QuestionnaireCondensedFormConstructor from '@/components/mission-planning/questionnaires/parts/QuestionnaireCondensedFormConstructor.vue';
import CategoryProductFilterModal from '@/components/mission-planning/questionnaires/filters/CategoryProductFilterModal.vue';
import CategoryProductSwitcher from '@/components/mission-planning/questionnaires/filters/CategoryProductSwitcher.vue';
import stringHandler from '@/utils/stringHandler';
import arrayHandler from '@/utils/arrayHandler';
import {createReadingIfNotExists} from "@/stores/manager/readingManager";
import dateHandler from "@/utils/dateHandler";
import {
  createOrUpdateConditions,
  saveReadingCounters,
  clearInvalidAnswers,
  getQuestionsConditionsWithAnswers} from "@/stores/manager/conditionsManager";
import ScreenByCategory from "@/components/mission-planning/questionnaires/screens/ScreenByCategory.vue";
import OfflineImage from "@/components/global/OfflineImage.vue";
import offlineImageHandler from "@/utils/offlineImageHandler";
import BarCodeReaderModal from "@/components/global/BarCodeReaderModal.vue";

defineOptions({
  inheritAttrs: false
})

const storeManager = useStoreManager();
const applicationStore = useApplicationStore();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const bottomAppBarRef = ref(null);
const bottomAppBarBoundingClientRect = ref(null);
const actionBarRef = ref(null);
const actionBarBoundingClientRect = ref(null);

const readingConditionStore = useReadingConditionStore();
const snackbar = ref(false);
const isCondensedView = ref(applicationStore.data.condensedForm);
const insertedAnswersFromHistoryCount = ref(0);
const actionBarButtonSize = ref(36);

const props = defineProps({
  barcode: {
    type: String,
    required: false
  }
});

const productTableComputedHeight = computed(() => {
  const calculatedHeight =
    (bottomAppBarBoundingClientRect.value?.top || 0) -
    (actionBarBoundingClientRect.value?.bottom || 0) -
    16 // padding
  return calculatedHeight > 0 ? calculatedHeight : 400;
});
const changeFormView = (status) => {
  applicationStore.updateCondensedForm(status);
}

const currentMissionPlanningId = computed(() => route.params.id);
provide('currentMissionPlanningId', currentMissionPlanningId);
const currentScreenId = computed(() => route.params.screen_id);
provide('currentScreenId', currentScreenId);

const screen = computed(() => {
  const currentScreen =
    configuration?.value?.screen?.filter((scr) => scr.id.toString() === currentScreenId.value)[0] ??
    []

  const conditions =
    readingConditionStore.readingConditions[currentReadingId.value || 'default']?.screens

  if (!!conditions)
    currentScreen.visibility = conditions[currentScreen.id]
      ? conditions[currentScreen.id].visibility
      : true

  return currentScreen
})

const currentCategoryId = computed(() => route.params.category_id);
provide('currentCategoryId', currentCategoryId);
const userDetails = computed(() => applicationStore.userDetails);
provide('userDetails', userDetails);
const showHiddenQuestions = computed(() => applicationStore.showHiddenQuestions);
provide('showHiddenQuestions', showHiddenQuestions);

const missionPlanning = computed(
  () =>
  storeManager.getFromStoreByCategory('mission', 'missionsPlannings')[currentMissionPlanningId.value] || {}
);
provide('missionPlanning', missionPlanning);

const mission = computed(
  () =>
  storeManager.getFromStoreByCategory('mission', 'missions')[missionPlanning.value?.mission?.id] || {}
);
provide('mission', mission);

const configuration = computed(
  () =>
  storeManager.getFromStoreByCategory('configuration', 'configurations')[mission.value?.configuration?.id] || {}
);
provide('configuration', configuration);

const readingsHistory = computed(() =>
  storeManager.getFromStoreByCategory('readingHistory', 'reading') || {}
);
provide('readingsHistory', readingsHistory);

const readings = computed(() =>
  storeManager.getFromStoreByCategory('reading', 'reading') || {}
);
provide('readings', readings);

const readingAnswers = computed(() =>
  storeManager.getFromStoreByCategory('reading', 'answer') || {}
);
provide('readingAnswers', readingAnswers);

const missionReadingHistory = computed(() => {
  return Object.values(readingsHistory.value).find((rh) => parseInt(rh.mission) === parseInt(missionPlanning.value?.mission?.id))
});

const attachmentLoopItems = computed(() => {
  const attachments = screen.value?.attachment?.value;

  if (attachments === READING.ATTACHMENT_TYPE.PRODUCT) {
    const filteredProductIds = filteredConfigurationProducts.value.map((p) => p.id?.toString())
    const filteredAttachmentItems = products.value.filter((pr) => {
      return filteredProductIds.includes(pr.id?.toString())
    });
    return { items: filteredAttachmentItems, labelKey: 'name', type: READING.ATTACHMENT_TYPE.PRODUCT };
  }

  if (attachments === READING.ATTACHMENT_TYPE.ELEMENT) {
    return { items: [], labelKey: 'name', type: READING.ATTACHMENT_TYPE.ELEMENT };
  }

  if (!attachments) {
    return { items: [], labelKey: 'name', type: 'none' };
  }

  return undefined;
});
provide('attachmentLoopItems', attachmentLoopItems);

const triggerReload = ref(false);
const globalHistoryRetrieveInProgress = ref(false);

const page = ref(1);
const itemsPerPage = ref(5);

const data = reactive({
  filter: {
    search: '',
    tags: [],
    dnType: null,
    incompleteOnly: null
  },
  sort: 'brand'
});
const answers = computed(
  () =>
        Object.values(storeManager.getFromStoreByCategory('reading', 'answer'))
            .filter(answer => answer.reading?.toString() === currentReadingId.value?.toString())
        || {});

const questionsConditionsWithAnswers = computed(() => {
  return getQuestionsConditionsWithAnswers(currentReadingId.value, answers.value);
});
provide('questionsConditionsWithAnswers', questionsConditionsWithAnswers);

const productBlockedInEdition = ref(null);
const productSelected = ref(null);
const showMissingMandatoryAnswersFlag = ref(false);

const historyRecoveryData = reactive({});

const filterList = [
  { title: t('mission-planning.filter.screen-by-category.brand'), value: 'brand' },
  { title: t('mission-planning.filter.screen-by-category.category'), value: 'category' }
];

const screens = computed(
  () =>
    configuration.value?.screen?.filter(
      (screen) => screen.id?.toString() === currentScreenId.value?.toString()
) || []);

const screenQuestions = computed(() =>
  extractScreenQuestions(Object.values(configuration.value?.questions || {}), screens.value)
);

const currentReadingId = computed(() =>
  extractReadingIdByParams(
    readings.value,
    mission.value?.configuration?.id,
    currentMissionPlanningId.value,
    userDetails.value?.id
  )
);

const unAnsweredMandatoryProductQuestions = ref([])

function countInsertedAnswers(insertedAnswers) {
  const answerKeys = Object.entries(readingAnswers.value)
          .filter( ([key, answer]) =>
        answer.reading?.toString() === currentReadingId.value?.toString() &&
        insertedAnswers.includes(key)
          )
      || {}
  return answerKeys.length;
}

const groupedQuestions = computed(() =>
  groupedQuestionsByGroupId(screenQuestions.value, currentReadingId.value || VALUE.DEFAULT)
);

const pointsOfSale = computed(() =>
    storeManager.getFromStoreByCategory('mission', 'pointsOfSale') || {}
);

const pointOfSale = computed(() => {
  const pos = pointsOfSale?.value[mission.value?.pos?.id] || null
  if (pos === null) {return {};}
  pos.id = mission.value?.pos?.id
  return pos
})
provide('currentPointOfSale', pointOfSale);

const dnProductIndicators = computed(() =>
    pointOfSale.value?.productIndicators?.dn
);

const dnoProductIndicators = computed(() =>
    pointOfSale.value?.productIndicators?.dno
);
const products = computed(() => Object.values(configuration.value?.catalog?.product || []).map(product => {
  const productIndicatorType =
      dnoProductIndicators.value?.includes(product?.id?.toString()) ? PDT.TYPE.DNO :
          (dnProductIndicators.value?.includes(product?.id?.toString()) ? PDT.TYPE.DN : '');
        return {
          ...product,
          productIndicatorType: productIndicatorType
        }
}).filter(product => {
        if (dnProductIndicators.value && dnProductIndicators.value.length > 0) {
          return dnProductIndicators.value.includes(product?.id?.toString())
  }
  else return true;
}) || {});

const configurationProducts = computed(() => {
  const outputProducts = currentCategoryId.value === SCREEN.CATEGORY.ALL ?
      products.value :
      products.value.filter(
          (p) => p?.category?.id?.toString() === currentCategoryId.value?.toString()
        ) || {}

  if (data.filter.incompleteOnly) {
    return outputProducts.filter((product) => {
      return unAnsweredMandatoryProductQuestions.value.includes(product.id?.toString())
    })
  }
  return outputProducts
})

const filterConfigurationProducts = () => {
  return configurationProducts.value
    .filter(p => (
        data.filter.search === '' || data.filter.search === null || stringHandler.substringFoundIn(data.filter.search, [p.name, p.ean, p.brand?.name])
      ) && (
        data.filter.tags.length === 0 || arrayHandler.hasCommonElement(p.tags, data.filter.tags)
      ) && (
        data.filter.dnType === null || p.productIndicatorType === data.filter.dnType
  )
    );
};

const filterIsSet = () =>
  data.filter.search !== '' ||
  data.filter.tags.length !== 0 ||
  data.filter.dnType !== null ||
  data.filter.incompleteOnly ||
  data.sort

const sortBy = (object) =>
  Object.values(object).sort((a, b) => {
    if (data.sort === 'category') {
    return a.category?.name?.localeCompare(b.category.name) || a.brand?.name?.localeCompare(b.brand.name);
    }
  return a.brand.name?.localeCompare(b.brand.name) || a.name?.localeCompare(b.name);
});

const filteredConfigurationProducts = computed(() => {
  return filterIsSet() ? sortBy(filterConfigurationProducts()) : sortBy(configurationProducts.value)
});

const paginatedItems = computed(() => {
  const startIndex = (page.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return filteredConfigurationProducts.value.slice(startIndex, endIndex);
});

const totalPages = computed(() =>
  Math.ceil(filteredConfigurationProducts.value.length / itemsPerPage.value));

const existsGroupQuestionsWithHistory =
  checkIfExistsSomeGroupQuestionsWithHistory(groupedQuestions.value, missionReadingHistory.value, READING.ATTACHMENT_TYPE.PRODUCT, filteredConfigurationProducts.value);

const productTags = (tags) =>
  Object.values(configuration.value?.catalog?.tag).filter((t) => tags.includes(t?.id)) || {};

const goToQuestionnaireCategories = () => {
  router.push({
    name: 'mission-planning-questionnaire-screen',
    params: {
      id: currentMissionPlanningId.value,
      screen_id: currentScreenId.value
    },
  });
}

const resetQueryParams = () => {
  router.push({
    name: 'mission-planning-questionnaire-screen-category',
    params: {
      screen_id: currentScreenId.value,
      category_id: 'all',
      id: currentMissionPlanningId.value
    }
  });
}

const onFilterReset = () => data.filter = {
    search: '',
    tags: [],
    dnType: null,
    incompleteOnly: null
  };

const onSortChange = (sort) => data.sort = sort;

const onCategoryChange = () => {
  page.value = 1;
  productBlockedInEdition.value = null;
}

const answerAlreadyPresent = (answerHistory) => {
  return answers.value.some(
    (answer) =>
      answer.question?.toString() === answerHistory.question_id?.toString() &&
      answer.attachment_id?.toString() === answerHistory.related_to_id?.toString() &&
      answer.attachment_type === answerHistory.related_to_type
  )
}
function updateConditions(readingId) {
  let initialContext = {
    currentValue: null,
    answers: answers.value,
    pos: pointOfSale.value,
    attachementType: 'none',
    attachementId: null,
    prod: null,
    nowDatetime: new Date()
  }
  const rawConfigurations = storeManager.getFromStoreByCategory('configuration', 'configurations')
  createOrUpdateConditions(
    rawConfigurations[mission.value?.configuration?.id],
    initialContext,
    readingId
  );
  saveReadingCounters(readingId, rawConfigurations[mission.value?.configuration?.id])
}

function triggerFormsReload() {
  triggerReload.value = !triggerReload.value;
}

const recursiveClearInvalidAnswers = async (readingId) => {
  updateConditions(readingId);
  if (await clearInvalidAnswers(readingId)) {
    await recursiveClearInvalidAnswers(readingId);
  }
}

const triggerHistoryRecoveryProcess = async (productAttachmentId = null) => {
  globalHistoryRetrieveInProgress.value = true;
  insertedAnswersFromHistoryCount.value = 0;
  const answersHistory = missionReadingHistory.value?.answers;
  if (!answersHistory) {
    globalHistoryRetrieveInProgress.value = false;
    return;
  }
  const productIds = productAttachmentId === null ?
      filteredConfigurationProducts.value.map((p) => p.id?.toString()) :
      [productAttachmentId?.toString()];

  const filteredProductsAnswersHistory = Object.values(answersHistory).filter(
      (ah) => ah.related_to_type === READING.ATTACHMENT_TYPE.PRODUCT && productIds.includes(ah.related_to_id?.toString())
  );
  if (!currentReadingId.value || currentReadingId.value === VALUE.DEFAULT) {
    await createReadingIfNotExists(
      currentReadingId.value,
      mission.value?.configuration?.id,
      currentMissionPlanningId.value || null,
      userDetails.value.id,
      configuration.value?.data || null
    );
  }
  const insertedAnswers = [];
  for (const answerHistory of filteredProductsAnswersHistory) {
    const question = screenQuestions.value.find((q) => q.id?.toString() === answerHistory.question_id?.toString());
    if (!question || !question.history || answerAlreadyPresent(answerHistory)) {
      continue;
    }
    insertedAnswers.push(
      await addNewReadingAnswer(
        answerHistory.question_id,
        currentReadingId.value,
        answerHistory.value,
        answerHistory.related_to_type,
        answerHistory.related_to_id
    ));
  }
  await recursiveClearInvalidAnswers(currentReadingId.value);
  triggerFormsReload();
  insertedAnswersFromHistoryCount.value = countInsertedAnswers(insertedAnswers);
  if (insertedAnswersFromHistoryCount.value > 0) snackbar.value = true;
  globalHistoryRetrieveInProgress.value = false;
}
async function addNewReadingAnswer(
  questionId,
  readingId,
  answerValue,
  attachmentType,
  attachmentId
) {
  const creationDate = dateHandler.generateNowUnixTimestamp();
  const readingAnswer = {
    reading: readingId,
    question: parseInt(questionId),
    value: answerValue,
    attachment_type: attachmentType,
    attachment_id: attachmentId,
    creation_date: creationDate
  };
  return await storeManager.add('reading', 'answer', readingAnswer);
}
function productColorClass(product) {
  switch (product?.productIndicatorType) {
    case PDT.TYPE.DNO:
      return 'text-green-darken-3';
    case PDT.TYPE.DN:
      return 'text-red-darken-2';
    default:
      return '';
  }
}

const answeredToAQuestion = (productId) => {
  tryToChangeRowSelection(productId);
  if (questionsConditionsWithAnswers.value.some((question) => {
    const attachmentId = question.code.split('|')[1] || null;
      return (
        !question.hasAnswer &&
        question.mandatory &&
        question.visibility &&
        attachmentId?.toString() === productId?.toString()
      )
    })
  ) {
    productBlockedInEdition.value = productId;
  }
  else {
    productBlockedInEdition.value = null;
  }
  setUnAnsweredProductQuestions();
}

const productHasInvalidAnswer = (productId) => {
  productBlockedInEdition.value = productId;
}

const barcodeSearch = (barcode) => {
  data.filter = {
    search: barcode,
    tags: [],
    dnType: null,
    incompleteOnly: null
  };
}

watch(
  () => props.barcode,
  (barcode) => {
      if (barcode) barcodeSearch(barcode);
  }
)

function tryToChangeRowSelection(productId) {
  if (productBlockedInEdition.value) {
    if (productBlockedInEdition.value !== productId) {
      showMissingMandatoryAnswersFlag.value = !showMissingMandatoryAnswersFlag.value;
    }
    return;
  }
  productSelected.value = productId;
}
watch(data, () => {
  resetQueryParams()
  page.value = 1;
});

function setUnAnsweredProductQuestions() {
  unAnsweredMandatoryProductQuestions.value = questionsConditionsWithAnswers.value
    .filter((question) => {
      return (
        !question.hasAnswer &&
        question.mandatory &&
        question.visibility &&
        question.code.includes('by_product')
      )
    })
    .map((question) => question.code.split('|')[1])
}

onMounted(() => {
  if (props.barcode) barcodeSearch(props.barcode);
  // see EDIT section of
  // https://www.reddit.com/r/sveltejs/comments/17yjzvy/help_getboundingclientrect_wrong/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button
  // Don't know why it works inside the callback of an empty timeout, but not outside...
  setTimeout(() => {
    bottomAppBarBoundingClientRect.value = useElementBounding(bottomAppBarRef)
    actionBarBoundingClientRect.value = useElementBounding(actionBarRef)
  }, 0);
})
</script>

<style scoped>
.question-cell-header {
  white-space: initial;
  z-index: 3;
}

.product-col {
  min-width: 100px;
  max-width: 370px;
  left: 0;
  z-index: 1;
}

.screen-product-table {
  border-collapse: collapse;
}

.screen-product-table th,
td {
  border: 1px solid #D1D1D1;
  background-color: white;
}

.v-btn-group--density-default.v-btn-group.reduced-height {
  height: 36px;
}
.text-body-1 {
  font-size: 0.75rem !important;
}
.v-card-title {
  font-size: 0.75rem !important;
  padding: 0.3rem 0.5rem;
}
.v-card-text {
  font-size: 0.75rem !important;
}
.v-table--density-default {
  --v-table-header-height: 30px;
  --v-table-row-height: 52px;
}
.v-chip.v-chip--density-default {
  font-size: 0.75rem;
}
.v-chip.v-chip--size-small {
  --v-chip-size: 0.75rem;
  --v-chip-height: 20px;
  font-size: 0.75rem;
  padding: 0 5px;
}
</style>
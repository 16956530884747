<template>
  <v-card class="pa-5 my-0" variant="tonal">
    <v-text-field
      clearable
      :prepend-inner-icon="mdiMagnify"
      v-model="filter.search"
      single-line
      density="compact"
      @update:modelValue="filterChanged"
    >
      <template v-slot:label>
        <span> {{ $t('point-of-sale.filter-form.label.search') }} </span>
      </template>
    </v-text-field>

    <v-sheet class="d-flex flex-column flex-md-row">
      <v-select
        clearable
        multiple
        :label="$t('point-of-sale.filter-form.label.store')"
        :placeholder="$t('global.text.all.f')"
        class="mr-md-5 text-black w-md-50"
        v-model="filter.store"
        :items="stores"
        item-title="name"
        return-object
        single-line
        density="compact"
        @update:modelValue="filterChanged"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index < 1">
            <span>{{ item.title }}</span>
          </v-chip>
          <span
            v-if="index === 1"
            class="text-grey text-caption align-self-center"
          >
            (+{{ filter.store.length - 1 }} {{ $t('global.text.others') }})
          </span>
        </template>
      </v-select>

      <v-select
        clearable
        multiple
        class="mr-md-5 w-md-50"
        :label="$t('point-of-sale.filter-form.label.department')"
        :placeholder="$t('global.text.all.m')"
        v-model="filter.department"
        :items="departments"
        item-title="name"
        return-object
        single-line
        density="compact"
        @update:modelValue="filterChanged"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index < 1">
            <span>{{ item.title }}</span>
          </v-chip>
          <span
            v-if="index === 1"
            class="text-grey text-caption align-self-center"
          >
            (+{{ filter.department.length - 1 }} {{ $t('global.text.others') }})
          </span>
        </template>
      </v-select>

      <v-btn
        :prepend-icon="mdiRefresh"
        class="my-md-1"
        variant="tonal"
        @click="resetFilter()"
      >
        {{ $t('global.btn.reset') }}
      </v-btn>
    </v-sheet>
  </v-card>
</template>

<script setup>
import { reactive, toRefs, watch } from 'vue';
import { mdiMagnify, mdiRefresh } from '@mdi/js';

const props = defineProps({
  stores: {
    type: Array,
    required: true
  },
  departments: {
    type: Array,
    required: true
  }
});

const { stores, departments } = toRefs(props);

const emit = defineEmits(['filterChanged']);

watch(stores, async () => {
  resetFilter();
});

watch(departments, async () => {
  resetFilter();
});

const filter = reactive({
  search: '',
  store: [],
  department: []
});

function resetFilter() {
  filter.search = '';
  filter.store = [];
  filter.department = [];
}

function filterChanged() {
  if (!filter.search) {
    filter.search = '';
  }
  emit('filterChanged', filter);
}

</script>
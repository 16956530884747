export const QST = {
  COUNTER: {
    TYPE: {
      OPTIONAL: 'optional',
      MANDATORY: 'mandatory',
      TOTAL: 'total',
    },
  },
  INPUT: {
    TYPE: {
      YES_NO: 'yesno',
      TEXTAREA: 'textarea',
      TEXT: 'text',
      NUMERIC: 'numeric',
      DROPDOWN: 'dropdown',
      DATE: 'date'
    }
  },
  SORT: {
    BY: {
      CATEGORY: 'category'
    }
  },
  TYPE: {
    GROUP: 'group',
    SCREEN: 'screen'
  }
}
import { defineStore } from 'pinia'
import { ref } from 'vue'
import _ from 'lodash'
import objectHandler from '@/utils/objectHandler';

export const useReadingConditionStore = defineStore(
  'readingConditionStore',
  () => {
    const readingConditions = ref({})

    function massUpsert(type,objectsToInsert) {
      initReadingIfNecessary(objectsToInsert.readingId)
      _.merge(readingConditions.value[objectsToInsert.readingId][type], objectHandler.mapToObject(objectsToInsert[type]))
    }

    function initReadingIfNecessary(readingId) {
      if (!readingConditions.value[readingId]) {
        initReading(readingId)
      }
    }

    function initReading(readingId) {
      readingConditions.value[readingId] = {
        groups: {},
        screens: {},
        questions: {}
      }
    }

    return {
      readingConditions,
      initReading,
      massUpsert
    }
  },
  {
    persistedState: {
      overwrite: true
    }
  }
)

<template>
  <v-card
    v-if="attachmentLoopItems.items?.length"
    variant="tonal"
    :class="{
      'mb-3': true,
      'text-disabled pointer-none': !screen.visibility,
      'd-none': !showHiddenQuestions && !screen.visibility
    }"
  >
    <v-card-text>
      <div class="d-flex flex-no-wrap justify-space-between">
        <v-icon class="mr-3 mt-1" :icon="mdiPlayCircle" />

        <strong
          class="text-uppercase cursor-pointer mt-1"
          @click="goToQuestionnaireCategoryFormPage(screen, SCREEN.CATEGORY.ALL)"
        >
          {{ $t('mission-planning.qst.my-questionnaires.screen.product.list.all') }}
        </strong>

        <v-spacer />

        <questionnaire-capsules
          questionnaire-type="screen"
          :questionnaire-type-id="screen.id"
          :attachment-type="READING.ATTACHMENT_TYPE.PRODUCT"
        />
      </div>
    </v-card-text>
  </v-card>

  <div v-if="!!attachmentLoopItems">
    <div v-for="attachmentItem in attachmentLoopItems.items" :key="attachmentItem.id">
      <v-card
          variant="tonal"
          v-if="hasCategoryProducts(attachmentItem?.id)"
          :class="{
            'mb-3': true,
            'text-disabled pointer-none': !screen.visibility,
            'd-none': !showHiddenQuestions && !screen.visibility
      }"
      >
        <v-card-text>
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-icon :color="getItemColor(attachmentItem)" class="mr-3 mt-1" :icon="mdiPlayCircle" />

            <strong
                class="font-weight-bold text-uppercase cursor-pointer mt-1"
                @click="goToQuestionnaireCategoryFormPage(screen, attachmentItem.id)"
            >
              {{ attachmentItem[attachmentLoopItems.labelKey] }}
            </strong>

            <v-spacer />
            <questionnaire-capsules
                questionnaire-type="screen"
                :questionnaire-type-id="screen.id"
                :attachment-type="attachmentLoopItems.type"
                :product-category-id="attachmentItem.id"
            />
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>

</template>

<script setup>
import { mdiPlayCircle } from '@mdi/js';
import { computed, inject } from 'vue';
import { useApplicationStore } from '@/stores/applicationStore';
import { getItemColor } from '@/stores/manager/screenManager';
import { useRouter } from 'vue-router';
import QuestionnaireCapsules from '@/components/mission-planning/questionnaires/parts/QuestionnaireCapsules.vue';
import { READING, SCREEN } from '@/constants';

const props = defineProps({
  screen: {
    type: Object,
    required: true
  },
  groupedQuestions: {
    type: Object,
    required: false
  },
  attachmentLoopItems: {
    type: Object,
    required: false
  },
});
const router = useRouter();

const currentConfiguration = inject('currentConfiguration');
const questionsConditionsWithAnswers = inject('questionsConditionsWithAnswers');

const applicationStore = useApplicationStore();

const showHiddenQuestions = computed(() => applicationStore.showHiddenQuestions);

const currentMissionPlanningId = inject('currentMissionPlanningId');

function goToQuestionnaireCategoryFormPage(screen, categoryId) {
  if (!screen.id || !categoryId) {
    return;
  }
  router.push({
    name: 'mission-planning-questionnaire-screen-category',
    params: {
      id: currentMissionPlanningId.value,
      screen_id: screen.id,
      category_id: categoryId
    },
  });
}

function hasCategoryProducts(categoryId) {
  const allProducts = currentConfiguration.value?.catalog?.product || {};
  const productsForCategory = Object.values(allProducts).filter(product => product.category.id.toString() === categoryId.toString());
  const categoryProductsIds = productsForCategory.map(product => product.id.toString());

  if (categoryProductsIds.length === 0) {
    return false;
  }
  return questionsConditionsWithAnswers.value.some(conditionItem => {
    const attachmentType = READING.ATTACHMENT_TYPE.PRODUCT;
    const questionProductCodeArray = conditionItem.code.split(`:${attachmentType}|`) || null;
    if (questionProductCodeArray) {
      const productId = questionProductCodeArray[1];
      return categoryProductsIds.includes(productId) && conditionItem.visibility;
    }
    return false;
  });
}


</script>



import { defineStore } from 'pinia';
import { useMockStore } from "./mockStore";
import { v4 as uuidv4 } from "uuid";
import { usePostItStore } from "./postItStore";
import { useReadingHistoryStore } from "./readingHistoryStore";
import { useReadingStore } from "./readingStore";
import { useConfigurationStore } from "./configurationStore";
import { useMissionStore } from "./missionStore";
import { useApplicationStore } from "./applicationStore";
import { computed } from 'vue';
import { useFlashNewsStore } from "./flashNewsStore";
import {useLogStore} from "./logStore";

export const useStoreManager = defineStore('storeManager', () => {

    const applicationStore = useApplicationStore();
    const logStore = useLogStore();
    const isLoggedIn = computed(() => applicationStore.isLoggedIn || false);
    const userDetails = computed(() => applicationStore.userDetails || false);

    const storeReferences = {
        mock: useMockStore(),
        postIt: usePostItStore(),
        readingHistory: useReadingHistoryStore(),
        reading: useReadingStore(),
        configuration: useConfigurationStore(),
        mission: useMissionStore(),
        flashNews: useFlashNewsStore(),
        application: useApplicationStore(),
    };

    async function pull(store, type = '', options = {}, commonStore = false) {
        const foundStore = getStore(store);
        const logRef = Date.now();
        logStore.insertLog( logRef, "Pulling data operation start", "message", store, null, null, foundStore?.data?.package_date);
        try {
            isUserLoggedIn();
            let pulledData;
            if (commonStore) {
                pulledData = await foundStore.options.apiManager.pull({'package_date': foundStore?.data?.package_date});
                logStore.insertLog( logRef, "Pulled data", 'pull', store, null, getStoreItemIds(pulledData));
                foundStore.data = mergePulledDataWithFreshOnes(pulledData, foundStore.data, logRef)
            }
            else {
                pulledData = await foundStore.options.apiManager.pull({'package_date': foundStore?.data[userDetails.value.id]?.package_date});
                logStore.insertLog( logRef, "Pulled data", 'pull', store, null, getStoreItemIds(pulledData));
                foundStore.data[userDetails.value.id] = mergePulledDataWithFreshOnes(pulledData, foundStore.data[userDetails.value.id], logRef)
            }
            if(typeof foundStore.postPullActions === 'function') {
                logStore.insertLog( logRef, "Starting post-pull actions", "message");
                await foundStore.postPullActions(pulledData);
                logStore.insertLog( logRef, "Post-pull actions finished", "message");
            }
        } catch (error) {
            logStore.insertLog( logRef, JSON.stringify(error.request?.response), 'error');
            console.error('PULL Error: ', error.message);
            return false;
        }
    }

    /* TODO : remains to manage the case for persistentLocalId */
    async function push(storeName) {
        const foundStore = getStore(storeName);
        const logRef = Date.now();
        logStore.insertLog( logRef, "Pushing data operation start", "message", storeName);
        if(typeof foundStore.prePushActions === 'function') {
            logStore.insertLog( logRef, "Starting pre-push actions", "message");
            foundStore.prePushActions();
            logStore.insertLog( logRef, "Pre-push actions finished", "message");
        }
        try {
            storeIsReadOnly(foundStore);
            isUserLoggedIn();
            const fullStoreData = all(storeName);
            const filteredStore = getFreshItemsToPushFromStore(fullStoreData, storeName);
            let data = false;
            if (Object.keys(filteredStore).length !== 0) {
              data = await foundStore.options.apiManager.push(filteredStore);
                logStore.insertLog(
                  logRef,
                  "Pushed data",
                  'push',
                  storeName,
                  getStoreItemIds(filteredStore),
                  getStoreItemIds(data),
                  null,
                  getStoreItemIds(foundStore.data),
                );
            }

            if (data) {
                logStore.insertLog( logRef, "Updating local store with pushed data", 'message');
                for (const [category, categoryData] of Object.entries(fullStoreData)) {
                    if (category === 'package_date') {
                        fullStoreData[category] = data[category] || categoryData;
                    }
                    else {
                        if (filteredStore[category]) {
                            for (const key in categoryData) {
                                if (filteredStore[category][key]) {
                                    logStore.insertLog( logRef, `Deleting ${category} ${key} from local store`, 'message');
                                    delete categoryData[key];
                                }
                            }
                        }
                        fullStoreData[category] = {...categoryData, ...data[category]};
                    }
                }
                if(typeof foundStore.postPushActions === 'function') {
                    logStore.insertLog( logRef, "Starting post-push actions", "message");
                    foundStore.postPushActions(data, filteredStore);
                    logStore.insertLog( logRef, "Post-push actions finished", "message");
                }
            }
        } catch (error) {
            logStore.insertLog( logRef, JSON.stringify(error.request?.response), 'error');
            console.error('PUSH Error: ', error.message);
            return false;
        }
        return false;
    }

    function all(storeName) {
        const store = getStore(storeName);
        return store.data[userDetails.value.id] || [];
    }

    function getFromStoreByCategory(storeName, category) {
        const store = getStore(storeName);
        if (!store.hasOwnProperty(category)) {
            throw new Error(`Invalid category: ${category} for store ${store.$id}`);
        }

        if(typeof store.getFromStoreByCategoryAdditionalOperations === 'function') {
            return store.getFromStoreByCategoryAdditionalOperations(store[category], category);
        }

        return store[category];
    }

    function getByIdAndCategory(storeName, id, category) {
        const foundStore = getStore(storeName);
        const categoryData = getDataByCategory(foundStore, category);
        if (categoryData) {
            const item = categoryData[id];
            if (item) {
                return item;
            }
        }
        return null;
    }

    function add(storeName, category, object) {
        const foundStore = getStore(storeName);
        storeIsReadOnly(foundStore);
        let categoryData;
        try {
            categoryData = getDataByCategory(foundStore, category);
        }
        catch (e) {
            categoryData = initCategory(foundStore, category);
        }
        if (categoryData) {
            const uuid = uuidv4();
            object.is_new = true;
            categoryData[uuid] = object;
            return uuid;
        }
        return false;
    }

    function remove(storeName, category, id) {
        const foundStore = getStore(storeName);
        storeIsReadOnly(foundStore);
        const categoryData = getDataByCategory(foundStore, category);
        if (categoryData) {
            const item = categoryData[id];
            if (item) {
                item.to_remove = true;
                return true;
            }
        }
        return false;
    }

    async function hardDelete(storeName, category, id) {
        const foundStore = getStore(storeName);
        storeIsReadOnly(foundStore);
        const categoryData = getDataByCategory(foundStore, category);
        if (categoryData) {
            const item = categoryData[id];
            if (item) {
                delete categoryData[id];
                return true;
            }
        }
        return false;
    }

    function update(storeName, category, id, object) {
        const foundStore = getStore(storeName);
        storeIsReadOnly(foundStore);
        const categoryData = getDataByCategory(foundStore, category);
        if (categoryData) {
            const item = categoryData[id];
            if (item) {
                object.to_sync = true;
            }
            categoryData[id] = object;
            return true;
        }
        return false
    }


    function call(storeName, method, options = {}) {
        const foundStore = getStore(storeName);
        if (foundStore[method] && typeof foundStore[method] === 'function') {
            return foundStore[method](...options);
        } else {
            throw new Error(`Method "${method}" not found on store "${storeName}"`);
        }
    }

    function getDataByCategory(store, category) {
        const itemsValue = store.data[userDetails.value.id] || null;
        if (!itemsValue.hasOwnProperty(category)) {
            throw new Error(`Invalid category: ${category} for store ${store.$id}`);
        }
        return itemsValue[category];
    }

    function initCategory(store, category) {
        const itemsValue = store.data[userDetails.value.id] || null;
        if (!itemsValue.hasOwnProperty(category)) {
            itemsValue[category] = {};
        }
        return itemsValue[category];
    }

    function getStore(storeName) {
        const foundStore = storeReferences[storeName];
        if (!foundStore) {
            throw new Error(`Unknown store type: ${storeName}`);
        }
        return foundStore;
    }

    function storeIsReadOnly(store) {
        if (store.options.readonly) {
            throw new Error(`Store can't update data, is readonly`);
        }
    }

    function isUserLoggedIn() {
        if (!isLoggedIn.value) {
            throw new Error('User is not logged in!');
        }
    }

    function mergePulledDataWithFreshOnes(pulledData, foundStoreData, logRef) {
        const storeDataToKeep = pulledData
        if (foundStoreData) {
            for (const [storeKey, storeValue] of Object.entries(foundStoreData)) {
                if (storeKey === "configurations") {
                    continue; // so that configuration is always replaced by pulled data
                }
                else if (storeKey === "package_date") {
                    storeDataToKeep[storeKey] = storeValue;
                }
                else {
                    for (const [storeItemKey, storeItemValue] of Object.entries(storeValue)) {
                        if (isFresh(storeItemValue)) {
                            console.log("storeItem kept Key", storeItemKey)
                            storeDataToKeep[storeKey][storeItemKey] = storeItemValue;
                        }
                    }
                }
            }
        }
        logStore.insertLog(
          logRef,
          "Data Merge",
          'mergePulledDataWithFreshOnes',
          null,
          null,
          getStoreItemIds(pulledData),
          null,
          getStoreItemIds(foundStoreData),
          getStoreItemIds(storeDataToKeep)
        );
        return storeDataToKeep;
    }
    function isFresh(obj) {
        return (obj.is_new === true || obj.to_remove === true || obj.to_sync === true)  &&
          !(obj.is_new === true && obj.to_remove === true) //just created but to be deleted is not considered fresh
    }

    function getFreshItemsToPushFromStore(fullStoreData, storeName) {
        const foundStore = getStore(storeName);
        const filteredStore = {};
        for (const [category, categoryData] of Object.entries(fullStoreData)) {
            const filteredData = {};
            if (category === "package_date")
            {
                continue;
            }
            for (const [key, item] of Object.entries(categoryData)) {
                if(typeof foundStore.isPushPrevented === 'function' && foundStore.isPushPrevented(item, category)) {
                    continue;
                }
                if (isFresh(item)) {
                    filteredData[key] = item;
                }
            }
            filteredStore[category] = filteredData;
        }
        return filteredStore;
    }

    function getStoreItemIds(data) {
        try {
            return JSON.stringify(
              Object.entries(data).reduce((acc, [key, item]) => {
                  acc[key] = Object.keys(item)?.join(',')?.toString();
                  return acc;
              }, {})
            );
        }
        catch (e) {
            return JSON.stringify("getStoreItemIds failed with error: " + e.message);
        }

    }

    return {
        pull,
        push,
        all,
        add,
        remove,
        hardDelete,
        call,
        update,
        getByIdAndCategory,
        getFromStoreByCategory,
        storeReferences,
        mergePulledDataWithFreshOnes,
        isFresh
    }
})

import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { ApiManager } from './api/ApiManager';
import { useApplicationStore } from './applicationStore';
import {READING, TABLE} from "@/constants";
import localForageService from "@/services/localForage";
import imageHandler from "@/utils/imageHandler";
import {StoreManagerRepository} from "@/repositories";

export const useReadingStore = defineStore(
  'readingStore',
  () => {
    const applicationStore = useApplicationStore();
    const userDetails = computed(() => applicationStore.userDetails || false);

    const storeCategories = {
      [userDetails.value.id]: {
        reading: {},
        answer: {},
        picture: {},
        element: {}
      }
    };

    const pullCallbackFunction = async (key, images) => {
      if (key === 'images') {
        localForageService.removeData(TABLE.QUESTION_PICTURES)
          .then(() => {
            Object.keys(images).forEach(imageKey => {
              imageHandler.fromBase64(images[imageKey], imageKey).then(imageFile => {
                localForageService.storeData(TABLE.QUESTION_PICTURES, imageKey, imageFile);
              });
            });
          });
      }
    };
    const apiPushOverrideFunction = async (payload) => {
      let response;
      payload.images = await imageHandler.extractImagesFromLocalForage(payload, TABLE.QUESTION_PICTURES);
      response = await StoreManagerRepository.push('readings', payload);
      delete response.data.data.images;
      return response.data.data;
    };

    const options = {
      apiManager: new ApiManager('readings', apiPushOverrideFunction, pullCallbackFunction),
      readonly: false,
      sync: true,
      allTime: false,
      persistentLocalId: false
    }
    const data = ref(storeCategories);

    const reading = computed(() => data.value[userDetails.value.id]?.reading || {});
    const answer = computed(() => data.value[userDetails.value.id]?.answer || {});
    const picture = computed(() => data.value[userDetails.value.id]?.picture || {});
    const element = computed(() => data.value[userDetails.value.id]?.element || {});

    function prePushActions() {
      // update reading "is_new" field to true where status is "toBeTransmitted"
      const readings = this.reading;
      Object.entries(readings).forEach(([readingId, reading]) => {
        if (reading.status === READING.STATUS.TO_BE_TRANSMITTED) {
          this.reading[readingId].is_new = true;
        }
      })
    }

    function getFromStoreByCategoryAdditionalOperations(storeData, category) {
      if (category === 'reading') {
        return Object.fromEntries(Object.entries(storeData).filter(([key, value]) => value.status !== READING.STATUS.DELETED));
      }
      return storeData;
    }

    return {
      data,
      options,
      reading,
      answer,
      picture,
      element,
      prePushActions,
      getFromStoreByCategoryAdditionalOperations
    }
  },
  {
    persistedState: {
      overwrite: true
    }
  }
);
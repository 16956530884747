<template>
  <v-btn
    size="x-small"
    color="surface-variant"
    variant="tonal"
    :icon="mdiTrashCan"
    @click="deleteElement"
  />
</template>

<script setup>
import { mdiTrashCan } from '@mdi/js';
import { inject } from 'vue';
import { useStoreManager } from '@/stores/storeManager';
import { removeExistingElement } from '@/stores/manager/readingElementsManager';
import { createOrUpdateConditions, saveReadingCounters } from '@/stores/manager/conditionsManager';
import { READING } from '@/constants';

const props = defineProps({
  readingElementId: {
    type: String,
    required: true,
  },
  readingElement: {
    type: Object,
    required: true,
  }
});

const storeManager = useStoreManager();
const currentReadingId = inject('currentReadingId');
const currentPointOfSale = inject('currentPointOfSale');
const currentConfiguration = inject('currentConfiguration');

const deleteElement = () => {
  const answersIds = Object.entries(storeManager.getFromStoreByCategory('reading', 'answer'))
    .filter(([key, value]) =>
      value?.reading.toString() === props.readingElement?.reading.toString()
      && value?.attachment_type?.toString() === READING.ATTACHMENT_TYPE.ELEMENT
      && value?.attachment_id.toString() === props.readingElementId.toString()
    )
    .map(([key, value]) => key);

  if (answersIds.length) {
    for (const answerId of answersIds) {
      storeManager.remove('reading', 'answer', answerId);
    }
  }

  removeExistingElement(props.readingElement, props.readingElementId)
    .then((result) => {
      if (result === true) {
        updateConditions();
      }
    });
}

const updateConditions = () => {
  let initialContext = {
    currentValue: null,
    answers: [],
    pos: currentPointOfSale.value,
    attachementType: READING.ATTACHMENT_TYPE.ELEMENT,
    attachementId: props.readingElementId,
    prod: null,
    nowDatetime: new Date()
  }
  createOrUpdateConditions(
    currentConfiguration.value,
    initialContext,
    currentReadingId.value
  );
  saveReadingCounters(currentReadingId.value, currentConfiguration.value)
}

</script>

<template>
  <v-card variant="tonal" class="mb-5">
    <v-card-title :style="{'color': appStyle.title_text_color}">
      <v-icon class="mx-3 my-1" size="small" :icon="mdiListBox" />
      {{ $t('mission-planning.info.flash-news.title') }}
    </v-card-title>

    <v-divider />

    <v-card-text>
      <div
        v-if="posFlashNews?.length"
        v-for="item in posFlashNews"
      >
        <div class="mx-3 my-3">
          <div class="text-body-1 font-weight-bold text-teal-darken-3">
            {{ `${item.title}:` }}
          </div>

          <div class="text-body-1">
            {{ item.argument_text }}
          </div>

          <div v-if="item.links">
            <div class="text-body-1" v-for="link in item.links">
              <v-btn color="default" variant="text" :href="link" target="_blank">
                <v-icon :icon="mdiLinkVariant"></v-icon> {{ link }}
              </v-btn>
            </div>
          </div>
        </div>

        <v-divider></v-divider>
      </div>

      <v-alert
        v-if="!posFlashNews?.length"
        variant="tonal"
        type="warning"
        :text="$t('mission-planning.info.informative-answer.message.no-instructions')"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { mdiListBox } from '@mdi/js';
import { extractFlashNewsByPosData } from '@/stores/manager/flashNewsManager';
import { mdiLinkVariant } from '@mdi/js';
import { useApplicationStore } from '@/stores/applicationStore';

const applicationStore = useApplicationStore();
const appStyle = computed(() => applicationStore.appStyle);

const props = defineProps({
  flashNews: {
    type: Object,
    required: true
  },
  pointOfSale: {
    type: Object,
    required: true
  }
});

const posFlashNews = extractFlashNewsByPosData(props.flashNews, props.pointOfSale);

</script>

<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel
      v-for="(group, index) in questionsWithAttachedConditions"
      :class="{
        'text-disabled pointer-none': !group.visibility,
        'd-none': !showHiddenQuestions && !group.visibility
      }"
    >
      <v-expansion-panel-title>
        <div class="d-flex flex-wrap w-100 align-center justify-space-between">
          <div class="my-1 expansion-panel-title">
            {{group.label}}
          </div>
          <div class="ml-auto">
            <questionnaire-capsules
                questionnaire-type="group"
                :questionnaire-type-id="group.id"
                :attachmentType="attachmentType"
                :attachmentId="attachmentId"
            />
          </div>
        </div>
      </v-expansion-panel-title>

      <questionnaire-group
        :questions="group.questions"
        :attachmentType="attachmentType"
        :attachmentId="attachmentId"
      />
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import {computed, defineProps, inject, ref} from 'vue'
import QuestionnaireGroup from '@/components/mission-planning/questionnaires/QuestionnaireGroup.vue'
import QuestionnaireCapsules from './parts/QuestionnaireCapsules.vue'
import { useReadingConditionStore } from "@/stores/conditionStore";
import { useApplicationStore } from '@/stores/applicationStore';

const props = defineProps({
  screen: {
    type: Object,
    required: true
  },
  questions: {
    type: Object,
    required: true
  },
  questionnaireType: {
    type: String,
    required: true
  },
  attachmentType: {
    type: String,
    required: false
  },
  attachmentId: {
    type: [Number, String],
    required: false
  }
})
const applicationStore = useApplicationStore();
const currentReadingId = inject('currentReadingId')
const readingConditionStore = useReadingConditionStore();

const showHiddenQuestions = computed(() => applicationStore.showHiddenQuestions);
const questionsReadingConditions = computed(() => readingConditionStore.readingConditions[currentReadingId.value || 'default']?.questions);

const questionsWithAttachedConditions = computed(() => {
  const groupConditions = readingConditionStore.readingConditions[currentReadingId.value || 'default']?.groups
  return props.questions.map((groupedQuestions) => {
    if (!questionsReadingConditions.value) return groupedQuestions;
    const remappedQuestions = groupedQuestions.questions.map((question) => {
      const questionCode = question.id +
          ((props.attachmentType && props.attachmentType !== "none") ? ":"+props.attachmentType+"|"+props.attachmentId : "");
      const conditions = questionsReadingConditions.value[questionCode]
      if (!!conditions) {
        question.visibility = conditions.visibility
        question.mandatory = conditions.mandatory
        question.validity = conditions.validity
      }
      return question
    })
    groupedQuestions.questions = remappedQuestions;
    return groupedQuestions
  }).sort((a, b) => a.order - b.order)
    .map((group) => {
      if (!groupConditions) return group;
      const groupKey = group.id +
          ((props.attachmentType && props.attachmentType !== "none") ? ":"+props.attachmentType+"|"+props.attachmentId : "");
      const conditions = groupConditions[groupKey]
      if (!!conditions) {
        group.visibility = conditions.visibility
      }
      return group
    })
});

// first panel expanded by default
const panel = ref([0])
</script>
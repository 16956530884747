<template>
  <v-container>
    <choose-configuration :title="$t('contact.config-select')" @configuration-selected="onConfigurationSelect" />

    <contact-filter
        :departments="departmentsForSelect"
        :point-of-sales="pointOfSalesForSelect"
        :townships="townshipsForSelect"
        :reset-filter-flag="resetFilterFlag"
        @filter-changed="onFilterChange"
        />

    <contacts-list
        :contacts="contacts"
        :contacts-list-title="$t('contact.list.title')"
        :show-back-button="false"
        :configuration="currentConfiguration"
    />

  </v-container>
</template>

<script setup>
import { computed, ref } from 'vue';
import ChooseConfiguration from '@/components/global/ChooseConfiguration.vue';
import { useStoreManager } from '@/stores/storeManager';
import ContactsList from "@/components/contact/ContactsList.vue";
import ContactFilter from "../components/contact/ContactFilter.vue";

const storeManager = useStoreManager();

const filterValues = ref({
  search: '',
  pointOfSale: [],
  department: [],
  township: []
});

const resetFilterFlag = ref(false);
const currentConfiguration = ref([])

const pointsOfSaleForCurrentConfiguration = computed(() => {
  const rawPointsOfSales = Object.entries(storeManager.getFromStoreByCategory('mission', 'pointsOfSale'));
  const pointOfSalesIdForConfiguration = Object.values(storeManager.getFromStoreByCategory('mission', 'missions'))
  .filter(mission => parseInt(mission.configuration.id) === parseInt(currentConfiguration.value.id))
  .map(mission => parseInt(mission.pos.id));
  return rawPointsOfSales
      .filter(([key, pos]) => pointOfSalesIdForConfiguration.includes(parseInt(key)))
      || []
});

const pointOfSalesForSelect = computed(() =>
  pointsOfSaleForCurrentConfiguration.value
  .filter(([key, pos]) =>
      filterValues.value.township.length === 0 || filterValues.value.township.includes(pos.city)
  )
  .filter(([key, pos]) =>
      filterValues.value.department.length === 0 || filterValues.value.department.includes(pos.postal_code.substring(0, 2))
  )
  .map(([key, pos]) => {
    return {
      "id": key,
      "postal_code": pos.postal_code,
      "city": pos.city,
      "name": pos.name
    }
  })
  || []
);

const departmentsForSelect = computed(() =>
    [...new Set(pointsOfSaleForCurrentConfiguration.value.map(([key, pos]) => pos.postal_code.substring(0, 2)))]
);

const townshipsForSelect = computed(() =>
    [...new Set(pointsOfSaleForCurrentConfiguration.value.map(([key, pos]) => pos.city))]
);

const filteredPointOfSalesIds = computed(() =>
    pointsOfSaleForCurrentConfiguration.value
        .filter(([key, pos]) =>
            filterValues.value.township.length === 0 || filterValues.value.township.includes(pos.city)
        )
        .filter(([key, pos]) =>
            filterValues.value.department.length === 0 || filterValues.value.department.includes(pos.postal_code.substring(0, 2))
        )
        .filter(([key, pos]) =>
            filterValues.value.pointOfSale.length === 0 || filterValues.value.pointOfSale.map( pos => parseInt(pos.id)).includes(parseInt(key))
        )
        .map(([key, pos]) => parseInt(key))
    || []
);

const contacts = computed(() =>
    Object.values(storeManager.getFromStoreByCategory('mission', 'contacts'))
        .filter( contact =>
            filterValues.value.search === '' ||
            contact.firstname?.toLowerCase().includes(filterValues.value.search.toLowerCase()) ||
            contact.lastname?.toLowerCase().includes(filterValues.value.search.toLowerCase()) ||
            contact.phone?.toLowerCase().includes(filterValues.value.search.toLowerCase())
        )
        .filter( contact => !contact?.to_remove && filteredPointOfSalesIds.value.includes(contact.point_of_sale.id))
    || {}
);

function onConfigurationSelect(selectedConfiguration) {
  resetFilterFlag.value = !resetFilterFlag.value;
  currentConfiguration.value = selectedConfiguration;
}

function onFilterChange(filter) {
  filterValues.value.search = filter.search;
  filterValues.value.pointOfSale = filter.pointOfSale;
  filterValues.value.department = filter.department;
  filterValues.value.township = filter.township;
}

</script>

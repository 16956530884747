import i18n from "@/i18n";
import questionsHandler from "@/utils/questionsHandler";
export class ValueComparisonItemCheck {
    t = i18n.global.t
    threshold = 1;

    types = {
        "EQ": (ctxVal, itemVal, reverse) => reverse ?
          ctxVal !== itemVal :
          ctxVal === itemVal,
        "GT": (ctxVal, itemVal, reverse) => reverse ?
          parseFloat(ctxVal) < parseFloat(itemVal) :
          parseFloat(ctxVal) > parseFloat(itemVal),
        "GTEQ": (ctxVal, itemVal, reverse) => reverse ?
          parseFloat(ctxVal) <= parseFloat(itemVal) :
          parseFloat(ctxVal) >= parseFloat(itemVal),
        "LT": (ctxVal, itemVal, reverse) => reverse ?
          parseFloat(ctxVal) > parseFloat(itemVal) :
          parseFloat(ctxVal) < parseFloat(itemVal),
        "LTEQ": (ctxVal, itemVal, reverse) => reverse ?
          parseFloat(ctxVal) >= parseFloat(itemVal) :
          parseFloat(ctxVal) <= parseFloat(itemVal),
        "IN": (ctxVal, itemVal, reverse) => reverse ?
          !itemVal.split(",").map(item => item.trim()).includes(ctxVal.trim()) :
          itemVal.split(",").map(item => item.trim()).includes(ctxVal.trim()),
        "TRUE": (ctxVal, itemVal, reverse) => reverse ?
          ctxVal === false || ctxVal === "false" || ctxVal === "0" || ctxVal === 0 :
          ctxVal === true || ctxVal === "true" || ctxVal === "1" || ctxVal === 1,
        "INT": (ctxVal, itemVal, reverse) => reverse ?
          ctxVal.toString() !== parseInt(ctxVal, 10).toString():
          ctxVal.toString() === parseInt(ctxVal, 10).toString(),
        "FLOATN": (ctxVal, itemVal, reverse) => reverse ?
          ctxVal.toString().match("/^[+-]?[0-9]*[.|,][0-9]+$/g") === null :
          ctxVal.toString().match("/^[+-]?[0-9]*[.|,][0-9]+$/g") !== null,
    }

    messages = {
        "EQ": (ctxVal, itemVal, reverse) => reverse ?
          this.t('conditions-messages.value-comparison.not-eq', {value:itemVal}) :
          this.t('conditions-messages.value-comparison.not-eq', {value:itemVal}),
        "GT": (ctxVal, itemVal, reverse) => reverse ?
          this.t('conditions-messages.value-comparison.not-gt', {value:itemVal}) :
          this.t('conditions-messages.value-comparison.gt', {value:itemVal}),
        "GTEQ": (ctxVal, itemVal, reverse) => reverse ?
          this.t('conditions-messages.value-comparison.not-gteq', {value:itemVal}) :
          this.t('conditions-messages.value-comparison.gteq', {value:itemVal}),
        "LT": (ctxVal, itemVal, reverse) => reverse ?
          this.t('conditions-messages.value-comparison.not-lt', {value:itemVal}) :
          this.t('conditions-messages.value-comparison.lt', {value:itemVal}),
        "LTEQ": (ctxVal, itemVal, reverse) => reverse ?
          this.t('conditions-messages.value-comparison.not-lteq', {value:itemVal}) :
          this.t('conditions-messages.value-comparison.lteq', {value:itemVal}),
        "IN": (ctxVal, itemVal, reverse) => reverse ?
          this.t('conditions-messages.value-comparison.not-in', {value:itemVal}) :
          this.t('conditions-messages.value-comparison.in', {value:itemVal}),
        "TRUE": (ctxVal, itemVal, reverse) => reverse ?
          this.t('conditions-messages.value-comparison.not-true') :
          this.t('conditions-messages.value-comparison.true'),
        "INT": (ctxVal, itemVal, reverse) => reverse ?
          this.t('conditions-messages.value-comparison.not-int') :
          this.t('conditions-messages.value-comparison.int'),
        "FLOATN": (ctxVal, itemVal, reverse) => reverse ?
          this.t('conditions-messages.value-comparison.not-floatn') :
          this.t('conditions-messages.value-comparison.floatn'),
    }
    informations = {
        "EQ": (ctxVal, itemVal, reverse) => {
            return reverse ?
              {} : // not applicable
              { min: Number(itemVal), max: Number(itemVal) }
        },
        "GT": (ctxVal, itemVal, reverse) => {
            return reverse ?
              { max: Number(itemVal)-this.threshold } :
              { min: Number(itemVal)+this.threshold }
        },
        "GTEQ": (ctxVal, itemVal, reverse) => {
            return reverse ?
              { max: Number(itemVal) } :
              { min: Number(itemVal) }
        },
        "LT": (ctxVal, itemVal, reverse) => {
            return reverse ?
              { min: Number(itemVal)+this.threshold } :
              { max: Number(itemVal)-this.threshold }
        },
        "LTEQ": (ctxVal, itemVal, reverse) => {
            return reverse ?
              { min: Number(itemVal) } :
              { max: Number(itemVal) }
        },
    }

    constructor() {

    }

    process(item, context) {
        context = this.checkContext(context, item);
        const itemBuiltId = questionsHandler.buildId(item.questionLinkedId, context.attachementType, context.attachementId?.toString())
        if (context.invisibleQuestions?.has(itemBuiltId)) {
            return {
                result: false,
                message: "invisible question"
            };
        }
        if (!context.currentValue && context.currentValue !==0) return false;
        return {
            result: this.types[item.type](context.currentValue, item.value, item.reverse),
            message: this.messages[item.type](context.currentValue, item.value, item.reverse)
        };
    }

    buildInformations(item, context, previousInformations) {
        const itemInformations = this.informations[item.type](context, item.value, item.reverse);
        if (itemInformations.min && previousInformations.min) {
            itemInformations.min = Math.max(itemInformations.min, previousInformations.min);
        }
        if (itemInformations.max && previousInformations.max) {
            itemInformations.max = Math.min(itemInformations.max, previousInformations.max);
        }
        return {...previousInformations, ...itemInformations};
    }

    checkContext(context, item) {
        if (item.questionLinkedId) {
            if (!context.hasOwnProperty("answers") || !context.hasOwnProperty("attachementType") || !context.hasOwnProperty("attachementId")) {
                throw new Error("Context doesn't match requirements");
            }
            let answer = context.answers.find(answer => {
                return answer.question === item.questionLinkedId &&
                answer.attachment_type === context.attachementType &&
                answer.attachment_id?.toString() === context.attachementId?.toString();
            });
            if (!answer) {
                answer = context.answers.find(answer => {
                    return answer.question === item.questionLinkedId &&
                      answer.attachment_type === 'none' &&
                      answer.attachment_id === null;
                });
            }
            context.currentValue = !answer ? '' : answer.value;
        }
        if ( !context.hasOwnProperty("currentValue") ) {
            throw new Error("Context doesn't match requirements");
        }
        return context;
    }
}
class BOImageService {

  async getProductImageBlob(url) {
    const domain = window.env.VITE_APP_API_URL || import.meta.env.VITE_APP_API_URL;
    const fullUrl = `${domain}${url}`;
    const response = await fetch(fullUrl)
    return await response.blob()
  }
}

export default new BOImageService()
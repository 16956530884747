<template>
  <v-card
    variant="tonal"
    class="mb-5"
    v-if="groups.length && groupQuestions.length"
  >
    <v-card-title />

    <v-divider />

    <v-card-text>
      <questionnaires-groups
        :groups="groups"
        :questions="groupQuestions"
      />
    </v-card-text>
  </v-card>

  <v-card
    variant="tonal"
    class="mb-5"
    v-if="screens?.length"
  >
    <v-card-title :style="{'color': appStyle.title_text_color}">
      <v-icon class="mx-3 my-1" size="small" :icon="mdiListBox" />
      {{ $t('mission-planning.qst.my-questionnaires.title') }}
    </v-card-title>

    <v-divider />

    <v-card-text>
      <questionnaires-screens
        :items="screens"
        :questions="screenQuestions"
        :current-reading-id="currentReadingId"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import { defineProps, onMounted, computed } from 'vue';
import { mdiListBox } from '@mdi/js';

import QuestionnairesGroups from '@/components/mission-planning/questionnaires/QuestionnairesGroups.vue';
import QuestionnairesScreens from '@/components/mission-planning/questionnaires/QuestionnairesScreens.vue';
import { createOrUpdateConditions } from '@/stores/manager/conditionsManager';
import { useApplicationStore } from '@/stores/applicationStore';

const props = defineProps({
  configuration: {
    type: Object,
    required: true
  },
  screens: {
    type: Object,
    required: true
  },
  groups: {
    type: Object,
    required: true
  },
  screenQuestions: {
    type: Object,
    required: true
  },
  groupQuestions: {
    type: Object,
    required: true
  },
  pointOfSale: {
    type: Object,
    required: false
  },
  currentReadingId: {
    required: true
  }
});

const applicationStore = useApplicationStore();
const appStyle = computed(() => applicationStore.appStyle);

function createOrUpdateDefaultConditions() {
  if (props.currentReadingId || props.currentReadingId === 'default') {
    return
  }
  let context = {
    currentValue: null,
    answers: [],
    pos: props.pointOfSale,
    attachementType: 'none',
    attachementId: null,
    prod: null,
    nowDatetime: new Date()
  }
  createOrUpdateConditions(props.configuration, context, 'default')
}

onMounted(() => {
  createOrUpdateDefaultConditions()
})
</script>
<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-tabs
              v-model="tab"
              :color="appStyle.title_text_color"
              grow
          >
            <v-tab
                v-for="thread in similarPosThreadsDataProvider"
                :value="thread?.configuration?.id">{{ thread?.configuration?.name }}</v-tab>
          </v-tabs>

          <v-card-text>
            <v-window v-model="tab">
              <v-window-item
                  v-for="thread in similarPosThreadsDataProvider"
                  :value="thread?.configuration?.id">
                <messenger
                    :current="tab === thread?.configuration?.id"
                    :threadId="thread?.thread?.id"
                    :thread="thread?.thread"
                    :threadMessages="thread?.messages"
                    :pointOfSales="pointOfSales"
                />
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import Messenger from '@/components/post-it/thread-details/Messenger.vue';
import { useRoute } from 'vue-router';
import { ref, computed } from 'vue';
import { useStoreManager } from '@/stores/storeManager';
import arrayHandler from '@/utils/arrayHandler';
import { useApplicationStore } from '@/stores/applicationStore';

const route = ref(useRoute());
const id = computed(() => route.value.params?.id);
const storeManager = useStoreManager();
const applicationStore = useApplicationStore();
const appStyle = computed(() => applicationStore.appStyle);

const threads = computed(() =>
    storeManager.getFromStoreByCategory('postIt', 'threads')
);

const currentThread = computed(() =>
    threads.value[id.value] || {}
);
const tab = ref(currentThread.value.configuration.id);

const messages = computed(() =>
    storeManager.getFromStoreByCategory('postIt', 'messages')
);

const pointOfSales = computed(() =>
  storeManager.getFromStoreByCategory('postIt', 'pointOfSales')
);

const getThreadMessages = (threadId) => {
  let result = {};
  for (const key in messages.value) {
    if (messages.value.hasOwnProperty(key)) {
      if (arrayHandler.isInArrayNotStrict(threadId, messages.value[key].threads_ids)) {
        result[key] = messages.value[key];
      }
    }
  }
  return result;
}

const similarPosThreadsDataProvider = computed(() => {
  const posCode = pointOfSales.value[currentThread.value?.pos?.id]?.pos_code
  const posIdsWithSamePosCode = Object.entries(pointOfSales.value).filter(([posId, pos]) => pos?.pos_code === posCode).map(([posId, pos]) => posId)
  const threadsWithSamePosCode = Object.entries(threads.value).filter(([tid, threadValue]) => posIdsWithSamePosCode.some(e => e == threadValue?.pos?.id))
  return threadsWithSamePosCode.map(([tid, threadValue]) => {
    return {
      configuration: threadValue?.configuration,
      thread: {id: tid, ...threadValue},
      messages: getThreadMessages(tid)
    }
  });
});

</script>

<template>
  <div v-for="(configuration, configurationId, index) in configurations">
  <v-card
    v-if="!unReadOnly || configurationByUserHasUnreadMessage(threads, configurationId, authUser, messages)"
    variant="tonal"
    class="mb-3"
  >
    <div class="ml-3 mr-3 mb-3">
      <v-card-title>
        <div class="d-flex flex-no-wrap w-100 justify-space-between">
          <div class="align-start text-wrap">
            <div class="text-h6 font-weight-medium">
              <v-icon :icon="mdiCog" size="x-small" class="mr-1" />
              {{ configuration.name }}
            </div>
          </div>

          <choose-point-of-sale :configuration="configuration" />
          <v-spacer />
          <v-btn
              :icon="hide[index] ? mdiChevronDown : mdiChevronUp"
              size="x-small"
              @click="hide[index] = !hide[index]"
              variant="text"
          ></v-btn>
        </div>
      </v-card-title>
      <v-expand-transition>
        <div v-show="!hide[index]">

          <template v-for="(thread, threadId) in filterThreadsByConfigId(threads, configurationId)">
            <post-it-config-thread
                v-if="canShowThread(threadId)"
                :thread="thread"
                :threadId="threadId"
                :lastMessage="messages[thread.last_message?.id] || {}"
                :pointOfSale="extractPointOfSaleById(pointOfSales, thread.pos.id)"
                :unreadMessagesCount="countUnreadMessagesForUserByThreadId(messages, authUser, threadId)"
            />
          </template>

          <v-alert
              v-if="Object.keys(threads).length === 0"
              type="warning"
              variant="tonal"
              :text="$t('post-it.config.message.no-threads')"
          />
        </div>
      </v-expand-transition>

    </div>
  </v-card>
  </div>
</template>

<script setup>
import PostItConfigThread from './PostItConfigThread.vue';
import { computed, inject, ref } from 'vue';
import { mdiCog } from '@mdi/js';
import ChoosePointOfSale from './point-of-sale/ChoosePointOfSale.vue';
import { useApplicationStore } from '@/stores/applicationStore';
import {
  filterThreadsByConfigId,
  extractPointOfSaleById,
  countUnreadMessagesForUserByThreadId,
  configurationByUserHasUnreadMessage,
  threadByUserHasUnreadMessage,
} from '@/stores/manager/postItManager';
import {
  mdiChevronUp,
  mdiChevronDown
} from '@mdi/js';

const props = defineProps({
  unReadOnly: {
    type: Boolean,
    required: false,
    default: false
  }
});

const applicationStore = useApplicationStore();
const authUser = computed(() => applicationStore.userDetails);
const messages = inject('messages');
const threads = inject('threads');
const pointOfSales = inject('pointOfSales');
const configurations = inject('configurations');
const hide = ref({});

function canShowThread(threadId) {
  return (!props.unReadOnly && !threadByUserHasUnreadMessage(messages.value, authUser.value, threadId)) ||
      (props.unReadOnly && threadByUserHasUnreadMessage(messages.value, authUser.value, threadId))
}

</script>
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { AuthRepository } from '@/repositories';
import notificationService from '@/services/notification';
import { extractConfigActiveAppStyle, extractConfigActiveAppLogo } from '@/stores/manager/configurationManager';
import dateHandler from '@/utils/dateHandler';
import router from '@/router';
import i18n from '../i18n'
import fr from 'moment/dist/locale/fr';
import en from 'moment/dist/locale/en-gb';

export const useApplicationStore = defineStore('applicationStore',
  () => {
    const storeCategories = {
      token: null,
      user: {},
      loggedIn: false,
      locale: null,
      appStyle: {},
      appLogo: null,
      showHiddenQuestions: false,
      showDebugMode: false,
      saveLogs: false,
      condensedForm: 1
    };

    const data = ref(storeCategories);

    async function loginUser(login, password) {
      try {
        const response = await AuthRepository.login(login, password);
        if (response.status === 200) {
          data.value.loggedIn = true;
          data.value.token = response.data.data.token;
          data.value.user  = response.data.data.user;
          data.value.user.login = login;

          updateLocale(response.data.data.locale || window.env.VITE_DEFAULT_LOCALE);
        }
        return true;
      } catch (error) {
        console.log(error.message);
        return false;
      }
    }

    function handleLogout() {
      resetStore();
      notificationService.logout();
      router.push({ path: '/login' });
    }

    function resetStore() {
      data.value.user = {};
      data.value.token = null;
      data.value.loggedIn = false;
      data.value.appStyle = extractConfigActiveAppStyle();
      data.value.appLogo = null;
    }

    function updateToken(newToken) {
      data.value.token = newToken;
    }

    function updateLocale(newLocale) {
      data.value.locale = newLocale;
      i18n.global.locale.value = newLocale;
      window.$moment.updateLocale(`${newLocale}`, newLocale);
    }

    function updateAppStyle(configurations) {
      data.value.appStyle = extractConfigActiveAppStyle(configurations);
      data.value.appLogo = extractConfigActiveAppLogo(configurations);
      console.log('[App style] ...updated..');
    }

    function updateShowHiddenQuestions(status) {
      data.value.showHiddenQuestions = status;
    }

    function updateShowDebugMode(status) {
      data.value.showDebugMode = status;
    }

    function updateSaveLogs(status) {
      data.value.saveLogs = status;
    }

    function updateCondensedForm(status) {
      data.value.condensedForm = status;
    }

    const location = ref({
      date: 0,
      latitude: 0,
      longitude: 0
    });

    const airplaneMode = ref(false);

    const lastSyncDate = ref(null);

    function updateLocation(locationData) {
      location.value = locationData;
    }

    function switchAirplaneMode(isActive) {
      airplaneMode.value = isActive;
    }

    function updateLastSyncDate() {
      lastSyncDate.value = dateHandler.generateNowUnixTimestamp();
    }

    const isLoggedIn = computed(() => data.value?.loggedIn || false);
    const userDetails = computed(() => data.value?.user || {});
    const userToken = computed(() => data.value?.token || null);
    const locale = computed(() => data.value?.locale || null);
    const accessControl = computed(() => data.value?.user?.access_control || {});
    const appStyle = computed(() => data.value?.appStyle || {});
    const appLogo = computed(() => data.value?.appLogo || null);
    const showHiddenQuestions = computed(() => data.value?.showHiddenQuestions || false);
    const showDebugMode = computed(() => data.value?.showDebugMode || false);
    const saveLogs = computed(() => data.value?.saveLogs || false);
    const condensedForm = computed(() => data.value?.condensedForm || 1);

    const syncStatus = ref({
      completeCycle: false,
      partialCycle: false,
    });

    return {
      data,
      isLoggedIn,
      userDetails,
      userToken,
      locale,
      accessControl,
      appStyle,
      appLogo,
      showHiddenQuestions,
      showDebugMode,
      saveLogs,
      condensedForm,
      location,
      loginUser,
      handleLogout,
      updateShowHiddenQuestions,
      updateSaveLogs,
      updateCondensedForm,
      updateShowDebugMode,
      updateToken,
      updateLocale,
      updateLocation,
      updateAppStyle,
      airplaneMode,
      switchAirplaneMode,
      lastSyncDate,
      updateLastSyncDate,
      syncStatus,
    }
  },
  {
    persistedState: {
      storage: localStorage,
      serialize: (value) => JSON.stringify(value),
      deserialize: (value) => JSON.parse(value),
      overwrite: true
    }
  }
);
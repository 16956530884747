import {defineStore} from 'pinia';
import {ref, computed} from 'vue';
import {ApiManager} from './api/ApiManager';
import {useApplicationStore} from "./applicationStore";

export const useReadingHistoryStore = defineStore(
  'readingHistoryStore',
  () => {
    const applicationStore = useApplicationStore();
    const userDetails = computed(() => applicationStore.userDetails || false);

    const storeCategories = {
      [userDetails.value.id]: {
        reading: [],
      }
    };

    const options = {
      apiManager: new ApiManager('packages/reading_history'),
      readonly: false,
      sync: true,
      allTime: false,
      persistentLocalId: false
    }

    const data = ref(storeCategories);

    const reading = computed(() => data.value[userDetails.value.id]?.reading || []);

    return {
      options,
      data,
      reading,
    }
  },
  {
    persistedState: {
      overwrite: true
    }
  }
);

export default {
  isInArray: function (value, array) {
    return array.indexOf(value) > -1;
  },
  isInArrayNotStrict: function (value, array) {
    return array.indexOf(value) > -1 ||
        array.indexOf(value.toString()) > -1 ||
        array.indexOf(parseInt(value)) > -1;
  },
  hasCommonElement: function (array1, array2) {
    return array1.some(element => array2.includes(element));
  }
}

export class OneSignalWebNotifications {

  isAvailable(){
    return true;
  }

  log(line){
    console.log(`[notifications onesignal web] ${line}`)
  }

  init = () => {
    this.log("initializing...")

    window.OneSignalDeferred = window.OneSignalDeferred || [];
    window.OneSignalDeferred.push(function(OneSignal) {
      OneSignal.init({
        appId: window.env.VITE_APP_ONESIGNAL_APP_ID || import.meta.env.VITE_APP_ONESIGNAL_APP_ID,
        serviceWorkerParam: { scope: '/push/onesignal/scope/' },
        serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js'
      });
    });

    window.OneSignalDeferred.push((OneSignal) => {
      this.log("requesting permission...")
      OneSignal.Notifications.requestPermission();
    });

    window.OneSignalDeferred.push((OneSignal) => {
      this.log("registering listeners...")
      //OneSignal.Notifications.addEventListener("foregroundWillDisplay", foregroundWillDisplayListener);
    });

  }

  login = (userId) => {
    this.log("login...")

    window.OneSignalDeferred.push((OneSignal) => {
      const isSupported = OneSignal.Notifications.isPushSupported();
      let hasNotifyPermission = OneSignal.Notifications.permission;
      if (isSupported && hasNotifyPermission) {
        OneSignal.login(userId);

        this.log(`...logged with user id: ${userId}`)
      } else {
        this.log('...failed to login')
      }
    });
  }

  logout = () => {
    this.log('logout...');

    window.OneSignalDeferred.push((OneSignal) => {
      this.log('...logout done.');
      OneSignal.logout();
    });
  }


}

<template>
  <v-btn
    :icon="mdiInformationSlabCircleOutline"
    :color="btnColor"
    @click="dialog = true"
  />

  <v-dialog
    v-model="dialog"
    width="auto"
    min-width="30%"
  >
    <v-card>
      <v-card-title>
        {{ $t('global.info-modal.title') }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-img
            height="150"
            src="/assets/logo.png"
        />
        <v-container>
          <div class="text-subtitle-1 mb-5">
            <span class="font-weight-bold">{{ $t('global.info-modal.label.last-sync') }}: </span>{{ lastSyncDateForHuman }}

            <template v-if="applicationStore.syncStatus.completeCycle">...</template>
            <template v-if="applicationStore.syncStatus.partialCycle">(...)</template>
          </div>

          <div class="text-subtitle-1 mb-2">
            <span class="font-weight-bold">{{ $t('global.info-modal.label.application-version') }}: </span>{{ buildDate }}
          </div>

          <div class="text-subtitle-1" style="display: flex; align-items: center;">
            <p class="font-weight-bold">{{ $t('global.info-modal.label.show-hidden-questions') }}: </p>
            <v-switch
              v-model="showHiddenQuestions"
              color="success"
              hide-details
              inset
              class="mx-2"
              @update:modelValue="changeShowHiddenQuestionsStatus"
            />
          </div>

          <div class="text-subtitle-1" style="display: flex; align-items: center;">
            <p class="font-weight-bold">{{ $t('global.info-modal.label.show-debug-mode') }}: </p>
            <v-switch
                v-model="showDebugMode"
                color="success"
                hide-details
                inset
                class="mx-2"
                @update:modelValue="changeShowDebugModeStatus"
            />
          </div>

          <div class="text-subtitle-1" style="display: flex; align-items: center;">
            <p class="font-weight-bold">{{ $t('global.info-modal.label.save-logs') }}: </p>
            <v-switch
                v-model="saveLogs"
                color="success"
                hide-details
                inset
                class="mx-2"
                @update:modelValue="changeSaveLogsStatus"
            />
            <v-btn
                variant="text"
                :icon="mdiBugOutline"
                @click="goToLogs"
            ></v-btn>
          </div>

          <div class="text-subtitle-1 mb-2">
            <span class="font-weight-bold">{{downloadedProductImages?.length}}/{{productImages?.length}}</span> {{ $t('global.info-modal.label.downloaded-prod-images') }}
          </div>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
            :disabled="!isConnectedAndOnline"
            variant="tonal"
            :loading="currentCycle"
            :prepend-icon="mdiSync"
            @click="processSync"
        >
          {{ $t('global.info-modal.label.synchro') }}
        </v-btn>
        <v-btn
            :disabled="!isConnectedAndOnline"
            variant="tonal"
            :loading="currentCycle"
            :prepend-icon="mdiContentSaveAll"
            @click="processSave"
        >
          {{ $t('global.info-modal.label.save') }}
        </v-btn>
        <v-btn
            v-if="hasLogs"
            variant="tonal"
            :loading="sendingLogs"
            :prepend-icon="mdiInvoiceTextSendOutline"
            @click="sendLogs"
        >
          {{ $t('global.info-modal.label.send-logs') }}
        </v-btn>

        <v-icon
            v-if="logSentSuccessfully"
            :icon="mdiCheck"
            color="success"></v-icon>
        <v-spacer />
        <v-btn
          class="float-right"
          color="grey-darken-2"
          :prepend-icon="mdiClose"
          variant="tonal"
          @click="dialog = false"
        >
          {{ $t('global.btn.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {
  mdiClose,
  mdiSync,
  mdiInformationSlabCircleOutline,
  mdiBugOutline,
  mdiInvoiceTextSendOutline,
  mdiCheck,
  mdiContentSaveAll
} from '@mdi/js';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useApplicationStore } from '@/stores/applicationStore';
import dateHandler from '@/utils/dateHandler';
import { useRouter } from 'vue-router';
import syncManager from "@/utils/sync/SyncManager";
import { useLogStore } from "@/stores/logStore";
import { liveQuery } from "dexie";
import { db } from '@/db.js';
import { useObservable }  from '@vueuse/rxjs';
import json from '@/buildinfo.json';

const props = defineProps({
  btnColor: {
    required: true
  },
  isOnline: {
    type: Boolean
  }
});

const { t } = useI18n();
const router = useRouter();
const applicationStore = useApplicationStore();
const logStore = useLogStore();
const buildDate = dateHandler.convertUnixTimestampToDateTime(json?.buildDateMs/1000)

const dialog = ref(false);
const sendingLogs = ref(false);
const logSentSuccessfully = ref(false);

const showHiddenQuestions = ref(applicationStore.showHiddenQuestions);
const showDebugMode = ref(applicationStore.showDebugMode);
const saveLogs = ref(applicationStore.saveLogs);
const userDetails = computed(() => applicationStore.userDetails || false);
const airplaneMode = computed(() => applicationStore.airplaneMode || false);
const productImages = useObservable( liveQuery (
        () => db.offlineImage.toArray()),
    {
      onError: (err) => {
        console.log(err.message)
      },
    });
const downloadedProductImages = useObservable( liveQuery (
    () => db.offlineImage
        .where({
          'downloaded': 1,
          'downloading': 0
        }).toArray()),
    {
      onError: (err) => {
        console.log(err.message)
      },
    });


const processSync = function(){
  syncManager.runCompleteCycle();
}

const processSave = function(){
  syncManager.pushAll();
}

const currentCycle = computed(() => {
  return applicationStore.syncStatus.completeCycle || applicationStore.syncStatus.partialCycle
})

const lastSyncDateForHuman = computed(() => {
  const date = applicationStore.lastSyncDate || null;
  return date ? dateHandler.convertUnixTimestampToStandardHuman(date) : t('global.text.none.m');
});

const hasLogs = computed(() => {
  return logStore?.logs?.[userDetails.value.id]?.length > 0;
});

const changeShowHiddenQuestionsStatus = (status) => {
  applicationStore.updateShowHiddenQuestions(status);
}

const changeShowDebugModeStatus = (status) => {
  applicationStore.updateShowDebugMode(status);
}

const changeSaveLogsStatus = (status) => {
  applicationStore.updateSaveLogs(status);
}
const goToLogs = () => {
  router.push({ name: 'logs' });
  dialog.value = false;
}

const sendLogs = async () => {
  sendingLogs.value = true;
  const response = await logStore.sendLogs();
  if (!response?.success) {
    console.error('Error sending logs', response?.data?.message || 'Unknown error');
  }
  else {
    logSentSuccessfully.value = true;
    logStore.clearLogs();
    setTimeout(() => {
      logSentSuccessfully.value = false;
    }, 3000);
  }
  sendingLogs.value = false;
}
const isConnectedAndOnline = computed(() => {
  return !airplaneMode.value && props.isOnline;
});

</script>
import {ApiManager} from "@/stores/api/ApiManager";

class BOAuthService {

  constructor(){
    this.url = "bo-auth"
  }

  async getMagicLink() {
    const apiManager = new ApiManager(this.url);
    return await apiManager.call();
  }
}

export default new BOAuthService()
<template>
  <template v-for="answerToQuestion in answersToQuestions">
    <v-card
      v-if="hasQuestionsNonNullValue(answerToQuestion?.questions)"
      variant="tonal"
      class="mb-5"
    >
      <v-card-title :style="{'color': appStyle.title_text_color}">
        <v-icon class="mx-3 my-1" size="small" :icon="mdiPlayCircle" />
        {{ answerToQuestion.name }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <question-answer-item
          v-for="item in answerToQuestion?.questions"
          :question="item.label"
          :answer="item.answer"
        />
      </v-card-text>
    </v-card>
  </template>
</template>

<script setup>
import { mdiPlayCircle } from '@mdi/js';
import { defineProps, inject, computed } from 'vue';
import {
  extractQuestionsWithRole,
  attachAnswersToQuestions,
  remapConfigQuestions
} from '@/stores/manager/readingHistoryManager';
import QuestionAnswerItem from "./QuestionAnswerItem.vue";
import { useApplicationStore } from '@/stores/applicationStore';
import { hasQuestionsNonNullValue } from '@/stores/manager/readingHistoryManager';

const props = defineProps({
  configuration: {
    type: Object,
    required: true
  },
});

const applicationStore = useApplicationStore();
const appStyle = computed(() => applicationStore.appStyle);

const lastReadingHistory = inject('lastReadingHistory', {});

const remappedConfigQuestions = remapConfigQuestions(
    extractQuestionsWithRole(Object.values(props.configuration?.questions || {}), 'app:visit-informations')
);

const answersToQuestions = attachAnswersToQuestions(remappedConfigQuestions, lastReadingHistory.value.answers);
</script>

<template>
  <span class="ml-3" v-if="question.history" :style="block?'display: block':''">
    <span
      v-if="valueFromHistory"
      class="text-grey-darken-1"
    >
    [{{ getHistoryValue(valueFromHistory, true) }}]
    </span>

    <v-tooltip
      v-if="valueFromHistory?.length > 10"
      location="top"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          variant="tonal"
          size="x-small"
          class="ml-1"
          icon
          v-bind="props"
        >
          <v-icon :icon="mdiHelp" />
        </v-btn>
      </template>
      <span>
        {{ getHistoryValue(valueFromHistory, false) }}
      </span>
    </v-tooltip>
  </span>
</template>

<script setup>
import { defineProps } from 'vue';
import _ from 'lodash';
import { QST } from '@/constants';
import { mdiHelp } from '@mdi/js';
import { extractAnswerFromReadingHistoryAnswers } from '@/stores/manager/readingHistoryManager';
import { useI18n } from "vue-i18n";

const props = defineProps({
  question: {
    type: Object,
    required: false
  },
  missionReadingHistory: {
    type: Object,
    required: false
  },
  attachmentType: {
    type: String,
    required: false
  },
  attachmentId: {
    type: [Number, String],
    required: false
  },
  block: {
    type: Boolean,
    required: false,
    default: false
  }
});

const { t } = useI18n();

const answer = extractAnswerFromReadingHistoryAnswers(props.missionReadingHistory?.answers, props.question.id, props.attachmentType, props.attachmentId);

let valueFromHistory = answer?.value || null;

if (props.question.data_type?.value === QST.INPUT.TYPE.YES_NO) {
  valueFromHistory = valueFromHistory ? (parseInt(valueFromHistory) ? t('global.text.bool.true') : t('global.text.bool.false')) : '';
}

function getHistoryValue(valueFromHistory = '', truncated = false) {
  if (truncated) {
    valueFromHistory = _.truncate(valueFromHistory, {'length': 13});
  }
  return props.question.history ? valueFromHistory : '';
}
</script>
<template>
  <v-dialog
      :model-value="showModal"
      @update:model-value="closeDialog()"
      max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span v-if="isNew" class="text-h5">{{ $t('contact.new-contact') }}</span>
        <span v-else class="text-h5">{{ $t('contact.edit-contact') }}</span>
      </v-card-title>

      <v-divider/>

      <v-card-text>
        <contact-form
            ref="contactForm"
            :contact="contact"
            :point-of-sale="pointOfSale"
            @updateContact="updateEditedContact"
        ></contact-form>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            variant="text"
            @click="closeDialog()"
        >
          {{ $t('global.btn.close') }}
        </v-btn>
        <v-btn
            color="blue-darken-1"
            variant="text"
            @click="saveContact"
        >
          {{ $t('global.btn.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue';
import ContactForm from "@/components/contact/contactForm.vue";
import { useStoreManager } from '@/stores/storeManager';

const props = defineProps({
  show: {
    type: Boolean,
    required: true
  },
  contact: {
    type: Object,
    required: false
  },
  pointOfSale: {
    type: Object,
    default: {},
    required: false
  },
  configuration: {
    type: Object,
    required: false
  }
});
const emit = defineEmits(['closeDialog']);

const storeManager = useStoreManager();
const editedContact = ref({});
const contactForm = ref(null);

function closeDialog() {
  emit('closeDialog');
}
function updateEditedContact(contact) {
  editedContact.value = contact;
}
function saveContact() {
  contactForm.value.validate().then((valid) => {
    if(valid.valid) {
      if (!editedContact.value.id) {
        editedContact.value.id = storeManager.add('mission', 'contacts', editedContact.value);
      }
      if (!editedContact.value?.configuration?.id) {
        editedContact.value.configuration = {id: parseInt(props.configuration.id)}
      }
      storeManager.update('mission', 'contacts', editedContact.value.id, editedContact.value)
      closeDialog()
    }
  })
}
const showModal = computed(() =>
    props.show
);

const isNew = computed(() =>
    props.contact?.id === undefined
);

</script>

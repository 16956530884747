<template>
  <div>
    <v-alert
      variant="text"
      class="mb-1 flex font-weight-bold"
      :icon="mdiPlayCircle"
      :color="readingStatus.color"
      density="compact"
    >
        {{ $t('mission-planning.statement-status.title') }}&nbsp;

      <reading-status-send-report
        v-if="reading.status === READING.STATUS.NEW && reading.counters?.countStatus === COUNTER.STATUS.READY"
        :reading="reading"
        :readingId="readingId"
      />
      <span v-else>{{ readingStatus.text }}</span>

    </v-alert>
  </div>

</template>

<script setup>
import { computed, inject } from 'vue';
import ReadingStatusSendReport from './modals/ReadingStatusSendReport.vue';
import { READING, COUNTER } from '@/constants';
import { mdiPlayCircle } from "@mdi/js";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps({
  readings: {
    type: Object,
    required: true,
  }
});
const readingId = inject('currentReadingId')

const reading = computed(() => props.readings[readingId.value] || {});

const currentReadingCounters = computed(() => {
  return reading.value?.counters || {};
});

const readingStatus = computed(() => {
  let text = t('mission-planning.statement-status.new');
  let color = 'blue-darken-3';
  if (currentReadingCounters.value?.countStatus === COUNTER.STATUS.NOT_READY) {
    text = t('mission-planning.statement-status.incomplete');
    color = 'deep-orange-darken-1';
  } else if (currentReadingCounters.value?.countStatus === COUNTER.STATUS.READY) {
    text = t('mission-planning.statement-status.ready');
    color = 'green-darken-3';
  }
  return {
    text,
    color
  }
})

</script>
<template>
  <v-container>
    <pos-card :pos="pos"/>
    <prepare-visit-card :missionPlanning="missionPlanning"/>
    <my-visit-card/>
  </v-container>
</template>
<script setup>
import {computed, inject} from "vue";
import { useStoreManager } from '@/stores/storeManager';
import PosCard from "../components/mission/details/PosCard.vue";
import PrepareVisitCard from "../components/mission/details/PrepareVisitCard.vue";
import MyVisitCard from "../components/mission/details/MyVisitCard.vue";

const currentMissionPlanningId = inject('currentMissionPlanningId');
const storeManager = useStoreManager();
const missionPlanning = computed(() =>
  storeManager.getFromStoreByCategory('mission', 'missionsPlannings')[currentMissionPlanningId.value] || {}
);
const mission = computed(() =>
  storeManager.getFromStoreByCategory('mission', 'missions')[missionPlanning.value.mission.id] || {}
);
const pos = computed(() =>
  storeManager.getFromStoreByCategory('mission', 'pointsOfSale')[mission.value.pos.id] || {}
);
</script>
<style>
.mission-block {
  position: relative;
  overflow: visible;
  border: 4px solid #282828;
  & .v-btn {
    border: 2px solid #282828;
  }
  & .block-title {
    position: absolute;
    top: -20px;
    left: 10px;
    background: white;
    padding: 5px 10px;
    font-size: 18px;
    font-weight: 700;
  }
}
</style>